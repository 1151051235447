import { combineReducers } from 'redux';
import { Inventory } from '../../appState';
import * as retrievalInventorylActionCreators from '../usecases/inventory-retrieval/actionCreators';

export const data = (
  state: Inventory | null = null,
  action: retrievalInventorylActionCreators.Actions,
) => {
  switch (action.type) {
    case 'INVENTORY_RETRIEVED':
      return action.payload.inventory;
    default: return state;
  }
};

const isLoading = (state: boolean = true, action: retrievalInventorylActionCreators.Actions) => {
  if (action.type === 'RETRIEVE_INVENTORY') { return true; }
  if (action.type === 'INVENTORY_RETRIEVED') { return false; }
  return state;
};

export const inventoryReducer = combineReducers({
  isLoading,
  data,
});
