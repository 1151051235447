import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { times } from 'lodash';
import { DropItem, DropType } from '../../../../../appState';

interface Props {
    drop: DropItem;
    dropsId: string;
    isSoldOut: boolean;
    currentLanguage: string;
    fullWidth?: boolean;
    dropType: DropType;
}

function CatalogAwardItem({
  drop, dropsId, isSoldOut, currentLanguage, fullWidth, dropType,
}: Props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1080px)' });
  const itemDescription = drop.name.split('/')[0];
  const itemBrand = drop.name.split('/')[1];
  return (
    <Link to={`/inventory/drop/buy/${dropType}/${dropsId}/${drop.id}`} className={`${isSoldOut && 'pointer-events-none'} w-full`}>
      <div className="relative flex flex-col gap-2" key={drop.id}>
        <div className={`bg-gray-500 relative flex justify-center items-center ${(!fullWidth || (fullWidth && isTabletOrMobile)) && 'aspect-square md:aspect-square '} ${isTabletOrMobile ? 'w-[90%]' : 'w-full'} bg-opacity-30 rounded-lg flex-col`}>
          <div className={`flex ${fullWidth ? 'w-96 justify-center items-center ' : 'w-full'} px-0`}>
            <img src={drop.image} className={`${fullWidth ? 'h-56 mb-8' : 'w-full'}`} alt={`${drop.id}`} />
            {isSoldOut && (
            <div className="absolute bottom-0 rounded-b-lg w-full md:w-full h-6 md:h-7 bg-blue-550">
              <div className="flex flex-row justify-center items-start overflow-hidden w-full h-full">
                <p className="text-white font-grotesk font-semibold text-md md:text-lg text-center">
                  {times(14, (index) => (
                    <span key={index}>
                      SOLD
                      {' '}
                      <span className="font-extrabold">
                        OUT
                      </span>
                      {' '}
                    </span>
                  ))}
                </p>
              </div>
            </div>
            )}
          </div>
          <div className="flex flex-row justify-start rounded-md py-2 px-2 absolute top-2 md:top-2 left-2">
            <p className={`${isSoldOut && 'line-through'} font-grotesk flex flex-row justify-center items-center gap-2 text-[14px] md:text-[22px] bg-black rounded-lg py-1 px-2 font-semibold text-white`}>
              {drop.price}
              {' '}
              {currentLanguage === 'en' ? '$' : '€'}
            </p>
          </div>
        </div>
        <div className="w-[90%] md:w-full h-full flex flex-col justify-start items-start">
          <p className="text-gray-350 font-grotesk font-semibold text-md md:text-xl text-start uppercase">{itemDescription}</p>
          <p className="font-inter font-medium text-md md:text-lg text-gray-450 text-center">{itemBrand}</p>
        </div>
      </div>
    </Link>
  );
}

export default CatalogAwardItem;
