import { combineReducers } from 'redux';
import { MadHunnyWhitelistCheck } from '../../appState';
import * as subscribeToMadHunnyWhitelistCheckActionCreators from '../usecases/madhunny-is-whitelist-check/actionCreators';

export const data = (
  state: MadHunnyWhitelistCheck | null = null,
  action: subscribeToMadHunnyWhitelistCheckActionCreators.Actions,
) => {
  switch (action.type) {
    case 'IS_MADHUNNY_WHITELIST_CHECKED':
      return action.payload.madHunnyWhitelistCheck;
    case 'CHECK_IS_MADHUNNY_WHITELIST':
      return null;
    default: return state;
  }
};

export const isLoading = (
  state: boolean = false,
  action: subscribeToMadHunnyWhitelistCheckActionCreators.Actions,
) => {
  switch (action.type) {
    case 'IS_MADHUNNY_WHITELIST_CHECKED':
      return false;
    case 'CHECK_IS_MADHUNNY_WHITELIST':
      return true;
    default: return state;
  }
};

export const isMadHunnyWhitelistCheckReducer = combineReducers({
  data,
  isLoading,
});
