import { Error } from '../../error';
import * as subscribeToEthereumWalletRetrievalActionCreators from '../usecases/ethereum-wallet-retrieval/actionCreators';
import * as subcribeToErrorActionCreators from '../usecases/error-clearing/actionCreators';
import * as subscribeToInventoryRetrievalActionCreators from '../usecases/inventory-retrieval/actionCreators';

export const errorReducer = (
  state: Error | null = null,
  action: subscribeToInventoryRetrievalActionCreators.Actions | subcribeToErrorActionCreators.Actions | subscribeToEthereumWalletRetrievalActionCreators.Actions,
) => {
  switch (action.type) {
    case 'RETRIEVE_INVENTORY_ERROR':
      return action.payload.error;
    case 'RETRIEVE_ETHERIUM_WALLET_ERROR':
      return action.payload.error;
    case 'CLEAR_ERROR':
      return null;
    default: return state;
  }
};
