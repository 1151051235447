import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import FlowerBannerSeparator from '../../../components/Staniflou/StaniflouBannerSeparator';
import FlowerDropShowcase from '../../../components/Staniflou/StaniflouDropShowcase';
import FlowerGridShowcase from '../../../components/Staniflou/StaniflouGridShowcase';
import FlowerBoxSlider from '../../../components/Staniflou/StaniflouBoxSlider';
import Header from '../../../_main/Header';
import { HalfArrowRightSvg } from '../../../icons/svg';
import ColorSelection from '../../../components/Collab/Landing/ColorSelection';
import NewFooter from '../../../components/Home/Sections/NewFooter';

const zenGardenDrop = {
  image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/metakicks-animations/landing/zengarden/desktop/zen-garden-01.jpg',
  imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/metakicks-animations/landing/zengarden/mobile/zen-garden-mobile-01.jpg',
  fromColor: 'from-[#4D640A]',
};

function StaniflouDropLanding() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="bg-[#606D30] relative">
      <Header />
      <div className="fixed w-full z-[100] px-4 lg:px-10 bottom-0 text-gray-350 py-2 bg-gray-950 bg-opacity-95 backdrop-blur-md  font-grotesk">
        <div className="flex flex-col lg:flex-row gap-2 mb-2 lg:mb-0 lg:justify-between lg:items-center w-full opacity-95">
          <div>
            <p className="text-2xl lg:text-3xl font-bold">
              ZEN GARDEN
            </p>
            <p className="font-inter text-md lg:text-xl font-medium lg:font-semibold">
              Staniflou x Sneakmart
            </p>
          </div>
          <Link to="buy" className="hover:scale-[98%] transition-transform duration-500">
            <div className="flex flex-row justify-center items-center gap-2 bg-[#606D30] hover:bg-white hover:text-[#606D30] py-2 md:px-10 font-bold rounded-lg text-xl lg:text-2xl">
              Sold out
              <HalfArrowRightSvg height={30} width={20} />
            </div>
          </Link>
        </div>
      </div>
      <div className="w-full">
        <div className="flex flex-col lg:flex-row mb-8 md:mb-14 gap-4 lg:gap-16">
          <div className="w-full">
            <div
              className="h-screen bg-cover bg-center"
              style={{
                backgroundImage: `url(${isTabletOrMobile ? zenGardenDrop.imageMobile : zenGardenDrop.image})`,
              }}
            >
              <div className="flex flex-col h-full justify-end text-gray-350 bg-gradient-to-t from-[#606D30] via-transparent to-transparent" />
            </div>
          </div>
        </div>
        <FlowerBannerSeparator text="ZEN GARDEN" />
        <FlowerDropShowcase />
        <div className="h-12 md:h-0" />
        <FlowerBoxSlider />
        <div className="mx-4 md:px-10">
          <ColorSelection />
          <div className="h-12" />
          <FlowerGridShowcase />
        </div>
        <div className="mx-4 lg:px-10 pb-32 md:pb-2">
          <NewFooter />
        </div>
      </div>
    </div>
  );
}

export default StaniflouDropLanding;
