import React from 'react';
import { useParams } from 'react-router-dom';
import LarveeIceNotFound from '../../../../components/Larvee/Ice/LarveeIceNotFound';
import LarveeIceDisplay from '../../../../components/Larvee/Ice/LarveeIceDisplay';

type UrlParams = 'larveeIceId'

function LarveeIceSingleItem() {
  const urlParams = useParams<Record<UrlParams, string>>();
  const larveeIceId = urlParams.larveeIceId || '';

  return (
    <div className="w-screen min-h-screen bg-black text-gray-350">
      <div className="pt-[40px] overflow-x-hidden">
        <div className="m-auto max-w-screen-xl px-4 lg:px-10 2xl:px-0 py-8 md:py-20">
          <div>
            <div>
              {Number(larveeIceId) < 1 || Number(larveeIceId) > 100 ? (
                <LarveeIceNotFound />
              ) : (
                <LarveeIceDisplay larveeEmeralId={larveeIceId} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LarveeIceSingleItem;
