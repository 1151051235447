import { combineReducers } from 'redux';
import { CreateStripeBuyingSession } from '../../appState';
import * as suscribeToCreateMadHunnyStripeBuyingSessionActionCreators from '../usecases/madhunny-stripe-buying-session-create/actionCreators';

const data = (
  state: CreateStripeBuyingSession | null = null,
  action: suscribeToCreateMadHunnyStripeBuyingSessionActionCreators.Actions,
) => {
  switch (action.type) {
    case 'MAD_HUNNY_STRIPE_BUYING_SESSION_CREATED':
      return { clientSecret: action.payload.clientSecret };
    default: return state;
  }
};

const isLoading = (
  state: boolean = false,
  action: suscribeToCreateMadHunnyStripeBuyingSessionActionCreators.Actions,
) => {
  switch (action.type) {
    case 'MAD_HUNNY_STRIPE_BUYING_SESSION_CREATED':
      return false;
    case 'CREATE_MAD_HUNNY_STRIPE_BUYING_SESSION':
      return true;
    case 'CREATE_MAD_HUNNY_STRIPE_BUYING_SESSION_ERROR':
      return false;
    default: return state;
  }
};

export const createMadHunnyStripeBuyingSessionReducer = combineReducers({
  data,
  isLoading,
});
