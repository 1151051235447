import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { PortalKeyInventoryItem } from '../../../../../appState';
import Button, { TextSize } from '../../../_main/Form/Button';
import AutomaticPlayer from '../../../_main/Video/AutomaticPlayer';

interface Props {
  portalKeys: PortalKeyInventoryItem[]
}

function PassList({ portalKeys }: Props) {
  return (
    <div data-testid="mystery-boxes-list" className="grid grid-cols-1 lg:grid-cols-3 gap-8">
      {portalKeys.map((portalKey) => (
        <div key={portalKey.id}>
          <Link to={`/inventory/portal/${portalKey.id}`}>
            <div className="bg-gray-200 relative">
              <div className="bg-blue-550 rotate-90 py-2 pr-3 pl-2 absolute left-[10px] top-[13px]">
                <p className="text-gray-350 font-extrabold text-[14px]">
                  Pass
                  #
                  {portalKey.id}
                </p>
              </div>
              <AutomaticPlayer>
                <ReactPlayer
                  url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/mtks-pass.mp4"
                  height="100%"
                  width="100%"
                  loop
                  muted
                  playsinline
                />
              </AutomaticPlayer>
            </div>
          </Link>
          <div className="mt-4">
            <a target="_blank" href={`https://opensea.io/assets/ethereum/${process.env.REACT_APP_PORTAL_KEY_CONTRACT_ADDRESS!}/${portalKey.id}`} rel="noreferrer">
              <Button textSize={TextSize.Small} text="View on Opensea" dark />
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PassList;
