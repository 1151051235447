import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import { HalfArrowRightSvg } from '../../icons/svg';

const asicsSizes = [
  {
    asicsId: 1,
    size: 42.5,
  },
  {
    asicsId: 2,
    size: 43,
  },
  {
    asicsId: 3,
    size: 48,
  },
  {
    asicsId: 4,
    size: 44,
  },
  {
    asicsId: 5,
    size: 44.5,
  },
  {
    asicsId: 6,
    size: 41.5,
  },
  {
    asicsId: 7,
    size: 43.5,
  },
  {
    asicsId: 8,
    size: 38,
  },
  {
    asicsId: 9,
    size: 45,
  },
  {
    asicsId: 10,
    size: 44.5,
  },
  {
    asicsId: 11,
    size: 44,
  },
  {
    asicsId: 12,
    size: 45,
  },
  {
    asicsId: 13,
    size: 42,
  },
  {
    asicsId: 14,
    size: 44.5,
  },
  {
    asicsId: 15,
    size: 42.5,
  },
  {
    asicsId: 16,
    size: 44,
  },
  {
    asicsId: 17,
    size: 41.5,
  },
  {
    asicsId: 18,
    size: 40.5,
  },
  {
    asicsId: 19,
    size: 40.5,
  },
  {
    asicsId: 20,
    size: 42.5,
  },
];

interface Props {
  metakicksAsicsId: string
}

function MetakicksAsicsDisplay({ metakicksAsicsId }: Props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1080px)' });
  const currentSize = asicsSizes.find((asicsSize) => asicsSize.asicsId === parseInt(metakicksAsicsId, 10));

  const renderDescription = () => (
    <div>
      {isTabletOrMobile && (
        <div className="text-2xl font-semibold mb-2">
          Description
        </div>
      )}
      <p className="text-gray-450 font-inter font-medium text-base md:text-base">
        The GEL NYC “KB to NY”, a unique journey between Kobe, Japan, where Asics was born in 1949, and New York, the home of streetwear and hip-hop culture.
        <br />
        <br />
        Each physical pair comes with a digital Sneakmart+ version to unlock unique perks within the Sneakmart ecosystem.
      </p>
    </div>
  );

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
      <div className="aspect-square rounded-xl overflow-hidden bg-gray-200 w-full">
        <ReactPlayer
          url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/nft-video-2.mp4"
          height="100%"
          width="100%"
          playing
          loop
          muted
          playsinline
        />
      </div>
      <div className="flex flex-col justify-end gap-6 lg:gap-3">
        <h1 className="text-3xl md:text-5xl text-gray-350 font-extrabold">
          GEL NYC “KB to NY”
          <div className="text-base text-gray-450 md:text-2xl font-medium">
            Sneakmart x JW Customs x Asics
          </div>
        </h1>
        {!isTabletOrMobile && (
          renderDescription()
        )}
        <div>
          <div className="w-full flex flex-row justify-between text-lg md:text-xl font-medium">
            <p className="text-xl font-bold font-grotesk">
              Number
            </p>
            {' '}
            <p className="text-gray-450">
              #
              {metakicksAsicsId}
            </p>
          </div>
          <div className="w-full flex flex-row justify-between text-lg md:text-xl font-medium">
            <p className="text-xl font-bold font-grotesk">
              Size
            </p>
            {' '}
            <div className="flex flex-row justify-between items-center gap-2">
              <p className="text-gray-450">
                {currentSize?.size}
                {' '}
                EU
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row justify-between text-lg md:text-xl font-medium">
            <p className="text-xl font-bold font-grotesk">
              Rarity
            </p>
            {' '}
            <div className="flex flex-row justify-between items-center gap-2">
              <div className="bg-orange-650 rounded-full w-3 h-3" />
              <p className="text-gray-450">
                Mythical
              </p>
            </div>
          </div>
        </div>
        <div className="w-full ">
          <Link to="/inventory">
            <div className="bg-brown-550 rounded-lg h-full py-1 flex justify-center items-center text-xl font-bold font-grotesk gap-2">
              Connect to inventory
              <HalfArrowRightSvg width={20} height={20} />
            </div>
          </Link>
        </div>
        {isTabletOrMobile && (
          renderDescription()
        )}
      </div>
    </div>
  );
}

export default MetakicksAsicsDisplay;
