import { ActionsUnion, createAction } from '../../../store';
import { StackingHistoryItem } from '../../../appState';

export const Actions = {
  retrieveStackingHistory: () => createAction('RETRIEVE_STACKING_HISTORY'),
  stackingHistoryRetrieved: (history: StackingHistoryItem[] | null) => createAction('STACKING_HISTORY_RETRIEVED', { history }),

};

export type Actions = ActionsUnion<typeof Actions>
