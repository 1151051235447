import { EthereumWalletGateway } from '../../gateways/ethereumWalletGateway';
import { ThunkResult } from '../../../store';
import { Actions } from './actionCreators';
import { Integration } from '../../../appState';

export const retrieveEthereumWallet: (integration: Integration) => ThunkResult<Promise<void>> = (integration) => async (
  dispatch,
  getState,
  { ethereumWalletGateway }: {
    ethereumWalletGateway: EthereumWalletGateway
  },
) => {
  let ethereumWallet = null;

  if (integration === Integration.Crossmint) {
    ethereumWallet = await ethereumWalletGateway.retrieveCrossmint();
  } else {
    ethereumWallet = await ethereumWalletGateway.retrieve(integration);
  }

  dispatch(Actions.ethereumWalletRetrieved(ethereumWallet.orDefault(null)));

  ethereumWallet.ifLeft((err) => {
    dispatch(Actions.retrieveEthereumWalletError(err));
  });
};
