import { ThunkResult } from '../../../store';
import { EthereumWalletGateway } from '../../gateways/ethereumWalletGateway';
import * as actionCreators from './actionCreators';

export const deconnectEthereumWallet: ThunkResult<Promise<void>> = async (
  dispatch,
  getState,
  { ethereumWalletGateway }: {
    ethereumWalletGateway: EthereumWalletGateway
  },
) => {
  await ethereumWalletGateway.disconnect();
  dispatch(actionCreators.Actions.deconnectEthereumWallet());
};
