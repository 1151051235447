import { combineReducers } from 'redux';
import { Stacking } from '../../appState';
import * as subcribeToStackingAwardRedeemActionCreators from '../usecases/stacking-award-redeem/actionCreators';

export const data = (
  state: Stacking | null = null,
  action: subcribeToStackingAwardRedeemActionCreators.Actions,
) => {
  switch (action.type) {
    case 'STACKING_AWARD_REDEEMED':
      return { stripeUrl: action.payload.stripeUrl, award: action.payload.award };
    default:
      return state;
  }
};

export const isLoading = (state: boolean = false, action: subcribeToStackingAwardRedeemActionCreators.Actions) => {
  switch (action.type) {
    case 'REDEEM_STACKING_AWARD':
      return true;
    case 'STACKING_AWARD_REDEEMED':
      return false;
    default:
      return state;
  }
};

export const currentStackingAwardRedeemReducer = combineReducers({
  isLoading,
  data,
});
