import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { retrieveInventory } from '../../../../../core/usecases/inventory-retrieval/retrieveInventory';
import { selectEthereumWalletVM } from '../../../../view-models-generation/generateEthereumWalletViewModel';
import FlowerLoading from './FlowerLoading';
import { selectFlower } from '../../../../view-models-generation/generateInventoryViewModel';
import FlowerDisplay from './FlowerDisplay';
import FlowerNotFound from './FlowerNotFound';

type UrlParams = 'flowerId'

function FlowerItem() {
  const dispatch = useDispatch();
  const urlParams = useParams<Record<UrlParams, string>>();

  const flowerId = urlParams.flowerId || '';

  const { isLoading, flower } = useSelector(selectFlower(flowerId));
  const { address } = useSelector(selectEthereumWalletVM);

  useEffect(() => {
    dispatch(retrieveInventory);
  }, [dispatch, address]);

  return (
    <div>
      <div>
        <h1 className="text-gray-350 text-[25px] lg:text-[90px] mb-6 font-extrabold">
          MY MAD HUNNY
        </h1>
        {isLoading ? <FlowerLoading /> : !flower ? <FlowerNotFound /> : (
          <FlowerDisplay flowerId={flowerId} />
        )}
      </div>
    </div>
  );
}

export default FlowerItem;
