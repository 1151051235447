import i18next from 'i18next';
import en from './en.json';
import fr from './fr.json';

const resources = {
  en: { common: en },
  fr: { common: fr },
};

export const initi18n = (plugin: any, extraConfig: any = {}, languageDetectorPlugin?: any) => {
  i18next
    .use(plugin)
    .use(languageDetectorPlugin)
    .init({
      debug: false,
      resources,
      ns: ['common'],
      defaultNS: 'common',
      fallbackLng: 'en',
      supportedLngs: ['en', 'fr'],
      ...extraConfig,
    });

  return i18next;
};
