import { combineReducers } from 'redux';
import { Stacking } from '../../appState';
import * as subscribeToStackingHistoryActionCreators from '../usecases/stacking-history-retrieval/actionCreators';

export const data = (
  state: Stacking | null = null,
  action: subscribeToStackingHistoryActionCreators.Actions,
) => {
  switch (action.type) {
    case 'STACKING_HISTORY_RETRIEVED':
      return action.payload.history;
    default:
      return state;
  }
};

export const isLoading = (state: boolean = false, action: subscribeToStackingHistoryActionCreators.Actions) => {
  switch (action.type) {
    case 'RETRIEVE_STACKING_HISTORY':
      return true;
    case 'STACKING_HISTORY_RETRIEVED':
      return false;
    default:
      return state;
  }
};

export const stackingHistoryReducer = combineReducers({
  isLoading,
  data,
});
