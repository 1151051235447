import { ThunkResult } from '../../../store';
import { StackingGateway } from '../../gateways/stackingGateway';
import { Actions } from './actionCreators';

export const stackStanKey = ({ stanId, walletAddress }: {stanId: string, walletAddress: string}): ThunkResult<Promise<void>> => async (
  dispatch,
  getState,
  { stackingGateway }: {
    stackingGateway: StackingGateway,
  },
) => {
  dispatch(Actions.stackStanKey(stanId));

  await stackingGateway.stackStanKey(stanId, walletAddress);

  dispatch(Actions.stanKeyStacked(stanId));
};
