import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ReactPlayer from 'react-player';

import PortalKeyBoxSlider from './PortalKeyBoxSlider';
import AutomaticPlayer from '../../_main/Video/AutomaticPlayer';

const photos = [
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-showcase-2.png',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-showcase-1.png',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-showcase-4.png',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-showcase-3.png',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-showcase-5.png',
];

const textPoints = [
  'Holders only monthly physical sneakers & streetwear airdrops: we will distribute physical items for free to the holders per month to be available in your inventory.',
  'Holders only monthly physical sneakers retail drops: holders will get a chance to buy a selection of highly coveted sneakers at retail price, each month.',
  '50% reduction on the mint price for the 1st main Metakicks boxes drop.',
  'Exclusive airdrops (NFTs) and cumulative rewards on the long term.',
];

const photoOverlay = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-sold-out.png';

function PortalKeyDropShowcase() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  return (
    <div className="flex flex-col">
      <div className="z-50 flex justify-center">
        <img
          src={photoOverlay}
          alt=""
          className="z-50 w-[380px] md:w-[1000px] mt-[-40px] md:mt-[-150px]"
        />
      </div>
      <div className="flex flex-col items-center mx-4 md:mx-16 pt-0 text-gray-350 font-inter 2xl:mx-[100px]">
        <div className="flex flex-col md:flex-row gap-4 md:gap-8 xl:mx-[5vw] 2xl:gap-28 2xl:mx-[7vw]">
          {isTabletOrMobile && (
            <div className="rounded-xl overflow-hidden">
              <AutomaticPlayer>
                <ReactPlayer
                  url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/mtks-pass.mp4"
                  height="100%"
                  width="100%"
                  loop
                  muted
                  playsinline
                />
              </AutomaticPlayer>
            </div>
          )}
          <div className="flex flex-col md:mb-72 ">
            <p className="font-grotesk text-4xl font-bold">
              PORTAL KEY
            </p>
            <div className="text-xl pt-4">
              <p className="mb-4">
                Forged from the rarest crystals known in the Metakicks universe, the first element giving access to the Metakicks benefits is presented in the form of a key.
                <br />
                <br />
                Allowing the beholder to open up the Metakicks Portal and visit unknown universes unknown to humankind...
                <br />
                <br />
                You may even be able to bring a little something with yourself on your way back.
              </p>
            </div>
          </div>
          {!isTabletOrMobile && (
            <div className="rounded-xl overflow-hidden">
              <AutomaticPlayer>
                <ReactPlayer
                  url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/mtks-pass.mp4"
                  height="100%"
                  width="100%"
                  loop
                  muted
                  playsinline
                />
              </AutomaticPlayer>
            </div>
          )}
        </div>
        {!isTabletOrMobile && (
        <div className="flex flex-col gap-4 md:gap-0 pt-4 md:pt-0 md:flex-row">
          <img src={photos[1]} alt="" className="rounded-lg h-full md:h-[600px] 2xl:h-[700px] md:relative z-[20] md:mt-[-250px] md:ml-8 2xl:mt-[-350px] 2xl:ml-[0px] hover:scale-[98%] transition-transform duration-500" />
          <img src={photos[2]} alt="" className="rounded-lg h-full md:h-[500px] 2xl:h-[600px] md:relative z-[49] md:right-4 md:mt-[-100px] lg:mt-[-20px] 2xl:mt-[-50px] hover:scale-[98%] transition-transform duration-500" />
        </div>
        )}
        <div className="flex flex-col md:flex-col gap-4 md:pt-4">
          {!isTabletOrMobile ? (
            <div className="flex gap-4 md:gap-0 pt-4 md:pt-0 md:flex-row">
              <img src={photos[3]} alt="" className="rounded-lg h-full md:h-[600px] 2xl:h-[800px] md:relative md:mt-[-150px] 2xl:mt-[-350px] 2xl:ml-[100px] hover:scale-[98%] transition-transform duration-500" />
            </div>
          ) : (<PortalKeyBoxSlider />)}
          <div className="flex flex-col md:flex-row items-center justify-center gap-4">
            <div className="flex flex-col px-4 md:ml-16 2xl:ml-[6vw]">
              <p className="font-grotesk text-4xl font-bold">
                MADE FOR SNEAKER FANATICS
              </p>
              <div className="text-xl pt-4">
                <p className="mb-6">
                  By holding the Portal Key you will access:
                </p>
                {textPoints.map((point, index) => (
                  <div key={index} className="flex flex-row text-lg items-center gap-2">
                    <li className="mb-4">
                      {point}
                    </li>
                  </div>
                ))}
                <p />
              </div>
            </div>
            {!isTabletOrMobile && (
              <img src={photos[4]} alt="" className="rounded-lg h-full 2xl:h-[800px] z-[60] md:h-[50vw] relative md:mr-[-20px] 2xl:mr-0 2xl:mt-[-300px] md:mt-[-30vh]  hover:scale-[98%] transition-transform duration-500" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortalKeyDropShowcase;
