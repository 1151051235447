import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSwitch() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const onClick = (newLanguage: string) => () => {
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div className="flex flex-row border-2 border-white p-1 rounded-lg">
      <div className={`absolute w-8 h-[20px] bg-blue-550 rounded-sm transition-all duration-500 ${currentLanguage === 'en' ? 'translate-x-8' : ''}`} />
      <div className="flex flex-row relative h-5">
        <div className="flex justify-center items-center w-8 pb-2 md:pb-0">
          <button onClick={onClick('fr')} type="button">
            <span className="text-gray-350 font-extrabold text-sm cursor-pointer leading-none z-10">FR</span>
          </button>
        </div>
        <div className="flex justify-center items-center w-8 pb-2 md:pb-0">
          <button onClick={onClick('en')} type="button">
            <span className="text-gray-350 font-extrabold text-sm cursor-pointer leading-none z-10">EN</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default LanguageSwitch;
