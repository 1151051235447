import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../_main/Form/Button';

function PortalKeyNotFound() {
  return (
    <div data-testid="portal-key-empty" className="w-full lg:w-3/6 pb-[30px] lg:pb-[130px]">
      <p className="text-lg lg:text-[24px]">
        Sorry, we couldn
        {'\''}
        t find the page you
        {'\''}
        re asking 🤷
      </p>
      <div className="mt-6 max-w-[400px]">
        <Link to="/inventory/portal-keys">
          <Button text="Go back to inventory" dark />
        </Link>
      </div>
    </div>
  );
}

export default PortalKeyNotFound;
