import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../_main/Form/Button';

function MetakicksStaniflouNotFound() {
  return (
    <div data-testid="portal-key-empty" className="w-full lg:w-5/6 pb-8 lg:pb-16">
      <p className="mt-12 text-lg md:text-4xl">
        Sorry, the Sneakmart x Staniflou 1906D you requested does not exist.
      </p>
      <div className="mt-12 lg:mt-36 max-w-[400px]">
        <Link to="/inventory/portal-keys">
          <Button text="Go back to inventory" />
        </Link>
      </div>
    </div>
  );
}

export default MetakicksStaniflouNotFound;
