import React, { useEffect } from 'react';
import shallow from 'zustand/shallow';
import isEmpty from 'lodash/isEmpty';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { useStackingStore } from '../../../store/stackingStore';
import {
  FlowerStackingItem, jwasicsStackingItem, StackingPortalKey, StaniflouColorway, StansStackingItem,
} from '../../../../../appState';
import StackingEmpty from './StackingEmpty';
import { CheckMarkStackingSvg, KicksLogoSvg } from '../../../icons/svg';
import {
  selectFlowerKeyAwardVM, selectjwasicsKeyAwardVM, selectPortalkeyAwardVM, selectStackingVM, selectStanKeyAwardVM,
} from '../../../../view-models-generation/generateStackingViewModel';
import { stackPortalKey } from '../../../../../core/usecases/portal-key-stacking/stackPortalKey';
import { unstackPortalKey } from '../../../../../core/usecases/portal-key-unstacking/unstackPortalKey';
import AutomaticPlayer from '../../../_main/Video/AutomaticPlayer';
import { stackFlowerKey } from '../../../../../core/usecases/flower-key-stacking/stackFlowerKey';
import { unstackFlowerKey } from '../../../../../core/usecases/flower-key-unstacking/unstackFlowerKey';
import { stackStanKey } from '../../../../../core/usecases/stan-key-stacking/stackStanKey';
import { unstackStanKey } from '../../../../../core/usecases/stan-key-unstacking/unstackStanKey';
import { selectEthereumWalletVM } from '../../../../view-models-generation/generateEthereumWalletViewModel';
import { stackjwasicsKey } from '../../../../../core/usecases/jwasics-key-stacking/stackJwasicsKey';
import { unstackjwasicsKey } from '../../../../../core/usecases/jwasics-key-unstacking/unstackJwasicsKey';

const colorways = {
  [StaniflouColorway.MOCHA]: {
    name: 'mocha',
    value: StaniflouColorway.MOCHA,
    hex: [
      '#56421E',
      '#EFEAD5',
      '#A89B62',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-COFFEE.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-COFFEESCENE.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-COFFEE-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-COFFEE.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-COFFEE-COLORWAY.webp',
  },
  [StaniflouColorway.SAKURA]: {
    name: 'sakura',
    value: StaniflouColorway.SAKURA,
    hex: [
      '#EFBABA',
      '#FFE4E3',
      '#72265D',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-CHERRY.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-CHERRY.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-CHERRY-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-CHERRY.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-CHERRY-COLORWAY.webp',
  },
  [StaniflouColorway.GUMMY]: {
    name: 'gummy',
    value: StaniflouColorway.GUMMY,
    hex: [
      '#F7F1CF',
      '#BC879D',
      '#81B084',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-JADED.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-JADED.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-JADED-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-JADED.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-JADED-COLORWAY.webp',
  },
  [StaniflouColorway.MATCHA]: {
    name: 'matcha',
    value: StaniflouColorway.MATCHA,
    hex: [
      '#FEE4E4',
      '#A6995F',
      '#752A76',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-ROCKY.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-ROCKY.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-ROCKY-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-ROCKY.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-ROCKY-COLORWAY.webp',
  },
  [StaniflouColorway.AQUA]: {
    name: 'aqua',
    value: StaniflouColorway.AQUA,
    hex: [
      '#B8D7EB',
      '#EACDAB',
      '#003C67',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-DESERT.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-DESERT.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-DESERT-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-DESERT.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-DESERT-COLORWAY.webp',
  },
};

interface Props {
  portalKeys?: StackingPortalKey[] | [] | null;
  flowers?: FlowerStackingItem[] | null;
  stans?: StansStackingItem[] | null;
  jwasics?: jwasicsStackingItem[] | null;
}

function StackingCurrentInformation({
  portalKeys, flowers, stans, jwasics,
}: Props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const dispatch = useDispatch();
  const { stacking } = useSelector(selectStackingVM);
  const { address } = useSelector(selectEthereumWalletVM);

  const { currentPortalKeyIdViewing, updateCurrentPortalKeyViewing } = useStackingStore((state) => ({ currentPortalKeyIdViewing: state.currentPortalKeyIdViewing, updateCurrentPortalKeyViewing: state.updateCurrentPortalKeyViewing }), shallow);
  const { currentFlowerKeyIdViewing, updateCurrentFlowerKeyViewing } = useStackingStore((state) => ({ currentFlowerKeyIdViewing: state.currentFlowerKeyIdViewing, updateCurrentFlowerKeyViewing: state.updateCurrentFlowerKeyViewing }), shallow);
  const { currentStansKeyIdViewing, updateCurrentStansKeyViewing } = useStackingStore((state) => ({ currentStansKeyIdViewing: state.currentStansKeyIdViewing, updateCurrentStansKeyViewing: state.updateCurrentStansKeyViewing }), shallow);
  const { currentjwasicsKeyIdViewing, updateCurrentjwasicsKeyViewing } = useStackingStore((state) => ({ currentjwasicsKeyIdViewing: state.currentjwasicsKeyIdViewing, updateCurrentjwasicsKeyViewing: state.updateCurrentjwasicsKeyViewing }), shallow);

  const { updateCurrentStansKeyColorwayViewing } = useStackingStore((state) => ({ currentStansKeyColorwayViewing: state.currentStansKeyColorwayViewing, updateCurrentStansKeyColorwayViewing: state.updateCurrentStansKeyColorwayViewing }), shallow);

  const { portalKey } = useSelector(selectPortalkeyAwardVM(currentPortalKeyIdViewing));
  const { flowerKey } = useSelector(selectFlowerKeyAwardVM(currentFlowerKeyIdViewing));
  const { stanKey } = useSelector(selectStanKeyAwardVM(currentStansKeyIdViewing));
  const { jwAsicKey } = useSelector(selectjwasicsKeyAwardVM(currentjwasicsKeyIdViewing));

  const onUpdateCurrentPortalKeyIndexSelected = (newPortalkeyId: string) => () => {
    updateCurrentPortalKeyViewing(newPortalkeyId);
    updateCurrentFlowerKeyViewing('');
    updateCurrentjwasicsKeyViewing('');
    updateCurrentStansKeyViewing('');
  };

  const onUpdateCurrentFlowerKeyIndexSelected = (newFlowerkeyId: string) => () => {
    updateCurrentFlowerKeyViewing(newFlowerkeyId);
    updateCurrentPortalKeyViewing('');
    updateCurrentStansKeyViewing('');
    updateCurrentjwasicsKeyViewing('');
  };

  const onUpdateCurrentjwasicsKeyIndexSelected = (newjwasicskeyId: string) => () => {
    updateCurrentjwasicsKeyViewing(newjwasicskeyId);
    updateCurrentFlowerKeyViewing('');
    updateCurrentPortalKeyViewing('');
    updateCurrentStansKeyViewing('');
  };

  const onUpdateCurrentStansKeyIndexSelected = (newStanskeyId: string) => () => {
    updateCurrentStansKeyViewing(newStanskeyId);
    updateCurrentStansKeyColorwayViewing(colorways[stans?.find((stan) => stan.stanId === newStanskeyId)?.colorway!].value);
    updateCurrentPortalKeyViewing('');
    updateCurrentFlowerKeyViewing('');
    updateCurrentjwasicsKeyViewing('');
  };

  const onStackPortalKey = () => {
    if (!currentPortalKeyIdViewing || !address) return;
    dispatch(stackPortalKey({ portalKeyId: currentPortalKeyIdViewing, walletAddress: address! }));
  };

  const onUnstackPortalKey = () => {
    if (!currentPortalKeyIdViewing || !address) return;
    dispatch(unstackPortalKey({ portalKeyId: currentPortalKeyIdViewing, walletAddress: address! }));
  };

  const onStackFlowerKey = () => {
    if (!currentFlowerKeyIdViewing || !address) return;
    dispatch(stackFlowerKey({ flowerId: currentFlowerKeyIdViewing, walletAddress: address! }));
  };

  const onUnstackFlowerKey = () => {
    if (!currentFlowerKeyIdViewing || !address) return;
    dispatch(unstackFlowerKey({ flowerId: currentFlowerKeyIdViewing, walletAddress: address! }));
  };

  const onStackjwasicsKey = () => {
    if (!currentjwasicsKeyIdViewing || !address) return;
    dispatch(stackjwasicsKey({ jwasicsId: currentjwasicsKeyIdViewing, walletAddress: address! }));
  };

  const onUnstackjwasicsKey = () => {
    if (!currentjwasicsKeyIdViewing || !address) return;
    dispatch(unstackjwasicsKey({ jwasicsId: currentjwasicsKeyIdViewing, walletAddress: address! }));
  };

  const onStackStanKey = () => {
    if (!currentStansKeyIdViewing || !address) return;
    dispatch(stackStanKey({ stanId: currentStansKeyIdViewing, walletAddress: address! }));
  };

  const onUnstackStanKey = () => {
    if (!currentStansKeyIdViewing || !address) return;
    dispatch(unstackStanKey({ stanId: currentStansKeyIdViewing, walletAddress: address! }));
  };

  const renderPortalKeyChoice = () => (
    <div className="grid w-full grid-rows-1 md:grid-cols-2 gap-3">
      <button
        onClick={currentPortalKeyIdViewing ? (portalKey?.isCurrentlyStacked ? onUnstackPortalKey : onStackPortalKey) : currentFlowerKeyIdViewing ? (flowerKey?.isCurrentlyStacked ? onUnstackFlowerKey : onStackFlowerKey)
          : currentStansKeyIdViewing ? (stanKey?.isCurrentlyStacked ? onUnstackStanKey : onStackStanKey) : (jwAsicKey?.isCurrentlyStacked ? onUnstackjwasicsKey : onStackjwasicsKey)}
        className={`${(portalKey?.isCurrentlyStacked || flowerKey?.isCurrentlyStacked || stanKey?.isCurrentlyStacked || jwAsicKey?.isCurrentlyStacked) ? 'bg-pink-550 text-gray-350' : 'bg-green-550 text-gray-950'} rounded-lg h-full w-full flex items-center justify-center`}
      >
        <h1 className=" text-[22px] text-center mx-2 md:mx-0 md:py-2 font-bold">
          {(portalKey?.isCurrentlyStacked || flowerKey?.isCurrentlyStacked || stanKey?.isCurrentlyStacked || jwAsicKey?.isCurrentlyStacked) ? 'UNSTOCK' : 'STOCK'}
        </h1>
      </button>
      <a
        target="_blank"
        href={
          currentPortalKeyIdViewing ? `https://opensea.io/assets/ethereum/${process.env.REACT_APP_PORTAL_KEY_CONTRACT_ADDRESS!}/${currentPortalKeyIdViewing}`
            : currentFlowerKeyIdViewing ? `https://opensea.io/assets/matic/${process.env.REACT_APP_MAD_HUNNY_CONTRACT_ADRESS_PROD!}/${currentFlowerKeyIdViewing}` : currentStansKeyIdViewing
              ? `https://opensea.io/assets/ethereum/${process.env.REACT_APP_STANIFLOU_CONTRACT_ADDRESS!}/${currentStansKeyIdViewing}` : `https://opensea.io/assets/ethereum/${process.env.REACT_APP_JW_ASICS_CONTRACT_ADDRESS!}/${currentjwasicsKeyIdViewing}`
        }
        rel="noreferrer"
        className="border-2 border-gray-350 rounded-lg bg-gray-950 h-full w-full flex items-center justify-center"
      >
        <h1 className="text-gray-350 text-[22px] text-center mx-2 md:mx-0 md:py-2 font-bold">VIEW ON OPENSEA</h1>
      </a>
    </div>
  );

  const renderLine = ({ info, balance }: { info: string, balance: number | undefined }) => (
    <div className="text-gray-350 w-full">
      <div className="flex flex-row justify-between font-grotesk">
        <p className="text-2xl w-[70%] font-semibold">
          {info}
        </p>
        <p className="flex flex-row gap-2 justify-end items-center text-2xl w-[50%] align-center md:text-[40px] font-semibold">
          {balance}
          <span>
            <KicksLogoSvg width={20} />
            {' '}
          </span>
        </p>
      </div>
    </div>
  );

  useEffect(() => {
    if (!isEmpty(portalKeys) && !currentPortalKeyIdViewing) {
      updateCurrentPortalKeyViewing(portalKeys![0]?.portalKeyId);
      updateCurrentFlowerKeyViewing('');
      updateCurrentStansKeyViewing('');
    } else if (!isEmpty(flowers) && !currentFlowerKeyIdViewing) {
      updateCurrentFlowerKeyViewing(flowers![0]?.flowerId);
      updateCurrentPortalKeyViewing('');
      updateCurrentStansKeyViewing('');
    } else if (!isEmpty(stans) && !currentStansKeyIdViewing) {
      updateCurrentStansKeyViewing(stans![0]?.stanId);
      updateCurrentStansKeyColorwayViewing(colorways[stans![0]?.colorway!].value);
      updateCurrentPortalKeyViewing('');
      updateCurrentFlowerKeyViewing('');
    } else if (!isEmpty(jwasics) && !currentjwasicsKeyIdViewing) {
      updateCurrentjwasicsKeyViewing(jwasics![0]?.jwasicsId);
      updateCurrentFlowerKeyViewing('');
      updateCurrentPortalKeyViewing('');
      updateCurrentStansKeyViewing('');
    }
  }, [portalKeys, flowers, stans, jwasics]);

  return (
    <div className="flex flex-col justify-between w-full h-full">
      <div>
        <div className="font-grotesk text-gray-350 text-3xl md:text-5xl font-semibold">
          MY ASSETS
        </div>
        {!isEmpty(portalKeys) || !isEmpty(flowers) || !isEmpty(stans) || !isEmpty(jwasics) ? (
          <div className="grid grid-cols-2 gap-4 mt-6  md:flex flex-row flex-wrap mb-4 md:mb-4">
            {portalKeys?.map((portalKey) => (
              <div key={portalKey.portalKeyId} className="w-full md:w-[31%] relative">
                <div>
                  <div className={`${portalKey?.isPremium ? 'bg-orange-550' : 'bg-gray-950'} rounded-md py-1 mt-0 px-2 absolute top-2 md:top-[14px] left-2`}>
                    <p className="text-gray-350 font-extrabold text-sm">
                      #
                      {portalKey.portalKeyId}
                    </p>
                  </div>
                  {portalKey?.isCurrentlyStacked && (
                  <div className=" bg-gray-950 rounded-md py-1 mt-0 px-2 absolute top-2 md:top-[14px] right-4">
                    <CheckMarkStackingSvg height={20} />
                  </div>
                  )}
                  {portalKey?.isCurrentlyStacked && portalKey?.currentLevel! >= 1 && (
                  <div className=" bg-gray-950 text-sm text-gray-350 font-bold rounded-md py-1 px-2 absolute bottom-4 left-2">
                    Level
                    {' '}
                    {portalKey?.currentLevel}
                  </div>
                  )}
                </div>
                <button
                  key={portalKey.portalKeyId}
                  onClick={onUpdateCurrentPortalKeyIndexSelected(portalKey.portalKeyId)}
                  className={`${currentPortalKeyIdViewing === portalKey.portalKeyId ? 'ring-green-550 ring-4 rounded-lg' : ''}`}
                >
                  <img src={portalKey.isPremium ? 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/portal-key-premium.jpg' : 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/images/mtks-pass-1.webp'} alt="portal-key" className="aspect-square rounded-lg " />
                </button>
              </div>
            ))}
            {flowers?.map((flower) => (
              <div key={flower.flowerId} className="w-full md:w-[31%] relative">
                <div>
                  <div className="bg-gray-950 rounded-md py-1 mt-0 px-2 absolute top-2 md:top-[14px] left-2">
                    <p className="text-gray-350 font-extrabold text-sm">
                      #
                      {flower.flowerId}
                    </p>
                  </div>
                  {flower?.isCurrentlyStacked && (
                  <div className=" bg-gray-950 rounded-md py-1 mt-0 px-2 absolute top-2 md:top-[14px] right-4">
                    <CheckMarkStackingSvg height={20} />
                  </div>
                  )}
                  {flower?.isCurrentlyStacked && flower?.currentLevel! >= 1 && (
                  <div className=" bg-gray-950 text-sm text-gray-350 font-bold rounded-md py-1 px-2 absolute bottom-4 left-2">
                    Level
                    {' '}
                    {flower?.currentLevel}
                  </div>
                  )}
                </div>
                <button
                  key={flower.flowerId}
                  onClick={onUpdateCurrentFlowerKeyIndexSelected(flower.flowerId)}
                  className={`${currentFlowerKeyIdViewing === flower.flowerId ? 'ring-green-550 ring-4 rounded-lg' : ''}`}
                >
                  <div className="aspect-square rounded-lg overflow-hidden">
                    <AutomaticPlayer>
                      <ReactPlayer
                        url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/flower/flower-animation.mp4"
                        height="100%"
                        width="100%"
                        loop
                        muted
                        playsinline
                      />
                    </AutomaticPlayer>
                  </div>
                </button>
              </div>
            ))}
            {stans?.map((stan) => {
              const colorway = colorways[stan.colorway].videoMobile as string;
              if (!colorway) return null;
              return (
                <div key={stan.stanId} className="w-full md:w-[31%] relative">
                  <div>
                    <div className="bg-gray-950 rounded-md py-1 mt-0 px-2 absolute top-2 md:top-[14px] left-2">
                      <p className="text-gray-350 font-extrabold text-sm">
                        #
                        {stan.stanId}
                      </p>
                    </div>
                    {stan?.isCurrentlyStacked && (
                    <div className=" bg-gray-950 rounded-md py-1 mt-0 px-2 absolute top-2 md:top-[14px] right-4">
                      <CheckMarkStackingSvg height={20} />
                    </div>
                    )}
                    {stan?.isCurrentlyStacked && stan?.currentLevel! >= 1 && (
                    <div className=" bg-gray-950 text-sm text-gray-350 font-bold rounded-md py-1 px-2 absolute bottom-4 left-2">
                      Level
                      {' '}
                      {stan?.currentLevel}
                    </div>
                    )}
                  </div>
                  <button
                    key={stan.stanId}
                    onClick={onUpdateCurrentStansKeyIndexSelected(stan.stanId)}
                    className={`${currentStansKeyIdViewing === stan.stanId ? 'ring-green-550 ring-4 rounded-lg' : ''}`}
                  >
                    <div className="aspect-square rounded-lg overflow-hidden">
                      <AutomaticPlayer>
                        <ReactPlayer
                          url={colorway}
                          height="100%"
                          width="100%"
                          loop
                          muted
                          playsinline
                        />
                      </AutomaticPlayer>
                    </div>
                  </button>
                </div>
              );
            })}
            {jwasics?.map((jwAsic) => (
              <div key={jwAsic.jwasicsId} className="w-full md:w-[31%] relative">
                <div>
                  <div className="bg-gray-950 rounded-md py-1 mt-0 px-2 absolute top-2 md:top-[14px] left-2">
                    <p className="text-gray-350 font-extrabold text-sm">
                      #
                      {jwAsic.jwasicsId}
                    </p>
                  </div>
                  {jwAsic?.isCurrentlyStacked && (
                  <div className=" bg-gray-950 rounded-md py-1 mt-0 px-2 absolute top-2 md:top-[14px] right-4">
                    <CheckMarkStackingSvg height={20} />
                  </div>
                  )}
                  {jwAsic?.isCurrentlyStacked && jwAsic?.currentLevel! >= 1 && (
                  <div className=" bg-gray-950 text-sm text-gray-350 font-bold rounded-md py-1 px-2 absolute bottom-4 left-2">
                    Level
                    {' '}
                    {jwAsic?.currentLevel}
                  </div>
                  )}
                </div>
                <button
                  key={jwAsic.jwasicsId}
                  onClick={onUpdateCurrentjwasicsKeyIndexSelected(jwAsic.jwasicsId)}
                  className={`${currentjwasicsKeyIdViewing === jwAsic.jwasicsId ? 'ring-green-550 ring-4 rounded-lg' : ''}`}
                >
                  <div className="aspect-square rounded-lg overflow-hidden">
                    <AutomaticPlayer>
                      <ReactPlayer
                        url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/nft-video-2.mp4"
                        height="100%"
                        width="100%"
                        loop
                        muted
                        playsinline
                      />
                    </AutomaticPlayer>
                  </div>
                </button>
              </div>
            ))}
          </div>
        ) : <div className="mt-10"><StackingEmpty /></div>}
      </div>
      {isTabletOrMobile && (
      <div className="pb-6">
        {renderPortalKeyChoice()}
      </div>
      )}
      <div>
        <ol className="font-inter text-md md:text-lg text-gray-350 font-medium">
          <li>
            <span className="pr-2">
              1.
            </span>
            Reach level 1 by stacking for
            {' '}
            {isTabletOrMobile && (<br />) }
            <span className={`${isTabletOrMobile && 'pl-5'}`}>

              <span className="text-blue-550">
                0-3 months
              </span>
              {' '}
              in a row.
            </span>
          </li>
          <li>
            2. Reach level 2 by stacking for
            {' '}
            {isTabletOrMobile && (<br />) }
            <span className={`${isTabletOrMobile && 'pl-5'}`}>
              <span className="text-green-550">
                3-6 months
              </span>
              {' '}
              in a row.
            </span>
          </li>
          <li>
            3. Reach level 3 by stacking for
            {' '}
            {isTabletOrMobile && (<br />) }
            <span className={`${isTabletOrMobile && 'pl-5'}`}>

              <span className="text-orange-550">
                6+ months
              </span>
              {' '}
              in a row.
            </span>
          </li>
        </ol>
        {isTabletOrMobile && (
          <div className="pt-2">
            {renderLine({ info: 'KICKS BALANCE', balance: stacking.currentBalance })}
          </div>
        )}
      </div>
    </div>
  );
}

export default StackingCurrentInformation;
