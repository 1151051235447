/* eslint-disable max-len */
import React from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import { Trans, useTranslation } from 'react-i18next';
import { ArrowRightSvg, DiscordSvg } from '../../icons/svg';
import AutomaticPlayer from '../../_main/Video/AutomaticPlayer';

function Introduction() {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="w-screen py-8 md:pb-20">
      <div id="metakicks" className="m-auto max-w-screen-xl px-8 lg:px-16 2xl:px-0 pt-[40px]">
        <h1 className="text-[36px] lg:text-[80px] 2xl:text-[144px] font-extrabold text-gray-950 leading-none pb-6">
          <Trans i18nKey="introSneakerBoxes" />

        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-10">
          {isTabletOrMobile && (
          <p className="text-sm text-gray-950 md:text-2xl">
            {t('introInformation')}
          </p>
          )}
          <div className="overflow-hidden">
            <div className="aspect-square bg-contain bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/mystery-box.png')] bg-gray-200 w-full">
              <AutomaticPlayer>
                <ReactPlayer
                  url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/mystery-box.mp4"
                  height="100%"
                  width="100%"
                  loop
                  muted
                  playsinline
                />
              </AutomaticPlayer>
            </div>
          </div>
          {isTabletOrMobile && (
          <h1 className="text-gray-950 font-extrabold text-[22px] md:text-[48px]">
            {t('mintComingSoon')}

          </h1>
          )}
          <div className="flex flex-col justify-between mt-[-30px] md:pt-20">
            <p className=" text-gray-950 text-sm md:text-1xl">
              {!isTabletOrMobile && t('introInformation')}
              <br />
              <br />
              {t('introStories')}
              <br />
              <br />
              {t('introPartners')}
              <br />
              <br />
              {t('introLucky')}
              <br />
              <br />
              <span className="font-bold">
                {t('introJoinDiscord')}
              </span>
            </p>
            <button
              type="button"
              className="mt-6 md:mt-0 w-full duration-300 ease-in-out group hover:bg-gray-950 border-2 rounded-md border-gray-950 hover:drop-shadow-[0_0_1.5rem_gray]"
            >
              <a
                target="_blank"
                href="https://discord.gg/metakicks"
                rel="noreferrer"
                className="flex px-3 flex-row items-center gap-4 md:gap-5 w-full py-1 md:py-2 justify-between"
              >
                {isTabletOrMobile
                  ? <DiscordSvg className="fill-gray-950 group-hover:fill-gray-350" height="30" width="30" />
                  : <DiscordSvg className="fill-gray-950 group-hover:fill-gray-350" />}
                <p className="text-gray-950 group-hover:text-gray-350 font-extrabold text-[5vw] whitespace-nowrap md:text-[30px]">
                  {t('joinUsOnDiscord')}

                </p>
                <ArrowRightSvg className="fill-gray-950 group-hover:fill-gray-350" />
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Introduction;
