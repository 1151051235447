import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Drawer from './Drawer';
import { SneakmartPlusWhiteSvg } from '../../icons/svg';
import ConnectWalletButton from '../Wallet/ConnectWalletButton';
import LanguageSwitch from './LanguageSwitch';

interface Props {
  dark?: boolean;
  isEventOnScrollActivated?: boolean
}

function Header({ dark, isEventOnScrollActivated }: Props) {
  const isHeaderMobileOponed = useRef<boolean>(false);
  const lastScrollY = useRef<number | null>(null);
  const [isOpened, setIsOpened] = useState(false);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { t } = useTranslation();

  const navs = [
    {
      id: 'id0',
      label: 'DROPS',
      href: '/',
      hash: '#drops',
    },
  ];

  if ((process.env.REACT_APP_PROTECT_ACCESS_METAKICKS !== 'true' || process.env.REACT_APP_PROTECT_ACCESS_PASS !== 'true')) {
    navs.push({
      id: 'id5',
      label: `${t('menuInventory')}`,
      href: '/inventory',
      hash: '',
    });
  }

  const handleScroll = () => {
    const div = document.getElementById('header');
    const divBackground = document.getElementById('background-header');

    if (isEventOnScrollActivated) {
      if (window.pageYOffset > 600) {
        if (div?.style && divBackground?.style) {
          if (isHeaderHidden) {
            divBackground.style.background = 'transparent';
            divBackground.style.transition = 'background 0.4s ease-in-out';
          } else {
            divBackground.style.background = 'rgba(0, 0, 0, 0.9)';
          }
          div.style.backgroundColor = 'linear-gradient(rgba(0, 0, 0, 0.9), transparent)';
          div.style.borderColor = 'transparent';
        }
      }
      if (window.pageYOffset < 600) {
        if (div?.style && divBackground?.style) {
          if (dark) { divBackground.style.background = 'transparent'; }
          div.style.backgroundColor = 'transparent';
          div.style.borderColor = 'transparent';
        }
      }
    }

    if (!isHeaderHidden && !isHeaderMobileOponed.current && lastScrollY.current
      && window.pageYOffset > (isEventOnScrollActivated ? 1200 : 0) && window.pageYOffset - lastScrollY.current > 30) {
      setIsHeaderHidden(true);
    } else if (isHeaderHidden && (window.pageYOffset < (isEventOnScrollActivated ? 1200 : 0)
      || (lastScrollY.current && window.pageYOffset > 150
      && window.pageYOffset - lastScrollY.current < 0))) {
      setIsHeaderHidden(false);
    }

    lastScrollY.current = window.pageYOffset;
  };

  const toggleIsOpened = () => {
    if (!isOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
      document.body.style.overflowX = 'hidden';
    }
    setIsOpened((v) => {
      isHeaderMobileOponed.current = !v;
      return !v;
    });

    setIsHeaderHidden(false);
  };

  useLayoutEffect(() => {
    document.body.classList.add(dark ? 'scrollbar-dark' : 'scrollbar-light');
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY.current, dark, isHeaderHidden, isOpened, isHeaderMobileOponed.current]);

  return (
    <>
      <header
        className="flex justify-center w-screen sticky top-0 overfow-x-hidden"
        style={{ zIndex: 101 }}
      >
        <div
          id="background-header"
          className="absolute w-screen h-[60px] md:h-[90px] pointer-events-none"
          style={{
            background: 'transparent',
          }}
        />
        <div
          id="header"
          className={`overflow-x-hidden absolute flex flex-row items-center lg:mt-[20px] h-[60px] lg:h-[55px] w-full lg:mx-16 max-w-screen-xl justify-between px-4 md:px-10 transition-all duration-700 ease-in-out ${isHeaderHidden ? 'translate-y-[-150%]' : ''}`}
        >
          <div>
            <a href="/">
              <SneakmartPlusWhiteSvg
                className="fill-gray-350 mt-1 h-[18px] w-[180px] lg:h-[45px] lg:w-[280px]"
              />
            </a>
          </div>
          {isTabletOrMobile ? (
            <button
              type="button"
              onClick={toggleIsOpened}
              className="flex flex-col items-center justify-between w-[29px] h-[16px] cursor-pointer"
            >
              <span className={`w-full h-[2px] bg-gray-350 transition-all duration-500 ease-in-out ${isOpened && 'rotate-45  translate-y-[7px]'}`} />
              <span className={`w-full h-[2px] bg-gray-350 transition-all duration-500 ease-in-out ${isOpened && 'w-[0%] opacity-0'}`} />
              <span className={`w-full h-[2px] bg-gray-350 transition-all duration-500 ease-in-out ${isOpened && '-rotate-45  -translate-y-[7px]'}`} />
            </button>
          ) : (
            <div className="flex flex-1 justify-end ml-12">
              <ul className="flex flex-row gap-7 items-center">
                <a
                  href="https://sneakmart.com"
                  target="_blank"
                  className="cursor-pointer"
                  rel="noreferrer"
                >
                  <span
                    className="relative text-gray-350 font-extrabold text-[14px] after:content-[''] after:absolute after:bottom-0 after:left-[50%] after:w-[0%] after:h-[2px] after:bg-blue-550 after:transition-all after:duration-300 hover:after:left-0 hover:after:w-[100%]"
                  >
                    APP
                  </span>
                </a>
                {navs.map((item) => (
                  <Link
                    to={{ pathname: item.href, hash: item.hash }}
                    key={item.id}
                    className="cursor-pointer"
                  >
                    <li
                      className="relative text-gray-350 font-extrabold text-[14px] after:content-[''] after:absolute after:bottom-0 after:left-[50%] after:w-[0%] after:h-[2px] after:bg-blue-550 after:transition-all after:duration-300 hover:after:left-0 hover:after:w-[100%]"
                    >
                      {item.label}
                    </li>
                  </Link>
                ))}
                <LanguageSwitch />
                {(process.env.REACT_APP_PROTECT_ACCESS_METAKICKS !== 'true' || process.env.REACT_APP_PROTECT_ACCESS_PASS !== 'true') && (
                  <li>
                    <ConnectWalletButton />
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </header>
      { isTabletOrMobile && (
      <Drawer isOpened={isOpened}>
        <div className="justify-center flex flex-col max-h-screen h-screen overflow-y-hidden">
          <div>
            <ul className="flex flex-col gap-7 items-center" style={{ fontSize: '3vh' }}>
              <a
                onClick={toggleIsOpened}
                href="https://sneakmart.com"
                target="_blank"
                className="cursor-pointer"
                rel="noreferrer"
              >
                <span className="text-gray-350 font-extrabold">APP</span>
              </a>
              {navs.map((item) => (
                <Link
                  onClick={toggleIsOpened}
                  to={{ pathname: item.href, hash: item.hash }}
                  key={item.id}
                  className="cursor-pointer"
                >
                  <li className="text-gray-350 font-extrabold">{item.label}</li>
                </Link>
              ))}
              <LanguageSwitch />
              {(process.env.REACT_APP_PROTECT_ACCESS_METAKICKS !== 'true' || process.env.REACT_APP_PROTECT_ACCESS_PASS !== 'true') && (
              <li>
                <ConnectWalletButton onCloseDrawer={setIsOpened} />
              </li>
              )}
            </ul>
          </div>
          <div className="absolute bottom-0 flex flex-row gap-4 items-center w-full flex-wrap justify-center mb-[25px]" />
        </div>
      </Drawer>
      )}
    </>
  );
}

export default Header;
