import React from 'react';
import { ArrowRightSvg, SneakmartPlusWhiteSvg } from '../icons/svg';

function Error404() {
  return (
    <div className="w-screen max-h-screen bg-gray-950 overflow-x-hidden">
      <div className="absolute px-10 h-[80px] items-center flex">
        <div>
          <SneakmartPlusWhiteSvg
            height="80"
            width="200"
            className="fill-gray-350"
          />
        </div>
      </div>
      <div className="max-w-screen-xl h-screen px-10 lg:px-0 m-auto flex flex-col items-center justify-center">
        <h1 className="text-[90px] lg:text-[244px] lg:leading-[160px] font-bold text-gray-350 font-grotesk">404</h1>
        <h1 className="text-2xl lg:text-[80px] leading-[40px] lg:leading-[120px] font-bold text-gray-350 md:pb-12 font-inter">This page does not exist</h1>
        <button type="button" className="flex max-w-2xl mt-5 lg:mt-0 flex-row items-center gap-5 w-full py-2 border-2 rounded-md justify-center duration-300 ease-in-out group hover:bg-gray-350 border-gray-350">
          <a href="/" className="relative flex flex-row w-full items-center justify-center gap-5">
            <p className="text-gray-350 group-hover:text-gray-950 font-bold text-[22px] lg:text-[30px]">Go back to home</p>
            <div className="absolute right-[15px]">
              <ArrowRightSvg className="fill-gray-350 group-hover:fill-gray-950" />
            </div>
          </a>
        </button>
      </div>
    </div>
  );
}

export default Error404;
