import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveInventory } from '../../../../../core/usecases/inventory-retrieval/retrieveInventory';
import { selectEthereumWalletVM } from '../../../../view-models-generation/generateEthereumWalletViewModel';
import { selectInventoryVM } from '../../../../view-models-generation/generateInventoryViewModel';
import PassList from './PassList';
import PortalEmptyResult from './PortalEmptyResult';
import PortalListLoading from './PortalListLoading';

function CatalogPortalKeys() {
  const dispatch = useDispatch();
  const { isLoading, inventory } = useSelector(selectInventoryVM);
  const { address } = useSelector(selectEthereumWalletVM);

  useEffect(() => {
    dispatch(retrieveInventory);
  }, [dispatch, address]);

  return isLoading ? <PortalListLoading /> : inventory!.portalKeys!.length === 0 ? <PortalEmptyResult /> : (
    <div className="mt-6">
      <PassList portalKeys={inventory.portalKeys!.slice(0)} />
    </div>
  );
}

export default CatalogPortalKeys;
