import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import Header from '../../../_main/Header';
import NewFooter from '../../../components/Home/Sections/NewFooter';
import { HalfArrowRightSvg } from '../../../icons/svg';
import PortalKeyBannerSeparator from '../../../components/PortalKey/PortalKeyBannerSeparator';
import PortalKeyDropShowcase from '../../../components/PortalKey/PortalKeyDropShowcase';
import PortalKeyVideoStories from '../../../components/PortalKey/PortalKeyVideoStories';
import PortalKeyGridShowcase from '../../../components/PortalKey/PortalKeyGridShowcase';

const image = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-hero-1.jpg';
const imageMobile = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/metakicks-animations/landing/pass-mobile/MOBILE_PORTALAB_0162.jpg';

function PortalKeyDropLanding() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="bg-[#1A1A1A] relative">
      <Header />
      <div className="fixed w-full z-[100] px-4 lg:px-10 bottom-0 text-gray-350 py-2 bg-gray-950 bg-opacity-95 backdrop-blur-md  font-grotesk">
        <div className="flex flex-col lg:flex-row gap-2 mb-2 lg:mb-0 lg:justify-between lg:items-center w-full opacity-95">
          <div>
            <p className="text-2xl lg:text-3xl font-bold">
              PORTAL KEYS
            </p>
            <p className="font-inter text-md lg:text-xl font-medium lg:font-semibold">
              Sneakmart
            </p>
          </div>
          <Link to="buy" className="hover:scale-[98%] transition-transform duration-500">
            <div className="flex flex-row justify-center items-center gap-2 bg-blue-550 hover:bg-white hover:text-blue-550 py-2 md:px-10 font-bold rounded-lg text-xl lg:text-2xl">
              Sold out
              <HalfArrowRightSvg height={30} width={20} />
            </div>
          </Link>
        </div>
      </div>
      <div className="w-full">
        <div className="flex flex-col lg:flex-row mb-8 md:mb-14 gap-4 lg:gap-16">
          <div className="w-full">
            <div
              className="h-screen bg-cover bg-center"
              style={{
                backgroundImage: `url(${isTabletOrMobile ? imageMobile : image})`,
              }}
            >
              <div className={`h-full text-gray-350 ${isTabletOrMobile && 'bg-gradient-to-t from-gray-550 via-transparent to-transparent'}`} />
            </div>
          </div>
        </div>
        <PortalKeyBannerSeparator text="PORTAL KEY" />
        <PortalKeyVideoStories />
        <PortalKeyDropShowcase />
        <PortalKeyGridShowcase />
        <div className="mx-4 lg:mx-10 pb-32 md:pb-2">
          <NewFooter />
        </div>
      </div>
    </div>
  );
}

export default PortalKeyDropLanding;
