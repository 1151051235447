import { combineReducers } from 'redux';
import { CreateStripeBuyingSession } from '../../appState';
import * as suscribeToCreateStripeBuyingSessionActionCreators from '../usecases/stripe-buying-session-create/actionCreators';

const data = (
  state: CreateStripeBuyingSession | null = null,
  action: suscribeToCreateStripeBuyingSessionActionCreators.Actions,
) => {
  switch (action.type) {
    case 'STRIPE_BUYING_SESSION_CREATED':
      return { clientSecret: action.payload.clientSecret };
    default: return state;
  }
};

const isLoading = (
  state: boolean = false,
  action: suscribeToCreateStripeBuyingSessionActionCreators.Actions,
) => {
  switch (action.type) {
    case 'STRIPE_BUYING_SESSION_CREATED':
      return false;
    case 'CREATE_STRIPE_BUYING_SESSION':
      return true;
    case 'CREATE_STRIPE_BUYING_SESSION_ERROR':
      return false;
    default: return state;
  }
};

export const createStripeBuyingSessionReducer = combineReducers({
  data,
  isLoading,
});
