import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../_main/Header';
import NewFooter from '../../../components/Home/Sections/NewFooter';
import AutomaticPlayer from '../../../_main/Video/AutomaticPlayer';
import { checkMadHunnyConfirmation } from '../../../../../core/usecases/mad-hunny-confirmation-check/checkMadHunnyConfirmation';
import { selectMadHunnyConfirmationCheck } from '../../../../view-models-generation/generateMadHunnyDropViewModel';
import MadHunnyBuyConfirmationLoading from '../../../components/MadHunny/MadHunnyBuy/MadHunnyBuyConfirmationLoading';
import MadHunnyBuyConfirmationAccepted from '../../../components/MadHunny/MadHunnyBuy/MadHunnyBuyConfirmationAccepted';
import MadHunnyBuyConfirmationRefused from '../../../components/MadHunny/MadHunnyBuy/MadHunnyBuyConfirmationRefused';

const videoUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/mad-hunny-nft.mp4';

type UrlParams = 'flowerOrderId'

function MadHunnyConfirmation() {
  const urlParams = useParams<Record<UrlParams, string>>();
  const dispatch = useDispatch();
  const { data } = useSelector(selectMadHunnyConfirmationCheck);

  const flowerOrderId = urlParams.flowerOrderId || '';

  useEffect(() => {
    if (flowerOrderId && (!data || data?.status === 'pending')) {
      const interval = setInterval(() => {
        dispatch(checkMadHunnyConfirmation(flowerOrderId));
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [flowerOrderId, data, dispatch]);

  return (
    <div className="bg-black">
      <Header dark />
      <div className="mx-4 md:mx-16">
        <div className="flex flex-col md:flex-row pt-20 md:pt-28 mb-14 gap-4 md:gap-16">
          <div className="md:grid md:grid-cols-2">
            <div className="rounded-lg overflow-hidden w-auto h-auto">
              <AutomaticPlayer>
                <ReactPlayer
                  height="100%"
                  width="100%"
                  loop
                  playsinline
                  muted
                  url={videoUrl}
                />
              </AutomaticPlayer>
            </div>
            <div className="items-center h-full w-full justify-center px-8 mt-10 md:mt-0 md:px-20 flex flex-col">
              {(!data || data?.status === 'refused' || data?.status === 'pending') && <MadHunnyBuyConfirmationLoading />}
              {data?.status === 'accepted' && <MadHunnyBuyConfirmationAccepted />}
              {data?.status === 'refund' && <MadHunnyBuyConfirmationRefused />}
            </div>
          </div>
        </div>
        <NewFooter />
      </div>
    </div>
  );
}

export default MadHunnyConfirmation;
