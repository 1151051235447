import { StackingAward } from '../../../appState';
import { ThunkResult } from '../../../store';
import { StackingGateway } from '../../gateways/stackingGateway';
import { Actions } from './actionCreators';

export const redeemStackingAward = ({
  award, size, walletAddress,
}: {award: StackingAward, walletAddress: string, size: string}): ThunkResult<Promise<void>> => async (
  dispatch,
  getState,
  { stackingGateway }: {
    stackingGateway: StackingGateway,
  },
) => {
  dispatch(Actions.redeemStackingAward(award));

  const stripeUrlRedirection = await stackingGateway.redeemStackingAward(award, size, walletAddress);

  dispatch(Actions.stackingAwardRedeemed(stripeUrlRedirection, award));
};
