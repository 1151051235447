import React from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../../../_main/Header';
import NewFooter from '../../../components/Home/Sections/NewFooter';
import AutomaticPlayer from '../../../_main/Video/AutomaticPlayer';
import { HalfArrowRightSvg } from '../../../icons/svg';
import AsicsVideoStories from '../../../components/Asics/AsicsVideoStories';
import AsicsDropShowcase from '../../../components/Asics/AsicsDropShowcase';

const videoUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/DESKTOP-HEADER-V4.mp4';
const videoUrlMobile = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/mobile/MOBILE-HEADER-V4.mp4';

function AsicsDropLanding() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className="bg-brown-550 relative">
      <Header />
      <div className="fixed w-full md:w-full z-[100] px-4 lg:px-10 bottom-0 text-gray-350 py-2 bg-gray-950 bg-opacity-95 backdrop-blur-md font-grotesk">
        <div className="flex flex-col lg:flex-row gap-2 mb-2 lg:mb-0 lg:justify-between lg:items-center md:w-full opacity-95">
          <div>
            <p className="text-2xl lg:text-3xl font-bold">
              GEL-NYC &quot;KB TO NY&quot;
            </p>
            <p className="font-inter text-md lg:text-xl font-medium lg:font-semibold">
              Sneakmart X ASICS X JW Customs
            </p>
          </div>
          {process.env.REACT_APP_ASICS_DROP_ACCESS === 'true' && (
            <Link to="buy" className="hover:scale-[98%] transition-transform duration-500">
              <div className="flex flex-row justify-center items-center gap-2 bg-brown-550 hover:bg-white hover:text-brown-550 py-2 md:px-10 font-bold rounded-lg text-xl lg:text-2xl">
                Buy Now 395
                {currentLanguage === 'fr' ? '€' : '$'}
                <HalfArrowRightSvg height={30} width={20} />
              </div>
            </Link>
          )}
          {process.env.REACT_APP_ASICS_DROP_ACCESS === 'false' && (
            <Link to="buy" className="hover:scale-[98%] transition-transform duration-500">
              <div className="flex flex-row justify-center items-center gap-2 bg-brown-550 hover:bg-white hover:text-brown-550 py-2 md:px-10 font-bold rounded-lg text-xl lg:text-2xl">
                Sold out
                <HalfArrowRightSvg height={30} width={20} />
              </div>
            </Link>
          )}
        </div>
      </div>
      <div className="w-full">
        <div className="flex flex-col lg:flex-row mb-8 md:mb-8 gap-4 lg:gap-16">
          <div className="w-full">
            <div className="rounded-xl object-cover min-h-[692px] md:min-h-[900px]">
              {isTabletOrMobile ? (
                <AutomaticPlayer>
                  <ReactPlayer
                    height="100%"
                    width="100vw"
                    loop
                    playsinline
                    muted
                    url={videoUrlMobile}
                  />
                </AutomaticPlayer>
              ) : (
                <AutomaticPlayer>
                  <ReactPlayer
                    height="100%"
                    width="100vw"
                    loop
                    playsinline
                    muted
                    url={videoUrl}
                  />
                </AutomaticPlayer>
              )}
            </div>
          </div>
        </div>
        <div className="mx-4 mb-4 md:mb-6 md:mx-10 rounded-xl overflow-hidden">
          <img
            className="overflow-hidden w-full"
            src={isTabletOrMobile ? 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/mobile/jwasics-parts-mobile.jpg' : 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/jwasics-parts-desktop.png'}
            alt=""
          />
        </div>
        <div className="flex flex-col md:flex-row mx-4 md:mx-10 md:mb-4 gap-3 md:gap-8">
          <div className="flex md:w-[180%] items-center rounded-xl overflow-hidden">
            <img
              className="w-full"
              src={isTabletOrMobile ? 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/mobile/nft-picture.jpg' : 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/DESKTOP-NFT-PHOTO-2.png'}
              alt=""
            />
          </div>
          <div className="flex flex-col gap-2 md:gap-4 md:w-[90%] text-white justify-start md:mt-8">
            <h1 className="text-grotesk text-4xl font-semibold">
              FROM KOBE TO NEW YORK
            </h1>
            <div className="flex flex-col gap-2 md:gap-4 text-inter text-lg">
              <p>
                Kobe, Japan in 1949, Kihachiro Onitsuka founded the &quot;Onitsuka Co&quot;, which became &quot;Asics&quot; in 1977.
                74 years later, Asics is worn all over the world and has become a major player in the world of sport and lifestyle fashion.
              </p>
              <p>
                Today, in 2023, we celebrate this cultural journey between Japan and New York, the homeland of streetwear and hip-hop culture, with a pair that is already iconic: the GEL-NYC.
              </p>
              <p>
                To realize this, we&apos;ve teamed up with Jakob Werner aka JW Customs, a talented and passionate artist based in Germany, aged just 24. Jakob shares the same values as we do at Sneakmart: passion, innovation and community.
              </p>
            </div>
          </div>
        </div>
        <AsicsVideoStories />
        <AsicsDropShowcase />
        <div className="mx-4 lg:mx-10 pb-32 md:pb-2">
          <NewFooter />
        </div>
      </div>
    </div>
  );
}

export default AsicsDropLanding;
