import React from 'react';
import shallow from 'zustand/shallow';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { useStackingStore } from '../../../store/stackingStore';
import {
  selectFlowerKeyAwardVM, selectjwasicsKeyAwardVM, selectPortalkeyAwardVM, selectStanKeyAwardVM,
} from '../../../../view-models-generation/generateStackingViewModel';
import { KicksLogoSvg } from '../../../icons/svg';
import { stackFlowerKey } from '../../../../../core/usecases/flower-key-stacking/stackFlowerKey';
import { unstackFlowerKey } from '../../../../../core/usecases/flower-key-unstacking/unstackFlowerKey';
import { stackPortalKey } from '../../../../../core/usecases/portal-key-stacking/stackPortalKey';
import { unstackPortalKey } from '../../../../../core/usecases/portal-key-unstacking/unstackPortalKey';
import { stackStanKey } from '../../../../../core/usecases/stan-key-stacking/stackStanKey';
import { unstackStanKey } from '../../../../../core/usecases/stan-key-unstacking/unstackStanKey';
import { selectEthereumWalletVM } from '../../../../view-models-generation/generateEthereumWalletViewModel';
import { stackjwasicsKey } from '../../../../../core/usecases/jwasics-key-stacking/stackJwasicsKey';
import { unstackjwasicsKey } from '../../../../../core/usecases/jwasics-key-unstacking/unstackJwasicsKey';

interface Props {
  currentBalance: number;
}

function StackMenuView({ currentBalance }: Props) {
  const { currentPortalKeyIdViewing } = useStackingStore((state) => ({ currentPortalKeyIdViewing: state.currentPortalKeyIdViewing }), shallow);
  const { currentFlowerKeyIdViewing } = useStackingStore((state) => ({ currentFlowerKeyIdViewing: state.currentFlowerKeyIdViewing }), shallow);
  const { currentjwasicsKeyIdViewing } = useStackingStore((state) => ({ currentjwasicsKeyIdViewing: state.currentjwasicsKeyIdViewing }), shallow);
  const { currentStansKeyIdViewing } = useStackingStore((state) => ({ currentStansKeyIdViewing: state.currentStansKeyIdViewing }), shallow);
  const { currentStansKeyColorwayViewing } = useStackingStore((state) => ({ currentStansKeyColorwayViewing: state.currentStansKeyColorwayViewing }), shallow);

  const { portalKey } = useSelector(selectPortalkeyAwardVM(currentPortalKeyIdViewing));
  const { flowerKey } = useSelector(selectFlowerKeyAwardVM(currentFlowerKeyIdViewing));
  const { jwAsicKey } = useSelector(selectjwasicsKeyAwardVM(currentjwasicsKeyIdViewing));
  const { stanKey } = useSelector(selectStanKeyAwardVM(currentStansKeyIdViewing));

  const { address } = useSelector(selectEthereumWalletVM);

  let stanUrl = '';

  switch (currentStansKeyColorwayViewing) {
    case 'mocha':
      stanUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-COFFEESCENE.mp4';
      break;
    case 'sakura':
      stanUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-CHERRY.mp4';
      break;
    case 'gummy':
      stanUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-JADED.mp4';
      break;
    case 'matcha':
      stanUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-ROCKY.mp4';
      break;
    case 'aqua':
      stanUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-DESERT.mp4';
      break;
    default:
      stanUrl = '';
      break;
  }

  const dispatch = useDispatch();

  const renderLine = ({ info, balance }: {info: string, balance: number}) => (
    <div className="text-gray-350 w-full">
      <div className="flex flex-row justify-between font-grotesk">
        <p className="text-xl md:text-5xl font-semibold">
          {info}
        </p>
        <p className="flex flex-row gap-2 justify-end items-center text-[20px] w-[50%] align-center md:text-[40px] font-semibold">
          {balance}
          <span>
            <KicksLogoSvg width={20} />
            {' '}

          </span>
        </p>
      </div>
    </div>
  );

  const onStackPortalKey = () => {
    if (!currentPortalKeyIdViewing || !address) return;
    dispatch(stackPortalKey({ portalKeyId: currentPortalKeyIdViewing, walletAddress: address! }));
  };

  const onUnstackPortalKey = () => {
    if (!currentPortalKeyIdViewing || !address) return;
    dispatch(unstackPortalKey({ portalKeyId: currentPortalKeyIdViewing, walletAddress: address! }));
  };

  const onStackFlowerKey = () => {
    if (!currentFlowerKeyIdViewing || !address) return;
    dispatch(stackFlowerKey({ flowerId: currentFlowerKeyIdViewing, walletAddress: address! }));
  };

  const onUnstackFlowerKey = () => {
    if (!currentFlowerKeyIdViewing || !address) return;
    dispatch(unstackFlowerKey({ flowerId: currentFlowerKeyIdViewing, walletAddress: address! }));
  };

  const onStackjwasicsKey = () => {
    if (!currentjwasicsKeyIdViewing || !address) return;
    dispatch(stackjwasicsKey({ jwasicsId: currentjwasicsKeyIdViewing, walletAddress: address! }));
  };

  const onUnstackjwasicsKey = () => {
    if (!currentjwasicsKeyIdViewing || !address) return;
    dispatch(unstackjwasicsKey({ jwasicsId: currentjwasicsKeyIdViewing, walletAddress: address! }));
  };

  const onStackStanKey = () => {
    if (!currentStansKeyIdViewing || !address) return;
    dispatch(stackStanKey({ stanId: currentStansKeyIdViewing, walletAddress: address! }));
  };

  const onUnstackStanKey = () => {
    if (!currentStansKeyIdViewing || !address) return;
    dispatch(unstackStanKey({ stanId: currentStansKeyIdViewing, walletAddress: address! }));
  };

  const renderPortalKeyChoice = () => (
    <div className="grid w-full grid-rows-1 md:grid-cols-2 gap-3">
      <button
        onClick={
          currentPortalKeyIdViewing ? (portalKey?.isCurrentlyStacked ? onUnstackPortalKey : onStackPortalKey) : currentFlowerKeyIdViewing ? (flowerKey?.isCurrentlyStacked ? onUnstackFlowerKey : onStackFlowerKey)
            : currentjwasicsKeyIdViewing ? (jwAsicKey?.isCurrentlyStacked ? onUnstackjwasicsKey : onStackjwasicsKey) : (stanKey?.isCurrentlyStacked ? onUnstackStanKey : onStackStanKey)
}
        className={`${(portalKey?.isCurrentlyStacked || flowerKey?.isCurrentlyStacked || stanKey?.isCurrentlyStacked || jwAsicKey?.isCurrentlyStacked) ? 'bg-pink-550 text-gray-350' : 'bg-green-550 text-gray-950'} rounded-lg h-full w-full flex items-center justify-center`}
      >
        <h1 className=" text-[22px] text-center mx-2 md:mx-0 md:py-2 font-bold">
          {portalKey?.isCurrentlyStacked || flowerKey?.isCurrentlyStacked || stanKey?.isCurrentlyStacked || jwAsicKey?.isCurrentlyStacked ? 'UNSTOCK' : 'STOCK'}
        </h1>
      </button>
      <a
        target="_blank"
        href={
        currentPortalKeyIdViewing ? `https://opensea.io/assets/ethereum/${process.env.REACT_APP_PORTAL_KEY_CONTRACT_ADDRESS!}/${currentPortalKeyIdViewing}`
          : currentFlowerKeyIdViewing ? `https://opensea.io/assets/matic/${process.env.REACT_APP_MAD_HUNNY_CONTRACT_ADRESS_PROD!}/${currentFlowerKeyIdViewing}`
            : currentStansKeyIdViewing ? `https://opensea.io/assets/ethereum/${process.env.REACT_APP_STANIFLOU_CONTRACT_ADDRESS!}/${currentStansKeyIdViewing}` : `https://opensea.io/assets/ethereum/${process.env.REACT_APP_JW_ASICS_CONTRACT_ADDRESS!}/${currentjwasicsKeyIdViewing}`
}
        rel="noreferrer"
        className="border-2 border-gray-350 rounded-lg bg-gray-950 h-full w-full flex items-center justify-center"
      >
        <h1 className="text-gray-350 text-[22px] text-center mx-2 md:mx-0 md:py-2 font-bold">VIEW ON OPENSEA</h1>
      </a>
    </div>
  );

  return (
    <div className="flex col-span-2 md:col-span-1 md:flex-1 relative">
      <div className="flex relative flex-col justify-start items-end w-full h-full gap-4">
        {renderLine({ info: 'BALANCE', balance: currentBalance })}
        <div className="h-full w-full relative">
          <div className={`${portalKey?.isPremium ? 'bg-orange-550' : 'bg-gray-950'} rounded-md py-2 pr-3 pl-2 absolute top-[21px] md:top-[14px] left-4`}>
            <p className="text-gray-350 font-extrabold text-sm">
              {portalKey?.isPremium ? 'PREMIUM ' : ''}
              #
              {currentPortalKeyIdViewing && currentPortalKeyIdViewing}
              {currentFlowerKeyIdViewing && currentFlowerKeyIdViewing}
              {currentStansKeyIdViewing && currentStansKeyIdViewing}
              {currentjwasicsKeyIdViewing && currentjwasicsKeyIdViewing}
            </p>
          </div>
          <div className="relative rounded-lg overflow-hidden">
            {currentPortalKeyIdViewing && (
              <ReactPlayer
                height="100%"
                width="100%"
                loop
                playing
                playsinline
                muted
                url={portalKey?.isPremium ? 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/portal-key-premium-550.mp4' : 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/portal-key.mp4'}
              />
            )}
            {currentFlowerKeyIdViewing && (
              <ReactPlayer
                height="100%"
                width="100%"
                loop
                playing
                playsinline
                muted
                url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/flower/flower-animation.mp4"
              />
            )}
            {currentStansKeyIdViewing && (
              <ReactPlayer
                height="100%"
                width="100%"
                loop
                playing
                playsinline
                muted
                url={stanUrl}
              />
            )}
            {currentjwasicsKeyIdViewing && (
              <ReactPlayer
                height="100%"
                width="100%"
                loop
                playing
                playsinline
                muted
                url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/nft-video-2.mp4"
              />
            )}
            <div className="absolute bottom-0 w-full px-2 py-2 text-gray-350 bg-gradient-to-t from-gray-950 via-gray-850 ">
              <div className="font-grotesk text-xl font-bold">
                Stocking level:
                {' '}
                {portalKey?.currentLevel || flowerKey?.currentLevel || stanKey?.currentLevel || jwAsicKey?.currentLevel }
              </div>
              <div className="flex flex-row font-inter font-bold justify-between gap-2 text-sm py-2">
                <div className={`flex flex-row border-[1px] rounded-lg px-2 py-2
                ${(portalKey?.isCurrentlyStacked && portalKey?.currentLevel === 1) || (flowerKey?.isCurrentlyStacked && flowerKey?.currentLevel === 1) || (stanKey?.isCurrentlyStacked && stanKey?.currentLevel === 1) || (jwAsicKey?.isCurrentlyStacked && jwAsicKey?.currentLevel === 1) ? 'bg-gray-350 text-gray-950' : 'bg-gray-950 bg-opacity-75 text-gray-350'}
                `}
                >
                  Level 1:
                  {' '}
                  {currentPortalKeyIdViewing && (portalKey?.isPremium ? '20' : '10')}
                  {currentFlowerKeyIdViewing && '5'}
                  {currentjwasicsKeyIdViewing && '5'}
                  {currentStansKeyIdViewing && '5'}
                  <span className="pr-1">
                    <KicksLogoSvg
                      width={15}
                      height={20}
                      fill={(portalKey?.isCurrentlyStacked && portalKey?.currentLevel === 1) || (flowerKey?.isCurrentlyStacked && flowerKey?.currentLevel === 1) || (stanKey?.isCurrentlyStacked && stanKey?.currentLevel === 1) || (jwAsicKey?.isCurrentlyStacked && jwAsicKey?.currentLevel === 1) ? 'rgb(32 32 32)' : 'rgb(247 240 238)'}
                    />
                  </span>
                  per month
                </div>
                <div className={`flex flex-row border-[1px] rounded-lg px-2 py-2
                ${(portalKey?.isCurrentlyStacked && portalKey?.currentLevel === 2) || (flowerKey?.isCurrentlyStacked && flowerKey?.currentLevel === 2) || (stanKey?.isCurrentlyStacked && stanKey?.currentLevel === 2) || (jwAsicKey?.isCurrentlyStacked && jwAsicKey?.currentLevel === 2) ? 'bg-gray-350 text-gray-950' : 'bg-gray-950 bg-opacity-75 t'}
                `}
                >
                  Level 2:
                  {' '}
                  {currentPortalKeyIdViewing && (portalKey?.isPremium ? '30' : '15')}
                  {currentFlowerKeyIdViewing && '10'}
                  {currentjwasicsKeyIdViewing && '10'}
                  {currentStansKeyIdViewing && '10'}
                  <span className="pr-1">
                    <KicksLogoSvg
                      width={15}
                      height={20}
                      fill={(portalKey?.isCurrentlyStacked && portalKey?.currentLevel === 2) || (flowerKey?.isCurrentlyStacked && flowerKey?.currentLevel === 2) || (stanKey?.isCurrentlyStacked && stanKey?.currentLevel === 2) || (jwAsicKey?.isCurrentlyStacked && jwAsicKey?.currentLevel === 2) ? 'rgb(32 32 32)' : 'rgb(247 240 238)'}
                    />
                  </span>
                  per month
                </div>
                <div className={`flex flex-row border-[1px] rounded-lg px-2 py-2
                ${(portalKey?.isCurrentlyStacked && portalKey?.currentLevel === 3) || (flowerKey?.isCurrentlyStacked && flowerKey?.currentLevel === 3) || (stanKey?.isCurrentlyStacked && stanKey?.currentLevel === 3) || (jwAsicKey?.isCurrentlyStacked && jwAsicKey?.currentLevel === 3) ? 'bg-gray-350 text-gray-950' : 'bg-gray-950 bg-opacity-75 t'}
                `}
                >
                  Level 3:
                  {' '}
                  {currentPortalKeyIdViewing && (portalKey?.isPremium ? '40' : '20')}
                  {currentFlowerKeyIdViewing && '15'}
                  {currentjwasicsKeyIdViewing && '15'}
                  {currentStansKeyIdViewing && '15'}
                  <span className="pr-1">
                    <KicksLogoSvg
                      width={15}
                      height={20}
                      fill={(portalKey?.isCurrentlyStacked && portalKey?.currentLevel === 3) || (flowerKey?.isCurrentlyStacked && flowerKey?.currentLevel === 3) || (stanKey?.isCurrentlyStacked && stanKey?.currentLevel === 3) || (jwAsicKey?.isCurrentlyStacked && jwAsicKey?.currentLevel === 3) ? 'rgb(32 32 32)' : 'rgb(247 240 238)'}
                    />
                  </span>
                  per month
                </div>
              </div>
            </div>
          </div>
          {(currentPortalKeyIdViewing || currentFlowerKeyIdViewing || currentStansKeyIdViewing || currentjwasicsKeyIdViewing) && (
          <div className="pt-4">
            {renderPortalKeyChoice()}
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StackMenuView;
