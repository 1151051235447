import React from 'react';

function DropBuyFormLoading() {
  return (
    <div data-testid="loading">
      <div className="grid grid-cols-2 gap-10">
        <div className="bg-gray-950 aspect-square animate-pulse" />
        <div className="flex flex-col pt-10 justify-between">
          <div className="h-20 bg-gray-950 animate-pulse" />
          <div className="w-[250px] bg-gray-950 animate-pulse h-20" />
        </div>
      </div>
    </div>
  );
}

export default DropBuyFormLoading;
