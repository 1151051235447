import React from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';
import { useMediaQuery } from 'react-responsive';
import FrameByFrame from '../../../_main/Animation/FrameByFrame';
import {
  DiscordSvg, TwitterSvg, OpenSeaSvg, InstagramSvg,
} from '../../../icons/svg';
import { useWindowSize } from '../../../hooks/useWindowDimensions';

const social = [
  {
    id: 'id0',
    icon: DiscordSvg,
    href: 'https://discord.gg/metakicks',
  },
  {
    id: 'id1',
    icon: TwitterSvg,
    href: 'https://twitter.com/sneakmart',
  },
  {
    id: 'id2',
    icon: InstagramSvg,
    href: 'https://www.instagram.com/sneakmart/',
  },
  {
    id: 'id3',
    icon: OpenSeaSvg,
    href: 'https://opensea.io/collection/metakicks-portal-keys',
  },
];

const ratio = 16 / 9;

function LandingMysteryBox() {
  const { width, height } = useWindowSize();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  // Get hexa value of ETH to write on the contract
  // console.log('right price is :', ethers.utils.parseEther('0.28'));

  const currentFrame = (index: number) => {
    if (isTabletOrMobile) {
      return `https://sneakmartbucket.s3.eu-west-3.amazonaws.com/lab-mobile/${(index + 1).toString()}.jpg`;
    }
    return `https://sneakmartbucket.s3.eu-west-3.amazonaws.com/lab-desktop/${(index).toString()}.webp`;
  };

  const onFrameUpdate = (frameIndex: number) => {
    const div = document.getElementById('info-landing');
    const divFrameBackground = document.getElementById('frame-background');
    if (frameIndex < 94) {
      if (divFrameBackground?.style && div?.style && frameIndex < 94) {
        div.style.display = 'block';
        if (frameIndex > 10) {
          div.style.opacity = ((100 - ((frameIndex - 80) * 100) / (94 - 80)) / 100).toFixed(2);
        }
        divFrameBackground.style.backgroundColor = 'rgba(247, 240, 238, 1)';
      }
    }
    if (frameIndex >= 94) {
      if (divFrameBackground?.style && div?.style) {
        div.style.display = 'none';
        divFrameBackground.style.backgroundColor = 'transparent';
      }
    }
  };

  return (
    <>
      <FrameByFrame
        totalDuration={700}
        onFrameUpdate={onFrameUpdate}
        frameCount={100}
        currentFrame={currentFrame}
        canvasTailwindStyle="sticky top-0 h-screen w-screen z-10 pointer-events-none m-auto object-cover"
        idDiv="frame-by-frame-landing"
      />
      <div id="frame-background" className="w-screen pointer-events-none z-100 fixed h-screen top-0" />
      <div
        id="info-landing"
        style={{
          paddingBottom: getCookieConsentValue('cookie-consent') ? 40 : 100,
          zIndex: 99,
          ...width! > 2200 && {
            paddingRight: (width! - (height! * ratio)) / 2,
            paddingLeft: (width! - (height! * ratio)) / 2,
          },
        }}
        className="fixed bottom-0 w-full"
      >
        {!isTabletOrMobile && (
        <div className="flex flex-col items-end mr-5 gap-4">
          {social.map((item) => {
            const Icon = item.icon;
            return (
              <a key={item.id} target="_blank" href={item.href} rel="noreferrer">
                <Icon className="fill-gray-350 hover:fill-blue-550 transition-all duration-100" height="46" width="46" />
              </a>
            );
          })}
        </div>
        )}
      </div>
    </>
  );
}

export default LandingMysteryBox;
