import React, { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import useImagePreloader from '../../../hooks/useImagePreloader';
import { MtksWhiteLogo, SneakmartPlusWhiteSvg } from '../../../icons/svg';

interface Props {
  setIsLoading: (x: boolean) => void
}

function InitializationMysteryBox({ setIsLoading }: Props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const LENGTH_IMAGES = isTabletOrMobile ? 200 : 93;

  const currentFrame = (index: number) => {
    if (isTabletOrMobile) {
      return `https://sneakmartbucket.s3.eu-west-3.amazonaws.com/lab-mobile/${(index + 1).toString()}.jpg`;
    }
    return `https://sneakmartbucket.s3.eu-west-3.amazonaws.com/lab-desktop/${(index).toString()}.webp`;
  };

  const imagesList: string[] = useMemo(() => Array.from({ length: LENGTH_IMAGES }, (_, index) => (currentFrame(index))), [isTabletOrMobile]);

  const { progress, imagesPreloaded } = useImagePreloader(imagesList);

  useEffect(() => {
    if (imagesPreloaded) {
      setIsLoading(false);
    }
  }, [imagesPreloaded]);

  return (
    <div className="w-screen bg-gray-950">
      <div className="absolute px-10 h-[80px] items-center flex">
        <div>
          <SneakmartPlusWhiteSvg
            {...isTabletOrMobile ? {
              height: '80',
              width: '180',
            } : {
              height: '80',
              width: '180',
            }}
            className="fill-gray-350"
          />
        </div>
      </div>
      <div className="w-screen h-screen flex flex-col justify-center items-center">
        {isTabletOrMobile
          ? (
            <div className="flex justify-center pb-12 w-8/12">
              <MtksWhiteLogo
                height={100}
                width={200}
              />
            </div>
          )
          : (
            <div className="flex justify-center pb-8 w-1/3">
              <MtksWhiteLogo
                height={150}
                width={500}
              />
            </div>
          )}
        <div className="flex flex-col md:flex-row items-center justify-center">
          <div className="flex text-gray-350 text-[50px] lg:text-[80px] font-extrabold">
            <div>Loading</div>
            <div className="flex">
              <span className="block text-blue-550 animate-loading-dots1">.</span>
              <span className="block text-blue-550 animate-loading-dots2">.</span>
              <span className="block text-blue-550 animate-loading-dots3">.</span>
            </div>
          </div>
          <p className="text-gray-350 text-[50px] lg:text-[80px] font-extrabold md:ml-24 w-[100px]">
            {Math.floor(progress)}
            <span>%</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default InitializationMysteryBox;
