import { ThunkResult } from '../../../store';
import { MadHunnyDropGateway } from '../../gateways/madHunnyDropGateway';
import { Actions } from './actionCreators';

export const checkMadHunnyConfirmation: (flowerOrderId: string) => ThunkResult<Promise<void>> = (flowerOrderId) => async (
  dispatch,
  getState,
  { madHunnyDropGateway }: {
    madHunnyDropGateway: MadHunnyDropGateway,
  },
) => {
  dispatch(Actions.checkMadHunnyConfirmation());
  const check = await madHunnyDropGateway.checkConfirmation(flowerOrderId);
  dispatch(Actions.madHunnyConfirmationChecked(check.orDefault(null)));
};
