import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveInventory } from '../../../../../core/usecases/inventory-retrieval/retrieveInventory';
import { selectInventoryVM } from '../../../../view-models-generation/generateInventoryViewModel';
import CatalogLoading from './CatalogLoading';
import CatalogPortalPrize from './CatalogPortalPrize';
import CatalogRetailDrop from './CatalogRetailDrop';
import CatalogPrivateDrop from './CatalogPrivateDrop';
import { selectEthereumWalletVM } from '../../../../view-models-generation/generateEthereumWalletViewModel';
import AwardsEmpty from '../AwardsEmpty';
// import InventorySelectedMembers from '../InventorySelectedMembers';

function CatalogAwards() {
  const dispatch = useDispatch();
  const { isLoading, inventory } = useSelector(selectInventoryVM);
  const { address } = useSelector(selectEthereumWalletVM);

  useEffect(() => {
    dispatch(retrieveInventory);
  }, [dispatch, address]);

  if (isLoading) return <CatalogLoading />;

  return (inventory?.privateDrops?.length === 0 && inventory?.retailDrops?.length === 0 && inventory?.freeDrops?.length === 0) ? <AwardsEmpty /> : (
    <div className="grid grid-flow-row gap-8">
      {/* <InventorySelectedMembers /> */}
      {inventory?.privateDrops && inventory?.privateDrops?.length > 0 && (
        <CatalogPrivateDrop drops={inventory!.privateDrops!} />
      )}
      {inventory?.retailDrops && inventory?.retailDrops?.length > 0 && (
        <CatalogRetailDrop drops={inventory!.retailDrops} />
      )}
      {inventory?.freeDrops && inventory?.freeDrops?.length > 0 && (
        <CatalogPortalPrize drops={inventory!.freeDrops!} />
      )}
    </div>
  );
}

export default CatalogAwards;
