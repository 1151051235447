import React from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import Header from '../../../_main/Header';
import NewFooter from '../../../components/Home/Sections/NewFooter';
import AutomaticPlayer from '../../../_main/Video/AutomaticPlayer';
import AsicsDropCheckout from '../../../components/Collab/Claim/AsicsStripe/AsicsDropCheckout';

const videoUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/nft-video-2.mp4';

const drop = {
  title: 'Gel-NYC "KB TO NY"',
  partners: 'Sneakmart x JW Customs x Asics',
  price: 395,
  description: 'Introducing the GEL NYC “KB to NY”, a unique journey between Kobe, Japan, where Asics was born in 1949, and New York, the home of streetwear and hip-hop culture. A one of a kind collaborative Sneaker between Sneakmart, JW Customs and Asics.',
  description2: 'Each pair is carefully hand-crafted by Jakob Werner, aka JW Customs, making each one very unique. Only 20 pairs made. Price includes the physical sneakers, NFC chip numbered in each pair and the digital Sneakmart+ version.',
  preOrderText: 'Pre-order, shipping within 6-8 weeks, pairs will be hand customized by JW Customs. ',
};

function AsicsDropPage() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="bg-black">
      <Header dark />
      <div className="mx-4 md:mx-16">
        <div className="flex flex-col md:flex-row pt-20 md:pt-28 mb-2 md:mb-14 gap-4 md:gap-16">
          <div className="flex 2xl:justify-center w-full h-full rounded-xl overflow-hidden">
            {isTabletOrMobile ? (
              <div className="rounded-lg overflow-hidden w-auto h-auto">
                <AutomaticPlayer>
                  <ReactPlayer
                    height="100%"
                    width="100%"
                    loop
                    playsinline
                    muted
                    url={videoUrl}
                  />
                </AutomaticPlayer>
              </div>
            ) : (
              <div className="rounded-lg overflow-hidden">
                <AutomaticPlayer>
                  <ReactPlayer
                    height="600px"
                    width="600px"
                    loop
                    playsinline
                    muted
                    url={videoUrl}
                  />
                </AutomaticPlayer>
              </div>
            )}
          </div>
          <div className="flex flex-col w-full text-gray-350 font-inter text-lg font-medium gap-8">
            <AsicsDropCheckout drop={drop} />
          </div>
        </div>
        {isTabletOrMobile && (
          <div className="rounded-xl overflow-hidden">
            <img src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/mobile/jwasics-mobile-2.jpg" alt="" />
          </div>
        )}
        {!isTabletOrMobile && (
          <div className="flex flex-row w-full gap-4 justify-center">
            <img src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/jwasics-1.jpg" alt="" className="aspect-square w-80 object-cover rounded-xl overflow-hidden" />
            <img src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/jwasics-2.jpg" alt="" className="aspect-square w-80 object-cover rounded-xl overflow-hidden" />
            <img src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/jwasics-3.jpg" alt="" className="aspect-square w-80 object-cover rounded-xl overflow-hidden" />
            <img src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/jwasics-4.jpg" alt="" className="aspect-square w-80 object-cover rounded-xl overflow-hidden" />
          </div>
        )}
        <NewFooter />
      </div>
    </div>
  );
}

export default AsicsDropPage;
