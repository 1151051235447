import React from 'react';
import { useMediaQuery } from 'react-responsive';
import DropDescription from '../Collab/BuyNewDrop/DropDescription';
import { HalfArrowRightSvg } from '../../icons/svg';

interface Props {
  drop: {
    title: string
    partners: string,
    price: number,
    description: string,
    description2: string,
    preOrderText: string,
  }
}

function MadHunnyDropCheckout({ drop }: Props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div data-testid="fefe">
      <div>
        <div className="pb-2">
          <DropDescription drop={drop} />
        </div>
        <div className="flex flex-col justify-start">
          <button className="font-grotesk font-bold text-2xl">
            <div className="flex flex-row justify-center items-center gap-2 md:mt-5 bg-gray-550 text-gray-450 pointer-events-none cursor-no-drop w-full rounded-lg px-8 py-2 ">
              The drop is sold out
              <div>
                <HalfArrowRightSvg />
              </div>
            </div>
          </button>
        </div>
        {isTabletOrMobile && (
          <div className="pb-4">
            <DropDescription mobile drop={drop} />
          </div>
        )}
      </div>
    </div>
  );
}

export default MadHunnyDropCheckout;
