import React, {
  useState, useRef,
} from 'react';

function SubscribeToNewsletter() {
  const [, setEmail] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onSubscribe = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <form onSubmit={onSubscribe} className="mt-4 flex flex-row w-full items-center justify-between text-gray-350">
        <input
          onChange={onChange}
          ref={inputRef}
          type="email"
          required
          placeholder="Email address"
          className="placeholder-blue-550 text-gray-350 w-full text-xl font-bold bg-transparent appearance-none outline-none"
        />
      </form>
      <div className="flex flex-row mt-1">
        <div className="w-full h-[4px] bg-blue-550 rounded-full" />
        <div className="bg-blue-550 rounded-full h-[5px] w-[5px] ml-2" />
      </div>
    </>
  );
}

export default SubscribeToNewsletter;
