export interface AppState {
  inventory: {
    isLoading: boolean;
    data: Inventory | null;
  };
  stacking: {
    isLoading: boolean;
    data: Stacking | null;
  }
  currentMadHunnyDropInformationSaving: {
    isLoading: boolean;
    data: CurrentMadHunnyDropInformationSaving | null
    error: CurrentMadHunnyDropInformationSavingError | null
  }
  currentAsicsDropInformationSaving: {
    isLoading: boolean;
    data: CurrentAsicsDropInformationSaving | null
    error: CurrentAsicsDropInformationSavingError | null
  }
  newsletter: {
    isLoading: boolean;
    errorMessage: string | null;
    data: Newsletter;
  }
  stackingHistory: {
    isLoading: boolean;
    data: StackingHistoryItem[] | null;
  }
  currentStackingAwardRedeem: {
    isLoading: boolean;
    data: CurrentStackingAwardRedeem | null
  }
  dropAnnouncement: {
    isLoading: boolean;
    errorMessage: string | null;
    data: DropAnnouncement;
  }
  ethereumWallet: EthereumWallet | null
  leaderboard: {
    isLoading: boolean;
    data: LeaderboardItem[] | null
  }
  createStripeBuyingSession: {
    isLoading: boolean;
    data: CreateStripeBuyingSession | null
  }
  createMadHunnyStripeBuyingSession: {
    isLoading: boolean;
    data: CreateStripeBuyingSession | null
  }
  isMadHunnyWhitelistCheck: {
    isLoading: boolean;
    data: MadHunnyWhitelistCheck | null
  }
  checkMadHunnyConfirmation: {
    isLoading: boolean;
    data: MadHunnyConfirmationCheck | null
  }
  createAsicsStripeBuyingSession: {
    isLoading: boolean;
    data: CreateStripeBuyingSession | null
  }
  isAsicsWhitelistCheck: {
    isLoading: boolean;
    data: AsicsWhitelistCheck | null
  }
  checkAsicsConfirmation: {
    isLoading: boolean;
    data: AsicsConfirmationCheck | null
  }
  dropBuyingConfirmationCheck: {
    isLoading: boolean;
    data: DropBuyingConfirmationCheck | null
  }
  error: ErrorDomain
}

export interface CreateStripeBuyingSession {
  clientSecret: string;
}

export interface CurrentStackingAwardRedeem {
  award: {
    id: string;
    image: string;
    name: string;
    price: number;
    type: 'sneakers' | 'clothes' | 'accessories'
  }
  stripeUrl: string
}

export interface Stacking {
  currentBalance: number;
  keys: StackingPortalKey[];
  flowers: FlowerStackingItem[];
  awards: StackingAward[];
  stans: StansStackingItem[];
  jwasics: jwasicsStackingItem[];
}

export interface StackingHistoryItem {
  id: string;
  amount: number;
  occuresAt: Date;
  type: 'gain' | 'loss';
  portalKeyId?: string;
  stanId?: string;
  flowerId?: string;
  jwasicsId?: string;
  awardName?: string;
  awardImage?: string;
}

export interface StackingAward {
  id: string;
  image: string;
  name: string;
  price: number;
  type: 'sneakers' | 'clothes' | 'accessories'
}

export enum StaniflouColorway {
  MOCHA = 'mocha',
  SAKURA = 'sakura',
  GUMMY = 'gummy',
  MATCHA = 'matcha',
  AQUA = 'aqua',
}

export interface StackingPortalKey {
  portalKeyId: string;
  isCurrentlyStacked: boolean;
  currentMonth: number;
  currentLevel?: number;
  dateOfLastGain: Date | null;
  dateOfNextGain: Date | null;
  stackedAt: Date | null;
  isPremium?: boolean;
}

export interface ErrorDomain {
  text: string;
  help?: string
}

export enum Integration {
  Metamask,
  WalletConnect,
  Crossmint,
}

export interface CurrentMadHunnyDropInformationSaving {
  status: string;
}

export interface CurrentMadHunnyDropInformationSavingError {
  key: string;
  message: string;
}

export interface MadHunnyWhitelistCheck {
  isWhitelisted: boolean;
}

export interface MadHunnyConfirmationCheck {
  status: string | null;
}
export interface CurrentAsicsDropInformationSaving {
  status: string;
}

export interface CurrentAsicsDropInformationSavingError {
  key: string;
  message: string;
}

export interface AsicsWhitelistCheck {
  isWhitelisted: boolean;
}

export interface AsicsConfirmationCheck {
  status: string | null;
}

export interface DropBuyingConfirmationCheck {
  orderId: string;
  status: string | null;
  productName: string;
  productImage: string;
}

export interface EthereumWallet {
  signerAddress: string;
  signerBalance: number;
  integration: Integration;
  isWhitelisted?: boolean,
  whitelistSupply?: number,
  signMessage: (plainMessage: string) => Promise<string>;
}

export interface Inventory {
  flowers: FlowerInventoryItem[] | null;
  portalKeys: PortalKeyInventoryItem[] | null;
  freeDrops: Drop[] | null;
  retailDrops: Drop[] | null;
  privateDrops?: Drop[] | null;
  stans?: StanItem[] | null;
  jwasics?: jwasicsItem[] | null;
  promoCodes?: string[] | null;
}

export interface FlowerInventoryItem {
  id: string;
}

export interface FlowerStackingItem {
  flowerId: string;
  currentLevel: number;
  currentMonth: number;
  isCurrentlyStacked: boolean;
  stackedAt?: Date | null;
  dateOfLastGain?: Date | null;
  dateOfNextGain?: Date | null;
}

export interface StansStackingItem {
  stanId: string;
  colorway: StaniflouColorway
  currentLevel: number;
  currentMonth: number;
  isCurrentlyStacked: boolean;
  stackedAt?: Date | null;
  dateOfLastGain?: Date | null;
  dateOfNextGain?: Date | null;
}

export interface StanItem {
  id: string;
  colorway: StaniflouColorway
  currentLevel: number;
  currentMonth: number;
  isCurrentlyStacked: boolean;
  stackedAt?: Date | null;
  dateOfLastGain?: Date | null;
  dateOfNextGain?: Date | null;
}

export interface jwasicsItem {
  id: string;
  currentLevel: number;
  currentMonth: number;
  isCurrentlyStacked: boolean;
  stackedAt?: Date | null;
  dateOfLastGain?: Date | null;
  dateOfNextGain?: Date | null;
}

export interface jwasicsStackingItem {
  jwasicsId: string;
  currentLevel: number;
  currentMonth: number;
  isCurrentlyStacked: boolean;
  stackedAt?: Date | null;
  dateOfLastGain?: Date | null;
  dateOfNextGain?: Date | null;
}

export interface Drop {
  id: string;
  items: DropItem[];
  itemBought: DropItemBought | null;
}

export interface DropItemBought{
  name: string;
  image: string;
}

export interface DropItem {
  id: string;
  name: string;
  image: string;
  price: number;
  sizes: { size: string; quantity: number }[];
  type: 'sneakers' | 'clothes' | 'accessories'
  allSizes: boolean;
}

export interface PortalKeyInventoryItem {
  id: string;
}

export interface Newsletter {
  isSubscribed: boolean;
}
export interface DropAnnouncement {
  isSubscribed: boolean;
}

export interface LeaderboardItem {
  id: string;
  name: string;
  stock: number;
  image: string
  claimed: number
}

export enum SaleStatus {
  NotStarted = 0,
  Whitelist = 1,
  Public = 2,
  Migration = 3,
}

export type DropType = 'free' | 'retail' | 'private';
