import React from 'react';
import { useMediaQuery } from 'react-responsive';

const photos = [
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/spedity-madhunny-photo-1.jpeg',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/spedity-madhunny-photo-3.jpeg',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/spedity-madhunny-photo-2.jpeg',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/spedity-madhunny-photo-4.jpeg',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/madhunny-collage-mobile-4x.jpg',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/madhunny-collage-desktop.jpg',
];

const photoOverlay = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/overlay-photo.png';

function MadHunnyDropShowcase() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="flex flex-col">
      <div className="z-50">
        <img src={photoOverlay} alt="" className="z-50 w-[380px] md:w-[1000px] mb-[-200px] md:mt-[-60px] md:mb-[-250px]" />
      </div>
      <div className="flex flex-col items-center mx-4 md:mx-16 pt-20 text-gray-350 font-inter">
        <div className="flex flex-col md:flex-row gap-4 md:gap-8">
          <img src={photos[0]} alt="" className="rounded-lg h-full md:h-[700px] md:ml-14 2xl:ml-[700px] z-[48] hover:scale-[98%] transition-transform duration-500" />
          <div className="flex flex-col lg:mr-28 2xl:mr-[500px]">
            <p className="font-grotesk text-4xl font-bold">
              SNEAKMART X FLOWER INSTINCTS
            </p>
            <div className="text-xl pt-4">
              <p className="mb-4">
                Without bees, there would be no chocolate or coffee. Bees are crucial pollinators. Both cacao plants and robusta coffee plants rely on bee pollination. Bees are essential to people and the planet. The future of humanity depends on bees.
              </p>
              <p>
                That is why we decided to team up with Flower Instincts to create the Spedity “Mad Hunny”, a unique collaboration where the captivating essence of bees and honey blends seamlessly. Bzz Bzz.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 md:gap-0 pt-4 md:pt-0 md:flex-row">
          <img src={photos[1]} alt="" className="rounded-lg h-full md:h-[600px] md:relative md:mt-[-100px] hover:scale-[98%] transition-transform duration-500" />
          <img src={photos[2]} alt="" className="rounded-lg h-full md:h-[700px] md:relative md:right-4 md:mt-[-100px] lg:mt-[-220px] 2xl:mt-[-350px] hover:scale-[98%] transition-transform duration-500" />
        </div>
        <div className="flex flex-col md:flex-row gap-4 pt-4">
          <div className="flex flex-col md:ml-16 2xl:ml-[500px]">
            <p className="font-grotesk text-4xl font-bold">
              PERFECT 2 IN 1 COLLECTIBLE
            </p>
            <div className="text-xl pt-4">
              <p className="mb-6">
                Each physical pair comes with a digital version. Only 555 physical sneakers made, each linked to a unique digital version.
              </p>
              <p>
                The digital version acts as a membership to unlock special access to private drops on physical streetwear items from various brands and Flower Instincts. In addition, all Sneakmart+ members will enjoy unique perks and special status on the new Sneakmart app (coming soon).
              </p>
            </div>
          </div>
          <img src={photos[3]} alt="" className="rounded-lg h-full md:h-[600px] relative md:mr-72 2xl:mr-[800px] bg-red-500 2xl:mt-[-300px] md:mt-[-140px]  hover:scale-[98%] transition-transform duration-500" />
        </div>
      </div>
      <div className="h-full w-full mt-8 md:mt-16">
        <img src={`${isTabletOrMobile ? photos[4] : photos[5]}`} alt="" />
      </div>
    </div>
  );
}

export default MadHunnyDropShowcase;
