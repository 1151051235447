import React, { useEffect } from 'react';
import { Location } from 'history';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Trans, useTranslation } from 'react-i18next';
import Footer from '../_main/Footer';
import Header from '../_main/Header';
import Button from '../_main/Form/Button';

function CrossmintPurchaseCompletePass() {
  const location = useLocation() as Location;
  const navigate = useNavigate();
  const state = location.state as any;
  const { t } = useTranslation();

  useEffect(() => {
    if (!state?.id) {
      navigate('/get-a-pass', { replace: true });
    }
  }, []);

  return (
    <div data-testid="purchase-complete" className="w-screen bg-gray-350">
      <Header dark />
      <div className="pt-[120px] pb-[50px] overflow-x-hidden">
        <div className="max-w-screen-xl m-auto px-8 lg:px-16 2xl:px-0 md:pt-4 md:pb-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10">
            <ReactPlayer
              height="100%"
              width="100%"
              loop
              playsinline
              muted
              playing
              url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/mtks-pass.mp4"
            />
            <div className="h-full flex flex-col justify-between py-1">
              <h1 className="text-gray-950 text-[44px] lg:text-[104px] font-extrabold leading-[45px] md:leading-[90px]">
                <Trans i18nKey="crossmintPurchaseCompleteTitle" />

              </h1>
              <div>
                <div className="mb-4">
                  <p className="text-lg md:text-xl text-gray-950 font-bold mb-4">
                    {t('crossmintPurchaseCompleteText1')}
                    {' '}
                    {state?.tokenId}
                  </p>
                  <p className="text-lg md:text-xl text-gray-950">
                    <Trans
                      i18nKey="crossmintPurchaseCompleteText2"
                      components={[
                        <a href="/inventory" target="_blank" rel="noreferrer" className="text-blue-550 font-bold underline">
                          <span />
                        </a>,
                      ]}
                    />
                    <br />
                    <Trans
                      i18nKey="crossmintPurchaseCompleteText3"
                      components={[
                        <a href="/inventory" target="_blank" rel="noreferrer" className="text-blue-550 font-bold underline">
                          <span />
                        </a>,
                      ]}
                    />
                  </p>
                </div>
                <div className="max-w-[500px]">
                  <Link to="/inventory" replace>
                    <Button text="View in inventory" dark />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CrossmintPurchaseCompletePass;
