import React from 'react';
import { useMediaQuery } from 'react-responsive';

function AsicsDropShowcase() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const photo1 = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/DESKTOP-SHOE-INTRODUCTION-2.png';
  const photo2 = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/DESKTOP-OUTRO-IMAGE-2.png';
  const photo3 = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/mobile/jwasics-mobile-2.jpg';
  const photo4 = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/mobile/MOBILE-OUTRO-IMAGE-2.png';

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-center mx-4 md:mx-16 mt-4 md:mt-20 text-gray-350 font-inter">
        <div className="flex flex-col-reverse md:flex-row md:mx-10 md:mb-4 gap-4 md:gap-8">
          <div className="flex flex-col gap-2 md:gap-4 md:w-[90%] text-white justify-start md:mt-0">
            <h1 className="text-grotesk text-4xl font-semibold">
              SNEAKMART x JW CUSTOMS x ASICS
            </h1>
            <div className="flex flex-col gap-2 md:gap-4 text-inter text-lg">
              <p>
                Introducing the GEL NYC “KB to NY”, a one of a kind collaborative Sneaker between Sneakmart, JW Customs and Asics.
              </p>
              <p>
                Each pair is carefully hand-crafted by Jakob Werner, aka JW Customs, making each one very unique. Only 20 pairs made, linked to their digital Sneakmart+ version. The perfect 2 in 1 sneaker.
              </p>
              <p>
                The Sneakmart+ version is a digital asset acting as a membership unlocking special access to private drops on Physical Streetwear items and other perks within the Sneakmart ecosystem.
              </p>
              <p>
                You can unlock these perks on https://plus.sneakmart.com/inventory and soon to come directly on the Sneakmart app.
              </p>
            </div>
          </div>
          <div className="flex md:w-[180%] md:h-[500px] items-center rounded-xl overflow-hidden">
            <img
              className="w-full"
              src={!isTabletOrMobile ? photo1 : photo3}
              alt="custom asics shoe made with Sneakmart and JW Customs"
            />
          </div>
        </div>
      </div>
      <div className="mx-4 md:mx-10 md:mb-4 mt-4 md:mt-6 rounded-xl overflow-hidden">
        <img
          className="w-full"
          src={!isTabletOrMobile ? photo2 : photo4}
          alt=""
        />
      </div>
    </div>
  );
}

export default AsicsDropShowcase;
