import create from 'zustand';
import { StaniflouColorway } from '../../../appState';

export interface StaniflouDropStore {
  currentChoosenColorway: StaniflouColorway;
  updateCurrentChoosenColorway: (newColorway: StaniflouColorway) => void;
}

export const useStaniflouDrop = create<StaniflouDropStore>((set) => ({
  currentChoosenColorway: StaniflouColorway.MOCHA,
  updateCurrentChoosenColorway: (newColorway: StaniflouColorway) => set({ currentChoosenColorway: newColorway }),
}));
