import { ThunkResult } from '../../../store';
import { StackingGateway } from '../../gateways/stackingGateway';
import { Actions } from './actionCreators';

export const unstackFlowerKey = ({ flowerId, walletAddress }: {flowerId: string, walletAddress: string}): ThunkResult<Promise<void>> => async (
  dispatch,
  getState,
  { stackingGateway }: {
    stackingGateway: StackingGateway,
  },
) => {
  dispatch(Actions.unstackFlowerKey(flowerId));

  await stackingGateway.unstackFlowerKey(flowerId, walletAddress);

  dispatch(Actions.flowerKeyUnstacked(flowerId));
};
