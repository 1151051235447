import React from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import Header from '../../../_main/Header';
import NewFooter from '../../../components/Home/Sections/NewFooter';
import AutomaticPlayer from '../../../_main/Video/AutomaticPlayer';
import PortalKeyDropCheckout from '../../../components/PortalKey/PortalKeyDropCheckout';

const videoUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/mtks-pass.mp4';

const drop = {
  title: 'Portal Key',
  partners: 'Sneakmart',
  price: 150,
  description: 'The Sneakmart+ Portal Key acts like a pass, and is your key to the Sneakmart+ universe.',
  description2: 'Each key holder is eligible for monthly physical airdrops and retail drops of highly coveted Sneakers & Streetwear, claimable in your inventory.',
  preOrderText: '',
};

function PortalKeyDropPage() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="bg-black">
      <Header dark />
      <div className="mx-4 md:mx-16">
        <div className="flex flex-col md:flex-row items-end pt-20 md:pt-28 mb-14 gap-4 md:gap-16">
          <div className="flex 2xl:justify-center w-full h-full rounded-xl overflow-hidden">
            {isTabletOrMobile ? (
              <div className="rounded-lg overflow-hidden w-auto h-auto">
                <AutomaticPlayer>
                  <ReactPlayer
                    height="100%"
                    width="100%"
                    loop
                    playsinline
                    muted
                    url={videoUrl}
                  />
                </AutomaticPlayer>
              </div>
            ) : (
              <div className="rounded-lg overflow-hidden">
                <AutomaticPlayer>
                  <ReactPlayer
                    height="600px"
                    width="600px"
                    loop
                    playsinline
                    muted
                    url={videoUrl}
                  />
                </AutomaticPlayer>
              </div>
            )}
          </div>
          <div className="flex flex-col w-full text-gray-350 font-inter text-lg font-medium gap-8">
            <PortalKeyDropCheckout drop={drop} />
          </div>
        </div>
        <NewFooter />
      </div>
    </div>
  );
}

export default PortalKeyDropPage;
