import { Inventory, StaniflouColorway } from '../../../appState';
import { InventoryGateway } from '../../../core/gateways/inventoryGateway';

export const sampleInventory: Inventory = {
  portalKeys: [
    {
      id: '12',
    },
  ],
  freeDrops: [
    {
      id: 'id1',
      // itemBought: {
      //   name: 'Nike Dunkkkk Low Syracuse (2022) / Nike',
      //   image:
      //     'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
      // },
      itemBought: null,
      items: [
        {
          id: 'id2',
          name: 'Casquette Flower',
          image:
            'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
          price: 0,
          type: 'accessories',
          allSizes: true,
          sizes: [],
        },
        {
          id: 'id2',
          name: 'Nike Dunk Low Syracuse (2022)',
          image:
            'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
          price: 120,
          type: 'sneakers',
          allSizes: false,
          sizes: [
            {
              size: '42',
              quantity: 0,
            },
          ],
        },
        {
          id: 'id2',
          name: 'Nike Dunk Low Syracuse (2024)',
          image:
            'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
          price: 120,
          type: 'sneakers',
          allSizes: false,
          sizes: [
            {
              size: '42',
              quantity: 2,
            },
          ],
        },
        {
          id: 'id2',
          name: 'Nike Dunk Low Syracuse (2025) / Nike',
          image:
            'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
          price: 120,
          type: 'sneakers',
          allSizes: false,
          sizes: [
            {
              size: '42',
              quantity: 0,
            },
          ],
        },
      ],
    },
  ],
  retailDrops: [
    {
      id: 'retailDropId1',
      // itemBought: {
      //   name: 'Nike Dunk Low Syracuse (2022) / Jordannnzzz',
      //   image:
      //     'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
      // },
      itemBought: null,
      items: [
        {
          id: 'id1',
          name: 'Nike Dunk Low Syracuse (2021) / Nike',
          image:
            'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
          price: 120,
          type: 'sneakers',
          allSizes: false,
          sizes: [
            {
              size: '42',
              quantity: 0,
            },
            {
              size: '43',
              quantity: 0,
            },
            {
              size: '44',
              quantity: 0,
            },
            {
              size: '45',
              quantity: 0,
            },
            {
              size: '46',
              quantity: 0,
            },
            {
              size: '47',
              quantity: 0,
            },
            {
              size: '48',
              quantity: 0,
            },
          ],
        },
        {
          id: 'id2',
          name: 'Jordan 1 High Zoom Air CMFT - Fossile Stone-White / Jordan',
          image:
            'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
          price: 120,
          type: 'sneakers',
          allSizes: false,
          sizes: [
            {
              size: '42',
              quantity: 5,
            },
          ],
        },
        {
          id: 'id3',
          name: 'Jordan 2 Retro Low SE Nina Chanel Abney White Malachite (Womens) / Jordan',
          image:
            'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
          price: 120,
          type: 'sneakers',
          allSizes: false,
          sizes: [
            {
              size: '42',
              quantity: 5,
            },
          ],
        },
        {
          id: 'id3',
          name: 'Nike Dunk Low Syracuse (2025) / Nike',
          image:
            'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
          price: 120,
          type: 'sneakers',
          allSizes: false,
          sizes: [
            {
              size: '42',
              quantity: 0,
            },
          ],
        },
      ],
    },
  ],
  privateDrops: [
    {
      id: 'id1',
      itemBought: {
        name: 'Nike Dunk Low Syracuse (2022) / Jordannnzzz',
        image:
          'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
      },
      items: [
        {
          id: 'id5',
          name: 'Nike Dunk Low Syracuse (2021) / Nike',
          image:
            'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
          price: 120,
          type: 'sneakers',
          allSizes: true,
          sizes: [
            {
              size: '42',
              quantity: 0,
            },
          ],
        },
      ],
    },
    {
      id: 'id2',
      itemBought: {
        name: 'Nike Dunk Low Syracuse (2022) / Jordannnzzz',
        image:
          'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
      },
      items: [
        {
          id: 'id5',
          name: 'Nike Dunk Low Syracuse (2022) / Nike',
          image:
            'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
          price: 120,
          type: 'sneakers',
          allSizes: false,
          sizes: [
            {
              size: '42',
              quantity: 1,
            },
          ],
        },
      ],
    },
    {
      id: 'id3',
      itemBought: null,
      items: [
        {
          id: 'id5',
          name: 'Nike Dunk Low Syracuse (2023) / Jordan',
          image:
            'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
          price: 120,
          type: 'sneakers',
          allSizes: false,
          sizes: [
            {
              size: '42',
              quantity: 4,
            },
          ],
        },
      ],
    },
    {
      id: 'id4',
      itemBought: null,
      items: [
        {
          id: 'id5',
          name: 'Nike Dunk Low Syracuse (2024) / Adidas',
          image:
            'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/dunk-low-syracuse.png',
          price: 120,
          type: 'sneakers',
          allSizes: false,
          sizes: [
            {
              size: '42',
              quantity: 0,
            },
          ],
        },
      ],
    },
  ],
  stans: [
    {
      id: '1',
      colorway: StaniflouColorway.AQUA,
      currentLevel: 1,
      currentMonth: 1,
      isCurrentlyStacked: false,
    },
    {
      id: '2',
      colorway: StaniflouColorway.MOCHA,
      currentLevel: 1,
      currentMonth: 1,
      isCurrentlyStacked: true,
    },
  ],
  flowers: [
    {
      id: '30',
    },
  ],
  jwasics: [
    {
      id: '17',
      currentLevel: 1,
      currentMonth: 1,
      isCurrentlyStacked: false,
    },
  ],
};

export class FakeInventoryGateway implements InventoryGateway {
  private _inventory: Inventory | null = null;

  retrieve(): Promise<Inventory | null> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this._inventory);
      }, 1000);
    });
  }

  set inventory(value: Inventory) {
    this._inventory = value;
  }
}
