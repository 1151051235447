import { EthereumWallet } from '../../appState';
import * as subscribeToEthereumWalletActionCreators from '../usecases/ethereum-wallet-retrieval/actionCreators';
import * as subscribeToDeconnectEthereumWalletActionCreators from '../usecases/ethereum-wallet-deconnection/actionCreators';

export const ethereumWalletReducer = (
  state: EthereumWallet | null = null,
  action: subscribeToEthereumWalletActionCreators.Actions | subscribeToDeconnectEthereumWalletActionCreators.Actions,
) => {
  switch (action.type) {
    case 'ETHEREUM_WALLET_RETRIEVED':
      return action.payload.ethereumWallet;
    case 'DECONNECT_ETHEREUM_WALLET':
      return null;
    default: return state;
  }
};
