import { ThunkResult } from '../../../store';
import { AsicsDropGateway } from '../../gateways/asicsDropGateway';
import { Actions } from './actionCreators';

export const checkAsicsConfirmation: (asicsOrderId: string) => ThunkResult<Promise<void>> = (asicsOrderId) => async (
  dispatch,
  getState,
  { asicsDropGateway }: {
    asicsDropGateway: AsicsDropGateway,
  },
) => {
  dispatch(Actions.checkAsicsConfirmation());
  const check = await asicsDropGateway.checkConfirmation(asicsOrderId);
  dispatch(Actions.asicsConfirmationChecked(check.orDefault(null)));
};
