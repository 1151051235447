import { CrossmintEnvironment } from '@crossmint/connect';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import './index.css';

/* SCREENS */
import LanguageDetector from 'i18next-browser-languagedetector';
// import App from './App';
import Error404 from './primary-adapters/react/screens/404';
import CrossmintPurchaseCompletePass from './primary-adapters/react/screens/crossmint-purchase-complete-pass';
import Inventory from './primary-adapters/react/screens/inventory';
import NavigationWrapper from './primary-adapters/react/screens/navigation-wrapper';
import reportWebVitals from './reportWebVitals';
/* ERROR */
import ErrorModal from './primary-adapters/react/_main/Error/ErrorModal';

/* CSS */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/* EVENTS-HANDLER */
import { listenMetamaskAccount } from './primary-adapters/event-handlers/listenMetamaskAccount';

/* ADAPTERS */
import { retrieveEthereumWallet } from './core/usecases/ethereum-wallet-retrieval/retrieveEthereumWallet';
import { EtherJsEthereumWalletGateway } from './secondary-adapters/gateways/etherjs/EtherJsEthereumWalletGateway';
import { FakeEthereumWalletGateway, sampleEthereumWallet } from './secondary-adapters/gateways/fake/FakeEthereumWalletGateway';
import { FakeInventoryGateway, sampleInventory } from './secondary-adapters/gateways/fake/FakeInventoryGateway';
import { FakeStackingGateway, sampleHistory, sampleStacking } from './secondary-adapters/gateways/fake/FakeStackingGateway';
import { HttpStackingGateway } from './secondary-adapters/gateways/http/HtppStackingGateway';
import { HttpInventoryGateway } from './secondary-adapters/gateways/http/HttpInventoryGateway';
import { InMemoryEthereumWalletGateway } from './secondary-adapters/gateways/in-memory/InMemoryEthereumWalletGateway';
import { InMemoryInventoryGateway } from './secondary-adapters/gateways/in-memory/InMemoryInventoryGateway';
import { InMemoryStackingGateway } from './secondary-adapters/gateways/in-memory/InMemoryStackingGateway';
import { configureStore } from './store';

/* GATEWAY */
import { Integration } from './appState';
import { EthereumWalletGateway } from './core/gateways/ethereumWalletGateway';
import { MadHunnyDropGateway } from './core/gateways/madHunnyDropGateway';
import { StripeGateway } from './core/gateways/stripeGateway';
import { initi18n } from './core/i18n/index';
import AsicsDropPage from './primary-adapters/react/screens/drops/Asics/asics-drop-buy-page';
import AsicsDropLanding from './primary-adapters/react/screens/drops/Asics/asics-landing';
import MadHunnyConfirmation from './primary-adapters/react/screens/drops/MadHunny/mad-hunny-confirmation';
import MadHunnyDropLanding from './primary-adapters/react/screens/drops/MadHunny/madHunny-drop-landing';
import MadHunnyDropPage from './primary-adapters/react/screens/drops/MadHunny/madHunny-drop-buy-page';
import MetakicksFlowerItem from './primary-adapters/react/screens/drops/MadHunny/single-mad-hunny-item';
import PortalKeyDropPage from './primary-adapters/react/screens/drops/PortalKey/portalKey-drop-buy-page';
import PortalKeyDropLanding from './primary-adapters/react/screens/drops/PortalKey/portalKey-drop-landing';
import MetakicksStaniflouItem from './primary-adapters/react/screens/drops/Staniflou/single-metakicks-staniflou-item';
import StaniflouColorwayBuy from './primary-adapters/react/screens/drops/Staniflou/staniflou-colorway-buy-page';
import StaniflouDropLanding from './primary-adapters/react/screens/drops/Staniflou/staniflou-drop-landing';
import CrossmintPurchaseCompleteStaniflou from './primary-adapters/react/screens/drops/crossmint-purchase-complete-staniflou';
import DropBuy from './primary-adapters/react/screens/inventory/drop/buy';
import DropBuyConfirm from './primary-adapters/react/screens/inventory/drop/confirm';
import PortalKeys from './primary-adapters/react/screens/inventory/portal-keys';
import SingleFlower from './primary-adapters/react/screens/inventory/single-flower';
import SinglePortalKey from './primary-adapters/react/screens/inventory/single-portal-key';
import StackingAward from './primary-adapters/react/screens/inventory/stacking-award/redeem';
import HomeLanding from './primary-adapters/react/screens/landing/home-landing';
import MysteryBox from './primary-adapters/react/screens/projects/mystery-box';
import { FakeMadHunnyDropGateway } from './secondary-adapters/gateways/fake/FakeMadHunnyDropGateway';
import { FakeStripeGateway, sampleStripeClientSecret } from './secondary-adapters/gateways/fake/FakeStripeGateway';
import { HttpMadHunnyGateway } from './secondary-adapters/gateways/http/HttpMadHunnyGateway';
import { HttpStripeGateway } from './secondary-adapters/gateways/http/HttpStripeGateway';
import { InMemoryMadHunnyDropGateway } from './secondary-adapters/gateways/in-memory/InMemoryMadHunnyDropGateway';
import StackingAwardBuyConfirm from './primary-adapters/react/screens/inventory/stacking-award/award-buy-confirm';
import { InMemoryStripeGateway } from './secondary-adapters/gateways/in-memory/InMemoryStripeGateway';
import AsicsConfirmation from './primary-adapters/react/screens/drops/Asics/asics-confirmation';
import AsicsItem from './primary-adapters/react/screens/drops/Asics/single-asics-item';
import SingleAsics from './primary-adapters/react/screens/inventory/single-asics';
import { AsicsDropGateway } from './core/gateways/asicsDropGateway';
import { InMemoryAsicsDropGateway } from './secondary-adapters/gateways/in-memory/InMemoryAsicsDropGateway';
import { FakeAsicsDropGateway } from './secondary-adapters/gateways/fake/FakeAsicsDropGateway';
import { HttpAsicsGateway } from './secondary-adapters/gateways/http/HttpAsicsGateway';
import LarveeEmeraldRaffleLanding from './primary-adapters/react/screens/drops/Larvee/Emerald/larvee-emerald-landing';
import SingleLarveeEmerald from './primary-adapters/react/screens/inventory/single-larvee-emerald';
import LarveeIceRaffleLanding from './primary-adapters/react/screens/drops/Larvee/Ice/larvee-ice-landing';
import SingleLarveeIce from './primary-adapters/react/screens/inventory/single-larvee-ice';

let stripeGateway: StripeGateway;
let madHunnyDropGateway: MadHunnyDropGateway;
let ethereumWalletGateway: EthereumWalletGateway;
let asicsDropGateway: AsicsDropGateway;
let inventoryGateway;
let stackingGateway;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const endPointApiMetakicks = process.env.REACT_APP_METAKICKS_API_END_POINT
  ? process.env.REACT_APP_METAKICKS_API_END_POINT
  : 'http://localhost:4000';

if (process.env.REACT_APP_IN_MEMORY === 'true') {
  stripeGateway = new InMemoryStripeGateway();
  ethereumWalletGateway = new InMemoryEthereumWalletGateway();
  inventoryGateway = new InMemoryInventoryGateway();
  madHunnyDropGateway = new InMemoryMadHunnyDropGateway();
  asicsDropGateway = new InMemoryAsicsDropGateway();
  inventoryGateway.inventory = sampleInventory;
  (ethereumWalletGateway as InMemoryEthereumWalletGateway).ethereumWallet = {
    signerAddress: '0x5618449c2A2f38E1efd9B9c589b97e7AF367E4B3',
    signerBalance: 10,
  };
  (stripeGateway as InMemoryStripeGateway).stripeClientSecret = sampleStripeClientSecret;
  stackingGateway = new InMemoryStackingGateway();
  stackingGateway.stacking = sampleStacking;
  stackingGateway.history = sampleHistory;
} else if (process.env.REACT_APP_FAKE === 'true') {
  stripeGateway = new FakeStripeGateway();
  ethereumWalletGateway = new FakeEthereumWalletGateway();
  (ethereumWalletGateway as FakeEthereumWalletGateway).ethereumWallet = sampleEthereumWallet;
  inventoryGateway = new FakeInventoryGateway();
  madHunnyDropGateway = new FakeMadHunnyDropGateway();
  asicsDropGateway = new FakeAsicsDropGateway();
  inventoryGateway.inventory = sampleInventory;
  (stripeGateway as FakeStripeGateway).stripeClientSecret = sampleStripeClientSecret;
  stackingGateway = new FakeStackingGateway();
  stackingGateway.stacking = sampleStacking;
  stackingGateway.history = sampleHistory;
} else {
  ethereumWalletGateway = new EtherJsEthereumWalletGateway();
  madHunnyDropGateway = new HttpMadHunnyGateway();
  stackingGateway = new HttpStackingGateway();
  (stackingGateway as HttpStackingGateway).axiosClient = endPointApiMetakicks;
  (madHunnyDropGateway as HttpMadHunnyGateway).axiosClient = endPointApiMetakicks;
  inventoryGateway = new HttpInventoryGateway();
  (inventoryGateway as HttpInventoryGateway).axiosClient = endPointApiMetakicks;
  stripeGateway = new HttpStripeGateway();
  asicsDropGateway = new HttpAsicsGateway();
  (asicsDropGateway as HttpAsicsGateway).axiosClient = endPointApiMetakicks;
  (stripeGateway as HttpStripeGateway).axiosClient = endPointApiMetakicks;
  (ethereumWalletGateway as EtherJsEthereumWalletGateway).crossmintEnvironment = process.env.REACT_APP_CROSSMINT_ENVIRONMENT! === 'staging' ? CrossmintEnvironment.STAGING : CrossmintEnvironment.PROD;

  const handleEthereum = () => {
    (ethereumWalletGateway as EtherJsEthereumWalletGateway).metamask = (window as any).ethereum;
  };

  if ((window as any)?.ethereum) {
    handleEthereum();
  } else {
    window.addEventListener('ethereum#initialized', handleEthereum, {
      once: true,
    });

    setTimeout(handleEthereum, 3000);
  }
}

const store = configureStore({
  inventoryGateway,
  ethereumWalletGateway,
  madHunnyDropGateway,
  stripeGateway,
  stackingGateway,
  asicsDropGateway,
}, persistConfig);

const persistor = persistStore(store);

history.scrollRestoration = 'manual';

listenMetamaskAccount(() => store.dispatch(retrieveEthereumWallet(Integration.Metamask)));

initi18n(initReactI18next, {
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'],
    cookieMinutes: 10,
    htmlTag: document.documentElement,
  },
}, LanguageDetector);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <NavigationWrapper>
            <Routes>
              <Route path="/" element={<HomeLanding />} />
              {(process.env.REACT_APP_PROTECT_ACCESS_METAKICKS !== 'true' || process.env.REACT_APP_PROTECT_ACCESS_PASS !== 'true') && (
                <Route path="/inventory" element={<Inventory />} />
              )}
              <Route path="/mystery-box" element={<MysteryBox />} />
              <Route path="/drop/portal-key" element={<PortalKeyDropLanding />} />
              <Route path="/drop/portal-key/buy" element={<PortalKeyDropPage />} />

              {process.env.REACT_APP_PROTECT_ACCESS_PASS !== 'true' && (
                <>
                  <Route path="/inventory/portal-keys" element={<PortalKeys />} />
                  <Route path="/inventory/portal/:portalKeyId" element={<SinglePortalKey />} />
                  <Route path="/get-a-pass/crossmint-purchase-complete" element={<CrossmintPurchaseCompletePass />} />
                  <Route path="/inventory/stacking/redeem/:awardId" element={<StackingAward />} />
                  <Route path="/inventory/award/buy/complete/:orderId" element={<StackingAwardBuyConfirm />} />
                  <Route path="/inventory/drop/buy/:type/:dropId/:itemId" element={<DropBuy />} />
                  <Route path="/inventory/drop/buy/confirm/:orderId" element={<DropBuyConfirm />} />
                </>
              )}
              {/* Mad Hunny Routes */}
              <Route path="/drop/mad-hunny" element={<MadHunnyDropLanding />} />
              <Route path="/drop/mad-hunny/buy" element={<MadHunnyDropPage />} />
              <Route path="/drop/mad-hunny/buy/complete/:flowerOrderId" element={<MadHunnyConfirmation />} />
              <Route path="/drop/mad-hunny/:metakicksFlowerId" element={<MetakicksFlowerItem />} />
              <Route path="/inventory/mad-hunny/:flowerId" element={<SingleFlower />} />
              {/* Asics Routes */}
              <Route path="/drop/asics" element={<AsicsDropLanding />} />
              <Route path="/drop/asics/buy" element={<AsicsDropPage />} />
              <Route path="/drop/asics/buy/complete/:asicsOrderId" element={<AsicsConfirmation />} />
              <Route path="/drop/asics/:metakicksAsicsId" element={<AsicsItem />} />
              <Route path="/inventory/asics/:metakicksAsicsId" element={<SingleAsics />} />
              {/* Laarvee Emerald Routes */}
              <Route path="/drop/laarvee/emerald" element={<LarveeEmeraldRaffleLanding />} />
              <Route path="/inventory/laarvee/emerald/:larveeEmeralId" element={<SingleLarveeEmerald />} />
              {/* Laarvee Ice Routes */}
              <Route path="/drop/laarvee/ice" element={<LarveeIceRaffleLanding />} />
              <Route path="/inventory/laarvee/ice/:larveeIceId" element={<SingleLarveeIce />} />
              {/* Staniflou Routes */}
              <Route path="/drop/staniflou" element={<StaniflouDropLanding />} />
              <Route path="/drop/staniflou/buy" element={<StaniflouColorwayBuy />} />
              {process.env.REACT_APP_PROTECT_ACCESS_COLLAB_DROPS !== 'true' && (
                <>
                  <Route path="/drop/staniflou/customize/crossmint-purchase-complete" element={<CrossmintPurchaseCompleteStaniflou />} />
                  <Route path="/drop/staniflou/:metakicksStaniflouId" element={<MetakicksStaniflouItem />} />
                </>
              )}
              <Route
                path="*"
                element={<Error404 />}
              />
            </Routes>
          </NavigationWrapper>
        </BrowserRouter>
        <ErrorModal />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
