import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import shallow from 'zustand/shallow';
import PointList from '../../Projects/Pass/PointList';
import PickStaniflouColorways from './ColorButtons';
import { useStaniflouDrop } from '../../../store/staniflouDropStore';
import { StaniflouColorway } from '../../../../../appState';

const colorways = [
  {
    name: 'mocha',
    value: StaniflouColorway.MOCHA,
    hex: [
      '#56421E',
      '#EFEAD5',
      '#A89B62',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-COFFEE.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-COFFEESCENE.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-COFFEE-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-COFFEE.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-COFFEE-COLORWAY.webp',
  },
  {
    name: 'sakura',
    value: StaniflouColorway.SAKURA,
    hex: [
      '#EFBABA',
      '#FFE4E3',
      '#72265D',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-CHERRY.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-CHERRY.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-CHERRY-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-CHERRY.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-CHERRY-COLORWAY.webp',
  },
  {
    name: 'gummy',
    value: StaniflouColorway.GUMMY,
    hex: [
      '#F7F1CF',
      '#BC879D',
      '#81B084',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-JADED.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-JADED.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-JADED-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-JADED.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-JADED-COLORWAY.webp',
  },
  {
    name: 'matcha',
    value: StaniflouColorway.MATCHA,
    hex: [
      '#FEE4E4',
      '#A6995F',
      '#752A76',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-ROCKY.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-ROCKY.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-ROCKY-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-ROCKY.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-ROCKY-COLORWAY.webp',
  },
  {
    name: 'aqua',
    value: StaniflouColorway.AQUA,
    hex: [
      '#B8D7EB',
      '#EACDAB',
      '#003C67',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-DESERT.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-DESERT.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-DESERT-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-DESERT.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-DESERT-COLORWAY.webp',
  },
];

function ColorSelection() {
  const [colorwayState, setColorwayState] = useState<number>(0);
  const { updateCurrentChoosenColorway } = useStaniflouDrop((state) => ({ updateCurrentChoosenColorway: state.updateCurrentChoosenColorway }), shallow);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  useTranslation();

  const points = [
    {
      id: 'id0',
      text:
  <p className="text-gray-350 text-sm md:text-[18px]">
    <Trans
      i18nKey="collabSneakerDescription1"
      components={[<span className="font-bold" />]}
    />
  </p>,
    },
    {
      id: 'id1',
      text:
  <p className="text-gray-350 text-sm md:text-[18px]">
    <Trans
      i18nKey="collabSneakerDescription2"
      components={[<span className="font-bold" />]}
    />
  </p>,
    },
    {
      id: 'id2',
      text:
  <p className="text-gray-350 text-sm md:text-[18px]">
    <Trans
      i18nKey="collabSneakerDescription3"
      components={[<span className="font-bold" />]}
    />
  </p>,
    },
  ];

  const onClickColorway = (e: React.MouseEvent<HTMLButtonElement>, colorway: StaniflouColorway) => {
    updateCurrentChoosenColorway(colorway);
    setColorwayState(e.currentTarget.value as unknown as number);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <div className="lg:col-span-2 grid grid-cols-1 gap-5 md:gap-20 md:gap-y-2 lg:grid-cols-2">
      <div className="flex flex-col mt-6 md:mt-12 lg:mt-0 h-full">
        <h1 className="flex items-end h-full font-extrabold text-gray-350 leading-[40px] md:leading-[50px] text-2xl md:text-4xl">
          SNEAKMART x STANIFLOU 1906D ZEN GARDEN
        </h1>
        {isTabletOrMobile && (
          <ul className="gap-4 flex flex-col">
              {points.map((point) => (
                <PointList key={point.id} text={point.text} />
              ))}
          </ul>
        )}
        {isTabletOrMobile && <img className="mt-5 object-cover rounded-xl" src={colorways[colorwayState].image} alt="all-sneakers" />}
        {!isTabletOrMobile && (
        <div className="h-full flex items-end">
          <ul className="gap-6 lg:gap-8 2xl:gap-10 flex mt-5 flex-col">
            {points.map((point) => (
              <PointList key={point.id} text={point.text} />
            ))}
          </ul>
        </div>
        )}
      </div>
      <div className="flex items-center">
        {!isTabletOrMobile && <img className="object-contain rounded-xl" src={colorways[colorwayState].image} alt="all-sneakers" />}
      </div>
      <div className="content-end md:mt-4" />
      <PickStaniflouColorways colorways={colorways} onClickColorway={onClickColorway} handleSubmit={handleSubmit} />
    </div>
  );
}

export default ColorSelection;
