import React from 'react';
import times from 'lodash/times';

interface Link {
  text: string;
  href: string
}

interface Props {
  text: string;
  extraLink?: Link;
}

function AnimationBannerSeparator({ text, extraLink }: Props) {
  return (
    <div className="w-screen bg-blue-550 flex items-center justify-center overflow-hidden">
      {times(3, (index) => (
        <div
          key={index}
          className="whitespace-nowrap font-grotesk animate-slide-left"
        >
          <span className="text-gray-350 px-10 font-bold text-[20px] md:text-[24px]">{text}</span>
          <span className="text-gray-350 px-10 font-bold text-[20px] md:text-[24px]">{text}</span>
          <span className="text-gray-350 px-10 font-bold text-[20px] md:text-[24px]">{text}</span>
          <span className="text-gray-350 px-10 font-bold text-[20px] md:text-[24px]">{text}</span>
          <span className="text-gray-350 px-10 font-bold text-[20px] md:text-[24px]">{text}</span>
          <span className="text-gray-350 px-10 font-bold text-[20px] md:text-[24px]">{text}</span>
          <span className="text-gray-350 px-10 font-bold text-[20px] md:text-[24px]">{text}</span>
          <span className="text-gray-350 px-10 font-bold text-[20px] md:text-[24px]">{text}</span>
          {extraLink && (
          <a target="_blank" rel="noreferrer" href={extraLink.href}>
            <span className="text-gray-350 px-10 font-bold text-[20px] md:text-[24px]">
              {extraLink.text}
            </span>
          </a>
          )}
        </div>
      ))}
    </div>
  );
}

export default AnimationBannerSeparator;
