import React from 'react';
import { useParams } from 'react-router-dom';
import NewFooter from '../../../components/Home/Sections/NewFooter';
import AsicsNotFound from '../../../components/Asics/AsicsNotFound';
import MetakicksAsicsDisplay from '../../../components/Asics/MetakicksAsicsDisplay';

type UrlParams = 'metakicksAsicsId'

function AsicsItem() {
  const urlParams = useParams<Record<UrlParams, string>>();
  const metakicksAsicsId = urlParams.metakicksAsicsId || '';

  return (
    <div className="w-screen min-h-screen bg-black text-gray-350">
      <div className="pt-[40px] overflow-x-hidden">
        <div className="m-auto max-w-screen-xl px-4 lg:px-10 2xl:px-0 py-8 md:py-20">
          <div>
            <div>
              {Number(metakicksAsicsId) < 1 || Number(metakicksAsicsId) > 20 ? (
                <AsicsNotFound />
              ) : (
                <MetakicksAsicsDisplay metakicksAsicsId={metakicksAsicsId} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 md:px-10">
        <NewFooter />
      </div>
    </div>
  );
}

export default AsicsItem;
