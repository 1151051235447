import React from 'react';
import times from 'lodash/times';

interface Link {
  text: string;
  href: string
}

interface Props {
  text: string;
  extraLink?: Link;
}

function MadHunnyBannerSeparator({ text, extraLink }: Props) {
  return (
    <div className="w-screen bg-transparent flex items-center justify-center overflow-hidden">
      {times(3, (index) => (
        <div
          key={index}
          className="whitespace-nowrap font-inter animate-slide-left-slow font-black text-[200px] md:text-[400px] leading-none"
        >
          <span className="text-[#FFBA52] px-10 ">{text}</span>
          <span className="text-[#FFBA52] px-10 ">{text}</span>
          <span className="text-[#FFBA52] px-10 ">{text}</span>
          <span className="text-[#FFBA52] px-10 ">{text}</span>

          {extraLink && (
          <a target="_blank" rel="noreferrer" href={extraLink.href}>
            <span className="text-[#FFBA52] px-10 font-extrabold text-[200px] leading-nonemd:text-9xl">
              {extraLink.text}
            </span>
          </a>
          )}
        </div>
      ))}
    </div>
  );
}

export default MadHunnyBannerSeparator;
