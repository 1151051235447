export const Error = {
  MetamaskNotConnected: {
    text: 'Your Metamask is not connected',
    help: 'Please connect to Metamask before buying a Sneakmart+ digital collectible or before trying to interact with the website.<br/><br/>If you are on mobile, go to the browser on the metamask application or click <span className="text-blue-550 underline"><a href="https://metamask.app.link/dapp/plus.sneakmart.com">here</a></span>.',
  },
  WalletConnectError: {
    text: 'Wallet Connect failed',
  },
  CryptoTransactionError: {
    text: 'Something went wrong during the transaction.',
  },
  WhitelistTransactionError: {
    text: 'Something went wrong: You are probably not on the list to access the drop 😟',
  },
  OpenMysteryBoxError: {
    text: 'Something went wrong during the opening',
  },
  ClaimMetakicksPrizeError: {
    text: 'Something went wrong: You are probably not on the list to access the drop 😟',
  },
  NeedToChooseAConnectionWallet: {
    text: 'You need to connect your wallet first',
  },
};
