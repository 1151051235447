import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { selectStackingVM } from '../../../../view-models-generation/generateStackingViewModel';
import StackingCurrentInformation from './StackingCurrentInformation';
import StackMenuView from './StackMenuView';
import StackingMenuLoading from './StackingMenuLoading';
import { selectEthereumWalletVM } from '../../../../view-models-generation/generateEthereumWalletViewModel';

function StackingMenu() {
  const { isLoading, stacking } = useSelector(selectStackingVM);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { address } = useSelector(selectEthereumWalletVM);
  const { t } = useTranslation();

  if (isLoading || !address) return <StackingMenuLoading />;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:mt-6 md:gap-2">
      <div>
        <StackingCurrentInformation
          portalKeys={stacking.keys!}
          flowers={stacking.flowers!}
          stans={stacking.stans!}
          jwasics={stacking.jwasics!}
        />
      </div>
      {!isTabletOrMobile && (stacking.keys || stacking.flowers || stacking.stans || stacking.jwasics) && <StackMenuView currentBalance={stacking.currentBalance!} />}
      <div className="px-5 w-full md:w-[700px] mt-2 m-auto flex flex-col gap-4">
        <p className="text-white">{t('stackingPause1')}</p>
        <p className="text-white">{t('stackingPause2')}</p>
        <p className="text-white">{t('stackingPause3')}</p>
      </div>
    </div>
  );
}

export default StackingMenu;
