import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { times } from 'lodash';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  image: string;
  fullWidth?: boolean;
}

function CatalogAwardItemSecured({
  name, image, fullWidth,
}: Props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1080px)' });
  const itemDescription = name.split('/')[0];
  const itemBrand = name.split('/')[1];
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className="w-full">
      <div className="relative flex flex-col gap-2" key={name}>
        <div className={`bg-gray-500 relative flex justify-center items-center ${(!fullWidth || (fullWidth && isTabletOrMobile)) && 'aspect-square md:aspect-square '} ${isTabletOrMobile ? 'w-[90%]' : 'w-full'} bg-opacity-30 rounded-lg flex-col`}>
          <div className="flex w-96 justify-center items-center px-0">
            <img src={image} className="h-56 mb-8" alt={`${name}`} />
            <div className="absolute bottom-0 rounded-b-lg w-full md:w-full h-6 md:h-7 bg-green-550">
              <div className="flex flex-row justify-center items-start overflow-hidden w-full h-full">
                <p className="text-white font-grotesk font-semibold text-md md:text-lg text-center">
                  {times(14, () => (
                    <span className="mx-1">
                      SECURED
                      {' '}
                    </span>
                  ))}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-start rounded-md py-2 px-2 absolute top-2 md:top-2 left-2">
            <p className="font-grotesk flex flex-row justify-center items-center gap-2 text-md md:text-lg bg-black rounded-lg py-1 px-2 font-semibold text-white">
              {currentLanguage === 'en' ? 'SECURED' : 'ACHETÉ'}
            </p>
          </div>
        </div>
        <div className="w-[90%] md:w-full h-full flex flex-col justify-start items-start">
          <p className="text-gray-350 font-grotesk font-semibold text-md md:text-xl text-start uppercase">{itemDescription}</p>
          <p className="font-inter font-medium text-md md:text-lg text-gray-450 text-center">{itemBrand}</p>
        </div>
      </div>
    </div>
  );
}

export default CatalogAwardItemSecured;
