import React from 'react';
import { useMediaQuery } from 'react-responsive';

interface Props {
    drop: {
        title: string,
        partners: string,
        price: number,
        description: string,
        description2: string,
        preOrderText?: string,
    }
    mobile?: boolean
}
function DropDescription({ drop, mobile }: Props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  return (
    !mobile ? (
      <>
        <div className="pb-0 md:pb-4">
          <h1 className="font-grotesk font-extrabold text-3xl md:text-4xl">
            {drop.title}
          </h1>
          <p className="text-xl text-gray-450">
            {drop.partners}
          </p>
          <p className="font-grotesk font-extrabold text-2xl">
            {drop.price}
            €
          </p>
        </div>
        <div className="flex flex-col gap-2 font-inter font-medium text-gray-450">
          {!isTabletOrMobile && (
            <>
              <p>
                {drop.description}
              </p>
              <p>
                {drop.description2}
              </p>
            </>
          )}
          {drop.preOrderText && (
            <p>
              <span className="text-blue-550">*</span>
              {drop.preOrderText}
            </p>
          )}
        </div>
      </>
    ) : (
      <div className="flex flex-col gap-2 font-inter font-medium text-gray-450 pt-4">
        <p className="text-2xl text-gray-350">
          Description:
        </p>
        <p>
          {drop.description}
        </p>
        <p>
          {drop.description2}
        </p>
      </div>

    )
  );
}

export default DropDescription;
