import { combineReducers } from 'redux';
import { AsicsConfirmationCheck } from '../../appState';
import * as subscribeToAsicsConfirmationCheckCheckActionCreators from '../usecases/asics-confirmation-check/actionCreators';

export const data = (
  state: AsicsConfirmationCheck | null = null,
  action: subscribeToAsicsConfirmationCheckCheckActionCreators.Actions,
) => {
  switch (action.type) {
    case 'ASICS_CONFIRMATION_CHECKED':
      return action.payload.asicsConfirmationCheck;
    case 'CHECK_ASICS_CONFIRMATION':
      return null;
    default: return state;
  }
};

export const isLoading = (
  state: boolean = false,
  action: subscribeToAsicsConfirmationCheckCheckActionCreators.Actions,
) => {
  switch (action.type) {
    case 'ASICS_CONFIRMATION_CHECKED':
      return false;
    case 'CHECK_ASICS_CONFIRMATION':
      return true;
    default: return state;
  }
};

export const checkAsicsConfirmationReducer = combineReducers({
  data,
  isLoading,
});
