import React from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import AutomaticPlayer from '../../../_main/Video/AutomaticPlayer';

const videos = [
  {
    url: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/video/desktop/website-laarvee-loop-1.mp4',
  },
  {
    url: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/video/desktop/website-laarvee-loop-2v2.mp4',
  },
  {
    url: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/video/desktop/website-laarvee-loop-3.mp4',
  },
];

function LarveeVideoStories() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="flex flex-none md:flex-row justify-center items-center gap-0 md:gap-2 h-full mx-4 md:mx-10 mt-4 md:pt-4">
      {!isTabletOrMobile ? (
        videos.map((video, index) => (
          <div
            key={index}
            className="rounded-xl object-contain transition-transform duration-500 overflow-hidden"
          >
            <AutomaticPlayer>
              <ReactPlayer
                height="100%"
                width="100%"
                loop
                playsinline
                muted
                url={video.url}
              />
            </AutomaticPlayer>
          </div>
        ))
      ) : (
        <div className="flex justify-center items-center rounded-xl aspect-story overflow-hidden transition-transform duration-500">
          <AutomaticPlayer>
            <ReactPlayer
              height="100%"
              width="100%"
              loop
              playsinline
              muted
              url={videos[1].url}
            />
          </AutomaticPlayer>
        </div>
      )}
    </div>
  );
}

export default LarveeVideoStories;
