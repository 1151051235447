import React, { useEffect, useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';
import { useMediaQuery } from 'react-responsive';

interface Props {
  children: React.ReactElement
}

export default function AutomaticPlayer({ children }: Props) {
  const myRef = useRef<HTMLDivElement>(null);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const [isMounted, setIsMouted] = useState(!isTabletOrMobile);

  const {
    inViewport,
  } = useInViewport(
    myRef,
  );

  useEffect(() => {
    if (isTabletOrMobile) {
      setTimeout(() => setIsMouted(true), 2000);
    }
  }, []);

  return (
    <div ref={myRef}>
      {React.isValidElement(children)
      && React.cloneElement<any>(children, { playing: isMounted ? inViewport : true })}
    </div>
  );
}
