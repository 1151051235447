import { ErrorDomain } from '../../../appState';
import { ActionsUnion, createAction } from '../../../store';

export const Actions = {
  createStripeBuyingSession: () => createAction('CREATE_STRIPE_BUYING_SESSION'),
  stripeBuyingSessionCreated: (clientSecret: string) => createAction('STRIPE_BUYING_SESSION_CREATED', { clientSecret }),
  createStripeBuyingSessionError: (error: ErrorDomain) => createAction('CREATE_STRIPE_BUYING_SESSION_ERROR', { error }),
};

export type Actions = ActionsUnion<typeof Actions>
