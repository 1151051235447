import React from 'react';
import { useStaniflouDrop } from '../../../store/staniflouDropStore';
import { StaniflouColorway } from '../../../../../appState';

interface Props {
    colorways: Array<{ name: string, value: StaniflouColorway, hex: Array<string>, image?: string, video?: string, poster?: string }>
    onClickColorway: (event: React.MouseEvent<HTMLButtonElement>, colorway: StaniflouColorway) => void,
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
    darkMode?: boolean,
    greyMode?: boolean,
}

function PickStaniflouColorways({
  colorways, onClickColorway, handleSubmit, darkMode, greyMode,
}: Props) {
  const { colorwayChoosen } = useStaniflouDrop((state) => ({ colorwayChoosen: state.currentChoosenColorway }));

  const handleOnClick = (colorway: StaniflouColorway) => (e: React.MouseEvent<HTMLButtonElement>) => {
    onClickColorway(e, colorway);
  };

  return (
    <div className="mt-2 md:mt-4">
      <form className="grid grid-cols-3 md:grid-cols-5 place-items-center pt-2 gap-x-4 gap-y-2 md:gap-4 w-full" onSubmit={handleSubmit}>
        {colorways.map((colorway, index) => (
          <button
            value={index}
            key={index}
            onClick={handleOnClick(colorway.value)}
            style={{ cursor: 'pointer' }}
            className="hover:text-blue-550"
          >
            <div key={colorway.name} className={`flex flex-col items-center w-full md:w-full md:gap-1 text-center text-lg md:text-xl font-bold ${darkMode ? 'text-gray-950' : greyMode ? 'text-gray-450' : 'text-gray-350'} `}>
              <div className={`grid grid-cols-3 border-2 rounded-md overflow-hidden ${darkMode ? 'border-gray-950' : 'border-gray-350'}`}>
                {colorway.hex.map((hex, index) => (
                  <div key={hex} className={`h-8 w-12 md:h-5 md:w-8  ${index === 0 && 'rounded-l-sm'} ${index === 2 && 'rounded-r-sm'}`} style={{ backgroundColor: `${hex}` }} />
                ))}
              </div>
              <div>
                <div className={`uppercase ${colorway.name === colorwayChoosen ? 'text-gray-350' : darkMode ? 'text-gray-950' : greyMode ? 'text-gray-450' : 'text-[#29310D]'}`}>
                  {colorway.name}
                </div>
              </div>
            </div>
          </button>
        ))}
      </form>
    </div>
  );
}

export default PickStaniflouColorways;
