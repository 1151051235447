import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { selectErrorModalVM } from '../../../view-models-generation/generateErrorModalViewModal';
import { clearError } from '../../../../core/usecases/error-clearing/clearError';
import Button from '../Form/Button';

if (typeof (window) !== 'undefined') {
  Modal.setAppElement('body');
}

function ErrorModal() {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const dispatch = useDispatch();
  const error = useSelector(selectErrorModalVM);

  const handleCloseModal = () => {
    setIsModalOpened(false);
    dispatch(clearError);
  };

  useEffect(() => {
    if (error) { setIsModalOpened(true); }
  }, [error]);

  return (
    <Modal
      isOpen={isModalOpened}
      onRequestClose={handleCloseModal}
      style={{
        overlay: {
          zIndex: 1000,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
        content: {
          zIndex: 1000,
          inset: 0,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
        },
      }}
    >
      <div className="relative max-h-[90vh] flex items-center justify-center m-auto h-screen w-[90vw] md:max-w-[700px]">
        <div className="w-full bg-gray-350 rounded-md overflow-hidden">
          <div className="bg-red-500 px-5 py-3">
            <p className="text-gray-350 text-[48px]">
              Error
            </p>
          </div>
          <div className="px-10 py-10">
            <p className="text-gray-950 text-[28px] lg:text-[40px] leading-[26px] lg:leading-[36px] font-bold" data-testid="error-text">{error?.text}</p>
            {error?.help && (
            <p className="mt-5 text-[16px] lg:text-[24px] leading-[18px] lg:leading-[30px] text-gray-950 font-semibold">
              {parse(error.help)}
            </p>
            )}
          </div>
          <div className="px-5 flex h-[100px] items-center justify-end border-t mx-5 border-gray-950">
            <div className="w-[150px] mr-5">
              <Button onClick={handleCloseModal} dark text="Close" />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ErrorModal;
