import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../_main/Form/Button';

function StackingEmpty() {
  const { t } = useTranslation();

  return (
    <div data-testid="inventory-empty" className="w-full text-gray-350">
      <p className="text-lg font-semibold lg:text-[32px]">
        Your
        {' '}
        <span className="text-blue-550">inventory</span>
        {' '}
        is empty.
        Buy a
        {' '}
        <span className="text-blue-550">pass</span>
        {' '}
        to start !
      </p>
      <div className="flex max-w-[500px]">
        <a
          href="https://opensea.io/collection/metakicks-portal-keys"
          target="_blank"
          className="flex w-full flex-row items-center gap-2 md:gap-5 py-2 md:py-4 justify-between"
          rel="noreferrer"
        >
          <Button text={t('buyPortalKey')} />
        </a>
      </div>
    </div>
  );
}

export default StackingEmpty;
