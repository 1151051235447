import React from 'react';
import { Link } from 'react-router-dom';

function MadHunnyBuyConfirmationAccepted() {
  return (
    <>
      <h1 className="font-grotesk text-5xl md:text-6xl text-white text-center font-bold mb-6">WE GOT YOU.</h1>
      <p className="text-center font-medium text-xl text-gray-450">
        You successfully bought the very unique
        Spedity Mad Hunny.
        You will be able to see your digital
        version in a few hours in your
        {' '}
        <Link to="/inventory" className="text-blue-550">
          inventory.
        </Link>
      </p>
    </>
  );
}

export default MadHunnyBuyConfirmationAccepted;
