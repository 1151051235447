import { ThunkResult } from '../../../store';
import { StackingGateway } from '../../gateways/stackingGateway';
import { Actions } from './actionCreators';

export const stackPortalKey = ({ portalKeyId, walletAddress }: {portalKeyId: string, walletAddress: string}): ThunkResult<Promise<void>> => async (
  dispatch,
  getState,
  { stackingGateway }: {
    stackingGateway: StackingGateway,
  },
) => {
  dispatch(Actions.stackPortalKey(portalKeyId));

  await stackingGateway.stackPortalKey(portalKeyId, walletAddress);

  dispatch(Actions.portalKeyStacked(portalKeyId));
};
