import React from 'react';

function PortalKeyLoading() {
  return (
    <div data-testid="loading">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="bg-gray-550 aspect-square w-full animate-pulse" />
        <div className="flex h-full justify-between flex-col">
          <div className="bg-gray-550 w-full h-20 mt-6 animate-pulse" />
          <div>
            <div className="bg-gray-550 w-5/6 animate-pulse h-8 mb-2" />
            <div className="bg-gray-550 w-full animate-pulse h-12 mb-5" />
            <div className="bg-gray-550 w-4/6 animate-pulse h-20" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortalKeyLoading;
