import find from 'lodash/find';
import { AppState } from '../../appState';

export const selectStackingVM = (state: AppState) => {
  const { isLoading } = state.stacking;
  const stackingData = state.stacking.data;

  return {
    isLoading,
    stacking: { ...stackingData, portalKeysTotal: state.stacking.data?.keys?.length || 0 },
  };
};

export const selectStackingAwardVM = (awardId: string | null) => (state: AppState) => {
  const award = find(state.stacking.data?.awards, (award) => award.id?.toString() === awardId?.toString());

  return {
    isLoading: state.stacking.isLoading,
    award,
  };
};

export const selectPortalkeyAwardVM = (keyId: string | null) => (state: AppState) => {
  const portalKey = find(state.stacking.data?.keys, (key) => key.portalKeyId?.toString() === keyId?.toString()) || null;

  return {
    isLoading: state.stacking.isLoading,
    portalKey,
  };
};

export const selectFlowerKeyAwardVM = (flowerKeyId: string | null) => (state: AppState) => {
  const flowerKey = find(state.stacking.data?.flowers, (flower) => flower.flowerId?.toString() === flowerKeyId?.toString()) || null;

  return {
    isLoading: state.stacking.isLoading,
    flowerKey,
  };
};

export const selectjwasicsKeyAwardVM = (jwasicsKeyId: string | null) => (state: AppState) => {
  const jwAsicKey = find(state.stacking.data?.jwasics, (jwAsic) => jwAsic.jwasicsId?.toString() === jwasicsKeyId?.toString()) || null;

  return {
    isLoading: state.stacking.isLoading,
    jwAsicKey,
  };
};

export const selectStanKeyAwardVM = (stanKeyId: string | null) => (state: AppState) => {
  const stanKey = find(state.stacking.data?.stans, (stan) => stan.stanId?.toString() === stanKeyId?.toString()) || null;

  return {
    isLoading: state.stacking.isLoading,
    stanKey,
  };
};

export const selectStackingHistoryVM = (state: AppState) => {
  const { isLoading } = state.stackingHistory;
  const history = state.stackingHistory.data;

  return {
    isLoading,
    history,
  };
};

export const selectCurrentStackingAwardRedeemVM = (state: AppState) => {
  const { isLoading } = state.currentStackingAwardRedeem;
  const redeem = state.currentStackingAwardRedeem.data;

  return {
    isLoading,
    redeem,
  };
};
