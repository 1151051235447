import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import AutomaticPlayer from '../../../_main/Video/AutomaticPlayer';

interface Props {
  title: string;
  videoURL: string;
  link: string;
  id: string;
}

function MyCollectionCard({
  title,
  videoURL,
  link,
  id,
}: Props) {
  return (
    <div key={`${id}-title`} className="w-40 md:w-96 aspect-square rounded-xl overflow-hidden">
      <Link to={link}>
        <div className="bg-gray-950 z-50 relative font-grotesk overflow-hidden rounded-xl">
          <div className="flex w-full justify-between absolute px-2 md:px-4 top-2">
            <div className="bg-gray-950 opacity-90 rounded-md py-1 px-2 leading-none md:py-2 md:px-3">
              <p className="text-gray-350 font-extrabold text-xs md:text-sm">
                {title}
              </p>
            </div>
            <div className="bg-gray-950 opacity-90 rounded-md py-1 px-2 leading-none md:py-2 md:px-3">
              <p className="text-gray-350 font-extrabold text-xs md:text-sm">
                #
                {id}
              </p>
            </div>
          </div>
          <AutomaticPlayer>
            <ReactPlayer
              url={videoURL}
              height="100%"
              width="100%"
              loop
              muted
              playsinline
            />
          </AutomaticPlayer>
        </div>
      </Link>
    </div>
  );
}

export default MyCollectionCard;
