import axios, { AxiosInstance } from 'axios';
import { Either, Right } from 'purify-ts';
import { ErrorDomain, AsicsConfirmationCheck, AsicsWhitelistCheck } from '../../../appState';
import { AsicsDropGateway } from '../../../core/gateways/asicsDropGateway';

export class HttpAsicsGateway implements AsicsDropGateway {
  private _axiosClient: AxiosInstance | null = null;

  async isWhitelist(email?: string): Promise<Either<ErrorDomain, AsicsWhitelistCheck | null>> {
    const isWhitelisted = await this._axiosClient!.get<boolean>('/jwasics/is-whitelist', {
      params: {
        email,
      },
    }).then((res) => res.data);

    return Right({
      isWhitelisted,
    });
  }

  async checkConfirmation(jwasicsOrderId: string): Promise<Either<ErrorDomain, AsicsConfirmationCheck | null>> {
    const status = await this._axiosClient!.get<string | null>('/jwasics-order/status-checking', {
      params: {
        jwasicsOrderId,
      },
    }).then((res) => res.data);

    return Right({
      status,
    });
  }

  async createBuyingSession(size: string, countryCode: string, email?: string): Promise<Either<ErrorDomain, string | null>> {
    const location = {
      countryCode,
    };

    const createStripeCheckoutUrl = await this._axiosClient!.post<string>('/jwasics/create-buying-session', {
      size,
      location,
      email,
    }).then((res) => res.data);

    return Right(createStripeCheckoutUrl);
  }

  set axiosClient(value: string) {
    this._axiosClient = axios.create({
      baseURL: `${value}/metakicks`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}
