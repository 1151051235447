/* eslint-disable max-len */
import React from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import { OpenSeaSvg } from '../../icons/svg';
import AutomaticPlayer from '../../_main/Video/AutomaticPlayer';

function Editions() {
  const { t } = useTranslation();

  return (
    <div className="w-screen py-8 md:py-20">
      <div className="max-w-screen-xl m-auto 2x:px-0 px-8 lg:px-16 2xl:px-0">
        <h1 className="font-extrabold text-gray-950 leading-[44px] text-[72px] lg:text-[140px] lg:leading-[90px] 2x:text-[240px] 2x:leading-[190px]">{t('specialTitle1')}</h1>
        <h1 className="font-extrabold text-gray-950 text-[32px] lg:text-[100px] 2x:text-[140px]">
          {t('specialTitle2')}

        </h1>
        <div className="grid grid-cols-1 mt-2 lg:grid-cols-2 items-center gap-8 md:gap-20">
          <div className="bg-gray-200 bg-contain bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/dourdarcels.jpg')] aspect-square">
            <AutomaticPlayer>
              <ReactPlayer
                playsinline
                url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/dourdarcel.mp4"
                muted
                width="100%"
                height="100%"
                loop
              />
            </AutomaticPlayer>
          </div>
          <div className="flex flex-col justify-between md:pt-5">
            <p className="text-sm md:text-xl text-gray-950">
              {t('specialText1')}
              <br />
              <br />
              {t('specialText2')}
              <span className="font-bold">
                {' '}
                <a target="_blank" href="https://www.dourdarcels.io/" rel="noreferrer" className="text-black underline">Dour Darcels</a>
                ,
                {' '}
                <a target="_blank" href="https://www.instagram.com/savoir_faire_paris/" rel="noreferrer" className="text-black underline">Savoir Faire Paris</a>
              </span>
              {' '}
              {t('specialText3')}
              <br />
              <br />
              {t('specialText4')}
              <br />
              <br />
              {t('specialText5')}
            </p>
            <div className="flex flex-row items-center mt-8 md:mt-4 gap-3">
              <OpenSeaSvg className="fill-gray-950" color="#202020" height="35" width="35" />
              <p className="text-gray-950 text-[20px] md:text-4xl font-extrabold">
                {t('comingSoonOpenSea')}

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Editions;
