import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import {
  InstagramSvg, TwitterSvg, DiscordSvg, OpenSeaSvg, SneakmartSmallWhiteLogo,
} from '../../icons/svg';
import SubscribeToNewsletter from './SubcribeToNewsletter';

const social = [
  {
    id: 'id0',
    icon: DiscordSvg,
    href: 'https://discord.gg/metakicks',
  },
  {
    id: 'id3',
    icon: OpenSeaSvg,
    href: 'https://opensea.io/collection/metakicks-portal-keys',
  },
  {
    id: 'id2',
    icon: InstagramSvg,
    href: 'https://www.instagram.com/sneakmart/',
  },
  {
    id: 'id1',
    icon: TwitterSvg,
    href: 'https://twitter.com/sneakmart',
  },
];

function Footer() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { t } = useTranslation();

  return (
    <div className="bg-gray-950 py-8 lg:pb-20">
      <div className="m-auto max-w-screen-xl px-8 lg:px-16 2xl:px-0">
        <p className="text-gray-350 leading-[25px] text-[22px] lg:text-[45px] lg:leading-[42px] uppercase font-extrabold">
          {t('newsletter1')}
          <br />
          {t('newsletter2')}
          <br />
          {t('newsletter3')}
        </p>
        <div className="w-full lg:w-5/12">
          <SubscribeToNewsletter />
        </div>
        <div className="flex flex-col lg:flex-row items-center lg:items-end mt-12 lg:mt-20 lg:justify-between">
          <SneakmartSmallWhiteLogo
            height="150"
            width="400"
            className="fill-gray-350 mt-1"
          />
          <div className="flex flex-row justify-center lgs:justify-start gap-4 lg:items-start mt-6 lg:mt-0">
            {social.map((item) => {
              const Icon = item.icon;
              if (isTabletOrMobile) {
                return (
                  <a href={item.href} key={item.id} target="_blank" rel="noreferrer">
                    <Icon height="40" width="40" className="fill-gray-350" />
                  </a>
                );
              }
              return (
                <a href={item.href} key={item.id} target="_blank" rel="noreferrer">
                  <Icon height="46" width="46" className="fill-gray-350 hover:fill-blue-550 transition-all duration-100" />
                </a>
              );
            })}
          </div>
          <ul className="flex flex-col items-center mt-4 lg:mt-0 lg:items-start">
            <li className="text-gray-350 font-lg">
              <a href="https://sneakmart.com/about/cookies" target="_blank" rel="noreferrer">PRIVACY POLICY</a>
            </li>
            <li className="text-gray-350 font-lg">TERMS & CONDITION</li>
            <li className="text-gray-350 font-lg">
              ©
              {new Date().getFullYear()}
              {' '}
              Sneakmart. All right reserved.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
