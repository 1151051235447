import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { retrieveInventory } from '../../../../../core/usecases/inventory-retrieval/retrieveInventory';
import { selectEthereumWalletVM } from '../../../../view-models-generation/generateEthereumWalletViewModel';
import { selectPortalKeyVM } from '../../../../view-models-generation/generatePortalKeyItemViewModel';
import PortalKeyDisplay from './PortalKeyDisplay';
import PortalKeyLoading from './PortalKeyLoading';
import PortalKeyNotFound from './PortalKeyNotFound';

type UrlParams = 'portalKeyId'

function PortalKeyItem() {
  const dispatch = useDispatch();
  const urlParams = useParams<Record<UrlParams, string>>();

  const portalKeyId = urlParams.portalKeyId || '';

  const { isLoading, portalKey } = useSelector(selectPortalKeyVM(portalKeyId));
  const { address } = useSelector(selectEthereumWalletVM);

  useEffect(() => {
    dispatch(retrieveInventory);
  }, [dispatch, address]);

  return (
    <div>
      <div>
        <h1 className="text-gray-350 text-[25px] lg:text-[90px] mb-6 font-extrabold">
          MY PORTAL KEY

        </h1>
        {isLoading ? <PortalKeyLoading /> : !portalKey ? <PortalKeyNotFound /> : (
          <PortalKeyDisplay portalKey={portalKey} />
        )}
      </div>
    </div>
  );
}

export default PortalKeyItem;
