import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeCard from '../HomeCard';

function PreviousDrops() {
  const { t } = useTranslation();

  const previousDrops = [
    {
      image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/image/desktop/home-laarvee-desktop-header.webp',
      imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/image/mobile/home-laarvee-mobile-header.webp',
      title: 'SNEAKMART X LARVEE',
      description: 'Sneakmart presents it’s first ever collaboration with new generation watch maker, Laarvee.',
      pageLink: '/drop/laarvee/emerald',
    },
    {
      image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/jwasics-desktop-hero-header-6.png',
      imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/asics-kb-header-7.png',
      title: 'SNEAKMART X ASICS X JW CUSTOMS',
      description: 'Discover a unique journey from Kobe to New York.',
      pageLink: '/drop/asics',
      fromColor: 'from-green-900',
    },
    {
      image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/mtks-flower-landing-hero.jpg',
      imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/flower-drop-mobile.png',
      title: 'FLOWER INSTINCTS X SNEAKMART',
      description: t('flowerDropDescription'),
      pageLink: '/drop/mad-hunny',
      fromColor: 'from-orange-600',
    },
    {
      image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/zen-garden-drop.jpg',
      imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/zen-garden-drop-mobile.jpg',
      title: 'SNEAKMART X STANIFLOU “ZEN GARDEN” COLLECTION',
      description: 'Handmade packaging and custom sneakers in France.',
      pageLink: '/drop/staniflou',
      fromColor: 'from-[#4D640A]',
    },
    {
      image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/portal-key-drop.jpg',
      imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/portal-key-drop-mobile.jpg',
      title: 'SNEAKMART PORTAL KEY',
      description: 'Where the journey started, for OG members.',
      pageLink: '/drop/portal-key',
      fromColor: 'from-blue-550',
    },
  ];

  return (
    <div id="drops" className="h-full">
      <div className="font-grotesk font-extrabold text-4xl md:text-5xl text-white uppercase  mb-4">
        Previous Drops
      </div>
      <div className="flex flex-col gap-8 w-full">
        {previousDrops.map((previousDrop) => (
          <HomeCard
            data={previousDrop}
            key={previousDrop.title}
            height="h-[550px] md:h-[600px]"
          />
        ))}
      </div>
    </div>
  );
}

export default PreviousDrops;
