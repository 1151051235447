import axios, { AxiosInstance } from 'axios';
import { Inventory, StaniflouColorway } from '../../../appState';
import { InventoryGateway } from '../../../core/gateways/inventoryGateway';

export class HttpInventoryGateway implements InventoryGateway {
  private _axiosClient: AxiosInstance | null = null;

  async retrieve(walletAddress: string): Promise<Inventory | null> {
    const inventory = await this._axiosClient!.get<InventoryView>(`/inventory/${walletAddress}`).then((res) => res.data);

    return {
      flowers: inventory.flowers,
      portalKeys: inventory.portalKeys,
      freeDrops: inventory.freeDrops,
      retailDrops: inventory.retailDrops,
      privateDrops: inventory.privateDrops,
      jwasics: inventory.jwasics,
      stans: inventory.stans,
      promoCodes: inventory.promoCodes,
    };
  }

  set axiosClient(value: string) {
    this._axiosClient = axios.create({
      baseURL: `${value}/metakicks`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}

export interface InventoryView {
  portalKeys: PortalKeyView[] | null;
  stans: StanView[] | null;
  flowers: FlowerView[] | null;
  freeDrops: FreeDropView[] | null;
  retailDrops: RetailDropView[] | null;
  privateDrops: PrivateDropView[] | null;
  jwasics: jwasicsView[] | null;
  promoCodes: string[] | null;
}

export interface PortalKeyView {
  id: string;
}

export interface FlowerView {
  id: string;
}

export interface jwasicsView {
  id: string;
  currentLevel: number;
  currentMonth: number;
  isCurrentlyStacked: boolean;
  stackedAt?: Date | null;
  dateOfLastGain?: Date | null;
}

export interface StanView {
  id: string;
  colorway: StaniflouColorway;
  currentLevel: number;
  currentMonth: number;
  isCurrentlyStacked: boolean;
  stackedAt?: Date | null;
  dateOfLastGain?: Date | null;
}

export interface FreeDropView {
  id: string;
  items: DropItemView[];
  itemBought: DropItemBoughtView | null;
}

export interface RetailDropView {
  id: string;
  items: DropItemView[];
  itemBought: DropItemBoughtView | null;
}

export interface PrivateDropView {
  id: string;
  items: DropItemView[];
  itemBought: DropItemBoughtView | null;
}

export interface DropItemView {
  id: string;
  name: string;
  image: string;
  price: number;
  allSizes: boolean;
  sizes: { size: string; quantity: number }[];
  type: 'sneakers' | 'clothes' | 'accessories';
}

export interface DropItemBoughtView {
  name: string;
  image: string;
}
