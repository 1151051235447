import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children: React.ReactNode
}

const NavigationWrapper = ({ children }: Props) => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === '') {
      document.documentElement.style.scrollBehavior = 'auto';
      setTimeout(() => window.scrollTo(0, 0), 5);
      setTimeout(() => { document.documentElement.style.scrollBehavior = 'smooth'; }, 5);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return <div>{children}</div>;
};

export default NavigationWrapper;
