import React from 'react';

interface Props {
  isOpened: boolean;
  children: React.ReactNode
}

export default function Drawer({ children, isOpened }: Props) {
  return (
    <main
      style={{
        zIndex: 100,
      }}
      className={
        `fixed overflow-hidden w-screen top-0 overflow-x-hidden inset-0 transform ease-in-out ${
          isOpened
            ? ' transition-opacity opacity-100 duration-500 translate-x-0  '
            : ' transition-all delay-500 opacity-0 translate-x-full  '}`
      }
    >
      <section
        className={
          ` w-screen z-100 right-0 absolute bg-black h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ${
            isOpened ? ' translate-x-0 ' : ' translate-x-full '}`
        }
      >
        <article className="relative w-full pb-10 flex flex-col space-y-6 overflow-y-scroll h-full">
          {children}
        </article>
      </section>
    </main>
  );
}
