import React from 'react';
import Header from '../../_main/Header';
import NewFooter from '../../components/Home/Sections/NewFooter';
import LarveeIceSingleItem from '../drops/Larvee/Ice/single-larvee-ice-item';

function SingleLarveeIce() {
  return (
    <div className="w-screen min-h-screen bg-black">
      <Header dark />
      <div>
        <LarveeIceSingleItem />
        <div className="px-6 md:px-16">
          <NewFooter />
        </div>
      </div>
    </div>
  );
}

export default SingleLarveeIce;
