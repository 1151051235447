import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../_main/Header';
import NewFooter from '../../../components/Home/Sections/NewFooter';
import DropBuyForm from '../../../components/Inventory/Drop/DropBuy/DropBuyForm';
import { DropType } from '../../../../../appState';

type UrlParams = 'type' | 'dropId' | 'itemId'

function DropBuy() {
  const params = useParams<Record<UrlParams, string>>();

  return (
    <div className="w-screen min-h-screen bg-black">
      <Header dark />
      <div className="pt-[80px] overflow-x-hidden">
        <div className="m-auto max-w-screen-xl px-8 lg:px-16 2xl:px-0">
          <DropBuyForm type={params.type as DropType} dropId={params.dropId!} itemId={params.itemId!} />
        </div>
      </div>
      <div className="px-5 md:px-10 mt-[-100px] md:mt-0 z-40">
        <NewFooter />
      </div>
    </div>
  );
}

export default DropBuy;
