import React from 'react';
import { useMediaQuery } from 'react-responsive';
import HomeCard from '../HomeCard';

const socials = [
  {
    title: 'Discord',
    link: 'https://discord.gg/metakicks',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/discord.jpg',
    imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/discord.jpg',
  },
  {
    title: 'Twitter',
    link: 'https://twitter.com/sneakmart',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/twitter.jpg',
    imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/twitter.jpg',
  },
  {
    title: 'Tiktok',
    link: 'https://www.tiktok.com/@sneakmart',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/tiktok.jpg',
    imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/tiktok.jpg',
  },
  {
    title: 'Instagram',
    link: 'https://www.instagram.com/sneakmart/',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/instagram.jpg',
    imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/instagram.jpg',
  },
  {
    title: 'Sneakmart App',
    link: 'https://onelink.to/sneakmartweb',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/download-app.jpg',
    imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/download-app.jpg',
  },
];

function JoinCommunity() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="h-full">
      <div className="font-grotesk font-extrabold text-4xl md:text-7xl text-center text-white uppercase my-10 md:my-20">
        Join the Sneakmart
        <br />
        community now.
      </div>
      <div className="grid grid-flow-rows grid-cols-4 md:grid-cols-6 grid-rows-3 md:grid-rows-2 gap-4 md:gap-8">
        {socials.map((social, index) => (
          <div key={social.title + index} className={`h-[100px] md:h-[260px] object ${!isTabletOrMobile ? `${index < 3 ? 'col-span-2' : 'col-span-3'}` : `${index < 4 ? 'col-span-2' : 'col-span-4'}`}  `}>
            <a href={social.link} target="_blank" key={social.title} rel="noreferrer">
              <HomeCard data={social} height="h-[100px] md:h-[250px]" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default JoinCommunity;
