import React from 'react';

function PortalKeyGridShowcase() {
  return (
    <div className="flex flex-col justify-center items-center gap-2 md:gap-4 px-4 md:px-20 mt-4 md:mt-8 2xl:px-[400px]">
      <div className="grid grid-cols-3 md:grid-cols-3 gap-2 md:gap-4">
        <img alt="mtks-pass-5" className="aspect-square rounded-xl" src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-grid-1.png" />
        <img alt="mtks-pass-5" className="aspect-square rounded-xl" src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-grid-2.png" />
        <img alt="mtks-pass-5" className="aspect-square rounded-xl" src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-grid-3.png" />
      </div>
      <div className="">
        <img
          alt="mtks-pass-5"
          className="w-full rounded-xl"
          src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-grid-4.png"
        />
      </div>
    </div>
  );
}

export default PortalKeyGridShowcase;
