import { ThunkResult } from '../../../store';
import { StackingGateway } from '../../gateways/stackingGateway';
import { Actions } from './actionCreators';

export const retrieveStacking = ({ walletAddress }: {walletAddress: string}): ThunkResult<Promise<void>> => async (
  dispatch,
  getState,
  { stackingGateway }: {
    stackingGateway: StackingGateway,
  },
) => {
  dispatch(Actions.retrieveStacking());

  const stacking = await stackingGateway.retrieve(walletAddress);

  dispatch(Actions.stackingRetrieved(stacking));
};
