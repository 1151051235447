import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../_main/Header';
import NewFooter from '../../../components/Home/Sections/NewFooter';
import { checkDropBuyingConfirmation } from '../../../../../core/usecases/drop-buying-confirmation-check/checkDropBuyingConfirmation';
import { selectCurrentDropConfirmation } from '../../../../view-models-generation/generateStripeSessionViewModel';

type UrlParams = 'orderId'

function DropBuyConfirm() {
  const params = useParams<Record<UrlParams, string>>();
  const dispatch = useDispatch();
  const { data } = useSelector(selectCurrentDropConfirmation);

  const orderId = params.orderId || '';

  useEffect(() => {
    if (orderId && (!data || data?.status === 'pending')) {
      const interval = setInterval(() => {
        dispatch(checkDropBuyingConfirmation(orderId));
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [orderId, data, dispatch]);

  const renderRefund = () => (
    <>
      <h1 className="font-grotesk text-[64px] text-white font-bold">OOPS.</h1>
      <p className="text-center font-medium text-[20px] text-gray-450">
        There was a problem with your transaction,
        it has failed.
        Please try again.
      </p>
    </>
  );

  const renderSucces = () => (
    <>
      <h1 className="font-grotesk text-5xl md:text-6xl text-white text-center font-bold mb-6">WE GOT YOU.</h1>
      <p className="text-center font-medium text-xl text-gray-450">
        You successfully bought the very unique
        {' '}
        {data?.productName}
        .
        You will receive an email when your item is shipped
      </p>
    </>
  );

  const renderLoading = () => (
    <div>
      <div className="bg-gray-550 w-[250px] md:w-[200px] h-8 m-auto mb-6 animate-pulse" />
      <div>
        <div className="bg-gray-550 w-[350px] md:w-[500px] animate-pulse h-8 mb-2" />
        <div className="bg-gray-550 w-[350px] md:w-[500px] animate-pulse h-8" />
      </div>
    </div>
  );

  return (
    <div className="w-screen min-h-screen bg-black">
      <Header dark />
      <div className="mx-4 md:mx-16">
        <div className="flex flex-col md:flex-row pt-20 md:pt-28 mb-14 gap-4 md:gap-16">
          <div className="md:grid md:grid-cols-2">
            <div className="rounded-lg overflow-hidden w-auto h-auto">
              {data?.productImage ? <img src={data?.productImage} alt="prize" className="aspect-square object-contain h-full w-full" /> : <div className="aspect-square animate-pulse bg-gray-550 object-contain h-full w-full" />}
            </div>
            <div className="items-center h-full w-full justify-center px-8 mt-10 md:mt-0 md:px-20 flex flex-col">
              {(!data || data?.status === 'refused' || data?.status === 'pending') && renderLoading()}
              {data?.status === 'accepted' && renderSucces()}
              {data?.status === 'refund' && renderRefund()}
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 md:px-10">
        <NewFooter />
      </div>
    </div>
  );
}

export default DropBuyConfirm;
