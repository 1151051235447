import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../_main/Footer';
import AnimationBannerSeparator from '../../components/Projects/AnimationBannerSeparator';
import Team from '../../components/Projects/Team';
import Press from '../../components/Projects/Press';
import Introduction from '../../components/Projects/Introduction';
import Stories from '../../components/Projects/Stories';
import Editions from '../../components/Projects/Editions';
import Rating from '../../components/Projects/Rating';
import AppPresentation from '../../components/Projects/AppPresentation';
import Header from '../../_main/Header';
import Head from '../../components/Home/Head';
import BackToTopButton from '../../_main/BackToTop';
import InitializationMysteryBox from '../../components/Projects/Initializations/InitializationMysteryBox';
import LandingMysteryBox from '../../components/Projects/LandingAnimations/LandingMysteryBox';

function MysteryBox() {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  return (
    <div className="dark-scrollbar w-screen max-w-screen bg-gray-350">
      <Head />
      {isLoading ? <InitializationMysteryBox setIsLoading={setIsLoading} /> : (
        <>
          <Header dark />
          <LandingMysteryBox />
          <div className="overflow-x-hidden">
            <AnimationBannerSeparator text="MINT COMING SOON." />
            <Introduction />
            <AnimationBannerSeparator text="MINT COMING SOON." />
            <Stories />
            <AnimationBannerSeparator text={t('bannerSpecialEdition')} />
            <Editions />
          </div>
          <div className="overflow-x-hidden">
            <Press />
            <AnimationBannerSeparator text={t('bannerSkmApp')} />
            <Rating />
            <AppPresentation />
            <Team />
            <AnimationBannerSeparator text={t('bannerMintSoon')} />
            <Footer />
          </div>
          <BackToTopButton />
        </>
      )}
    </div>
  );
}

export default MysteryBox;
