import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';

interface Props {
  onChange: (value: string) => void;
}

function DropBuyCountrySelector({ onChange }: Props) {
  const [value, setValue] = useState('');
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value: any) => {
    setValue(value);
    onChange(value.value as string);
  };

  return (
    <div className="mt-4 md:px-1">
      <Select
        placeholder="Choose your shipping country"
        menuPosition="fixed"
        styles={{
          control: (provided) => ({
            ...provided,
            color: 'white',
            backgroundColor: 'black',
            borderRadius: 8,
            borderWidth: 2,
            borderColor: 'white',
            boxShadow: 'none',
            '&:hover': {
              borderWidth: 2,
              borderColor: 'white',
            },
          }),
          option: (provided) => ({
            ...provided,
            color: 'black',
          }),
          singleValue: (provided) => ({
            ...provided,
            color: 'white',
          }),
          input: (provided) => ({
            ...provided,
            color: 'white',
          }),
          placeholder: (provided) => ({
            ...provided,
            color: 'white',
          }),
        }}
        options={options as any}
        value={value}
        onChange={changeHandler}
      />
    </div>
  );
}

export default DropBuyCountrySelector;
