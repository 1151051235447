import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

const imagesUrl = [
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-showcase-2.png',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-showcase-1.png',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-showcase-4-2.png',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-showcase-3.png',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/portal-showcase-5.png',
];

function PortalKeyBoxSlider() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: isTabletOrMobile ? '50px' : '35px',
    slidesToShow: 1,
    arrows: false,
    speed: 500,
    initalSlide: 1,
    beforeChange: (current: number, next: number) => setCurrentCarouselIndex(next),
  };
  return (
    <div className="md:pb-10 md:pt-5 md:col-span-2">
      <div className="w-screen">
        <Slider {...settings}>
          {imagesUrl.slice(1).map((item, index) => (
            <div key={index} className={`transform transition duration-500 ${index !== currentCarouselIndex ? 'scale-90' : ''}`}>
              <div
                className="w-full items-center justify-center bg-contain bg-black rounded-xl overflow-hidden md:items-start aspect-square"
              >
                <img
                  height="w-full"
                  width="h-full"
                  src={item}
                  alt={`$mtks-pass-${index}`}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default PortalKeyBoxSlider;
