import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DropType } from '../../../../../../appState';
import { retrieveInventory } from '../../../../../../core/usecases/inventory-retrieval/retrieveInventory';
import { selectEthereumWalletVM } from '../../../../../view-models-generation/generateEthereumWalletViewModel';
import { selectCreateStripeBuyingSession } from '../../../../../view-models-generation/generateStripeSessionViewModel';
import { createStripeBuyingSession } from '../../../../../../core/usecases/stripe-buying-session-create/createStripeBuyingSession';
import { selectDrop } from '../../../../../view-models-generation/generateInventoryViewModel';
import DropBuyFormLoading from './DropBuyFormLoading';
import DropBuyCountrySelector from './DropBuyCountrySelector';
import { HalfArrowRightSvg } from '../../../../icons/svg';

const shoeSizes = [
  { value: '36', label: '36' },
  { value: '37', label: '37' },
  { value: '37.5', label: '37.5' },
  { value: '38', label: '38' },
  { value: '38.5', label: '38.5' },
  { value: '39.5', label: '39.5' },
  { value: '40', label: '40' },
  { value: '40.5', label: '40.5' },
  { value: '41.5', label: '41.5' },
  { value: '42', label: '42' },
  { value: '42.5', label: '42.5' },
  { value: '43', label: '43' },
  { value: '44', label: '44' },
  { value: '44.5', label: '44.5' },
  { value: '45', label: '45' },
  { value: '45.5', label: '45.5' },
  { value: '46.5', label: '46.5' },
  { value: '47', label: '47' },
  { value: '47.5', label: '47.5' },
];
const clothesSizes = [
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'XL', label: 'XL' },
];

interface Props {
  type: DropType;
  dropId: string;
  itemId: string;
}

function DropBuyForm({ type, dropId, itemId }: Props) {
  const dispatch = useDispatch();
  const [size, setSize] = useState<string | null>('38.5');
  const [country, setCountry] = useState<string | null>(null);
  const { i18n } = useTranslation();
  const { address } = useSelector(selectEthereumWalletVM);
  const { item, isLoading } = useSelector(selectDrop(type, dropId, itemId));
  const { data } = useSelector(selectCreateStripeBuyingSession);
  const currentLanguage = i18n.language;
  const [availableSizes, setAvailableSizes] = useState<{value: string, label: string}[]>([]);

  const itemDescription = item?.name.split('/')[0];
  const itemBrand = item?.name.split('/')[1];

  useEffect(() => {
    dispatch(retrieveInventory);
  }, [dispatch, address]);

  useEffect(() => {
    if (data?.clientSecret) {
      window.location.replace(data.clientSecret);
    }
  }, [data]);

  const onBuy = () => {
    if ((!size && item?.type !== 'accessories') || !country || !address) return;

    dispatch(createStripeBuyingSession({
      dropId,
      itemId,
      size: size || '',
      walletAddress: address!,
      countryCode: country,
    }));
  };

  const onChangeSize = (newSize: string) => () => {
    setSize(newSize);
  };

  const onChangeCountryCode = (countryCode: string) => {
    setCountry(countryCode);
  };

  useEffect(() => {
    if (!item) return;
    const size = map(filter(item.sizes, (size) => size.quantity > 0), (size) => ({ value: size.size, label: size.size }));
    if (item.allSizes) {
      setSize(item.type === 'sneakers' ? '38.5' : 'M');
      setAvailableSizes((item.type === 'clothes' ? clothesSizes : shoeSizes));
    } else {
      setSize(size?.[0]?.value);
      setAvailableSizes(size);
    }
  }, [item]);

  return (
    <div data-testid="fefe" className="text-gray-350">
      {isLoading ? <DropBuyFormLoading /> : (
        item ? (
          <div data-testid="prize" className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:mt-6">
            <div className="flex items-center bg-gray-550 rounded-lg aspect-square justify-center">
              <img className="mt-[-40px]" src={item.image} alt="prize" />
            </div>
            <div className="flex flex-col justify-end pb-40 md:pb-0">
              <div className="flex flex-col justify-end font-grotesk w-full rounded-sm items-start text-2xl font-bold mb-6 md:mb-10">
                <span className="uppercase">{itemDescription}</span>
                <span className="font-inter font-medium text-xl md:text-xl upper text-gray-450">
                  {itemBrand}
                </span>
                <span className="font-extrabold">
                  {item.price}
                  {currentLanguage === 'en' ? '$' : '€'}
                </span>
              </div>
              <div className="flex flex-col justify-end">
                <p className="flex flex-row text-gray-450 text-2xl font-bold justify-start font-grotesk">
                  Size
                  {item.type === 'accessories' && (
                  <p>
                    : Unique
                  </p>
                  )}
                </p>
                {item.type !== 'accessories' && (
                <div className="grid grid-cols-3 md:grid-cols-5 gap-4 mt-2">
                  {(availableSizes).map((item) => (
                    <div
                      key={item.label}
                      className="w-full md:w-full md:px-1"
                    >
                      <button
                        className={`flex justify-center w-full text-lg md:text-xl px-8 md:px-12 border-2 font-grotesk font-bold rounded-lg ${size === item.value ? 'border-blue-550 bg-blue-550 text-gray-950 hover:border-blue-550' : 'border-gray-350'}`}
                        onClick={onChangeSize(item.value)}
                        name="size"
                        key={item.value}
                        value={item.value}
                      >
                        {item.label}
                      </button>
                    </div>
                  ))}
                </div>
                )}
              </div>
              <DropBuyCountrySelector onChange={onChangeCountryCode} />
              <div className="px-1 w-full">
                <button
                  onClick={onBuy}
                  disabled={(!size && (item?.type !== 'accessories')) || !country}
                  type="button"
                  className="font-grotesk w-full font-bold text-2xl"
                >
                  <div className={`flex flex-row justify-center items-center gap-2 mt-4 md:mt-5 ${country ? 'bg-blue-550' : 'bg-gray-400'} text-gray-350 w-full rounded-lg px-8 py-2`}>
                    Buy Now
                    <div>
                      <HalfArrowRightSvg />
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        ) : null
      )}
    </div>
  );
}

export default DropBuyForm;
