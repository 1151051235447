import { combineReducers } from 'redux';
import { MadHunnyConfirmationCheck } from '../../appState';
import * as subscribeToMadHunnyConfirmationCheckCheckActionCreators from '../usecases/mad-hunny-confirmation-check/actionCreators';

export const data = (
  state: MadHunnyConfirmationCheck | null = null,
  action: subscribeToMadHunnyConfirmationCheckCheckActionCreators.Actions,
) => {
  switch (action.type) {
    case 'MAD_HUNNY_CONFIRMATION_CHECKED':
      return action.payload.madHunnyConfirmationCheck;
    case 'CHECK_MAD_HUNNY_CONFIRMATION':
      return null;
    default: return state;
  }
};

export const isLoading = (
  state: boolean = false,
  action: subscribeToMadHunnyConfirmationCheckCheckActionCreators.Actions,
) => {
  switch (action.type) {
    case 'MAD_HUNNY_CONFIRMATION_CHECKED':
      return false;
    case 'CHECK_MAD_HUNNY_CONFIRMATION':
      return true;
    default: return state;
  }
};

export const checkMadHunnyConfirmationReducer = combineReducers({
  data,
  isLoading,
});
