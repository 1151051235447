import React from 'react';
import CookieConsent from 'react-cookie-consent';

function ModalAskCookies() {
  return (
    <CookieConsent
      location="none"
      buttonText="Accept"
      declineButtonText="Refuser"
      cookieName="cookie-consent"
      flipButtons
      containerClasses="bg-black gap-3 lg:gap-20 flex flex-row fixed justify-center w-full z-[9999] h-[80px] bottom-0 items-center px-4 lg:px-20"
      disableStyles
      buttonClasses="w-[60px] lg:w-[130px] border-gray-350 border-2 duration-200 ease-in-out hover:text-gray-950 hover:bg-gray-350 text-gray-350 font-extrabold text-[12px] lg:text-[20px] py-1"
      expires={150}
    >
      <p className="font-bold text-[12px] lg:text-[20px] w-full text-gray-350">
        By continuing to browse this website, you agree to use cookies
        {' '}
        <img
          alt="chocolate_emoji"
          src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/chocolate_emoji.png"
          className="inline-block mx-1"
          style={{
            width: '20px',
            height: '20px',
            verticalAlign: 'middle',
          }}
        />
        {' '}
        to collect website visit statistics.
        {' '}
        <a
          href="https://sneakmart.com/about/cookies"
          target="_blank"
          className="underline"
          rel="noreferrer"
        >
          <span className="font-normal inline text-[12px] lg:text-[20px]">
            Learn more
          </span>
        </a>
      </p>
    </CookieConsent>
  );
}

export default ModalAskCookies;
