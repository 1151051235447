import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useMediaQuery } from 'react-responsive';

gsap.registerPlugin(ScrollTrigger);

const animationFrames = {
  frame: 0,
};

interface Props {
  frameCount: number;
  currentFrame: (x: number) => string;
  onFrameUpdate?: (x: number) => void;
  totalDuration: number;
  idDiv: string;
  start?: string | number;
  end?: string | number;
  canvasTailwindStyle?: string;
  scrollTrigger?: string;
  noCleanAtEnd?: boolean
}

function FrameByFrame({
  frameCount,
  currentFrame,
  onFrameUpdate,
  totalDuration,
  idDiv,
  start,
  end,
  scrollTrigger,
  canvasTailwindStyle,
  noCleanAtEnd,
}: Props) {
  const images: Array<HTMLImageElement> = [];
  const canvas = useRef < HTMLCanvasElement | null >(null);
  const context = useRef < CanvasRenderingContext2D | null >(null);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1080px)' });

  function render() {
    if (context && context.current
       && canvas && canvas.current
        && canvas.current.width && canvas.current.height) {
      if (!noCleanAtEnd || animationFrames.frame < (frameCount - 1)) { context.current.clearRect(0, 0, canvas.current.width, canvas.current.height); }
      if (onFrameUpdate) { onFrameUpdate(animationFrames.frame); }
      if (animationFrames.frame < (frameCount - 1)) {
        context.current.drawImage(images[animationFrames.frame], 0, 0);
      }
    }
  }

  useEffect(() => {
    const lineTimeline = gsap.timeline();
    ScrollTrigger.defaults({
      scrub: 10,
      start: start ?? 'top top',
      end: end ?? `+=${totalDuration}`,
      animation: lineTimeline,
    });
    canvas.current = document.getElementById(idDiv) as HTMLCanvasElement;
    if (canvas && canvas.current) {
      context.current = canvas.current.getContext('2d') as CanvasRenderingContext2D;
    }

    if (canvas && canvas.current) {
      if (isTabletOrMobile) {
        canvas.current.width = 1080;
        canvas.current.height = 1920;
      } else {
        canvas.current.width = 1920;
        canvas.current.height = 1080;
      }
    }

    for (let i = 0; i < frameCount; i += 1) {
      const img = new Image();
      img.src = currentFrame(i);
      images.push(img);
    }

    if (scrollTrigger) {
      gsap.to(animationFrames, {
        frame: frameCount - 1,
        snap: 'frame',
        scrollTrigger: {
          scrub: 0.5,
          trigger: scrollTrigger,
        },
        onUpdate: render,
      });
    } else {
      gsap.to(animationFrames, {
        frame: frameCount - 1,
        snap: 'frame',
        scrollTrigger: {
          scrub: 3,
        },
        onUpdate: render,
      });
    }
    images[0].onload = render;
  }, []);

  return (
    <canvas
      id={idDiv}
      {...canvasTailwindStyle && { className: canvasTailwindStyle }}
    />
  );
}

export default FrameByFrame;
