import React from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import Header from '../../../_main/Header';
import NewFooter from '../../../components/Home/Sections/NewFooter';
import AutomaticPlayer from '../../../_main/Video/AutomaticPlayer';
import { HalfArrowRightSvg } from '../../../icons/svg';
import MadHunnyBannerSeparator from '../../../components/MadHunny/MadHunnyBannerSeparator';
import MadHunnyVideoStories from '../../../components/MadHunny/MadHunnyVideoStories';
import MadHunnyDropShowcase from '../../../components/MadHunny/MadHunnyDropShowcase';

const videoUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/flower-drop-hero-desktop.mp4';
const videoUrlMobile = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/flower-drop-hero-mobile.mp4';

function MadHunnyDropLanding() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="bg-orange-650 relative">
      <Header />
      <div className="fixed w-full z-[100] px-4 lg:px-10 bottom-0 text-gray-350 py-2 bg-gray-950 bg-opacity-95 backdrop-blur-md font-grotesk">
        <div className="flex flex-col lg:flex-row gap-2 mb-2 lg:mb-0 md:justify-between md:items-center w-full opacity-95">
          <div>
            <p className="text-2xl lg:text-3xl font-bold">
              MAD HUNNY
            </p>
            <p className="font-inter text-md lg:text-xl font-medium lg:font-semibold">
              Flower Instincts x Sneakmart
            </p>
          </div>
          <Link to="buy" className="hover:scale-[98%] transition-transform duration-500">
            <div className="flex flex-row justify-center items-center gap-2 bg-orange-650 hover:bg-white hover:text-orange-650 py-2 md:px-10 font-bold rounded-lg text-xl lg:text-2xl">
              Sold out
              <HalfArrowRightSvg height={30} width={20} />
            </div>
          </Link>
        </div>
      </div>
      <div className="w-full">
        <div className="flex flex-col lg:flex-row mb-8 md:mb-14 gap-4 lg:gap-16">
          <div className="w-full">
            <div className="rounded-xl object-cover">
              {isTabletOrMobile ? (
                <AutomaticPlayer>
                  <ReactPlayer
                    height="100%"
                    width="100vw"
                    loop
                    playsinline
                    muted
                    url={videoUrlMobile}
                  />
                </AutomaticPlayer>
              ) : (
                <AutomaticPlayer>
                  <ReactPlayer
                    height="100%"
                    width="100vw"
                    loop
                    playsinline
                    muted
                    url={videoUrl}
                  />
                </AutomaticPlayer>
              )}
            </div>
          </div>
        </div>
        <MadHunnyBannerSeparator text="MAD HUNNY" />
        <MadHunnyVideoStories />
        <MadHunnyDropShowcase />
        <div className="mx-4 lg:mx-10 pb-32 md:pb-2">
          <NewFooter />
        </div>
      </div>
    </div>
  );
}

export default MadHunnyDropLanding;
