import axios, { AxiosInstance } from 'axios';
import { Either, Right } from 'purify-ts';
import { DropBuyingConfirmationCheck, ErrorDomain } from '../../../appState';
import { StripeGateway } from '../../../core/gateways/stripeGateway';

export class HttpStripeGateway implements StripeGateway {
  private _axiosClient: AxiosInstance | null = null;

  async createBuyingSession(dropId: string, itemId: string, size: string, walletAddress: string, countryCode: string): Promise<Either<ErrorDomain, string | null>> {
    const location = {
      countryCode,
    };
    const createStripeCheckoutUrl = await this._axiosClient!.post<string>('/metakicks/drop/create-buying-session', {
      dropId,
      itemId,
      size,
      walletAddress,
      location,
    }).then((res) => res.data);

    return Right(createStripeCheckoutUrl);
  }

  async checkConfirmation(orderId: string): Promise<Either<ErrorDomain, DropBuyingConfirmationCheck | null>> {
    const confirmation = await this._axiosClient!.get<DropBuyingConfirmationCheck>('/metakicks/order/status-checking', {
      params: {
        orderId,
      },
    }).then((res) => res.data);

    return Right(confirmation);
  }

  set axiosClient(value: string) {
    this._axiosClient = axios.create({
      baseURL: `${value}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}
