import React from 'react';
import { useMediaQuery } from 'react-responsive';

function StaniflouGridShowcase() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  return (
    <div className="grid grid-cols-1 grid-flow-col md:grid-cols-2 md:col-span-2 gap-4">
      {!isTabletOrMobile ? (
        <>
          <div className="col-span-1">
            <img
              alt="mtks-pass-5"
              className="object-cover aspect-square rounded-xl"
              src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan-cherry%2Bbox.jpeg"
            />
          </div>
          <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
            <img alt="mtks-pass-5" className="aspect-square rounded-xl" src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/flou-real-pair-5.jpeg" />
            <img alt="mtks-pass-5" className="aspect-square rounded-xl" src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/flou-real-pair-2.jpeg" />
            <img alt="mtks-pass-5" className="aspect-square rounded-xl" src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/flou-real-pair-3.jpeg" />
            <img alt="mtks-pass-5" className="aspect-square rounded-xl" src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/flou-real-pair-4.jpeg" />
          </div>
        </>
      ) : (
        <div className="rounded-xl overflow-hidden">
          <img
            alt="mtks-pass-5"
            src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan-cherry%2Bbox.jpeg"
          />
        </div>
      )}
    </div>
  );
}

export default StaniflouGridShowcase;
