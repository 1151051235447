/* eslint-disable max-len */
import React from 'react';
import times from 'lodash/times';
import Tilt from 'react-parallax-tilt';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { ArrowStarSvg, ArrowRightSvg } from '../../icons/svg';

function Rating() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { t } = useTranslation();

  const ratings = [
    {
      id: 'id1',
      title: t('ratingTitle1'),
      text: t('rating1'),
    },
    {
      id: 'id4',
      title: t('ratingTitle2'),
      text: t('rating2'),
    },
    {
      id: 'id3',
      title: t('ratingTitle3'),
      text: t('rating3'),
    },
    {
      id: 'id2',
      title: t('ratingTitle4'),
      text: t('rating4'),
    },
    {
      id: 'id0',
      title: t('ratingTitle5'),
      text: t('rating5'),
    },
  ];

  return (
    <div
      id="about-us"
      className="w-screen pb-8 pt-4 md:py-10"
    >
      <div className="max-w-screen-xl m-auto px-8 lg:px-16 2xl:px-0">
        <h1 className="text-[45px] text-center md:text-left font-semibold leading-[80px] lg:text-[100px] lg:leading-[80px] 2xl:text-[140px] 2xl:leading-[120px] text-gray-950">
          sneakmart

        </h1>
        <h1 className="text-[19px] font-extrabold lg:text-[40px] 2xl:text-[48px] text-gray-950">
          {t('ratingTitle')}

        </h1>
        <p className="text-gray-950 text-sm md:text-1xl">
          {t('ratingText1')}
          <br />
          {t('ratingText2')}
          <br />
          {t('ratingText3')}
          <br />
          <br />
          {t('ratingText4')}
          {' '}
          <span className="font-bold">{t('ratingText5')}</span>
          <br />
          {t('ratingText6')}
          <br />
          {t('ratingText7')}
        </p>
        <button
          type="button"
          className="mt-4 w-full md:w-3/6 md:my-6 border-2 rounded-md duration-300 ease-in-out group hover:bg-gray-950 border-gray-950  hover:drop-shadow-[0_0_1.5rem_gray]"
        >
          <a
            className="relative flex w-full px-3 flex-row items-center md:gap-5 py-1 md:py-2 justify-between"
            target="_blank"
            href="https://sneakmart.fr"
            rel="noreferrer"
          >
            <p className="text-gray-950 group-hover:text-gray-350 font-extrabold text-[5vw] whitespace-nowrap md:text-[30px]">
              {t('ourWebsite')}

            </p>
            <div className="absolute right-[15px]">
              <ArrowRightSvg className="fill-gray-950 group-hover:fill-gray-350" />
            </div>
          </a>
        </button>
        <h1 className="uppercase leading-[28px] text-[24px] lg:text-[40px] lg:leading-[48px] 2xl:text-[48px] mt-4 2x:leading-[55px] font-extrabold text-gray-950">
          {t('usersLove')}

        </h1>
        <h1 className="leading-[25px] text-[18px] font-extrabold mt-2 lg:text-[40px] lg:leading-[48px] 2xl:text-[48px] 2xl:leading-[55px] text-gray-950">
          {t('usersLove2')}

        </h1>
        <h1 className="my-4 text-[14px] lg:text-[27px] lg:leading-[48px] 2xl:text-[32px] font-bold 2xl:leading-[55px] text-gray-950">
          Source: App Store (FR)

        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="flex flex-col gap-4">
            <Tilt tiltMaxAngleY={9} tiltMaxAngleX={9} transitionSpeed={2000}>
              <div className="bg-gray-950 rounded-xl py-2 flex w-full flex-col items-center justify-center hover:scale-[1.04] transition-transform duration-500">
                <h1 className="font-bold text-gray-350 py-1 md:py-0 text-[50px] leading-[70px]">
                  4,8
                  <span className="text-gray-200 font-medium text-[30px]">/5</span>
                </h1>
                <div className="flex flex-row">
                  {times(5, (index) => (
                    isTabletOrMobile ? <ArrowStarSvg key={index} height="37" width="39" /> : <ArrowStarSvg key={index} height="42" width="44" />
                  ))}
                </div>
                <p className="text-gray-200 text-[22px] mt-6">+1400 ratings</p>
              </div>
            </Tilt>
            {ratings.slice(0, 1).map((item) => (
              <Tilt key={item.id} tiltMaxAngleY={9} tiltMaxAngleX={9} transitionSpeed={2000}>
                <div key={item.id} className="bg-gray-950 flex gap-2 rounded-xl py-4 px-4 flex-col items-start justify-between hover:scale-[1.04] transition-transform duration-500">
                  <p className="text-gray-350 text-[22px] md:text-[28px] font-bold">{item.title}</p>
                  <div className="flex flex-row">
                    {times(5, (index) => (
                      isTabletOrMobile ? <ArrowStarSvg key={index} height="27" width="29" /> : <ArrowStarSvg key={index} height="42" width="44" />
                    ))}
                  </div>
                  <p className="text-gray-350 text-[14px] md:text-1xl">{item.text}</p>
                </div>
              </Tilt>
            ))}
          </div>
          {isTabletOrMobile && (
            <div className="flex flex-col gap-4">
              {ratings.slice(3, 4).map((item) => (
                <div key={item.id} className="bg-gray-950 flex gap-2 rounded-xl py-4 px-4 flex-col items-start justify-between hover:scale-[1.04] transition-transform duration-500">
                  <p className="text-gray-350 text-[22px] font-bold">{item.title}</p>
                  <div className="flex flex-row">
                    {times(5, (index) => (
                      <ArrowStarSvg key={index} height="27" width="29" />
                    ))}
                  </div>
                  <p className="text-gray-350 text-[14px]">{item.text}</p>
                </div>
              ))}
            </div>
          )}
          {!isTabletOrMobile && (
            <div className="flex flex-col gap-4">
              {ratings.slice(1, 3).map((item) => (
                <Tilt key={item.id} tiltMaxAngleY={9} tiltMaxAngleX={9} transitionSpeed={2000}>
                  <div key={item.id} className="bg-gray-950 flex gap-2 rounded-xl py-4 px-4 flex-col items-start justify-between hover:scale-[1.04] transition-transform duration-500">
                    <p className="text-gray-350 text-[28px] font-bold">{item.title}</p>
                    <div className="flex flex-row">
                      {times(5, (index) => (
                        <ArrowStarSvg key={index} height="42" width="44" />
                      ))}
                    </div>
                    <p className="text-gray-350 md:text-1xl">{item.text}</p>
                  </div>
                </Tilt>
              ))}
            </div>
          )}
          {!isTabletOrMobile && (
          <div className="flex flex-col gap-4">
            {ratings.slice(3, 5).map((item) => (
              <Tilt key={item.id} tiltMaxAngleY={9} tiltMaxAngleX={9} transitionSpeed={2000}>
                <div key={item.id} className="bg-gray-950 flex gap-4 rounded-xl py-4 px-4 flex-col items-start justify-between hover:scale-[1.04] transition-transform duration-500">
                  <p className="text-gray-350 text-[28px] font-bold">{item.title}</p>
                  <div className="flex flex-row mt-[-3px]">
                    {times(5, (index) => (
                      <ArrowStarSvg key={index} height="42" width="44" />
                    ))}
                  </div>
                  <p className="text-gray-350 text-1xl">{item.text}</p>
                </div>
              </Tilt>
            ))}
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Rating;
