import React from 'react';
import Header from '../../_main/Header';
import PortalKeyItem from '../../components/Inventory/Portal/PortalKeyItem';
import NewFooter from '../../components/Home/Sections/NewFooter';

function SinglePortalKey() {
  return (
    <div className="w-screen min-h-screen bg-black">
      <Header dark />
      <div className="pt-[80px] overflow-x-hidden">
        <div className="m-auto max-w-screen-xl px-8 lg:px-16 2xl:px-0 py-8 md:py-20">
          <PortalKeyItem />
        </div>
        <div className="px-6 md:px-16">
          <NewFooter />
        </div>
      </div>
    </div>
  );
}

export default SinglePortalKey;
