import React from 'react';

interface Props {
    height: string;
}

function ArrowSeparator({ height }: Props) {
  return (
    <div className="flex flex-row items-center">
      <div className={`${height} w-full bg-gray-950 items-center`} />
      <div className="h-0 w-0 border-y-[10px] border-y-transparent border-l-[16px] border-l-gray-950" />
    </div>
  );
}

export default ArrowSeparator;
