import React from 'react';
import { useSelector } from 'react-redux';
import {
  FlowerInventoryItem, jwasicsItem, PortalKeyInventoryItem, StanItem, StaniflouColorway,
} from '../../../../../appState';
import MyCollectionCard from './MyCollectionCard';
import { selectInventoryVM } from '../../../../view-models-generation/generateInventoryViewModel';

interface Props {
  portalKeys: PortalKeyInventoryItem[] | null,
  stan: StanItem[] | null | undefined,
  flowers: FlowerInventoryItem[] | null | undefined,
  jwasics: jwasicsItem[] | null | undefined,
}

const colorways = {
  [StaniflouColorway.MOCHA]: {
    name: 'mocha',
    value: StaniflouColorway.MOCHA,
    hex: [
      '#56421E',
      '#EFEAD5',
      '#A89B62',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-COFFEE.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-COFFEESCENE.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-COFFEE-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-COFFEE.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-COFFEE-COLORWAY.webp',
  },
  [StaniflouColorway.SAKURA]: {
    name: 'sakura',
    value: StaniflouColorway.SAKURA,
    hex: [
      '#EFBABA',
      '#FFE4E3',
      '#72265D',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-CHERRY.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-CHERRY.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-CHERRY-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-CHERRY.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-CHERRY-COLORWAY.webp',
  },
  [StaniflouColorway.GUMMY]: {
    name: 'gummy',
    value: StaniflouColorway.GUMMY,
    hex: [
      '#F7F1CF',
      '#BC879D',
      '#81B084',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-JADED.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-JADED.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-JADED-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-JADED.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-JADED-COLORWAY.webp',
  },
  [StaniflouColorway.MATCHA]: {
    name: 'matcha',
    value: StaniflouColorway.MATCHA,
    hex: [
      '#FEE4E4',
      '#A6995F',
      '#752A76',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-ROCKY.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-ROCKY.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-ROCKY-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-ROCKY.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-ROCKY-COLORWAY.webp',
  },
  [StaniflouColorway.AQUA]: {
    name: 'aqua',
    value: StaniflouColorway.AQUA,
    hex: [
      '#B8D7EB',
      '#EACDAB',
      '#003C67',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-DESERT.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-DESERT.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-DESERT-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-DESERT.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-DESERT-COLORWAY.webp',
  },
};

function MyCollectionCatalog({
  portalKeys,
  stan,
  flowers,
  jwasics,
}: Props) {
  const { inventory } = useSelector(selectInventoryVM);

  return (
    <div className="grid grid-cols-2 place-items-center md:grid-cols-3 gap-10 rounded-lg mt-6 md:mt-10 text-gray-350">
      {portalKeys && portalKeys.map((item) => (
        <MyCollectionCard
          key={item.id}
          link={`/inventory/portal/${item.id}`}
          title="PORTAL KEY"
          videoURL="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/mtks-pass.mp4"
          id={item.id}
        />
      ))}
      {inventory?.stans && inventory?.stans?.length > 0 && (
        stan!.map((item) => {
          const colorway = colorways[item.colorway].videoMobile as string;

          if (!colorway) return null;

          return (
            <MyCollectionCard
              title="ZEN GARDEN"
              id={item.id}
              key={item.id}
              videoURL={colorway}
              link={`/drop/staniflou/${item.id}`}
            />
          );
        })
      )}
      {flowers && flowers?.map((item) => (
        <MyCollectionCard
          link={`/inventory/mad-hunny/${item.id}`}
          title="FLOWER"
          key={item.id}
          id={item.id}
          videoURL="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/flower/flower-animation.mp4"
        />
      ))}
      {jwasics && jwasics?.map((item) => (
        <MyCollectionCard
          link={`/inventory/asics/${item.id}`}
          title="JW ASICS"
          key={item.id}
          id={item.id}
          videoURL="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/asics/desktop/nft-video-2.mp4"
        />
      ))}
    </div>
  );
}

export default MyCollectionCatalog;
