import { ErrorDomain } from '../../../appState';
import { ActionsUnion, createAction } from '../../../store';

export const Actions = {
  createAsicsStripeBuyingSession: () => createAction('CREATE_ASICS_STRIPE_BUYING_SESSION'),
  asicsStripeBuyingSessionCreated: (clientSecret: string) => createAction('ASICS_STRIPE_BUYING_SESSION_CREATED', { clientSecret }),
  createAsicsStripeBuyingSessionError: (error: ErrorDomain) => createAction('CREATE_ASICS_STRIPE_BUYING_SESSION_ERROR', { error }),
};

export type Actions = ActionsUnion<typeof Actions>
