import React from 'react';
import times from 'lodash/times';
import { useMediaQuery } from 'react-responsive';

function StackingMenuLoading() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div data-testid="stacking-menu-loading" className="mt-0">
      <div className="md:h-6" />
      <div className="grid md:grid-cols-2">
        <h1 className="text-3xl md:text-5xl font-grotesk font-bold text-gray-350 mb-4">
          PORTAL KEYS
        </h1>
        {!isTabletOrMobile && (
          <h1 className="text-start text-3xl md:text-5xl font-grotesk font-bold text-gray-350 mb-4">
            BALANCE
          </h1>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-0">
        <div className=" w-full">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:flex flex-row flex-wrap mb-0 md:mb-4">
            {times(6, (index) => <div key={index} className="aspect-square rounded-lg md:w-[30%] animate-pulse bg-gray-550" />)}
          </div>
        </div>
        {!isTabletOrMobile && (
        <div className="w-full h-full">
          <div className="bg-gray-550 rounded-lg animate-pulse aspect-square w-full" />
        </div>
        )}
      </div>
      <div className="h-8" />
    </div>
  );
}

export default StackingMenuLoading;
