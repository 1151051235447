/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import AutomaticPlayer from '../../../_main/Video/AutomaticPlayer';

export enum Direction {
  Right,
  Left
}

interface Props {
  videosURL?: string[];
  imagesUrl?: string[];
  alt?: string;
  settingsOverride?: any;
  direction: Direction
}

function BoxSlider({
  imagesUrl = [], videosURL = [], settingsOverride = {}, direction, alt,
}: Props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(settingsOverride?.initialSlide ?? 0);

  const settings = {
    centerPadding: '35px',
    autoplay: true,
    autoplaySpeed: 1,
    speed: 5000,
    arrows: false,
    initialSlide: 0,
    infinite: true,
    cssEase: 'linear',
    pauseOnHover: false,
    slidesToScroll: direction === Direction.Right ? -1 : 1,
    variableWidth: !isTabletOrMobile,
    beforeChange: (current: number, next: number) => setCurrentCarouselIndex(next),
    ...settingsOverride,
  };

  return (
    <Slider {...settings}>
      {imagesUrl.map((item, index) => (
        <div key={index} className={`pr-8 {transform ${isTabletOrMobile ? 'w-full h-full' : 'w-[350px] h-[350px]'} transition duration-500 ${isTabletOrMobile ? index !== currentCarouselIndex ? 'scale-90' : '' : 'scale-1'}`}>
          <div key={index} className="w-full rounded-xl shadow-lg items-center md:items-start overflow-hidden bg-gray-200">
            <img
              height={!isTabletOrMobile ? '350px' : '100%'}
              width={!isTabletOrMobile ? '350px' : '100%'}
              src={item}
              alt={`${alt}-${index}`}
            />
          </div>
        </div>
      ))}
      {videosURL.map((item, index) => (
        <div key={index} className={`pr-8 {transform ${isTabletOrMobile ? 'w-full h-full' : 'w-[350px] h-[350px]'} transition duration-500 ${isTabletOrMobile ? index !== currentCarouselIndex ? 'scale-90' : '' : 'scale-1'}`}>
          <div key={index} className="w-full items-center md:items-start aspect-square bg-gray-200">
            <AutomaticPlayer>
              <ReactPlayer
                height={!isTabletOrMobile ? '350px' : '100%'}
                width={!isTabletOrMobile ? '350px' : '100%'}
                loop
                playsinline
                muted
                url={item}
              />
            </AutomaticPlayer>
          </div>
        </div>
      ))}
    </Slider>
  );
}

export default BoxSlider;
