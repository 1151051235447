import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveInventory } from '../../../../../core/usecases/inventory-retrieval/retrieveInventory';
import { selectInventoryVM } from '../../../../view-models-generation/generateInventoryViewModel';
import { selectEthereumWalletVM } from '../../../../view-models-generation/generateEthereumWalletViewModel';
import MyCollectionCatalog from '../MyCollection/MyCollectionCatalog';
import MyCollectionCatalogLoading from '../MyCollection/MyCollectionCatalogLoading';
import MyCollectionEmpty from '../MyCollection/MyCollectionEmpty';

function Catalog() {
  const dispatch = useDispatch();
  const { isLoading, inventory } = useSelector(selectInventoryVM);
  const { address } = useSelector(selectEthereumWalletVM);

  useEffect(() => {
    dispatch(retrieveInventory);
  }, [dispatch, address]);

  if (isLoading) return <MyCollectionCatalogLoading />;

  return (inventory?.stans?.length === 0 && inventory?.portalKeys?.length === 0 && inventory?.flowers?.length === 0 && inventory?.jwasics?.length === 0) ? <MyCollectionEmpty /> : (
    <div className="grid grid-flow-row gap-8">
      <MyCollectionCatalog
        portalKeys={inventory?.portalKeys}
        stan={inventory?.stans}
        flowers={inventory?.flowers}
        jwasics={inventory?.jwasics}
      />
    </div>
  );
}

export default Catalog;
