import { combineReducers } from 'redux';
import findIndex from 'lodash/findIndex';
import { Stacking } from '../../appState';
import * as subscribeToStackingActionCreators from '../usecases/stacking-retrieval/actionCreators';
import * as subscribeToPortalKeyStackingActionCreators from '../usecases/portal-key-stacking/actionCreators';
import * as subscribeToPortalKeyUnstackingActionCreators from '../usecases/portal-key-unstacking/actionCreators';
import * as subscribeToFlowerKeyStackingActionCreators from '../usecases/flower-key-stacking/actionCreators';
import * as subscribeToFlowerKeyUnstackingActionCreators from '../usecases/flower-key-unstacking/actionCreators';
import * as subscribeTojwasicsKeyStackingActionCreators from '../usecases/jwasics-key-stacking/actionCreators';
import * as subscribeTojwasicsKeyUnstackingActionCreators from '../usecases/jwasics-key-unstacking/actionCreators';
import * as subscribeToStanKeyStackingActionCreators from '../usecases/stan-key-stacking/actionCreators';
import * as subscribeToStanKeyUnstackingActionCreators from '../usecases/stan-key-unstacking/actionCreators';
import * as subscribeToStackingAwardRedeemActionCreators from '../usecases/stacking-award-redeem/actionCreators';

export const data = (
  state: Stacking | null = null,
  action: subscribeToStackingAwardRedeemActionCreators.Actions | subscribeToPortalKeyUnstackingActionCreators.Actions |
  subscribeToStackingActionCreators.Actions | subscribeToPortalKeyStackingActionCreators.Actions |
  subscribeToFlowerKeyStackingActionCreators.Actions | subscribeToFlowerKeyUnstackingActionCreators.Actions |
  subscribeTojwasicsKeyStackingActionCreators.Actions | subscribeTojwasicsKeyUnstackingActionCreators.Actions |
  subscribeToStanKeyStackingActionCreators.Actions | subscribeToStanKeyUnstackingActionCreators.Actions,
) => {
  let portalKey = null;
  let flowerKey = null;
  let stanKey = null;
  let newKeys = null;
  let jwAsicKey = null;
  switch (action.type) {
    case 'STACKING_RETRIEVED':
      return action.payload.stacking;
    case 'PORTAL_KEY_STACKED':
      portalKey = findIndex(state?.keys, (key) => key.portalKeyId === action.payload.portalKeyId);

      if (!state || portalKey === -1) return null;

      newKeys = state.keys;
      newKeys[portalKey] = {
        ...state.keys[portalKey],
        isCurrentlyStacked: true,
        stackedAt: new Date(),
        dateOfNextGain: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      };

      return {
        ...state,
        keys: newKeys,
      };
    case 'FLOWER_KEY_STACKED':
      flowerKey = findIndex(state?.flowers, (flower) => flower.flowerId === action.payload.flowerId);

      if (!state || flowerKey === -1) return null;

      newKeys = state?.flowers;
      newKeys[flowerKey] = {
        ...state.flowers[flowerKey],
        isCurrentlyStacked: true,
        stackedAt: new Date(),
        dateOfNextGain: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      };

      return {
        ...state,
        flowers: newKeys,
      };
    case 'JWASICS_KEY_STACKED':
      jwAsicKey = findIndex(state?.jwasics, (jwasics) => jwasics.jwasicsId === action.payload.jwasicsId);

      if (!state || jwAsicKey === -1) return null;

      newKeys = state?.jwasics;
      newKeys[jwAsicKey] = {
        ...state.jwasics[jwAsicKey],
        isCurrentlyStacked: true,
        stackedAt: new Date(),
        dateOfNextGain: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      };

      return {
        ...state,
        jwasics: newKeys,
      };
    case 'STAN_KEY_STACKED':
      stanKey = findIndex(state?.stans, (stan) => stan.stanId === action.payload.stanId);

      if (!state || stanKey === -1) return null;

      newKeys = state?.stans;
      newKeys[stanKey] = {
        ...state.stans[stanKey],
        isCurrentlyStacked: true,
        stackedAt: new Date(),
        dateOfNextGain: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      };

      return {
        ...state,
        stans: newKeys,
      };
    case 'PORTAL_KEY_UNSTACKED':
      portalKey = findIndex(state?.keys, (key) => key.portalKeyId === action.payload.portalKeyId);

      if (!state || portalKey === -1) return null;

      newKeys = state.keys;
      newKeys[portalKey] = {
        ...state.keys[portalKey],
        isCurrentlyStacked: false,
        stackedAt: null,
      };

      return {
        ...state,
        keys: newKeys,
      };
    case 'FLOWER_KEY_UNSTACKED':
      flowerKey = findIndex(state?.flowers, (flower) => flower.flowerId === action.payload.flowerId);

      if (!state || flowerKey === -1) return null;

      newKeys = state.flowers;
      newKeys[flowerKey] = {
        ...state.flowers[flowerKey],
        isCurrentlyStacked: false,
        stackedAt: null,
      };

      return {
        ...state,
        flowers: newKeys,
      };
    case 'JWASICS_KEY_UNSTACKED':
      jwAsicKey = findIndex(state?.jwasics, (jwAsic) => jwAsic.jwasicsId === action.payload.jwasicsId);

      if (!state || jwAsicKey === -1) return null;

      newKeys = state.jwasics;
      newKeys[jwAsicKey] = {
        ...state.jwasics[jwAsicKey],
        isCurrentlyStacked: false,
        stackedAt: null,
      };

      return {
        ...state,
        jwasics: newKeys,
      };
    case 'STAN_KEY_UNSTACKED':
      stanKey = findIndex(state?.stans, (stan) => stan.stanId === action.payload.stanId);

      if (!state || stanKey === -1) return null;

      newKeys = state.stans;
      newKeys[stanKey] = {
        ...state.stans[stanKey],
        isCurrentlyStacked: false,
        stackedAt: null,
      };

      return {
        ...state,
        stans: newKeys,
      };
    case 'STACKING_AWARD_REDEEMED':
      if (!state) return null;

      return {
        ...state,
        currentBalance: state!.currentBalance - action.payload.award.price,
      };
    default:
      return state;
  }
};

export const isLoading = (state: boolean = false, action: subscribeToStackingActionCreators.Actions) => {
  switch (action.type) {
    case 'RETRIEVE_STACKING':
      return true;
    case 'STACKING_RETRIEVED':
      return false;
    default:
      return state;
  }
};

export const stackingReducer = combineReducers({
  isLoading,
  data,
});
