import { ThunkResult } from '../../../store';
import { StripeGateway } from '../../gateways/stripeGateway';
import { Actions } from './actionCreators';

export const createStripeBuyingSession = ({
  dropId, itemId, size, walletAddress, countryCode,
}: {dropId: string, itemId: string, walletAddress: string, size: string, countryCode: string}): ThunkResult<Promise<void>> => async (
  dispatch,
  getState,
  { stripeGateway }: {
    stripeGateway: StripeGateway
  },
) => {
  dispatch(Actions.createStripeBuyingSession());

  const clientSecret = await stripeGateway.createBuyingSession(dropId, itemId, size, walletAddress, countryCode);

  clientSecret.ifLeft((err) => {
    dispatch(Actions.createStripeBuyingSessionError(err));
  });

  clientSecret.ifRight((data) => {
    dispatch(Actions.stripeBuyingSessionCreated(data!));
  });
};
