import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ArrowDownSvgBlue } from '../../icons/svg';

function BackToTopButton() {
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });
  const handleScroll = () => {
    const div = document.getElementById('scroll-to-top');

    if (window.pageYOffset > 2000) {
      if (div?.style) {
        div.style.opacity = '1';
      }
    }
    if (window.pageYOffset < 2000) {
      if (div?.style) {
        div.style.opacity = '0';
      }
    }
  };

  const handleMouseEvent = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [window.pageYOffset]);

  if (!isDesktop) return null;

  return (
    <button id="scroll-to-top" className="flex justify-center items-center fixed w-[46px] h-[46px] rounded-full border-[3px] opacity-0 border-gray-550 bottom-6 right-0 mr-5 bg-gray-950 rotate-180 transition-all transition-delay-500" type="button" onClick={handleMouseEvent}>
      <ArrowDownSvgBlue width="28" height="14" className="animate-scroll-button" />
    </button>
  );
}

export default BackToTopButton;
