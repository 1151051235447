import React from 'react';

function AsicsBuyConfirmationLoading() {
  return (
    <div>
      <div className="bg-gray-550 w-[250px] md:w-[200px] h-8 m-auto mb-6 animate-pulse" />
      <div>
        <div className="bg-gray-550 w-[350px] md:w-[500px] animate-pulse h-8 mb-2" />
        <div className="bg-gray-550 w-[350px] md:w-[500px] animate-pulse h-8" />
      </div>
    </div>
  );
}

export default AsicsBuyConfirmationLoading;
