import { AppState } from '../../appState';
import { Error } from '../../error';

export interface EthereumWalletVM {
  addressFormated: string | null;
  address: string | null;
  signMessage: ((plainMessage: string) => Promise<string>) | null;
  openModal: boolean;
  isWhitelisted: boolean;
  whitelistSupply: number;
}

export const selectEthereumWalletVM = (state: AppState): EthereumWalletVM => ({
  addressFormated: state.ethereumWallet?.signerAddress ? `${state.ethereumWallet?.signerAddress.substring(0, 5)}...${state.ethereumWallet?.signerAddress.substring(38, 42)}` : null,
  address: state.ethereumWallet?.signerAddress || null,
  signMessage: state.ethereumWallet?.signMessage || null,
  isWhitelisted: state.ethereumWallet?.signerAddress ? state.ethereumWallet?.isWhitelisted || false : true,
  whitelistSupply: state.ethereumWallet?.signerAddress ? state.ethereumWallet?.whitelistSupply || 0 : 2,
  openModal: state.error === Error.NeedToChooseAConnectionWallet,
});
