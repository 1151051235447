import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

interface Props {
  countdownDate: Date
  renderText: (renderCountdown: React.ReactNode) => React.ReactElement
}

function CountdownDrops({ countdownDate, renderText }: Props) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const setNewTime = () => {
    if (countdownDate) {
      const currentTime = new Date().getTime();

      const distanceToDate = countdownDate.getTime() - currentTime;

      const days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
      );
      const seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      setState({
        days, hours, minutes, seconds,
      });
    }
  };

  useEffect(() => {
    setNewTime();
    setInterval(() => setNewTime(), 1000);
  }, []);

  const renderCountdown = ({
    days, hours, minutes, seconds,
  }: {
      days: number
      hours: number
      minutes: number
      seconds: number
    }) => (
      <span className="text-blue-550">
        {days ? `${days} ${t('countdownDays')}` : ''}
        {days > 1 ? 'S' : ''}
        {' '}
        {hours ? `${hours} ${t('countdownHours')}` : ''}
        {hours > 1 ? 'S' : ''}
        {isTabletOrMobile && days > 1 ? (<br />) : (' ')}
        {minutes ? `${minutes} ${t('countdownMinutes')}` : ''}
        {minutes > 1 ? 'S' : ''}
        {(days > 0 || hours > 0 || minutes > 0) && ' & '}
        {seconds >= 0 ? `${seconds} ${t('countdownSeconds')}` : ''}
        {seconds > 1 ? 'S' : ''}
      </span>
  );

  if (countdownDate.getTime() - new Date().getTime() < 0) return null;

  return renderText(renderCountdown(state));
}

export default CountdownDrops;
