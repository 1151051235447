import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../_main/Header';
import NewFooter from '../../../components/Home/Sections/NewFooter';
import MetakicksFlowerDisplay from '../../../components/Collab/Check/MetakicksFlowerDisplay';
import MetakicksFlowerNotFound from '../../../components/Collab/Check/MetakicksFlowerNotFound';

type UrlParams = 'metakicksFlowerId'

function MadHunnyItem() {
  const urlParams = useParams<Record<UrlParams, string>>();
  const metakicksFlowerId = urlParams.metakicksFlowerId || '';

  return (
    <div className="w-screen min-h-screen bg-black text-gray-350">
      <Header dark />
      <div className="pt-[40px] overflow-x-hidden">
        <div className="m-auto max-w-screen-xl px-4 lg:px-10 2xl:px-0 py-8 md:py-20">
          <div>
            <div>
              {Number(metakicksFlowerId) < 1 || Number(metakicksFlowerId) > 555 ? (
                <MetakicksFlowerNotFound />
              ) : (
                <MetakicksFlowerDisplay metakicksFlowerId={metakicksFlowerId} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 md:px-10">
        <NewFooter />
      </div>
    </div>
  );
}

export default MadHunnyItem;
