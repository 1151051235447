import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import validator from 'validator';
import DropDescription from '../../BuyNewDrop/DropDescription';
import { HalfArrowRightSvg } from '../../../../icons/svg';
import { createAsicsStripeBuyingSession } from '../../../../../../core/usecases/asics-stripe-buying-session-create/createAsicsStripeBuyingSession';
import { checkIfWhitelistAsics } from '../../../../../../core/usecases/asics-is-whitelist-check/checkIfWhitelistAsics';
import DropBuyCountrySelector from '../../../Inventory/Drop/DropBuy/DropBuyCountrySelector';
import { selectAsicsCheckWhitelist, selectCreateAsicsStripeBuyingSession } from '../../../../../view-models-generation/generateAsicsDropViewModel';

const shoeSizes = [
  { value: '37', label: '37' },
  { value: '37.5', label: '37.5' },
  { value: '38', label: '38' },
  { value: '39', label: '39' },
  { value: '40', label: '40' },
  { value: '40.5', label: '40.5' },
  { value: '41.5', label: '41.5' },
  { value: '42', label: '42' },
  { value: '42.5', label: '42.5' },
  { value: '43.5', label: '43.5' },
  { value: '44', label: '44' },
  { value: '44.5', label: '44.5' },
  { value: '45', label: '45' },
  { value: '46', label: '46' },
  { value: '46.5', label: '46.5' },
  { value: '47', label: '47' },
];

interface Props {
  drop: {
    title: string
    partners: string,
    price: number,
    description: string,
    description2: string,
    preOrderText: string,
  }
}

function AsicsDropCheckout({ drop }: Props) {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { data } = useSelector(selectCreateAsicsStripeBuyingSession);
  const [state, setState] = useState<{size: string, email: string, countryCode: string}>({
    size: '42.5',
    countryCode: '',
    email: '',
  });

  const { data: dataWhitelist } = useSelector(selectAsicsCheckWhitelist);

  useEffect(() => {
    if (data?.clientSecret) {
      window.location.replace(data?.clientSecret);
    }
  }, [data?.clientSecret]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      email: e.currentTarget.value,
    });
  };

  const onBuy = () => {
    if (!state) return;
    dispatch(createAsicsStripeBuyingSession({
      size: state.size,
      email: state.email,
      countryCode: state.countryCode,
    }));
  };

  const handleCheckIfWhitelist = () => {
    dispatch(checkIfWhitelistAsics(state.email));
  };

  const onChangeShoeSize = (e: React.MouseEvent<HTMLButtonElement>) => {
    setState({ ...state, [e.currentTarget.name]: e.currentTarget.value });
  };

  const onChangeCountryCode = (countryCode: string) => {
    setState({
      ...state,
      countryCode,
    });
  };

  useEffect(() => {
    if (dataWhitelist?.isWhitelisted === true) {
      onBuy();
    }
  }, [dataWhitelist]);

  return (
    <div>
      <div>
        <div className="pb-4">
          <DropDescription drop={drop} />
        </div>
        <div className="flex flex-col justify-start">
          <p className="font-grotesk text-2xl font-extrabold mb-2">
            Sizes (EU)
          </p>
          <div className="grid grid-cols-3 md:grid-cols-5 gap-4">
            {shoeSizes.map((size) => (
              <div
                key={size.label}
                className="w-full md:w-full md:px-1"
              >
                <button
                  className={`flex justify-center w-full text-lg md:text-xl px-8 md:px-12 border-2 font-grotesk font-bold rounded-lg ${state?.size === size.value ? `${process.env.REACT_APP_ASICS_IS_SOLDOUT === 'false' && 'border-blue-550 bg-blue-550 text-gray-950 hover:border-blue-550'}` : 'border-gray-350'} ${process.env.REACT_APP_ASICS_IS_SOLDOUT === 'true' && 'border-gray-500 bg-gray-550 pointer-events-none text-gray-450 bg-[url(https://sneakmartbucket.s3.eu-west-3.amazonaws.com/StrikeThroughSVG.svg)] z-50 bg-center'} `}
                  onClick={onChangeShoeSize}
                  name="size"
                  key={size.value}
                  value={size.value}
                >
                  {size.label}
                </button>
              </div>
            ))}
          </div>
          { process.env.REACT_APP_ASICS_IS_SOLDOUT !== 'true' && <DropBuyCountrySelector onChange={onChangeCountryCode} />}
          {process.env.REACT_APP_ASICS_IS_SOLDOUT === 'true' && (
            <button className="font-grotesk font-bold text-2xl">
              <div className="flex flex-row justify-center items-center gap-2 mt-4 md:mt-5 bg-gray-550 text-gray-450 pointer-events-none cursor-no-drop w-full rounded-lg px-8 py-2 ">
                The drop is sold out
                <div>
                  <HalfArrowRightSvg />
                </div>
              </div>
            </button>
          )}
          {process.env.REACT_APP_ASICS_IS_SOLDOUT === 'false' && (
            process.env.REACT_APP_SHOW_BUY_BUTTON_ON_ASICS === 'false' ? (
              <button className="font-grotesk font-bold text-2xl">
                <div className="flex flex-row justify-center items-center gap-2 mt-4 md:mt-5 bg-gray-550 text-gray-450 pointer-events-none cursor-no-drop w-full rounded-lg px-8 py-2 ">
                  Coming Soon
                  <div>
                    <HalfArrowRightSvg />
                  </div>
                </div>
              </button>
            ) : (
              <>
                <div className="md:px-1 mt-2 gap-2 flex flex-col">
                  <input
                    onChange={onChange}
                    type="email"
                    required
                    placeholder="Email address"
                    className="placeholder-white border-2 rounded-lg border-white text-gray-350 w-full text-xl px-3 py-2 bg-transparent appearance-none outline-none"
                  />
                  <button
                    onClick={handleCheckIfWhitelist}
                    type="button"
                    className="font-grotesk font-bold text-2xl w-full"
                  >
                    <div className={`flex flex-row justify-center items-center gap-2 ${state.countryCode && validator.isEmail(state?.email) ? 'bg-blue-550' : 'bg-gray-400'} text-gray-350 w-full rounded-lg px-8 py-2`}>
                      Buy Now
                      <div>
                        <HalfArrowRightSvg />
                      </div>
                    </div>
                  </button>
                </div>
                {dataWhitelist?.isWhitelisted === false && (
                <button className="font-grotesk font-bold text-2xl">
                  <div className="flex flex-row justify-center items-center gap-2 mt-4 md:mt-5 bg-red-500 text-gray-350 w-full rounded-lg px-8 py-2">
                    Sorry, you don&apos;t have access to this drop
                  </div>
                </button>
                )}
              </>
            )
          )}
        </div>
        {isTabletOrMobile && (
          <div className="pb-4">
            <DropDescription mobile drop={drop} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AsicsDropCheckout;
