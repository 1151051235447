import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ModalAskCookies from '../../_main/Cookies/ModalAskCookies';

function Head() {
  const { i18n } = useTranslation();
  const isFrench = i18n.language === 'fr';

  const title = isFrench
    ? 'Sneakmart+ : Collaborations, Sneakers et Streetwear à prix exclusif'
    : 'Sneakmart+: Collaborations, Sneakers and Streetwear at exclusive prices';

  const description = isFrench
    ? 'Des collaborations Sneakers et Streetwear innovantes. Accède à des prix exclusifs et des avantages exceptionnels sur tes sneakers préférées grâce à Sneakmart+.'
    : 'Innovative sneaker and streetwear collaborations. Access exclusive prices and exceptional perks on your favorite sneakers with Sneakmart+.';

  return (
    <div>
      <ModalAskCookies />
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>{title}</title>
        <meta
          name="description"
          content={description}
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemProp="name" content={title} />
        <meta
          itemProp="description"
          content={description}
        />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://plus.sneakmart.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content={description}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta
          name="twitter:description"
          content={description}
        />
      </Helmet>
    </div>
  );
}

export default Head;
