import { ThunkResult } from '../../../store';
import { StripeGateway } from '../../gateways/stripeGateway';
import { Actions } from './actionCreators';

export const checkDropBuyingConfirmation: (orderId: string) => ThunkResult<Promise<void>> = (orderId) => async (
  dispatch,
  getState,
  { stripeGateway }: {
    stripeGateway: StripeGateway,
  },
) => {
  dispatch(Actions.checkDropBuyingConfirmation());
  const check = await stripeGateway.checkConfirmation(orderId);
  dispatch(Actions.dropBuyingConfirmationChecked(check.orDefault(null)));
};
