import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import {
  DiscordSvg, InstagramSvg, OpenSeaSvg, TwitterSvg,
} from '../../../icons/svg';
import Button, { TextSize } from '../../../_main/Form/Button';

interface Props {
  flowerId: string
}

const social = [
  {
    id: 'id0',
    icon: DiscordSvg,
    href: 'https://discord.gg/metakicks',
  },
  {
    id: 'id1',
    icon: TwitterSvg,
    href: 'https://twitter.com/sneakmart',
  },
  {
    id: 'id2',
    icon: InstagramSvg,
    href: 'https://www.instagram.com/sneakmart/',
  },
  {
    id: 'id3',
    icon: OpenSeaSvg,
    href: 'https://opensea.io/collection/metakicks-portal-keys',
  },
];

function FlowerDisplay({ flowerId }: Props) {
  return (
    <div data-testid="metakicks-result" className="grid grid-cols-1 lg:grid-cols-2 gap-8 text-gray-350">
      <div className="aspect-square bg-gray-200 w-full">
        <ReactPlayer
          url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/flower/flower-animation.mp4"
          height="100%"
          width="100%"
          playing
          loop
          muted
          playsinline
        />
      </div>
      <div className="flex flex-col justify-between gap-6 lg:gap-3">
        <h1 className="text-[44px] uppercase lg:text-[80px] text-gray-350 leading-10 lg:leading-[80px] font-extrabold">
          Mad hunny
          #
          {flowerId}
        </h1>
        <p className="text-gray-350 font-medium text-xl">
          The Spedity &quot;Mad Hunny&quot; is a unique collaboration between Sneakmart & Flower Instincts.
          <br />
          <br />
          Your digital version of the Mad Hunny is a digital asset acting as a membership unlocking special access to private drops on Physical Streetwear items from various brands and from Flower Instincts.
        </p>
        <p className="text-gray-350 font-medium text-xl">
          You can unlock those accesses
          {' '}
          <a href="https://plus.sneakmart.com/inventory">
            <span className="underline text-blue-550">
              here
            </span>
          </a>
          {' '}
          and soon to come directly on the Sneakmart app.
        </p>
        <div className="flex flex-col">
          <div className="bg-gray-950 h-12 flex rounded-lg items-center px-4 justify-between mt-[-10px] md:mt-0">
            <p className="text-gray-350 font-bold text-[22px] lg:text-[30px]">
              Share
            </p>
            <div className="ml-5 flex flex-row gap-2">
              {social.map((item) => {
                const Icon = item.icon;
                return (
                  <a
                    href={item.href}
                    target="_blank"
                    key={item.id}
                    className="cursor-pointer"
                    rel="noreferrer"
                  >
                    <Icon key={item.id} height="30" width="30" className="fill-gray-350" />
                  </a>
                );
              })}
            </div>
          </div>
          <div className="w-full md:w-[50%] flex flex-col md:flex-row gap-3 mt-5 lg:w-full">
            <div className="w-full md:w-[50%]">
              <a target="_blank" href={`https://opensea.io/fr/assets/matic/${process.env.REACT_APP_MAD_HUNNY_CONTRACT_ADRESS_PROD!}/${flowerId}`} rel="noreferrer">
                <Button textSize={TextSize.Small} text="View on Opensea" />
              </a>
            </div>
            <div className="w-full md:w-[50%]">
              <Link to="/inventory">
                <Button textSize={TextSize.Small} text="Go back to inventory" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlowerDisplay;
