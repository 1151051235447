import React from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import Header from '../../../_main/Header';
import NewFooter from '../../../components/Home/Sections/NewFooter';
import AutomaticPlayer from '../../../_main/Video/AutomaticPlayer';
import MadHunnyDropCheckout from '../../../components/Collab/Claim/MadHunnyStripe/MadHunnyDropCheckout';

const videoUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/mad-hunny-nft.mp4';

const drop = {
  title: 'Spedity “Mad Hunny”',
  partners: 'Flower Instincts x Sneakmart',
  price: 269,
  description: 'Introducing The Spedity “Mad Hunny”, a one of a kind collaborative Sneaker between Sneakmart & Flower Instincts.',
  description2: 'Only 555 pairs made. Price includes the physical sneakers, special box, NFC chip numbered in each pair and the digital version.',
  preOrderText: 'Pre-order, shipping within 6/8 weeks (with a special surprise…). Zero waste. Fits true to size, take your usual size. All sales are final, no refunds.',
};

function MadHunnyDropPage() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="bg-black">
      <Header dark />
      <div className="mx-4 md:mx-16">
        <div className="flex flex-col md:flex-row pt-20 md:pt-28 mb-14 gap-4 md:gap-16">
          <div className="flex 2xl:justify-center w-full h-full rounded-xl overflow-hidden">
            {isTabletOrMobile ? (
              <div className="rounded-lg overflow-hidden w-auto h-auto">
                <AutomaticPlayer>
                  <ReactPlayer
                    height="100%"
                    width="100%"
                    loop
                    playsinline
                    muted
                    url={videoUrl}
                  />
                </AutomaticPlayer>
              </div>
            ) : (
              <div className="rounded-lg overflow-hidden">
                <AutomaticPlayer>
                  <ReactPlayer
                    height="600px"
                    width="600px"
                    loop
                    playsinline
                    muted
                    url={videoUrl}
                  />
                </AutomaticPlayer>
              </div>
            )}
          </div>
          <div className="flex flex-col w-full text-gray-350 font-inter text-lg font-medium gap-8">
            <MadHunnyDropCheckout drop={drop} />
          </div>
        </div>
        {isTabletOrMobile && (
          <div className="mx-[-16px]">
            <img src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/madhunny-packshot-mobile.jpg" alt="" />
          </div>
        )}
        {!isTabletOrMobile && (
          <div className="flex flex-row w-full gap-4 justify-center">
            <img src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/flower-4.jpg" alt="" className="aspect-square w-80 object-cover rounded-xl overflow-hidden" />
            <img src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/flower-3.jpg" alt="" className="aspect-square w-80 object-cover rounded-xl overflow-hidden" />
            <img src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/flower-2.jpg" alt="" className="aspect-square w-80 object-cover rounded-xl overflow-hidden" />
            <img src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/box-5.jpeg" alt="" className="aspect-square w-80 object-cover rounded-xl overflow-hidden" />
          </div>
        )}
        <NewFooter />
      </div>
    </div>
  );
}

export default MadHunnyDropPage;
