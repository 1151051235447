import React from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { HalfArrowRightSvg } from '../../../icons/svg';

interface Props {
  larveeEmeralId: string
}

function LarveeIceDisplay({ larveeEmeralId }: Props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1080px)' });
  const { t } = useTranslation();

  const renderDescription = () => (
    <div>
      {isTabletOrMobile && (
        <div className="text-2xl font-semibold mb-2">
          Description
        </div>
      )}
      <p className="text-gray-450 font-inter font-medium text-base md:text-base">
        {t('larvee.description.ice.1')}
        <br />
        <br />
        {t('larvee.description.ice.2')}
        <br />
        <br />
        {t('larvee.description.ice.3')}
      </p>
    </div>
  );

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
      <div className="aspect-square rounded-xl overflow-hidden w-full">
        <ReactPlayer
          url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/ice/desktop/videos/laarvee-ice-square.mp4"
          height="100%"
          width="100%"
          playing
          loop
          muted
          playsinline
        />
      </div>
      <div className="flex flex-col justify-end gap-6 lg:gap-3">
        <h1 className="text-3xl md:text-5xl text-gray-350 font-extrabold">
          PEA001 ICE
          <div className="text-xl font-semibold text-gray-450 md:text-2xl">
            Sneakmart x Larvee
          </div>
        </h1>
        {!isTabletOrMobile && (
          renderDescription()
        )}
        <div>
          <div className="w-full flex flex-row justify-between text-lg md:text-xl font-medium">
            <p className="text-xl font-bold font-grotesk">
              Number
            </p>
            {' '}
            <p className="text-gray-450">
              #
              {larveeEmeralId}
            </p>
          </div>
          <div className="w-full flex flex-row justify-between text-lg md:text-xl font-medium">
            <p className="text-xl font-bold font-grotesk">
              Rarity
            </p>
            {' '}
            <div className="flex flex-row justify-between items-center gap-2">
              <div className="bg-orange-650 rounded-full w-3 h-3" />
              <p className="text-gray-450">
                Mythical
              </p>
            </div>
          </div>
        </div>
        <div className="w-full ">
          <a href="https://onelink.to/laarveexsnkmt1">
            <div className="bg-[#2478E7] rounded-lg h-full py-1 flex justify-center items-center text-xl font-bold font-grotesk gap-2">
              {t('larvee.seeOnSneakmart')}
              <HalfArrowRightSvg width={20} height={20} />
            </div>
          </a>
        </div>
        {isTabletOrMobile && (
          renderDescription()
        )}
      </div>
    </div>
  );
}

export default LarveeIceDisplay;
