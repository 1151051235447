import axios, { AxiosInstance } from 'axios';
import { Either, Right } from 'purify-ts';
import {
  DropBuyingConfirmationCheck,
  ErrorDomain,
  Stacking, StackingAward, StackingHistoryItem,
} from '../../../appState';
import { StackingGateway } from '../../../core/gateways/stackingGateway';

export class HttpStackingGateway implements StackingGateway {
  private _axiosClient: AxiosInstance | null = null;

  async retrieve(walletAddress: string): Promise<Stacking | null> {
    return await this._axiosClient!.get<Stacking>(`/stacking/${walletAddress}`).then((res) => res.data);
  }

  async retrieveStackingHistory(walletAddress: string): Promise<StackingHistoryItem[] | null> {
    return await this._axiosClient!.get<StackingHistoryItem[]>(`/stacking-history/retrieve/${walletAddress}`).then((res) => res.data);
  }

  async stackPortalKey(portalKeyId: string, walletAddress: string): Promise<void> {
    await this._axiosClient!.post<void>('/portal-key/stack', {
      portalKeyId, walletAddress,
    });
  }

  async unstackPortalKey(portalKeyId: string, walletAddress: string): Promise<void> {
    await this._axiosClient!.post<void>('/portal-key/unstack', {
      portalKeyId, walletAddress,
    });
  }

  async stackFlowerKey(flowerId: string, walletAddress: string): Promise<void> {
    await this._axiosClient!.post<void>('/flower/stack', {
      flowerId, walletAddress,
    });
  }

  async unstackFlowerKey(flowerId: string, walletAddress: string): Promise<void> {
    await this._axiosClient!.post<void>('/flower/unstack', {
      flowerId, walletAddress,
    });
  }

  async stackjwasicsKey(jwasicsId: string, walletAddress: string): Promise<void> {
    await this._axiosClient!.post<void>('/jwasics/stack', {
      jwasicsId, walletAddress,
    });
  }

  async unstackjwasicsKey(jwasicsId: string, walletAddress: string): Promise<void> {
    await this._axiosClient!.post<void>('/jwasics/unstack', {
      jwasicsId, walletAddress,
    });
  }

  async stackStanKey(stanId: string, walletAddress: string): Promise<void> {
    await this._axiosClient!.post<void>('/stan/stack', {
      stanId, walletAddress,
    });
  }

  async unstackStanKey(stanId: string, walletAddress: string): Promise<void> {
    await this._axiosClient!.post<void>('/stan/unstack', {
      stanId, walletAddress,
    });
  }

  async checkConfirmation(orderId: string): Promise<Either<ErrorDomain, DropBuyingConfirmationCheck | null>> {
    const confirmation = await this._axiosClient!.get<DropBuyingConfirmationCheck>('/metakicks/order/status-checking', {
      params: {
        orderId,
      },
    }).then((res) => res.data);

    return Right(confirmation);
  }

  async redeemStackingAward(award: StackingAward, size: string, walletAddress: string): Promise<string> {
    let data = null;

    await axios.get('https://ipapi.co/json/').then((response) => {
      data = {
        countryName: response.data.country_name,
        countryCode: response.data.country_code,
        continentCode: response.data.continent_code,
      };
    });

    const createStripeCheckoutUrl = await this._axiosClient!.post<string>('stacking/create-buying-session', {
      walletAddress,
      awardId: award.id.toString(),
      size,
      location: data,
    }).then((res) => res.data);

    return createStripeCheckoutUrl;
  }

  set axiosClient(value: string) {
    this._axiosClient = axios.create({
      baseURL: `${value}/metakicks`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}
