import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import BoxSlider, { Direction } from '../GetABox/Box/BoxSlider';

const imagesUrl = [
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-1.jpg',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-2.jpg',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-3.jpg',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/flou-real-pair-1.jpeg',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-4.jpg',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-5.jpg',
];

function StaniflouBoxSlider() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: isTabletOrMobile ? '50px' : '35px',
    slidesToShow: 1,
    arrows: false,
    speed: 500,
    initalSlide: 1,
    beforeChange: (current: number, next: number) => setCurrentCarouselIndex(next),
  };
  return (
    <div className="md:pb-10 md:pt-5 md:col-span-2">
      {!isTabletOrMobile ? (
        <div className="py-12">
          <BoxSlider
            settingsOverride={{
              initialSlide: 1,
            }}
            direction={Direction.Right}
            imagesUrl={[
              'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-1.jpg',
              'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-2.jpg',
              'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-3.jpg',
              'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-5.jpg',
              'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/flou-real-pair-1.jpeg',
              'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-4.jpg',
            ]}
          />
          <div className="md:h-6" />
          <BoxSlider
            direction={Direction.Left}
            imagesUrl={[
              'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-6.jpg',
              'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/flou-real-pair-2.jpeg',
              'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-7.jpg',
              'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-8.jpg',
              'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/resized/floucustoms-9.jpg',
            ]}
            settingsOverride={{
              initialSlide: 5,
            }}
          />
        </div>
      )
        : (
          <div className="w-screen">
            <Slider {...settings}>
              {imagesUrl.slice(1).map((item, index) => (
                <div key={index} className={`transform transition duration-500 ${index !== currentCarouselIndex ? 'scale-90' : ''}`}>
                  <div
                    className="w-full items-center bg-contain rounded-xl overflow-hidden md:items-start aspect-square bg-gray-200"
                  >
                    <img
                      height="w-full"
                      width="h-full"
                      src={item}
                      alt={`$mtks-pass-${index}`}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )}
    </div>
  );
}

export default StaniflouBoxSlider;
