import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { HalfArrowRightSvg } from '../../icons/svg';

type Props = {
    data: {
        title?: string;
        description?: string;
        image: string;
        imageMobile?: string;
        pageLink?: string;
        fromColor?: string;
    },
    height?: string;
}

function HomeCard({ data, height }: Props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className={`${height}`} key={data?.pageLink}>
      <div
        style={{ backgroundImage: `${isTabletOrMobile ? `url(${data.imageMobile})` : `url(${data.image})`}` }}
        className="rounded-xl h-full bg-cover bg-center hover:scale-[98%] transition-transform duration-500"
      >
        <div className={`flex flex-col h-full justify-end text-white px-4 py-1 md:py-4 bg-gradient-to-t ${data?.fromColor} via-transparent rounded-xl`}>
          <div className="font-grotesk text-xl md:text-3xl font-extrabold">
            {data.title}
          </div>
          <div className="flex flex-col md:flex-row gap-4">
            <div className="font-inter w-full text-lg font-medium">
              {data.description}
            </div>
            {data.pageLink
              && (
              <div className="flex md:justify-end md:items-center h-full w-full md:w-full mb-4 md:mb-0">
                <Link to={data?.pageLink}>
                  <div className="flex flex-row justify-center items-center gap-4 font-grotesk font-bold text-lg md:text-2xl px-4 py-2 bg-gray-950 rounded-md md:rounded-lg opacity-80 hover:bg-white hover:text-gray-950 hover:bg-opacity-100 hover:scale-[98%]">
                    Visit Drop Page
                    <HalfArrowRightSvg />
                  </div>
                </Link>
              </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeCard;
