import {
  AppState, PortalKeyInventoryItem, FlowerInventoryItem, DropType, Drop, DropItem, jwasicsItem, StanItem,
} from '../../appState';

interface InventoryVM {
  isLoading: boolean;
  inventory: {
    portalKeys: PortalKeyInventoryItem[] | null;
    freeDrops: Drop[] | null;
    retailDrops: Drop[] | null;
    privateDrops?: Drop[] | null;
    stans?: StanItem[] | null;
    flowers?: FlowerInventoryItem[] | null;
    jwasics?: jwasicsItem[] | null;
    promoCodes?: string[] | null;
  }
}

export const selectInventoryVM = (state: AppState): InventoryVM => ({
  isLoading: state.inventory.isLoading,
  inventory: {
    portalKeys: state.inventory.data?.portalKeys || [],
    freeDrops: state.inventory.data?.freeDrops || [],
    retailDrops: state.inventory.data?.retailDrops || [],
    privateDrops: state.inventory.data?.privateDrops || [],
    stans: state.inventory.data?.stans || [],
    flowers: state.inventory.data?.flowers || [],
    jwasics: state.inventory.data?.jwasics || [],
    promoCodes: state.inventory.data?.promoCodes || [],
  },
});

export const selectDrop = (type: DropType, dropId: string, itemId: string) => (state: AppState): {isLoading: boolean, drop: Drop | null, item: DropItem | null} => {
  const mappingType = {
    free: 'freeDrops',
    retail: 'retailDrops',
    private: 'privateDrops',
  };

  const drop = (state.inventory.data as any)?.[(mappingType as any)[type as any] as any]?.find((drop: Drop) => drop.id === dropId);
  const item = drop?.items.find((item: DropItem) => item.id === itemId);

  return {
    isLoading: state.inventory.isLoading,
    drop,
    item,
  };
};

export const selectFlower = (flowerId: string) => (state: AppState) => {
  const flower = state.inventory.data?.flowers?.find((flower) => flower.id === flowerId);

  return {
    isLoading: state.inventory.isLoading,
    flower,
  };
};

export const selectjwasics = (metakicksAsicsId: string) => (state: AppState) => {
  const jwasics = state.inventory.data?.jwasics?.find((jwasics) => jwasics.id === metakicksAsicsId);

  return {
    isLoading: state.inventory.isLoading,
    jwasics,
  };
};
