import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { SneakmartWhiteReverseKSvg } from '../../../icons/svg';

const social = [
  {
    id: 'id0',
    name: 'Instagram',
    href: 'https://www.instagram.com/sneakmart/',
  },
  {
    id: 'id1',
    name: 'Discord',
    href: 'https://discord.gg/metakicks',
  },
  {
    id: 'id2',
    name: 'Twitter',
    href: 'https://twitter.com/sneakmart',
  },
  {
    id: 'id3',
    name: 'Tiktok',
    href: 'https://www.tiktok.com/@sneakmart',
  },
];

function NewFooter() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="bg-transparent py-8 md:pb-24">
      <div className="flex flex-col md:flex-row">
        {isTabletOrMobile && (
          <div className="flex justify-center">
            <SneakmartWhiteReverseKSvg height="200" width="300" className="fill-gray-350" />
          </div>
        )}
        <div className="flex flex-col justify-end gap-6">
          <p className="font-inter text-center md:text-start text-lg w-full md:w-[70%] text-gray-350 font-medium">
            Sneakmart is a Streetwear community app.
            {' '}
            <span className="font-bold font-grotesk">
              Sneakmart+
            </span>
            {' '}
            is a premium feature
            within the Sneakmart ecosystem, where we provide exclusive perks related
            to Streetwear culture. The Sneakmart app is only downloadable in France for
            now, but a brand new version will be soon available globally.
          </p>
          <div className="flex flex-col md:flex-row gap-6">
            <a href="https://sneakmart.com" target="_blank" rel="noreferrer">
              <p className="text-gray-350 font-grotesk font-bold border-2 hover:bg-white hover:text-gray-950 hover:bg-opacity-100 hover:scale-[98%] rounded-xl p-2">
                Visit Sneakmart website
              </p>
            </a>
            <a href="https://onelink.to/sneakmartweb" target="_blank" rel="noreferrer">
              <p className="text-gray-350 font-grotesk font-bold border-2 hover:bg-white hover:text-gray-950 hover:bg-opacity-100 hover:scale-[98%] rounded-xl p-2">
                Download Sneakmart App
              </p>
            </a>
          </div>
          {!isTabletOrMobile && (
            <div>
              <ul className="flex flex-row gap-8 items-center mt-4">
                <li className="text-gray-350 font-lg">
                  ©
                  {new Date().getFullYear()}
                  {' '}
                  Sneakmart. All right reserved.
                </li>
                <li className="text-gray-350 font-lg hover:text-blue-550">
                  <a href="https://sneakmart.com/about/cookies" target="_blank" rel="noreferrer">PRIVACY POLICY</a>
                </li>
                <li className="text-gray-350 font-lg hover:text-blue-550">
                  <a href="https://sneakmart.notion.site/sneakmart/Metakicks-Terms-Conditions-0a192262fb944627857c5c6291ebf3fe" target="_blank" rel="noreferrer">
                    TERMS & CONDITION
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="flex flex-col items-center lg:items-end mt-12 lg:mt-20 lg:justify-between">
          {!isTabletOrMobile && (
          <div>
            <SneakmartWhiteReverseKSvg height="200" width="300" className="fill-gray-350" />
          </div>
          )}
          <div className="flex flex-col justify-center lgs:justify-start gap-6 lg:items-start mt-6 lg:mt-0">
            <div className="flex flex-row gap-6 justify-center">
              {social.map((item) => (
                <a href={item.href} key={item.id} target="_blank" rel="noreferrer">
                  <p className="text-gray-350 hover:text-blue-550 transition-all duration-100">
                    {item.name}
                  </p>
                </a>
              ))}
            </div>
            {isTabletOrMobile && (
            <div>
              <ul>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-8">
                    <li className="text-gray-350 font-lg">©2024 Sneakmart.</li>
                    <li className="text-gray-350 font-lg">
                      ©
                      {new Date().getFullYear()}
                      {' '}
                      Sneakmart. All right reserved.
                    </li>
                  </div>
                  <div className="flex gap-8">
                    <li className="text-gray-350 font-lg hover:text-blue-550">
                      <a href="https://sneakmart.com/about/cookies" target="_blank" rel="noreferrer">PRIVACY POLICY</a>
                    </li>
                    <li className="text-gray-350 font-lg hover:text-blue-550">
                      <a href="https://sneakmart.notion.site/sneakmart/Metakicks-Terms-Conditions-0a192262fb944627857c5c6291ebf3fe" target="_blank" rel="noreferrer">
                        TERMS & CONDITION
                      </a>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewFooter;
