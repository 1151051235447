import { Either, Left, Right } from 'purify-ts';
import {
  Stacking, StackingHistoryItem, StackingAward, StaniflouColorway, DropBuyingConfirmationCheck, ErrorDomain,
} from '../../../appState';
import { StackingGateway } from '../../../core/gateways/stackingGateway';

export const sampleHistory: StackingHistoryItem[] = [
  {
    id: 'id0',
    amount: 200,
    occuresAt: new Date(2022, 2, 2, 2, 2),
    portalKeyId: '342',
    type: 'gain',
  },
  {
    id: 'id1',
    amount: 200,
    occuresAt: new Date(2022, 2, 2, 2, 2),
    awardName: 'Taxi Jordan 1',
    awardImage: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/%2Bj1-lost-found.png',
    type: 'gain',
  },
];

export const sampleStacking: Stacking = {
  currentBalance: 3600,
  keys: [
    {
      portalKeyId: '30',
      currentLevel: 1,
      isCurrentlyStacked: true,
      currentMonth: 1,
      isPremium: true,
      dateOfLastGain: new Date(2022, 11, 3),
      dateOfNextGain: new Date(2022, 11, 3),
      stackedAt: new Date(2022, 10, 3),
    },
    {
      portalKeyId: '31',
      currentLevel: 2,
      isCurrentlyStacked: false,
      currentMonth: 0,
      dateOfLastGain: new Date(2022, 11, 3),
      dateOfNextGain: null,
      stackedAt: new Date(2022, 10, 3),
    },
    {
      portalKeyId: '32',
      currentLevel: 3,
      isCurrentlyStacked: false,
      currentMonth: 1,
      dateOfLastGain: new Date(2022, 11, 3),
      dateOfNextGain: null,
      stackedAt: new Date(2022, 10, 3),
    },
    {
      portalKeyId: '33',
      currentLevel: 1,
      isCurrentlyStacked: false,
      currentMonth: 0,
      isPremium: true,
      dateOfLastGain: new Date(2022, 11, 3),
      dateOfNextGain: null,
      stackedAt: new Date(2022, 10, 3),
    },
  ],
  awards: [
    {
      id: 'id0',
      name: 'TAXI JORDAN 1',
      image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/aj1-dior.png',
      price: 200,
      type: 'sneakers',
    },
    {
      id: 'id1',
      name: 'NEW BALANCE 2002R',
      image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/nb-protection-pack.png',
      price: 100,
      type: 'sneakers',
    },
    {
      id: 'id2',
      name: 'JORDAN 1 HIGH MOCHA',
      image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/aj1-mocha.png',
      price: 500,
      type: 'sneakers',
    },
    {
      id: 'id3',
      name: 'JORDAN 4 SHIMMER',
      image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/aj4-shimmer.png',
      price: 200,
      type: 'sneakers',
    },
    {
      id: 'id4',
      name: 'NEW BALANCE 2002R',
      image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/nb-protection-pack.png',
      price: 100,
      type: 'sneakers',
    },
    {
      id: 'id5',
      name: 'JORDAN 1 HIGH MOCHA',
      image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/aj1-mocha.png',
      price: 500,
      type: 'sneakers',
    },
    {
      id: 'id6',
      name: 'JORDAN 4 SHIMMER',
      image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/redeem-shoes/aj4-shimmer.png',
      price: 200,
      type: 'sneakers',
    },
  ],
  flowers: [
    {
      flowerId: '30',
      currentLevel: 0,
      isCurrentlyStacked: false,
      currentMonth: 1,
    },
    // {
    //   flowerId: '42',
    //   currentLevel: 1,
    //   isCurrentlyStacked: false,
    //   currentMonth: 4,
    // },
    // {
    //   flowerId: '69',
    //   currentLevel: 2,
    //   isCurrentlyStacked: false,
    //   currentMonth: 7,
    // },
    // {
    //   flowerId: '16',
    //   currentLevel: 3,
    //   isCurrentlyStacked: false,
    //   currentMonth: 10,
    // },
  ],
  stans: [
    {
      stanId: '1',
      colorway: StaniflouColorway.SAKURA,
      currentLevel: 1,
      currentMonth: 1,
      isCurrentlyStacked: true,
    },
    // {
    //   stanId: '2',
    //   colorway: StaniflouColorway.MOCHA,
    //   currentLevel: 2,
    //   currentMonth: 1,
    //   isCurrentlyStacked: true,
    // },
    // {
    //   stanId: '3',
    //   colorway: StaniflouColorway.GUMMY,
    //   currentLevel: 3,
    //   currentMonth: 1,
    //   isCurrentlyStacked: true,
    // },
  ],
  jwasics: [
    {
      jwasicsId: '11',
      currentLevel: 1,
      currentMonth: 3,
      isCurrentlyStacked: false,
    },
    {
      jwasicsId: '20',
      currentLevel: 2,
      currentMonth: 3,
      isCurrentlyStacked: true,
    },
    {
      jwasicsId: '8',
      currentLevel: 3,
      currentMonth: 5,
      isCurrentlyStacked: true,
    },
  ],
};

export class FakeStackingGateway implements StackingGateway {
  private _stacking: Stacking | null = null;

  private _history: StackingHistoryItem[] | null = null;

  private _stackingRedeemBuyingCheckConfirmation: DropBuyingConfirmationCheck | null = null;

  retrieve(): Promise<Stacking | null> {
    return new Promise((resolve) => { setTimeout(() => resolve(this._stacking), 2000); });
  }

  retrieveStackingHistory(): Promise<StackingHistoryItem[] | null> {
    return new Promise((resolve) => { setTimeout(() => resolve(this._history), 2000); });
  }

  stackPortalKey(portalKeyId: string): Promise<void> {
    portalKeyId as unknown as void;

    return new Promise((resolve) => { setTimeout(() => resolve(), 2000); });
  }

  unstackPortalKey(portalKeyId: string): Promise<void> {
    portalKeyId as unknown as void;

    return new Promise((resolve) => { setTimeout(() => resolve(), 2000); });
  }

  stackFlowerKey(flowerId: string): Promise<void> {
    flowerId as unknown as void;

    return new Promise((resolve) => { setTimeout(() => resolve(), 2000); });
  }

  unstackFlowerKey(flowerId: string): Promise<void> {
    flowerId as unknown as void;

    return new Promise((resolve) => { setTimeout(() => resolve(), 2000); });
  }

  stackjwasicsKey(jwasicsId: string): Promise<void> {
    jwasicsId as unknown as void;

    return new Promise((resolve) => { setTimeout(() => resolve(), 2000); });
  }

  unstackjwasicsKey(jwasicsId: string): Promise<void> {
    jwasicsId as unknown as void;

    return new Promise((resolve) => { setTimeout(() => resolve(), 2000); });
  }

  stackStanKey(stanId: string): Promise<void> {
    stanId as unknown as void;

    return new Promise((resolve) => { setTimeout(() => resolve(), 2000); });
  }

  unstackStanKey(stanId: string): Promise<void> {
    stanId as unknown as void;

    return new Promise((resolve) => { setTimeout(() => resolve(), 2000); });
  }

  redeemStackingAward(award: StackingAward): Promise<string> {
    award as unknown as void;
    return new Promise((resolve) => { setTimeout(() => resolve('stripe'), 2000); });
  }

  async checkConfirmation(): Promise<Either<ErrorDomain, DropBuyingConfirmationCheck | null>> {
    if (!this._stackingRedeemBuyingCheckConfirmation) {
      return Left({
        text: 'Something goes wrong try later',
      });
    }

    return Right(this._stackingRedeemBuyingCheckConfirmation);
  }

  set stackingRedeemBuyingCheckConfirmation(value: DropBuyingConfirmationCheck) {
    this._stackingRedeemBuyingCheckConfirmation = value;
  }

  set stacking(value: Stacking) {
    this._stacking = value;
  }

  set history(value: StackingHistoryItem[] | null) {
    this._history = value;
  }
}
