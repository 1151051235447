import React from 'react';
import { useTranslation } from 'react-i18next';
import CatalogTitle from './CatalogTitle';
import CatalogAwardItem from './CatalogAwardItem';
import CatalogAwardItemSecured from './CatalogAwardItemSecured';
import { Drop } from '../../../../../appState';

interface Props {
  drops: Drop[]
}

function CatalogRetailDrop({ drops }: Props) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div data-testid="catalog-portal-prize" className="text-gray-350">
      <CatalogTitle title="RETAIL DROPS" />
      <div className="flex flex-col gap-4">
        {drops.map((drop) => {
          const isSoldOut = !drop.items[0].allSizes && drop.items[0].sizes.every((size) => size.quantity === 0);

          return (
            <div key={drop.id} className="md:relative flex flex-col items-center w-full">
              {drop.itemBought ? (
                <CatalogAwardItemSecured
                  name={drop.itemBought.name}
                  image={drop.itemBought.image}
                  fullWidth
                />
              ) : (
                <>
                  <CatalogAwardItem drop={drop.items[0]} dropType="retail" fullWidth dropsId={drop.id} isSoldOut={isSoldOut} currentLanguage={currentLanguage} />
                  <div className="grid grid-cols-1 md:grid-cols-3 place-content-start place-items-start mt-4 gap-x-3 gap-y-6">
                    {drop.items.slice(1).map((item) => {
                      const isSoldOut = !item.allSizes && item.sizes.every((size) => size.quantity === 0);
                      return (
                        <React.Fragment key={item.id}>
                          <CatalogAwardItem
                            drop={item}
                            dropType="retail"
                            dropsId={drop.id}
                            isSoldOut={isSoldOut}
                            currentLanguage={currentLanguage}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CatalogRetailDrop;
