import React from 'react';
import Header from '../../_main/Header';
import NewFooter from '../../components/Home/Sections/NewFooter';
import LarveeEmeraldSingleItem from '../drops/Larvee/Emerald/single-larvee-emerald-item';

function SingleLarveeEmerald() {
  return (
    <div className="w-screen min-h-screen bg-black">
      <Header dark />
      <div>
        <LarveeEmeraldSingleItem />
        <div className="px-6 md:px-16">
          <NewFooter />
        </div>
      </div>
    </div>
  );
}

export default SingleLarveeEmerald;
