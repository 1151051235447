import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

const imagesUrl = [
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/image/mobile/laarvee-emerald-caroussel-1.webp',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/image/mobile/laarvee-emerald-caroussel-2.webp',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/image/mobile/laarvee-emerald-caroussel-1.webp',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/image/mobile/laarvee-emerald-caroussel-2.webp',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/image/mobile/laarvee-emerald-caroussel-1.webp',
  'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/image/mobile/laarvee-emerald-caroussel-2.webp',
];

function LarveeDropShowcase() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { t } = useTranslation();
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: isTabletOrMobile ? '50px' : '35px',
    slidesToShow: 1,
    arrows: false,
    speed: 500,
    initalSlide: 1,
    beforeChange: (current: number, next: number) => setCurrentCarouselIndex(next),
  };

  const photo1 = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/image/desktop/larvee-3.webp';
  const photo2 = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/image/desktop/larvee-4.webp';
  const photo3 = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/emerald/image/mobile/laarvee-sneakmart-3.webp';

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-center mx-4 md:mx-16 mt-4 md:mt-20 text-gray-350 font-inter">
        <div className="flex flex-col-reverse md:flex-row md:mx-10 md:mb-4 gap-4 md:gap-8">
          <div className="flex flex-col gap-2 md:gap-4 md:w-[90%] text-white justify-start md:mt-0">
            <h1 className="text-grotesk text-3xl font-semibold">
              {t('larvee.title.2')}
            </h1>
            <div className="flex flex-col gap-2 md:gap-4 text-inter text-lg">
              <p>
                {t('larvee.description.emerald.4')}
              </p>
              <p>
                {t('larvee.description.emerald.5')}
              </p>
              <p>
                {t('larvee.description.emerald.6')}
              </p>
            </div>
          </div>
          <div className="flex md:w-[180%] md:h-[500px] items-center rounded-xl overflow-hidden">
            <img
              className="w-full"
              src={!isTabletOrMobile ? photo1 : photo3}
              alt="custom melting watch"
            />
          </div>
        </div>
      </div>
      {!isTabletOrMobile && (
        <div className="mx-4 md:mx-10 md:mb-4 mt-4 md:mt-6 rounded-xl overflow-hidden">
          <img
            className="w-full"
            src={photo2}
            alt=""
          />
        </div>
      )}
      <div className="pt-6 md:pb-10 md:pt-5 md:col-span-2">
        {isTabletOrMobile && (
          <div className="w-screen relative">
            <Slider {...settings}>
              {imagesUrl.slice(1).map((item, index) => (
                <div key={index} className={`transform rounded-lg overflow-hidden transition duration-500 ${index !== currentCarouselIndex ? 'scale-90' : ''}`}>
                  <div
                    className="w-full items-center bg-contain md:items-start aspect-square bg-gray-200"
                  >
                    <img
                      height="w-full"
                      width="h-full"
                      src={item}
                      alt={`$mtks-pass-${index}`}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
}

export default LarveeDropShowcase;
