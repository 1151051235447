import React from 'react';
import HomeCard from '../HomeCard';

const advantages = [
  {
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/2-1-collectible.jpg',
    imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/2-in-1-mobile.jpg',
    title: '2 IN 1 COLLECTIBLES',
    description: 'When buying on a Sneakmart+ drop you’re not only buying a physical product, you are also buying a digital version which acts as your membership. 2 in 1.',
    fromColor: 'from-[#8D3B78]',
  },
  {
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/private-drops.jpg',
    imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/private-drop-mobile.jpg',
    title: 'PRIVATE DROPS',
    description: 'All Sneakmart+ members have access to the Inventory section, where they can get access to physical products (mostly sneakers, clothing & collectibles) at cheap prices, way under retail.',
    fromColor: 'from-[#8A0526]',
  },
  {
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/exclusive-perks.jpg',
    imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/exclusive-perks-mobile.jpg',
    title: 'EXCLUSIVE PERKS FROM THE BRANDS YOU LOVE',
    description: 'Access free physical products and exclusive drops made for Sneakmart+ members only, from the brands you love.',
    fromColor: 'from-[#1E72A3]',
  },
];

const future = {
  image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/the-future-desktop.png',
  imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/the-future-mobile.png',
  title: 'THE FUTURE',
  description: 'Sneakmart is always evolving. All Sneakmart+ members will have a unique role in the newest upcoming Sneakmart app.',
};

function Advantages() {
  return (
    <div className="h-full">
      <div className="text-white">
        <div className="font-grotesk font-extrabold text-4xl md:text-5xl uppercase pb-2">
          Advantages
        </div>
        <div className="font-inter text-md md:text-lg pb-4">
          Being a Sneakmart+ member is not just buying a physical or digital product: it comes with a lot of perks.
          <br />
          How to join? Simply follow our journey and participate in our upcoming drops, or buy digital products from previous drops on the
          {' '}
          <span className="underline">
            <a href="https://linktr.ee/metakicksbysneakmart" target="_blank" rel="noreferrer">
              secondary market
            </a>
          </span>
          {' '}
          now.
        </div>
      </div>
      <div className="grid grid-flow-row md:grid-flow-col md:grid-cols-3 gap-8 mt-4">
        {advantages.map((advantage, index) => (
          <div className="flex items-end col-span-1 h-[550px] md:h-[660px]" key={advantage.title}>
            <HomeCard data={advantage} height={`h-full ${index === 1 && 'md:h-[590px]'} ${index === 2 && 'md:h-[650px]'}`} />
          </div>
        ))}
      </div>
      <div className="h-[200px] md:h-[250px] mt-8">
        <HomeCard data={future} height="h-[220px] md:h-[250px]" />
      </div>
    </div>
  );
}

export default Advantages;
