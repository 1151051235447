import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StackingMenu from './StackingMenu';
import { retrieveStacking } from '../../../../../core/usecases/stacking-retrieval/retrieveStacking';
import StackingHistory from './StackingHistory';
import { selectEthereumWalletVM } from '../../../../view-models-generation/generateEthereumWalletViewModel';
import { retrieveInventory } from '../../../../../core/usecases/inventory-retrieval/retrieveInventory';

function Stacking() {
  const dispatch = useDispatch();
  const { address } = useSelector(selectEthereumWalletVM);

  useEffect(() => {
    dispatch(retrieveInventory);
  }, [dispatch, address]);

  useEffect(() => {
    if (!address) return;
    dispatch(retrieveStacking({ walletAddress: address! }));
  }, [address]);

  return (
    <div className="mb-12">
      <div className="m-auto max-w-screen-xl px-8 md:leading-none">
        <StackingMenu />
      </div>
      <div className="m-auto max-w-screen-xl px-8 md:leading-none">
        <StackingHistory />
      </div>
    </div>
  );
}

export default Stacking;
