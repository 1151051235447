import { ThunkResult } from '../../../store';
import { MadHunnyDropGateway } from '../../gateways/madHunnyDropGateway';
import { Actions } from './actionCreators';

export const checkIfWhitelistMadHunny: (walletAddress?: string) => ThunkResult<Promise<void>> = (walletAddress) => async (
  dispatch,
  getState,
  { madHunnyDropGateway }: {
    madHunnyDropGateway: MadHunnyDropGateway,
  },
) => {
  dispatch(Actions.checkIsMadHunnyWhitelist());
  const claimCheck = await madHunnyDropGateway.isWhitelist(walletAddress);
  dispatch(Actions.isMadHunnyWhitelistChecked(claimCheck.orDefault(null)));
};
