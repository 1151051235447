import React from 'react';

interface Props {
  title: string;
}

function CatalogTitle({ title }: Props) {
  return (
    <div className="mb-2">
      <h1 className="text-gray-350 pt-1 md:pt-0 capitalize text-[24px] md:text-5xl font-extrabold">
        {title}
      </h1>
    </div>
  );
}

export default CatalogTitle;
