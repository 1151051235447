import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { retrieveInventory } from '../../../../../core/usecases/inventory-retrieval/retrieveInventory';
import { selectEthereumWalletVM } from '../../../../view-models-generation/generateEthereumWalletViewModel';
import { selectInventoryVM } from '../../../../view-models-generation/generateInventoryViewModel';

function PromoCode() {
  const dispatch = useDispatch();
  const { inventory } = useSelector(selectInventoryVM);
  const { address } = useSelector(selectEthereumWalletVM);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(retrieveInventory);
  }, [dispatch, address]);

  if (inventory?.promoCodes?.length === 0) return null;

  return (
    <div className="px-5 w-full md:w-[700px] mt-2 m-auto flex flex-col gap-4">
      <p className="text-white">{t('promoCodeText1')}</p>
      <p className="text-white">{t('promoCodeText2')}</p>
      <ul>
        <li className="text-white">{t('promoCodeText3')}</li>
        <li className="text-white">{t('promoCodeText4')}</li>
      </ul>
      <p className="text-white">{t('promoCodeText5')}</p>
      <p className="text-white">{t('promoCodeText6')}</p>
      <ul>
        <li className="text-blue-500 underline"><a href="https://onelink.to/sneakmartapp">{t('promoCodeText7')}</a></li>
        <li className="text-white">{t('promoCodeText8')}</li>
        <li className="text-white">{t('promoCodeText9')}</li>
      </ul>
      <div className="flex-col flex gap-1">
        {inventory?.promoCodes?.map((promoCode) => (
          <div key={promoCode}>
            <p className="text-white">
              -
              {' '}
              {promoCode}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PromoCode;
