import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { AwardsLogo, MyCollectionLogo, StockingLogo } from '../../icons/svg';

interface Props {
  currentNavigation: 'inventory' | 'stocking' | 'awards'
}

function ButtonNavigationInventory({ currentNavigation }: Props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="w-full lg:w-auto inline-block font-grotesk">
      <div className="flex flex-row justify-between px-4 ">
        <Link
          to={{ pathname: '/inventory' }}
        >
          <div className="py-2 flex flex-col items-center justify-center px-3 w-full md:px-0 md:w-[400px]">
            <MyCollectionLogo height={25} width={25} stroke={`${currentNavigation === 'inventory' ? 'rgb(25, 178, 236)' : 'gray'}`} />
            <p className={`${currentNavigation === 'inventory' ? 'text-gray-350' : 'text-[#7f8080]'} text-[16px] md:text-[24px] font-semibold`}>COLLECTION</p>
          </div>
        </Link>
        <Link
          to={{ pathname: '/inventory', hash: 'awards' }}
        >
          <div className="py-2 flex flex-col items-center justify-center px-3 w-full md:px-0 md:w-[400px]">
            <AwardsLogo height={25} width={25} stroke={`${currentNavigation === 'awards' ? 'rgb(25, 178, 236)' : 'gray'}`} />
            <p className={`${currentNavigation === 'awards' ? 'text-gray-350' : 'text-[#7F8080]'} text-[16px] md:text-[24px] font-semibold`}>
              AWARDS
              {isTabletOrMobile && <br />}
            </p>
          </div>
        </Link>
        <Link
          to={{ pathname: '/inventory', hash: 'stocking' }}
        >
          <div className="py-2 flex flex-col items-center justify-center px-3 w-full md:px-0 md:w-[400px]">
            <StockingLogo height={25} width={25} stroke={`${currentNavigation === 'stocking' ? 'rgb(25, 178, 236)' : 'gray'}`} />
            <p className={`${currentNavigation === 'stocking' ? 'text-gray-350' : 'text-[#7F8080]'} text-[16px] md:text-[24px] font-semibold`}>
              STOCKING
              {isTabletOrMobile && <br />}
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default ButtonNavigationInventory;
