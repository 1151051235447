import find from 'lodash/find';
import { AppState, PortalKeyInventoryItem } from '../../appState';

export interface PortalKeyVM {
  isLoading: boolean;
  portalKey: PortalKeyInventoryItem | null
}

export const selectPortalKeyVM = (portalKeyId: string) => (state: AppState) => ({
  isLoading: state.inventory.isLoading,
  portalKey: find(state?.inventory?.data?.portalKeys, (o) => o.id === portalKeyId) || null,
});
