import React from 'react';
import Tilt from 'react-parallax-tilt';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import AnimationBannerSeparator from './AnimationBannerSeparator';

const presentations = [
  {
    id: 'id0',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/images/SOCIAL-2.webp',
    alt: 'social',
  },
  {
    id: 'id1',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/images/MARKETPLACE-2.webp',
    alt: 'marketplace',
  },
  {
    id: 'id2',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/images/ANNONCE-2.webp',
    alt: 'annonce',
  },
  {
    id: 'id3',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/images/PROFIL-2.webp',
    alt: 'profil',
  },
];

function AppPresentation() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { t } = useTranslation();

  return (
    <div
      className="w-screen relative py-18 md:py-48 overflow-x-hidden"
      style={{
        background: 'linear-gradient(#F7F0EE 50%, #202020 50%)',
      }}
    >
      <div className="flex z-50 flex-row max-w-screen-xl px-8  m-auto gap-8 justify-center">
        {isTabletOrMobile ? (
          <img
            className="pb-10 z-50"
            src="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/images/Mockup Iphone Mobile.webp"
            alt="app-presention-mobile"
          />
        ) : presentations.map((item, index) => (
          <Tilt className="z-50" key={item.id} tiltMaxAngleY={9} tiltMaxAngleX={9} transitionSpeed={2000}>
            <img src={item.image} className={`z-50 w-[200px] xl:w-[290px] relative hover:scale-[0.95] transition-transform duration-500 ${index % 2 === 0 ? 'top-[100px]' : 'top-[-100px]'}`} alt={item.alt} key={item.id} />
          </Tilt>
        ))}
      </div>
      <div className="absolute top-[50%] w-full">
        <AnimationBannerSeparator text={t('bannerTeam')} />
      </div>
    </div>
  );
}

export default AppPresentation;
