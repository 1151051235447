import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../_main/Form/Button';

function PortalEmptyResult() {
  const { t } = useTranslation();

  return (
    <div data-testid="inventory-empty" className="w-full lg:w-3/6 pb-[30px] lg:pb-[130px] text-gray-350">
      <p className="text-lg lg:text-[24px]">
        Your inventory is empty.
        Buy a pass to start !
      </p>
      <div className="mt-6 max-w-[400px]">
        <a
          href="https://opensea.io/collection/metakicks-portal-keys"
          target="_blank"
          className="flex w-full flex-row items-center gap-2 md:gap-5 py-2 md:py-4 justify-between"
          rel="noreferrer"
        >
          <Button text={t('buyPortalKey')} />
        </a>
      </div>
    </div>
  );
}

export default PortalEmptyResult;
