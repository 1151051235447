import create from 'zustand';

export interface StackingStore {
  currentPortalKeyIdViewing: string | null;
  updateCurrentPortalKeyViewing: (newPortalKeyId: string) => void;
  currentFlowerKeyIdViewing: string | null;
  updateCurrentFlowerKeyViewing: (newFlowerKeyId: string) => void;
  currentjwasicsKeyIdViewing: string | null;
  updateCurrentjwasicsKeyViewing: (newjwasicsKeyId: string) => void;
  currentStansKeyIdViewing: string | null;
  updateCurrentStansKeyViewing: (newStansKeyId: string) => void;
  currentStansKeyColorwayViewing: string | null;
  updateCurrentStansKeyColorwayViewing: (newStansKeyColorway: string) => void;
  currentAwardIdViewing: string | null;
  updateCurrentAwardIdViewing: (newAwardId: string) => void;
}

export const useStackingStore = create<StackingStore>((set, get) => ({
  currentPortalKeyIdViewing: null,
  currentFlowerKeyIdViewing: null,
  currentjwasicsKeyIdViewing: null,
  currentStansKeyIdViewing: null,
  currentStansKeyColorwayViewing: null,
  updateCurrentPortalKeyViewing: (currentPortalKeyIdViewing: string) => set({ currentPortalKeyIdViewing }),
  updateCurrentFlowerKeyViewing: (currentFlowerKeyIdViewing: string) => set({ currentFlowerKeyIdViewing }),
  updateCurrentjwasicsKeyViewing: (currentjwasicsKeyIdViewing: string) => set({ currentjwasicsKeyIdViewing }),
  updateCurrentStansKeyViewing: (currentStansKeyIdViewing: string) => set({ currentStansKeyIdViewing }),
  updateCurrentStansKeyColorwayViewing: (currentStansKeyColorwayViewing: string) => set({ currentStansKeyColorwayViewing }),
  currentAwardIdViewing: null,
  updateCurrentAwardIdViewing: (currentAwardIdViewing: string) => {
    if (get().currentAwardIdViewing === currentAwardIdViewing) {
      set({ currentAwardIdViewing: null });
      return;
    }
    set({ currentAwardIdViewing });
  },
}));
