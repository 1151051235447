import { ThunkResult } from '../../../store';
import { StackingGateway } from '../../gateways/stackingGateway';
import { Actions } from './actionCreators';

export const retrieveStackingHistory = ({ walletAddress }: {walletAddress: string}): ThunkResult<Promise<void>> => async (
  dispatch,
  getState,
  { stackingGateway }: {
    stackingGateway: StackingGateway,
  },
) => {
  dispatch(Actions.retrieveStackingHistory());

  const history = await stackingGateway.retrieveStackingHistory(walletAddress);

  dispatch(Actions.stackingHistoryRetrieved(history));
};
