import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { retrieveEthereumWallet } from '../../../../core/usecases/ethereum-wallet-retrieval/retrieveEthereumWallet';
import { selectEthereumWalletVM } from '../../../view-models-generation/generateEthereumWalletViewModel';
import {
  HalfArrowRightSvg,
  CrossSvg, WalletConnectSvg, MetamaskSvg, ReverseKSvg,
} from '../../icons/svg';
import { Integration } from '../../../../appState';
import { clearError } from '../../../../core/usecases/error-clearing/clearError';
import { deconnectEthereumWallet } from '../../../../core/usecases/ethereum-wallet-deconnection/deconnectEthereumWallet';

interface Props {
  dark?: boolean;
  blue?: boolean;
  emailOnly?: boolean;
  buttonText?: string;
  onCloseDrawer?: Dispatch<SetStateAction<boolean>>;
}

function ConnectWalletButton({
  dark, blue, emailOnly, buttonText, onCloseDrawer,
}: Props) {
  const [isOpened, setIsOpened] = useState(false);
  const { addressFormated, openModal } = useSelector(selectEthereumWalletVM);
  const location = useLocation();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleOpenModal = () => setIsOpened((v) => !v);

  const connectToWallet = (integration: Integration) => () => {
    setIsOpened(false);
    if (onCloseDrawer) {
      onCloseDrawer(false);
    }
    dispatch(retrieveEthereumWallet(integration));
  };

  const onDeconnection = () => {
    dispatch(deconnectEthereumWallet);
  };

  useEffect(() => {
    if (openModal) {
      setIsOpened(true);
      dispatch(clearError);
    } else if (!location.pathname.includes('/inventory')) {
      setIsOpened(false);
    }
  }, [openModal]);

  useEffect(() => {
    if (addressFormated) {
      setIsOpened(false);
    }
  }, [addressFormated]);

  return (
    <>
      {addressFormated ? (
        <button
          type="button"
          data-testid="wallet-button"
          onClick={onDeconnection}
          className={`border-2 flex cursor-pointer relative items-center group justify-center px-4 py-1 duration-200 ease-in-out ${dark ? 'hover:bg-gray-950 border-gray-950 w-full' : 'hover:bg-gray-350 border-gray-350'} rounded-lg overflow-hidden`}
        >
          <p className="text-green-400 group-hover:invisible font-extrabold text-[35px] my-[-30px] ml-[-10px] pb-[14px] pr-[5px]">
            .
          </p>
          <p className="text-gray-950 font-extrabold text-[15px] invisible group-hover:visible absolute m-auto">{t('logOut')}</p>
          <p data-testid="metamask-address" className="text-blue-550 group-hover:invisible font-extrabold text-[15px]">
            {addressFormated}
          </p>
        </button>
      ) : (
        <button
          type="button"
          data-testid="button-open-modal"
          onClick={handleOpenModal}
          className={!blue
            ? `border-2 flex items-center justify-center duration-300 ease-in-out group ${dark ? 'hover:bg-gray-950 border-gray-950 w-full px-4' : 'hover:bg-gray-350 border-gray-350'} px-4 py-1 rounded-lg`
            : 'flex flex-row font-grotesk font-bold text-2xl justify-center items-center gap-2 mt-4 md:mt-5 bg-blue-550 text-gray-350 w-full rounded-lg px-8 py-2'}
        >
          {
            buttonText ? (
              <>
                <p className={`${dark ? 'text-gray-950 group-hover:text-gray-350' : 'text-gray-350 group-hover:text-gray-950'} text-gray-350 group-hover:text-gray-950 font-extrabold text-md `}>{buttonText}</p>
                <HalfArrowRightSvg height="20" width="20" />
              </>
            ) : (
              <p className={`${dark ? 'text-gray-950 group-hover:text-gray-350' : 'text-gray-350 group-hover:text-gray-950'} text-gray-350 group-hover:text-gray-950 font-extrabold text-[13px]`}>CONNECT</p>
            )
          }
        </button>
      )}
      <Modal
        isOpen={isOpened}
        onRequestClose={handleOpenModal}
        style={{
          overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          content: {
            zIndex: 1000,
            inset: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
            borderColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
          },
        }}
      >
        <div className="relative w-full flex items-center justify-center m-auto opacity-100">
          <div className="bg-black rounded-md w-[90%]  md:w-[40%] p-5 gap-4 flex flex-col pb-12">
            <div className="flex justify-end items-center w-full">
              <button type="button" onClick={handleOpenModal}>
                <CrossSvg height="30" width="30" />
              </button>
            </div>
            {!emailOnly && (
              <>
                <div className="flex flex-col items-center justify-center">
                  <button type="button" onClick={connectToWallet(Integration.Crossmint)} className="w-[90%] h-14 flex flex-row font-grotesk justify-center gap-4 border-2 border-blue-550 duration-300 group ease-in-out hover:ring-[1px] hover:ring-gray-350 items-center rounded-md cursor-pointer p-3 bg-blue-550">
                    <ReverseKSvg width="30" height="30" />
                    <p className="text-gray-950 font-extrabold text-md md:text-lg">
                      Login With Email
                    </p>
                  </button>
                </div>
                <div className="flex justify-center">
                  <div className="text-gray-400 font-inter font-semibold text-xl md:text-2xl">
                    Or
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <button type="button" data-testid="connect-wallet-button" onClick={connectToWallet(Integration.Metamask)} className="w-[90%] h-14 flex flex-row font-grotesk justify-center gap-4 border-2 border-gray-350 duration-300 group ease-in-out hover:ring-[1px] hover:ring-gray-350 items-center rounded-md cursor-pointer p-3">
                    <MetamaskSvg width="30" height="30" />
                    <p className="text-gray-350 text-md md:text-lg">
                      Login With Metamask
                    </p>
                    <HalfArrowRightSvg />
                  </button>
                </div>
                <div className="flex flex-col items-center">
                  <button type="button" onClick={connectToWallet(Integration.WalletConnect)} className="w-[90%] h-14 flex flex-row font-grotesk justify-center gap-4 border-2 border-gray-350 duration-300 group ease-in-out hover:ring-[1px] hover:ring-gray-350 items-center rounded-md cursor-pointer p-3">
                    <WalletConnectSvg width="30" height="30" />
                    <p className="text-gray-350 text-md md:text-lg">
                      Login With Wallet Connect
                    </p>
                    <HalfArrowRightSvg />
                  </button>
                </div>
              </>
            )}
            {emailOnly && (
              <div className="flex flex-col items-center mt-[-30px]">
                <button type="button" onClick={connectToWallet(Integration.Crossmint)} className="w-[90%] h-14 flex flex-row font-grotesk justify-center gap-4 border-2 border-gray-350 duration-300 group ease-in-out hover:ring-[1px] hover:ring-gray-350 items-center rounded-md cursor-pointer p-3">
                  <ReverseKSvg width="30" height="30" />
                  <p className="text-gray-350 text-[18px] pt-4">
                    email
                  </p>
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ConnectWalletButton;
