/* eslint-disable max-len */
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import AutomaticPlayer from '../../_main/Video/AutomaticPlayer';
import BoxSlider, { Direction } from '../GetABox/Box/BoxSlider';

const videos = [
  {
    id: 'id0',
    src: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/cyberware.mp4',
    poster: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/ripply.png',
  },
  {
    id: 'id1',
    src: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/electro-force.mp4',
    poster: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/electro-force.png',
  },
  {
    id: 'id2',
    src: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/disco.mp4',
    poster: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/disco.jpg',
  },
  {
    id: 'id3',
    src: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/game-of-kicks.mp4',
    poster: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/game-of-kick.jpg',
  },
  {
    id: 'id4',
    src: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/savoir-faire.mp4',
    poster: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/savoir-faire.jpg',
  },
  {
    id: 'id5',
    src: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/wild-west.mp4',
    poster: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/wild-west.jpg',
  },
];

function Stories() {
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { t } = useTranslation();

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '35px',
    slidesToShow: 1,
    arrows: false,
    speed: 500,
    initalSlide: 1,
    beforeChange: (current: number, next: number) => setCurrentCarouselIndex(next),
  };

  return (
    <div className="w-screen py-8 md:pt-20">
      <div className="max-w-screen-xl px-8 lg:px-16 2xl:px-0 m-auto">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 2xl:gap-10">
          {!isTabletOrMobile && (
            <div className="overflow-hidden">
              <div
                className="w-full aspect-square bg-gray-200 hover:scale-[1.3] transition-transform duration-1000"
                style={{
                  background: `url('${videos[0].poster}')`,
                  backgroundSize: 'contain',
                }}
              >
                <AutomaticPlayer>
                  <ReactPlayer
                    height="100%"
                    width="100%"
                    loop
                    playsinline
                    muted
                    url={videos[0].src}
                  />
                </AutomaticPlayer>
              </div>
            </div>
          )}
          <div className="col-span-2 row-span-2 flex justify-top flex-col gap-7">
            <h1 className="font-extrabold text-gray-950 text-[45px] lg:text-[110px] leading-none lg:leading-[90px] 2xl:text-[125px] 2xl:leading-[120px]">
              {t('storiesTitle')}
              <br />
              {t('storiesTitle2')}

            </h1>
            {isTabletOrMobile && (
            <div
              className="w-full my-5 aspect-square bg-gray-200"
              style={{
                background: `url('${videos[0].poster}')`,
                backgroundSize: 'contain',
              }}
            >
              <AutomaticPlayer>
                <ReactPlayer
                  height="100%"
                  width="100%"
                  loop
                  playsinline
                  muted
                  url={videos[0].src}
                />
              </AutomaticPlayer>
            </div>
            )}
            <p className="text-gray-950 text-sm md:text-1xl">
              {t('storiesText')}
              <br />
              {t('storiesText1')}
              <br />
              <br />
              {t('storiesText2')}
              <br />
              <br />
              {t('storiesText3')}
              <br />
              <br />
              {t('storiesText4')}
              <br />
              <br />
              {t('storiesText5')}
            </p>
            {isTabletOrMobile && (
              <h1 className="text-[22px] mt-4 text-center font-extrabold text-gray-950">
                Coming soon

              </h1>
            )}
          </div>
          {!isTabletOrMobile ? (
            <div>
              <div className="overflow-hidden">
                <div
                  className="w-full aspect-square bg-gray-200 hover:scale-[1.3] transition-transform duration-1000 "
                  style={{
                    background: `url('${videos[0].poster}')`,
                    backgroundSize: 'contain',
                  }}
                >
                  <AutomaticPlayer>
                    <ReactPlayer
                      height="100%"
                      width="100%"
                      loop
                      playsinline
                      muted
                      url={videos[1].src}
                    />
                  </AutomaticPlayer>
                </div>
              </div>
              <div>
                <div className="w-screen bg-gray-350 pt-8">
                  <BoxSlider
                    settingsOverride={{
                      initialSlide: 1,
                    }}
                    direction={Direction.Right}
                    videosURL={[
                      'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/wild-west.mp4',
                      'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/disco.mp4',
                      'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/game-of-kicks.mp4',
                      'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/videos/savoir-faire.mp4',
                    ]}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="w-screen left-[-30px] relative">
              <Slider {...settings}>
                {videos.slice(1).map((item, index) => (
                  <div key={item.id} className={`transform transition duration-500 ${index !== currentCarouselIndex ? 'scale-90' : ''}`}>
                    <div
                      style={{
                        background: `url('${item.poster}')`,
                        backgroundSize: 'contain',
                      }}
                      className="w-full items-center bg-contain md:items-start aspect-square bg-gray-200"
                    >
                      <AutomaticPlayer>
                        <ReactPlayer
                          height="100%"
                          width="100%"
                          loop
                          playsinline
                          muted
                          url={item.src}
                        />
                      </AutomaticPlayer>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Stories;
