import { ethers, providers } from 'ethers';
import { Either, Right, Left } from 'purify-ts';
import { BlockchainTypes, CrossmintEnvironment, CrossmintEVMWalletAdapter } from '@crossmint/connect';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { EthereumWalletGateway } from '../../../core/gateways/ethereumWalletGateway';
import { EthereumWallet, ErrorDomain, Integration } from '../../../appState';
import { Error } from '../../../error';

export class EtherJsEthereumWalletGateway implements EthereumWalletGateway {
  private _metamask: any | null = null;

  private _crossmintEmbed: any | null = null;

  private _address: string | null = null;

  private _walletConnect: any | null = null;

  private _crossmintEnvironment: CrossmintEnvironment | undefined = CrossmintEnvironment.STAGING;

  async disconnect(): Promise<void> {
    if (this._crossmintEmbed) {
      await this._crossmintEmbed.disconnect();
    }
  }

  async retrieveCrossmint(): Promise<Either<ErrorDomain, EthereumWallet | null>> {
    this._crossmintEmbed = new CrossmintEVMWalletAdapter({
      chain: BlockchainTypes.ETHEREUM,
      environment: this._crossmintEnvironment!,
      projectId: process.env.REACt_APP_CROSSMINT_PROJECT_ID!,
      // @ts-ignore
      forceWalletSelection: true,
    });

    if (!this._address) {
      await this._crossmintEmbed.connect();
      this._address = this._crossmintEmbed?.publicKeys?.[0];
    }

    const signMessage = async (plainMessage: string) => {
      const signature = await this._crossmintEmbed.signMessage(plainMessage);

      return signature;
    };

    if (!this._address) {
      return Left(Error.MetamaskNotConnected);
    }

    return Right({
      signerBalance: 0,
      signerAddress: this._address,
      integration: Integration.Crossmint,
      signMessage,
    });
  }

  async retrieve(integration: Integration): Promise<Either<ErrorDomain, EthereumWallet | null>> {
    if (this._metamask === null && integration === Integration.Metamask) return Left(Error.MetamaskNotConnected);

    let provider = null;

    if (integration === Integration.WalletConnect) {
      try {
        this._walletConnect = await EthereumProvider.init({
          projectId: '2b5447ffb1aa4ca3c5b434e23a424b17',
          chains: [1],
          showQrModal: true,
        });

        await this._walletConnect.enable();
      } catch (err) {
        return Left(Error.WalletConnectError);
      }
    }

    const web3Provider = integration === Integration.Metamask ? this._metamask : this._walletConnect;

    try {
      provider = new providers.Web3Provider(web3Provider);

      if (integration === Integration.Metamask) {
        await provider.send('eth_requestAccounts', []);
      }
    } catch (err) {
      if (integration === Integration.Metamask) {
        return Left(Error.MetamaskNotConnected);
      }
      if (integration === Integration.WalletConnect) {
        return Left(Error.WalletConnectError);
      }
    }

    const signer = provider!.getSigner();

    if (!signer) return Left(Error.MetamaskNotConnected);

    const signerAddress = await signer.getAddress();
    const signerBalance = +ethers.utils.formatEther(await signer.getBalance());

    const signMessage = async (plainMessage: string) => {
      const signature = await signer.signMessage(plainMessage);
      return signature;
    };

    return Right({
      signerAddress,
      signerBalance,
      integration,
      signMessage,
    });
  }

  set metamask(value: any) {
    this._metamask = value;
  }

  set walletConnect(value: any) {
    this._walletConnect = value;
  }

  set crossmintEnvironment(value: CrossmintEnvironment) {
    this._crossmintEnvironment = value;
  }
}
