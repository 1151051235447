import React from 'react';
import { useTranslation } from 'react-i18next';
import CatalogTitle from './CatalogTitle';
import CountdownDrops from '../Redeem/Claim/Countdown/CountdownDrops';
import CatalogAwardItem from './CatalogAwardItem';
import CatalogAwardItemSecured from './CatalogAwardItemSecured';
import { Drop } from '../../../../../appState';

interface Props {
  drops: Drop[]
}

function CatalogPrivateDrop({ drops }: Props) {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const isSoldOut = !drops[0].items[0].allSizes && drops[0].items[0].sizes.every((size) => size.quantity === 0);

  return (
    <div data-testid="catalog-portal-prize" className="text-gray-350">
      <CatalogTitle title="PRIVATE DROPS" />
      <CountdownDrops
        renderText={(renderCountdown) => (
          <div className="mt-8 text-gray-350 font-grotesk font-bold text-[18px] lg:text-[24px] mb-4 md:mb-8">
            <h1>
              {t('retailDropInventory1')}
              {' '}
              <span className="text-blue-550">
                {renderCountdown}
              </span>
              {' '}
              {t('retailDropInventory2')}
            </h1>
          </div>
        )}
        countdownDate={new Date('2022-12-05T23:59:59')}
      />
      <div className="flex flex-col gap-4">
        {drops[0].itemBought ? (
          <CatalogAwardItemSecured
            name={drops[0].itemBought.name}
            image={drops[0].itemBought.image}
            fullWidth
          />
        ) : (
          <CatalogAwardItem drop={drops[0].items[0]} dropType="private" fullWidth dropsId={drops[0].id} isSoldOut={isSoldOut} currentLanguage={currentLanguage} />
        )}
        <div className="items-start w-full grid grid-cols-1 md:grid-cols-3 place-content-start place-items-end gap-x-3 gap-y-3">
          {drops.slice(1).map((drop) => {
            const isSoldOut = !drop.items[0].allSizes && drop.items[0].sizes.every((size) => size.quantity === 0);
            return (
              <div key={drop.id} className="w-full">
                {drop.itemBought ? (
                  <CatalogAwardItemSecured
                    name={drop.itemBought.name}
                    image={drop.itemBought.image}
                  />
                ) : (
                  <div key={drop.id}>
                    <CatalogAwardItem drop={drop.items[0]} dropType="private" dropsId={drop.id} isSoldOut={isSoldOut} currentLanguage={currentLanguage} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CatalogPrivateDrop;
