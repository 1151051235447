import { combineReducers } from 'redux';
import { AsicsWhitelistCheck } from '../../appState';
import * as subscribeToAsicsWhitelistCheckActionCreators from '../usecases/asics-is-whitelist-check/actionCreators';

export const data = (
  state: AsicsWhitelistCheck | null = null,
  action: subscribeToAsicsWhitelistCheckActionCreators.Actions,
) => {
  switch (action.type) {
    case 'IS_ASICS_WHITELIST_CHECKED':
      return action.payload.asicsWhitelistCheck;
    case 'CHECK_IS_ASICS_WHITELIST':
      return null;
    default: return state;
  }
};

export const isLoading = (
  state: boolean = false,
  action: subscribeToAsicsWhitelistCheckActionCreators.Actions,
) => {
  switch (action.type) {
    case 'IS_ASICS_WHITELIST_CHECKED':
      return false;
    case 'CHECK_IS_ASICS_WHITELIST':
      return true;
    default: return state;
  }
};

export const isAsicsWhitelistCheckReducer = combineReducers({
  data,
  isLoading,
});
