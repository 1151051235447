import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { selectEthereumWalletVM } from '../../../../../view-models-generation/generateEthereumWalletViewModel';
import DropDescription from '../../BuyNewDrop/DropDescription';
import { HalfArrowRightSvg } from '../../../../icons/svg';
import { selectCheckWhitelist, selectCreateMadHunnyStripeBuyingSession } from '../../../../../view-models-generation/generateMadHunnyDropViewModel';
import { createMadHunnyStripeBuyingSession } from '../../../../../../core/usecases/madhunny-stripe-buying-session-create/createMadHunnyStripeBuyingSession';
import { checkIfWhitelistMadHunny } from '../../../../../../core/usecases/madhunny-is-whitelist-check/checkIfWhitelistMadHunny';
import DropBuyCountrySelector from '../../../Inventory/Drop/DropBuy/DropBuyCountrySelector';
import ConnectWalletButton from '../../../../_main/Wallet/ConnectWalletButton';

const shoeSizes = [
  { value: '36', label: '36' },
  { value: '36.5', label: '36.5' },
  { value: '37.5', label: '37.5' },
  { value: '38', label: '38' },
  { value: '38.5', label: '38.5' },
  { value: '39', label: '39' },
  { value: '40', label: '40' },
  { value: '40.5', label: '40.5' },
  { value: '41', label: '41' },
  { value: '42', label: '42' },
  { value: '42.5', label: '42.5' },
  { value: '43', label: '43' },
  { value: '44', label: '44' },
  { value: '44.5', label: '44.5' },
  { value: '45', label: '45' },
  { value: '45.5', label: '45.5' },
  { value: '46', label: '46' },
];

const isWhitelist = process.env.REACT_APP_MAD_HUNNY_SALE_MODE === 'whitelist';

interface Props {
  drop: {
    title: string
    partners: string,
    price: number,
    description: string,
    description2: string,
    preOrderText: string,
  }
}

function MadHunnyDropCheckout({ drop }: Props) {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { data } = useSelector(selectCreateMadHunnyStripeBuyingSession);
  const [state, setState] = useState<{size: string, email: string, countryCode: string}>({
    size: '42.5',
    countryCode: '',
    email: '',
  });

  const { address } = useSelector(selectEthereumWalletVM);

  const { data: dataWhitelist } = useSelector(selectCheckWhitelist);

  useEffect(() => {
    if (data?.clientSecret) {
      window.location.replace(data?.clientSecret);
    }
  }, [data?.clientSecret]);

  const onBuy = () => {
    if (!state) return;
    dispatch(createMadHunnyStripeBuyingSession({
      size: state.size,
      walletAddress: address,
      countryCode: state.countryCode,
    }));
  };

  useEffect(() => {
    if (!address) return;
    dispatch(checkIfWhitelistMadHunny(address));
  }, [address]);

  const onChangeShoeSize = (e: React.MouseEvent<HTMLButtonElement>) => {
    setState({ ...state, [e.currentTarget.name]: e.currentTarget.value });
  };

  const onChangeCountryCode = (countryCode: string) => {
    setState({
      ...state,
      countryCode,
    });
  };

  return (
    <div data-testid="fefe">
      <div>
        <div className="pb-4">
          <DropDescription drop={drop} />
        </div>
        <div className="flex flex-col justify-start">
          <p className="font-grotesk text-2xl font-extrabold mb-2">
            Sizes
          </p>
          <div className="grid grid-cols-3 md:grid-cols-5 gap-4">
            {shoeSizes.map((size) => (
              <div
                key={size.label}
                className="w-full md:w-full md:px-1"
              >
                <button
                  className={`flex justify-center w-full text-lg md:text-xl px-8 md:px-12 border-2 font-grotesk font-bold rounded-lg ${state?.size === size.value ? `${process.env.REACT_APP_MAD_HUNNY_IS_SOLDOUT === 'false' && 'border-blue-550 bg-blue-550 text-gray-950 hover:border-blue-550'}` : 'border-gray-350'} ${process.env.REACT_APP_MAD_HUNNY_IS_SOLDOUT === 'true' && 'border-gray-500 bg-gray-550 pointer-events-none text-gray-450 bg-[url(https://sneakmartbucket.s3.eu-west-3.amazonaws.com/StrikeThroughSVG.svg)] z-50 bg-center'} `}
                  onClick={onChangeShoeSize}
                  name="size"
                  key={size.value}
                  value={size.value}
                >
                  {size.label}
                </button>
              </div>
            ))}
          </div>
          { process.env.REACT_APP_MAD_HUNNY_IS_SOLDOUT !== 'true' && <DropBuyCountrySelector onChange={onChangeCountryCode} />}
          {process.env.REACT_APP_MAD_HUNNY_IS_SOLDOUT === 'true' && (
            <button className="font-grotesk font-bold text-2xl">
              <div className="flex flex-row justify-center items-center gap-2 mt-4 md:mt-5 bg-gray-550 text-gray-450 pointer-events-none cursor-no-drop w-full rounded-lg px-8 py-2 ">
                The drop is sold out
                <div>
                  <HalfArrowRightSvg />
                </div>
              </div>
            </button>
          )}
          {process.env.REACT_APP_MAD_HUNNY_IS_SOLDOUT === 'false' && (
            process.env.REACT_APP_SHOW_BUY_BUTTON_ON_MADHUNNY === 'false' ? (
              <button className="font-grotesk font-bold text-2xl">
                <div className="flex flex-row justify-center items-center gap-2 mt-4 md:mt-5 bg-gray-550 text-gray-450 pointer-events-none cursor-no-drop w-full rounded-lg px-8 py-2 ">
                  Coming Soon
                  <div>
                    <HalfArrowRightSvg />
                  </div>
                </div>
              </button>
            ) : (
              <>
                {(!isWhitelist || (isWhitelist && dataWhitelist?.isWhitelisted === true)) && (
                <button
                  onClick={state.countryCode ? onBuy : () => {}}
                  type="button"
                  className="font-grotesk font-bold text-2xl"
                >
                  <div className={`flex flex-row justify-center items-center gap-2 mt-4 md:mt-5 ${state.countryCode ? 'bg-blue-550' : 'bg-gray-400'} text-gray-350 w-full rounded-lg px-8 py-2`}>
                    Buy Now
                    <div>
                      <HalfArrowRightSvg />
                    </div>
                  </div>
                </button>
                )}
                {isWhitelist && dataWhitelist?.isWhitelisted === false && (
                <button className="font-grotesk font-bold text-2xl">
                  <div className="flex flex-row justify-center items-center gap-2 mt-4 md:mt-5 bg-red-500 text-gray-350 w-full rounded-lg px-8 py-2">
                    You are not on the whitelist, come back on Sunday for the public drop !
                  </div>
                </button>
                )}
                {isWhitelist && !address
                 && (<ConnectWalletButton blue buttonText="Select Size" />)}
              </>
            )
          )}
        </div>
        {isTabletOrMobile && (
          <div className="pb-4">
            <DropDescription mobile drop={drop} />
          </div>
        )}
      </div>
    </div>
  );
}

export default MadHunnyDropCheckout;
