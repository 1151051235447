import { ThunkResult } from '../../../store';
import { StackingGateway } from '../../gateways/stackingGateway';
import { Actions } from './actionCreators';

export const unstackjwasicsKey = ({ jwasicsId, walletAddress }: {jwasicsId: string, walletAddress: string}): ThunkResult<Promise<void>> => async (
  dispatch,
  getState,
  { stackingGateway }: {
    stackingGateway: StackingGateway,
  },
) => {
  dispatch(Actions.unstackjwasicsKey(jwasicsId));

  await stackingGateway.unstackjwasicsKey(jwasicsId, walletAddress);

  dispatch(Actions.jwasicsKeyUnstacked(jwasicsId));
};
