import React from 'react';
import Button, { TextSize } from '../../../_main/Form/Button';
import {
  OpenSeaSvg, ReverseKSvg,
} from '../../../icons/svg';

function MyCollectionEmpty() {
  return (
    <div className="text-gray-300 font-grotesk text-center flex flex-col items-center gap-4 pt-8">
      <div className="text-6xl font-bold">
        Your Inventory Is Empty
      </div>
      <div className="text-2xl font-inter">
        Contact us if you think
        <br />
        something is missing.
      </div>
      <div className="flex flex-col md:flex-row gap-8 pt-8">
        <div className="w-full my-3 md:mt-6 md:mb-0 xl:mt-0 xl:mb-0 rounded-xl">
          <a target="_blank" href="https://opensea.io/collection/metakicks-portal-keys" rel="noreferrer">
            <Button svg={<ReverseKSvg width={45} height={45} fill="#F7F0EE" className="fill-gray-350 group-hover:fill-gray-950" />} textSize={TextSize.Large} text="Check our Next Drop" />
          </a>
        </div>
        <div className="my-3 md:mt-6 md:mb-0 xl:mt-0 xl:mb-0 rounded-xl">
          <a target="_blank" href="https://opensea.io/collection/metakicks-portal-keys" rel="noreferrer">
            <Button svg={<OpenSeaSvg width={35} height={35} className="fill-gray-350 group-hover:fill-gray-950" />} textSize={TextSize.Large} text="Buy on Secondary Market" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default MyCollectionEmpty;
