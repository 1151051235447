import React, { useRef } from 'react';

interface Props {
  text: any;
}

function PointList({ text }: Props) {
  const myRef = useRef<HTMLLIElement>(null);

  return (
    <li ref={myRef} className="flex flex-row">
      <div className="bg-gray-350 mr-3 mt-[10px] rounded-full h-[5px] aspect-square inline-block" />
      {text}
    </li>
  );
}

export default PointList;
