import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import { HalfArrowRightSvg } from '../../../icons/svg';

const flowerSizes = [
  {
    flowerId: 1,
    size: 42,
  },
  {
    flowerId: 2,
    size: 43,
  },
  {
    flowerId: 3,
    size: 43,
  },
  {
    flowerId: 4,
    size: 37.5,
  },
  {
    flowerId: 5,
    size: 42.5,
  },
  {
    flowerId: 6,
    size: 43,
  },
  {
    flowerId: 7,
    size: 42,
  },
  {
    flowerId: 8,
    size: 42.5,
  },
  {
    flowerId: 9,
    size: 44,
  },
  {
    flowerId: 10,
    size: 42,
  },
  {
    flowerId: 11,
    size: 43,
  },
  {
    flowerId: 12,
    size: 42,
  },
  {
    flowerId: 13,
    size: 40,
  },
  {
    flowerId: 14,
    size: 42,
  },
  {
    flowerId: 15,
    size: 45,
  },
  {
    flowerId: 16,
    size: 46,
  },
  {
    flowerId: 17,
    size: 44.5,
  },
  {
    flowerId: 18,
    size: 44,
  },
  {
    flowerId: 19,
    size: 44,
  },
  {
    flowerId: 20,
    size: 44.5,
  },
  {
    flowerId: 21,
    size: 38.5,
  },
  {
    flowerId: 22,
    size: 43,
  },
  {
    flowerId: 23,
    size: 38.5,
  },
  {
    flowerId: 24,
    size: 44,
  },
  {
    flowerId: 25,
    size: 44.5,
  },
  {
    flowerId: 26,
    size: 44.5,
  },
  {
    flowerId: 27,
    size: 43,
  },
  {
    flowerId: 28,
    size: 45,
  },
  {
    flowerId: 29,
    size: 44,
  },
  {
    flowerId: 30,
    size: 43,
  },
  {
    flowerId: 31,
    size: 45,
  },
  {
    flowerId: 32,
    size: 46,
  },
  {
    flowerId: 33,
    size: 39,
  },
  {
    flowerId: 34,
    size: 42,
  },
  {
    flowerId: 35,
    size: 40,
  },
  {
    flowerId: 36,
    size: 42.5,
  },
  {
    flowerId: 37,
    size: 44.5,
  },
  {
    flowerId: 38,
    size: 44,
  },
  {
    flowerId: 39,
    size: 45,
  },
  {
    flowerId: 40,
    size: 44,
  },
  {
    flowerId: 41,
    size: 42.5,
  },
  {
    flowerId: 42,
    size: 45,
  },
  {
    flowerId: 43,
    size: 45,
  },
  {
    flowerId: 44,
    size: 40.5,
  },
  {
    flowerId: 45,
    size: 41,
  },
  {
    flowerId: 46,
    size: 45,
  },
  {
    flowerId: 47,
    size: 45.5,
  },
  {
    flowerId: 48,
    size: 42.5,
  },
  {
    flowerId: 49,
    size: 43,
  },
  {
    flowerId: 50,
    size: 43,
  },
  {
    flowerId: 51,
    size: 45,
  },
  {
    flowerId: 52,
    size: 43,
  },
  {
    flowerId: 53,
    size: 44,
  },
  {
    flowerId: 54,
    size: 46,
  },
  {
    flowerId: 55,
    size: 42,
  },
  {
    flowerId: 56,
    size: 44,
  },
  {
    flowerId: 57,
    size: 44,
  },
  {
    flowerId: 58,
    size: 42.5,
  },
  {
    flowerId: 59,
    size: 43,
  },
  {
    flowerId: 60,
    size: 41,
  },
  {
    flowerId: 61,
    size: 45,
  },
  {
    flowerId: 62,
    size: 45,
  },
  {
    flowerId: 63,
    size: 40.5,
  },
  {
    flowerId: 64,
    size: 42,
  },
  {
    flowerId: 65,
    size: 44,
  },
  {
    flowerId: 66,
    size: 43,
  },
  {
    flowerId: 67,
    size: 46,
  },
  {
    flowerId: 68,
    size: 42.5,
  },
  {
    flowerId: 69,
    size: 45,
  },
  {
    flowerId: 70,
    size: 42,
  },
  {
    flowerId: 71,
    size: 42.5,
  },
  {
    flowerId: 72,
    size: 45,
  },
  {
    flowerId: 73,
    size: 44,
  },
  {
    flowerId: 74,
    size: 42,
  },
  {
    flowerId: 75,
    size: 40,
  },
  {
    flowerId: 76,
    size: 41,
  },
  {
    flowerId: 77,
    size: 43,
  },
  {
    flowerId: 78,
    size: 46,
  },
  {
    flowerId: 79,
    size: 43,
  },
  {
    flowerId: 80,
    size: 42.5,
  },
  {
    flowerId: 81,
    size: 43,
  },
  {
    flowerId: 82,
    size: 42,
  },
  {
    flowerId: 83,
    size: 43,
  },
  {
    flowerId: 84,
    size: 44,
  },
  {
    flowerId: 85,
    size: 46,
  },
  {
    flowerId: 86,
    size: 45,
  },
  {
    flowerId: 87,
    size: 44,
  },
  {
    flowerId: 88,
    size: 46,
  },
  {
    flowerId: 89,
    size: 42.5,
  },
  {
    flowerId: 90,
    size: 42,
  },
  {
    flowerId: 91,
    size: 43,
  },
  {
    flowerId: 92,
    size: 36.5,
  },
  {
    flowerId: 93,
    size: 43,
  },
  {
    flowerId: 94,
    size: 44,
  },
  {
    flowerId: 95,
    size: 43,
  },
  {
    flowerId: 96,
    size: 41,
  },
  {
    flowerId: 97,
    size: 44,
  },
  {
    flowerId: 98,
    size: 45,
  },
  {
    flowerId: 99,
    size: 41,
  },
  {
    flowerId: 100,
    size: 45,
  },
  {
    flowerId: 101,
    size: 40,
  },
  {
    flowerId: 102,
    size: 45,
  },
  {
    flowerId: 103,
    size: 44.5,
  },
  {
    flowerId: 104,
    size: 43,
  },
  {
    flowerId: 105,
    size: 43,
  },
  {
    flowerId: 106,
    size: 36.5,
  },
  {
    flowerId: 107,
    size: 44,
  },
  {
    flowerId: 108,
    size: 44.5,
  },
  {
    flowerId: 109,
    size: 41,
  },
  {
    flowerId: 110,
    size: 43,
  },
  {
    flowerId: 111,
    size: 42.5,
  },
  {
    flowerId: 112,
    size: 42,
  },
  {
    flowerId: 113,
    size: 42.5,
  },
  {
    flowerId: 114,
    size: 39,
  },
  {
    flowerId: 115,
    size: 43,
  },
  {
    flowerId: 116,
    size: 41,
  },
  {
    flowerId: 117,
    size: 45,
  },
  {
    flowerId: 118,
    size: 41,
  },
  {
    flowerId: 119,
    size: 43,
  },
  {
    flowerId: 120,
    size: 40.5,
  },
  {
    flowerId: 121,
    size: 44,
  },
  {
    flowerId: 122,
    size: 42.5,
  },
  {
    flowerId: 123,
    size: 46,
  },
  {
    flowerId: 124,
    size: 43,
  },
  {
    flowerId: 125,
    size: 44,
  },
  {
    flowerId: 126,
    size: 44.5,
  },
  {
    flowerId: 127,
    size: 38.5,
  },
  {
    flowerId: 128,
    size: 45,
  },
  {
    flowerId: 129,
    size: 43,
  },
  {
    flowerId: 130,
    size: 44,
  },
  {
    flowerId: 131,
    size: 43,
  },
  {
    flowerId: 132,
    size: 45.5,
  },
  {
    flowerId: 133,
    size: 45.5,
  },
  {
    flowerId: 134,
    size: 45.5,
  },
  {
    flowerId: 135,
    size: 45.5,
  },
  {
    flowerId: 136,
    size: 45.5,
  },
  {
    flowerId: 137,
    size: 45.5,
  },
  {
    flowerId: 138,
    size: 45.5,
  },
  {
    flowerId: 139,
    size: 45.5,
  },
  {
    flowerId: 140,
    size: 45.5,
  },
  {
    flowerId: 141,
    size: 45.5,
  },
  {
    flowerId: 142,
    size: 46,
  },
  {
    flowerId: 143,
    size: 43,
  },
  {
    flowerId: 144,
    size: 43,
  },
  {
    flowerId: 145,
    size: 46,
  },
  {
    flowerId: 146,
    size: 45,
  },
  {
    flowerId: 147,
    size: 44,
  },
  {
    flowerId: 148,
    size: 45.5,
  },
  {
    flowerId: 149,
    size: 45.5,
  },
  {
    flowerId: 150,
    size: 45.5,
  },
  {
    flowerId: 151,
    size: 45.5,
  },
  {
    flowerId: 152,
    size: 45.5,
  },
  {
    flowerId: 153,
    size: 45.5,
  },
  {
    flowerId: 154,
    size: 45.5,
  },
  {
    flowerId: 155,
    size: 45.5,
  },
  {
    flowerId: 156,
    size: 45.5,
  },
  {
    flowerId: 157,
    size: 45.5,
  },
  {
    flowerId: 158,
    size: 45.5,
  },
  {
    flowerId: 159,
    size: 45.5,
  },
  {
    flowerId: 160,
    size: 45.5,
  },
  {
    flowerId: 161,
    size: 45.5,
  },
  {
    flowerId: 162,
    size: 45.5,
  },
  {
    flowerId: 163,
    size: 45.5,
  },
  {
    flowerId: 164,
    size: 45.5,
  },
  {
    flowerId: 165,
    size: 45.5,
  },
  {
    flowerId: 166,
    size: 45.5,
  },
  {
    flowerId: 167,
    size: 45.5,
  },
  {
    flowerId: 168,
    size: 45.5,
  },
  {
    flowerId: 169,
    size: 45.5,
  },
  {
    flowerId: 170,
    size: 45.5,
  },
  {
    flowerId: 171,
    size: 45.5,
  },
  {
    flowerId: 172,
    size: 45.5,
  },
  {
    flowerId: 173,
    size: 45.5,
  },
  {
    flowerId: 174,
    size: 45.5,
  },
  {
    flowerId: 175,
    size: 45.5,
  },
  {
    flowerId: 176,
    size: 45.5,
  },
  {
    flowerId: 177,
    size: 45.5,
  },
  {
    flowerId: 178,
    size: 45.5,
  },
  {
    flowerId: 179,
    size: 45.5,
  },
  {
    flowerId: 180,
    size: 45.5,
  },
  {
    flowerId: 181,
    size: 45.5,
  },
  {
    flowerId: 182,
    size: 45.5,
  },
  {
    flowerId: 183,
    size: 45.5,
  },
  {
    flowerId: 184,
    size: 45.5,
  },
  {
    flowerId: 185,
    size: 45.5,
  },
  {
    flowerId: 186,
    size: 45.5,
  },
  {
    flowerId: 187,
    size: 45.5,
  },
  {
    flowerId: 188,
    size: 45.5,
  },
  {
    flowerId: 189,
    size: 45.5,
  },
  {
    flowerId: 190,
    size: 45.5,
  },
  {
    flowerId: 191,
    size: 45.5,
  },
  {
    flowerId: 192,
    size: 45.5,
  },
  {
    flowerId: 193,
    size: 45.5,
  },
  {
    flowerId: 194,
    size: 45.5,
  },
  {
    flowerId: 195,
    size: 45.5,
  },
  {
    flowerId: 196,
    size: 45.5,
  },
  {
    flowerId: 197,
    size: 45.5,
  },
  {
    flowerId: 198,
    size: 45.5,
  },
  {
    flowerId: 199,
    size: 45.5,
  },
  {
    flowerId: 200,
    size: 45.5,
  },
  {
    flowerId: 201,
    size: 45.5,
  },
  {
    flowerId: 202,
    size: 45.5,
  },
  {
    flowerId: 203,
    size: 45.5,
  },
  {
    flowerId: 204,
    size: 45.5,
  },
  {
    flowerId: 205,
    size: 45.5,
  },
  {
    flowerId: 206,
    size: 45.5,
  },
  {
    flowerId: 207,
    size: 45.5,
  },
  {
    flowerId: 208,
    size: 45.5,
  },
  {
    flowerId: 209,
    size: 45.5,
  },
  {
    flowerId: 210,
    size: 45.5,
  },
  {
    flowerId: 211,
    size: 45.5,
  },
  {
    flowerId: 212,
    size: 45.5,
  },
  {
    flowerId: 213,
    size: 45.5,
  },
  {
    flowerId: 214,
    size: 45.5,
  },
  {
    flowerId: 215,
    size: 45.5,
  },
  {
    flowerId: 216,
    size: 45.5,
  },
  {
    flowerId: 217,
    size: 45.5,
  },
  {
    flowerId: 218,
    size: 45.5,
  },
  {
    flowerId: 219,
    size: 45.5,
  },
  {
    flowerId: 220,
    size: 45.5,
  },
  {
    flowerId: 221,
    size: 45.5,
  },
  {
    flowerId: 222,
    size: 45.5,
  },
  {
    flowerId: 223,
    size: 45.5,
  },
  {
    flowerId: 224,
    size: 45.5,
  },
  {
    flowerId: 225,
    size: 45.5,
  },
  {
    flowerId: 226,
    size: 45.5,
  },
  {
    flowerId: 227,
    size: 45.5,
  },
  {
    flowerId: 228,
    size: 45.5,
  },
  {
    flowerId: 229,
    size: 45.5,
  },
  {
    flowerId: 230,
    size: 45.5,
  },
  {
    flowerId: 231,
    size: 45.5,
  },
  {
    flowerId: 232,
    size: 45.5,
  },
  {
    flowerId: 233,
    size: 45.5,
  },
  {
    flowerId: 234,
    size: 45.5,
  },
  {
    flowerId: 235,
    size: 45.5,
  },
  {
    flowerId: 236,
    size: 45.5,
  },
  {
    flowerId: 237,
    size: 45.5,
  },
  {
    flowerId: 238,
    size: 46,
  },
  {
    flowerId: 239,
    size: 40,
  },
  {
    flowerId: 240,
    size: 42,
  },
  {
    flowerId: 241,
    size: 37.5,
  },
  {
    flowerId: 242,
    size: 42.5,
  },
  {
    flowerId: 243,
    size: 45,
  },
  {
    flowerId: 244,
    size: 40,
  },
  {
    flowerId: 245,
    size: 41,
  },
  {
    flowerId: 246,
    size: 42,
  },
  {
    flowerId: 247,
    size: 42.5,
  },
  {
    flowerId: 248,
    size: 41,
  },
  {
    flowerId: 249,
    size: 45.5,
  },
  {
    flowerId: 250,
    size: 45.5,
  },
  {
    flowerId: 251,
    size: 45.5,
  },
  {
    flowerId: 252,
    size: 45.5,
  },
  {
    flowerId: 253,
    size: 45.5,
  },
  {
    flowerId: 254,
    size: 45.5,
  },
  {
    flowerId: 255,
    size: 45.5,
  },
  {
    flowerId: 256,
    size: 45.5,
  },
  {
    flowerId: 257,
    size: 45.5,
  },
  {
    flowerId: 258,
    size: 45.5,
  },
  {
    flowerId: 259,
    size: 45.5,
  },
  {
    flowerId: 260,
    size: 45.5,
  },
  {
    flowerId: 261,
    size: 45.5,
  },
  {
    flowerId: 262,
    size: 45.5,
  },
  {
    flowerId: 263,
    size: 45.5,
  },
  {
    flowerId: 264,
    size: 45.5,
  },
  {
    flowerId: 265,
    size: 45.5,
  },
  {
    flowerId: 266,
    size: 45.5,
  },
  {
    flowerId: 267,
    size: 45.5,
  },
  {
    flowerId: 268,
    size: 45.5,
  },
  {
    flowerId: 269,
    size: 45.5,
  },
  {
    flowerId: 270,
    size: 45.5,
  },
  {
    flowerId: 271,
    size: 45.5,
  },
  {
    flowerId: 272,
    size: 45.5,
  },
  {
    flowerId: 273,
    size: 45.5,
  },
  {
    flowerId: 274,
    size: 45.5,
  },
  {
    flowerId: 275,
    size: 45.5,
  },
  {
    flowerId: 276,
    size: 45.5,
  },
  {
    flowerId: 277,
    size: 45.5,
  },
  {
    flowerId: 278,
    size: 45.5,
  },
  {
    flowerId: 279,
    size: 45.5,
  },
  {
    flowerId: 280,
    size: 45.5,
  },
  {
    flowerId: 281,
    size: 45.5,
  },
  {
    flowerId: 282,
    size: 45.5,
  },
  {
    flowerId: 283,
    size: 45.5,
  },
  {
    flowerId: 284,
    size: 45.5,
  },
  {
    flowerId: 285,
    size: 45.5,
  },
  {
    flowerId: 286,
    size: 45.5,
  },
  {
    flowerId: 287,
    size: 45.5,
  },
  {
    flowerId: 288,
    size: 45.5,
  },
  {
    flowerId: 289,
    size: 45.5,
  },
  {
    flowerId: 290,
    size: 45.5,
  },
  {
    flowerId: 291,
    size: 45.5,
  },
  {
    flowerId: 292,
    size: 45.5,
  },
  {
    flowerId: 293,
    size: 45.5,
  },
  {
    flowerId: 294,
    size: 45.5,
  },
  {
    flowerId: 295,
    size: 45.5,
  },
  {
    flowerId: 296,
    size: 45.5,
  },
  {
    flowerId: 297,
    size: 45.5,
  },
  {
    flowerId: 298,
    size: 45.5,
  },
  {
    flowerId: 299,
    size: 45.5,
  },
  {
    flowerId: 300,
    size: 45.5,
  },
  {
    flowerId: 301,
    size: 45.5,
  },
  {
    flowerId: 302,
    size: 45.5,
  },
  {
    flowerId: 303,
    size: 45.5,
  },
  {
    flowerId: 304,
    size: 45.5,
  },
  {
    flowerId: 305,
    size: 45.5,
  },
  {
    flowerId: 306,
    size: 45.5,
  },
  {
    flowerId: 307,
    size: 45.5,
  },
  {
    flowerId: 308,
    size: 45.5,
  },
  {
    flowerId: 309,
    size: 45.5,
  },
  {
    flowerId: 310,
    size: 45.5,
  },
  {
    flowerId: 311,
    size: 45.5,
  },
  {
    flowerId: 312,
    size: 45.5,
  },
  {
    flowerId: 313,
    size: 45.5,
  },
  {
    flowerId: 314,
    size: 45.5,
  },
  {
    flowerId: 315,
    size: 45.5,
  },
  {
    flowerId: 316,
    size: 45.5,
  },
  {
    flowerId: 317,
    size: 45.5,
  },
  {
    flowerId: 318,
    size: 45.5,
  },
  {
    flowerId: 319,
    size: 45.5,
  },
  {
    flowerId: 320,
    size: 45.5,
  },
  {
    flowerId: 321,
    size: 45.5,
  },
  {
    flowerId: 322,
    size: 45.5,
  },
  {
    flowerId: 323,
    size: 45.5,
  },
  {
    flowerId: 324,
    size: 45.5,
  },
  {
    flowerId: 325,
    size: 45.5,
  },
  {
    flowerId: 326,
    size: 45.5,
  },
  {
    flowerId: 327,
    size: 45.5,
  },
  {
    flowerId: 328,
    size: 45.5,
  },
  {
    flowerId: 329,
    size: 45.5,
  },
  {
    flowerId: 330,
    size: 45.5,
  },
  {
    flowerId: 331,
    size: 45.5,
  },
  {
    flowerId: 332,
    size: 45.5,
  },
  {
    flowerId: 333,
    size: 45.5,
  },
  {
    flowerId: 334,
    size: 45.5,
  },
  {
    flowerId: 335,
    size: 45.5,
  },
  {
    flowerId: 336,
    size: 45.5,
  },
  {
    flowerId: 337,
    size: 45.5,
  },
  {
    flowerId: 338,
    size: 45.5,
  },
  {
    flowerId: 339,
    size: 45.5,
  },
  {
    flowerId: 340,
    size: 45.5,
  },
  {
    flowerId: 341,
    size: 45.5,
  },
  {
    flowerId: 342,
    size: 45.5,
  },
  {
    flowerId: 343,
    size: 45.5,
  },
  {
    flowerId: 344,
    size: 45.5,
  },
  {
    flowerId: 345,
    size: 37.5,
  },
  {
    flowerId: 346,
    size: 45.5,
  },
  {
    flowerId: 347,
    size: 45.5,
  },
  {
    flowerId: 348,
    size: 45.5,
  },
  {
    flowerId: 349,
    size: 45.5,
  },
  {
    flowerId: 350,
    size: 45.5,
  },
  {
    flowerId: 351,
    size: 45.5,
  },
  {
    flowerId: 352,
    size: 45.5,
  },
  {
    flowerId: 353,
    size: 45.5,
  },
  {
    flowerId: 354,
    size: 45.5,
  },
  {
    flowerId: 355,
    size: 45.5,
  },
  {
    flowerId: 356,
    size: 45.5,
  },
  {
    flowerId: 357,
    size: 45.5,
  },
  {
    flowerId: 358,
    size: 45.5,
  },
  {
    flowerId: 359,
    size: 45.5,
  },
  {
    flowerId: 360,
    size: 45.5,
  },
  {
    flowerId: 361,
    size: 45.5,
  },
  {
    flowerId: 362,
    size: 45.5,
  },
  {
    flowerId: 363,
    size: 45.5,
  },
  {
    flowerId: 364,
    size: 45.5,
  },
  {
    flowerId: 365,
    size: 45.5,
  },
  {
    flowerId: 366,
    size: 45.5,
  },
  {
    flowerId: 367,
    size: 45.5,
  },
  {
    flowerId: 368,
    size: 45.5,
  },
  {
    flowerId: 369,
    size: 45.5,
  },
  {
    flowerId: 370,
    size: 45.5,
  },
  {
    flowerId: 371,
    size: 45.5,
  },
  {
    flowerId: 372,
    size: 45.5,
  },
  {
    flowerId: 373,
    size: 45.5,
  },
  {
    flowerId: 374,
    size: 45.5,
  },
  {
    flowerId: 375,
    size: 45.5,
  },
  {
    flowerId: 376,
    size: 45.5,
  },
  {
    flowerId: 377,
    size: 45.5,
  },
  {
    flowerId: 378,
    size: 45.5,
  },
  {
    flowerId: 379,
    size: 45.5,
  },
  {
    flowerId: 380,
    size: 45.5,
  },
  {
    flowerId: 381,
    size: 45.5,
  },
  {
    flowerId: 382,
    size: 45.5,
  },
  {
    flowerId: 383,
    size: 45.5,
  },
  {
    flowerId: 384,
    size: 45.5,
  },
  {
    flowerId: 385,
    size: 45.5,
  },
  {
    flowerId: 386,
    size: 45.5,
  },
  {
    flowerId: 387,
    size: 45.5,
  },
  {
    flowerId: 388,
    size: 45.5,
  },
  {
    flowerId: 389,
    size: 45.5,
  },
  {
    flowerId: 390,
    size: 45.5,
  },
  {
    flowerId: 391,
    size: 45.5,
  },
  {
    flowerId: 392,
    size: 45.5,
  },
  {
    flowerId: 393,
    size: 45.5,
  },
  {
    flowerId: 394,
    size: 45.5,
  },
  {
    flowerId: 395,
    size: 45.5,
  },
  {
    flowerId: 396,
    size: 45.5,
  },
  {
    flowerId: 397,
    size: 45.5,
  },
  {
    flowerId: 398,
    size: 45.5,
  },
  {
    flowerId: 399,
    size: 45.5,
  },
  {
    flowerId: 400,
    size: 45.5,
  },
  {
    flowerId: 401,
    size: 45.5,
  },
  {
    flowerId: 402,
    size: 45.5,
  },
  {
    flowerId: 403,
    size: 45.5,
  },
  {
    flowerId: 404,
    size: 45.5,
  },
  {
    flowerId: 405,
    size: 45.5,
  },
  {
    flowerId: 406,
    size: 45.5,
  },
  {
    flowerId: 407,
    size: 45.5,
  },
  {
    flowerId: 408,
    size: 45.5,
  },
  {
    flowerId: 409,
    size: 45.5,
  },
  {
    flowerId: 410,
    size: 45.5,
  },
  {
    flowerId: 411,
    size: 45.5,
  },
  {
    flowerId: 412,
    size: 45.5,
  },
  {
    flowerId: 413,
    size: 45.5,
  },
  {
    flowerId: 414,
    size: 45.5,
  },
  {
    flowerId: 415,
    size: 45.5,
  },
  {
    flowerId: 416,
    size: 45.5,
  },
  {
    flowerId: 417,
    size: 45.5,
  },
  {
    flowerId: 418,
    size: 45.5,
  },
  {
    flowerId: 419,
    size: 45.5,
  },
  {
    flowerId: 420,
    size: 45.5,
  },
  {
    flowerId: 421,
    size: 45.5,
  },
  {
    flowerId: 422,
    size: 45.5,
  },
  {
    flowerId: 423,
    size: 45.5,
  },
  {
    flowerId: 424,
    size: 45.5,
  },
  {
    flowerId: 425,
    size: 45.5,
  },
  {
    flowerId: 426,
    size: 45.5,
  },
  {
    flowerId: 427,
    size: 45.5,
  },
  {
    flowerId: 428,
    size: 45.5,
  },
  {
    flowerId: 429,
    size: 45.5,
  },
  {
    flowerId: 430,
    size: 45.5,
  },
  {
    flowerId: 431,
    size: 45.5,
  },
  {
    flowerId: 432,
    size: 45.5,
  },
  {
    flowerId: 433,
    size: 45.5,
  },
  {
    flowerId: 434,
    size: 45.5,
  },
  {
    flowerId: 435,
    size: 45.5,
  },
  {
    flowerId: 436,
    size: 45.5,
  },
  {
    flowerId: 437,
    size: 45.5,
  },
  {
    flowerId: 438,
    size: 45.5,
  },
  {
    flowerId: 439,
    size: 45.5,
  },
  {
    flowerId: 440,
    size: 45.5,
  },
  {
    flowerId: 441,
    size: 45.5,
  },
  {
    flowerId: 442,
    size: 45.5,
  },
  {
    flowerId: 443,
    size: 45.5,
  },
  {
    flowerId: 444,
    size: 45.5,
  },
  {
    flowerId: 445,
    size: 45.5,
  },
  {
    flowerId: 446,
    size: 42.5,
  },
  {
    flowerId: 447,
    size: 45.5,
  },
  {
    flowerId: 448,
    size: 45.5,
  },
  {
    flowerId: 449,
    size: 45.5,
  },
  {
    flowerId: 450,
    size: 45.5,
  },
  {
    flowerId: 451,
    size: 45.5,
  },
  {
    flowerId: 452,
    size: 45.5,
  },
  {
    flowerId: 453,
    size: 45.5,
  },
  {
    flowerId: 454,
    size: 45.5,
  },
  {
    flowerId: 455,
    size: 45.5,
  },
  {
    flowerId: 456,
    size: 45.5,
  },
  {
    flowerId: 457,
    size: 45.5,
  },
  {
    flowerId: 458,
    size: 45.5,
  },
  {
    flowerId: 459,
    size: 45.5,
  },
  {
    flowerId: 460,
    size: 45.5,
  },
  {
    flowerId: 461,
    size: 45.5,
  },
  {
    flowerId: 462,
    size: 45.5,
  },
  {
    flowerId: 463,
    size: 45.5,
  },
  {
    flowerId: 464,
    size: 45.5,
  },
  {
    flowerId: 465,
    size: 45.5,
  },
  {
    flowerId: 466,
    size: 45.5,
  },
  {
    flowerId: 467,
    size: 45.5,
  },
  {
    flowerId: 468,
    size: 45.5,
  },
  {
    flowerId: 469,
    size: 45.5,
  },
  {
    flowerId: 470,
    size: 45.5,
  },
  {
    flowerId: 471,
    size: 45.5,
  },
  {
    flowerId: 472,
    size: 45.5,
  },
  {
    flowerId: 473,
    size: 45.5,
  },
  {
    flowerId: 474,
    size: 45.5,
  },
  {
    flowerId: 475,
    size: 45.5,
  },
  {
    flowerId: 476,
    size: 45.5,
  },
  {
    flowerId: 477,
    size: 45.5,
  },
  {
    flowerId: 478,
    size: 45.5,
  },
  {
    flowerId: 479,
    size: 45.5,
  },
  {
    flowerId: 480,
    size: 45.5,
  },
  {
    flowerId: 481,
    size: 45.5,
  },
  {
    flowerId: 482,
    size: 45.5,
  },
  {
    flowerId: 483,
    size: 45.5,
  },
  {
    flowerId: 484,
    size: 45.5,
  },
  {
    flowerId: 485,
    size: 45.5,
  },
  {
    flowerId: 486,
    size: 45.5,
  },
  {
    flowerId: 487,
    size: 45.5,
  },
  {
    flowerId: 488,
    size: 45.5,
  },
  {
    flowerId: 489,
    size: 45.5,
  },
  {
    flowerId: 490,
    size: 45.5,
  },
  {
    flowerId: 491,
    size: 45.5,
  },
  {
    flowerId: 492,
    size: 45.5,
  },
  {
    flowerId: 493,
    size: 45.5,
  },
  {
    flowerId: 494,
    size: 45.5,
  },
  {
    flowerId: 495,
    size: 45.5,
  },
  {
    flowerId: 496,
    size: 45.5,
  },
  {
    flowerId: 497,
    size: 45.5,
  },
  {
    flowerId: 498,
    size: 45.5,
  },
  {
    flowerId: 499,
    size: 45.5,
  },
  {
    flowerId: 500,
    size: 45.5,
  },
  {
    flowerId: 501,
    size: 45.5,
  },
  {
    flowerId: 502,
    size: 45.5,
  },
  {
    flowerId: 503,
    size: 45.5,
  },
  {
    flowerId: 504,
    size: 45.5,
  },
  {
    flowerId: 505,
    size: 45.5,
  },
  {
    flowerId: 506,
    size: 45.5,
  },
  {
    flowerId: 507,
    size: 45.5,
  },
  {
    flowerId: 508,
    size: 45.5,
  },
  {
    flowerId: 509,
    size: 45.5,
  },
  {
    flowerId: 510,
    size: 45.5,
  },
  {
    flowerId: 511,
    size: 45.5,
  },
  {
    flowerId: 512,
    size: 45.5,
  },
  {
    flowerId: 513,
    size: 45.5,
  },
  {
    flowerId: 514,
    size: 45.5,
  },
  {
    flowerId: 515,
    size: 45.5,
  },
  {
    flowerId: 516,
    size: 45.5,
  },
  {
    flowerId: 517,
    size: 45.5,
  },
  {
    flowerId: 518,
    size: 45.5,
  },
  {
    flowerId: 519,
    size: 45.5,
  },
  {
    flowerId: 520,
    size: 45.5,
  },
  {
    flowerId: 521,
    size: 45.5,
  },
  {
    flowerId: 522,
    size: 45.5,
  },
  {
    flowerId: 523,
    size: 45.5,
  },
  {
    flowerId: 524,
    size: 45.5,
  },
  {
    flowerId: 525,
    size: 45.5,
  },
  {
    flowerId: 526,
    size: 45.5,
  },
  {
    flowerId: 527,
    size: 45.5,
  },
  {
    flowerId: 528,
    size: 45.5,
  },
  {
    flowerId: 529,
    size: 45.5,
  },
  {
    flowerId: 530,
    size: 45.5,
  },
  {
    flowerId: 531,
    size: 45.5,
  },
  {
    flowerId: 532,
    size: 45.5,
  },
  {
    flowerId: 533,
    size: 45.5,
  },
  {
    flowerId: 534,
    size: 45.5,
  },
  {
    flowerId: 535,
    size: 45.5,
  },
  {
    flowerId: 536,
    size: 45.5,
  },
  {
    flowerId: 537,
    size: 45.5,
  },
  {
    flowerId: 538,
    size: 45.5,
  },
  {
    flowerId: 539,
    size: 45.5,
  },
  {
    flowerId: 540,
    size: 45.5,
  },
  {
    flowerId: 541,
    size: 45.5,
  },
  {
    flowerId: 542,
    size: 45.5,
  },
  {
    flowerId: 543,
    size: 45.5,
  },
  {
    flowerId: 545,
    size: 45.5,
  },
  {
    flowerId: 546,
    size: 45.5,
  },
  {
    flowerId: 547,
    size: 45.5,
  },
  {
    flowerId: 548,
    size: 45.5,
  },
  {
    flowerId: 549,
    size: 45.5,
  },
  {
    flowerId: 550,
    size: 45.5,
  },
  {
    flowerId: 551,
    size: 45.5,
  },
  {
    flowerId: 552,
    size: 45.5,
  },
  {
    flowerId: 553,
    size: 45.5,
  },
  {
    flowerId: 554,
    size: 45.5,
  },
  {
    flowerId: 555,
    size: 45.5,
  },
];

interface Props {
  metakicksFlowerId: string
}

function MetakicksFlowerDisplay({ metakicksFlowerId }: Props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1080px)' });
  const currentSize = flowerSizes.find((flowerSize) => flowerSize.flowerId === parseInt(metakicksFlowerId, 10));

  const renderDescription = () => (
    <div>
      {isTabletOrMobile && (
        <div className="text-2xl font-semibold mb-2">
          Description
        </div>
      )}
      <p className="text-gray-450 font-inter font-medium text-base md:text-base">
        The Spedity &quot;Mad Hunny&quot; is a unique collaboration where the captivating essence of bees and honey blends seamlessly.
        Flower Instincts teamed up with Sneakmart to create this innovative collection.
        <br />
        <br />
        Each physical pair comes with a digital version to unlock unique perks within the Sneakmart ecosystem.
        Only 555 physical sneakers were made, each linked to its digital version.
      </p>
    </div>
  );

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
      <div className="aspect-square rounded-xl overflow-hidden bg-gray-200 w-full">
        <ReactPlayer
          url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/flower/flower-animation.mp4"
          height="100%"
          width="100%"
          playing
          loop
          muted
          playsinline
        />
      </div>
      <div className="flex flex-col justify-end gap-6 lg:gap-3">
        <h1 className="text-3xl md:text-5xl text-gray-350 font-extrabold">
          Spedity &quot;Mad Hunny&quot;
          <div className="text-base text-gray-450 md:text-2xl font-medium">
            Flower Instincts x Sneakmart
          </div>
        </h1>
        {!isTabletOrMobile && (
          renderDescription()
        )}
        <div>
          <div className="w-full flex flex-row justify-between text-lg md:text-xl font-medium">
            <p className="text-xl font-bold font-grotesk">
              Number
            </p>
            {' '}
            <p className="text-gray-450">
              #
              {metakicksFlowerId}
            </p>
          </div>
          <div className="w-full flex flex-row justify-between text-lg md:text-xl font-medium">
            <p className="text-xl font-bold font-grotesk">
              Size
            </p>
            {' '}
            <div className="flex flex-row justify-between items-center gap-2">
              <p className="text-gray-450">
                {currentSize?.size}
                {' '}
                EU
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row justify-between text-lg md:text-xl font-medium">
            <p className="text-xl font-bold font-grotesk">
              Rarity
            </p>
            {' '}
            <div className="flex flex-row justify-between items-center gap-2">
              <div className="bg-orange-650 rounded-full w-3 h-3" />
              <p className="text-gray-450">
                Mythical
              </p>
            </div>
          </div>
        </div>
        <div className="w-full ">
          <Link to="/inventory">
            <div className="bg-orange-650 rounded-lg h-full py-1 flex justify-center items-center text-xl font-bold font-grotesk gap-2">
              Connect to inventory
              <HalfArrowRightSvg width={20} height={20} />
            </div>
          </Link>
        </div>
        {isTabletOrMobile && (
          renderDescription()
        )}
      </div>
    </div>
  );
}

export default MetakicksFlowerDisplay;
