import React from 'react';
import { useParams } from 'react-router-dom';
import LarveeNotFound from '../../../../components/Larvee/Emerald/LarveeNotFound';
import LarveeEmeraldDisplay from '../../../../components/Larvee/Emerald/LarveeEmeraldDisplay';

type UrlParams = 'larveeEmeralId'

function LarveeEmeraldSingleItem() {
  const urlParams = useParams<Record<UrlParams, string>>();
  const larveeEmeralId = urlParams.larveeEmeralId || '';

  return (
    <div className="w-screen min-h-screen bg-black text-gray-350">
      <div className="pt-[40px] overflow-x-hidden">
        <div className="m-auto max-w-screen-xl px-4 lg:px-10 2xl:px-0 py-8 md:py-20">
          <div>
            <div>
              {Number(larveeEmeralId) < 1 || Number(larveeEmeralId) > 100 ? (
                <LarveeNotFound />
              ) : (
                <LarveeEmeraldDisplay larveeEmeralId={larveeEmeralId} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LarveeEmeraldSingleItem;
