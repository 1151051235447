import React from 'react';

function MadHunnyBuyConfirmationRefused() {
  return (
    <>
      <h1 className="font-grotesk text-[64px] text-white font-bold">OOPS.</h1>
      <p className="text-center font-medium text-[20px] text-gray-450">
        There was a problem with your transaction,
        it has failed.
        Please try again.
      </p>
    </>
  );
}

export default MadHunnyBuyConfirmationRefused;
