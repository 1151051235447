import React from 'react';
import Header from '../../_main/Header';
import Footer from '../../_main/Footer';
import CatalogPortalKeys from '../../components/Inventory/Portal/CatalogPortalKeys';

function PortalKeys() {
  return (
    <div className="w-screen min-h-screen bg-gray-350">
      <Header dark />
      <div className="pt-[80px] overflow-x-hidden">
        <div className="m-auto max-w-screen-xl px-8 lg:px-16 2xl:px-0 pb-8 md:pt-4 md:pb-4">
          <h1 className="text-[44px] lg:text-[144px] text-gray-950 font-extrabold leading-none">
            PORTAL KEYS
          </h1>
          <CatalogPortalKeys />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PortalKeys;
