import {
  Action, applyMiddleware, combineReducers, createStore, Store,
} from 'redux';
import thunk, { ThunkAction, ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { persistReducer } from 'redux-persist';
import { AppState } from './appState';
import { inventoryReducer as inventory } from './core/reducers/inventoryReducer';
import { ethereumWalletReducer as ethereumWallet } from './core/reducers/ethereumWalletReducer';
import { stackingHistoryReducer as stackingHistory } from './core/reducers/stackingHistoryReducers';
import { currentStackingAwardRedeemReducer as currentStackingAwardRedeem } from './core/reducers/currentStackingAwardRedeemReducer';
import { createStripeBuyingSessionReducer as createStripeBuyingSession } from './core/reducers/createStripeBuyingSessionReducer';
import { isMadHunnyWhitelistCheckReducer as isMadHunnyWhitelistCheck } from './core/reducers/isMadHunnyWhitelistCheckReducer';
import { isAsicsWhitelistCheckReducer as isAsicsWhitelistCheck } from './core/reducers/isAsicsWhitelistCheckReducer';
import { checkMadHunnyConfirmationReducer as checkMadHunnyConfirmation } from './core/reducers/checkMadHunnyConfirmationReducer';
import { createMadHunnyStripeBuyingSessionReducer as createMadHunnyStripeBuyingSession } from './core/reducers/createMadHunnyStripeBuyingSessionReducer';
import { createAsicsStripeBuyingSessionReducer as createAsicsStripeBuyingSession } from './core/reducers/createAsicsStripeBuyingSessionReducer';
import { checkAsicsConfirmationReducer as checkAsicsConfirmation } from './core/reducers/checkAsicsConfirmationReducer';
import { dropBuyingConfirmationCheckReducer as dropBuyingConfirmationCheck } from './core/reducers/dropBuyingConfirmationCheckReducer';
import { errorReducer as error } from './core/reducers/errorReducer';
import { InventoryGateway } from './core/gateways/inventoryGateway';
import { EthereumWalletGateway } from './core/gateways/ethereumWalletGateway';
import { StripeGateway } from './core/gateways/stripeGateway';
import { StackingGateway } from './core/gateways/stackingGateway';
import { stackingReducer as stacking } from './core/reducers/stackingReducer';
import { MadHunnyDropGateway } from './core/gateways/madHunnyDropGateway';
import { AsicsDropGateway } from './core/gateways/asicsDropGateway';

export interface Dependencies {
  inventoryGateway: InventoryGateway;
  ethereumWalletGateway: EthereumWalletGateway
  stripeGateway: StripeGateway;
  stackingGateway: StackingGateway;
  madHunnyDropGateway: MadHunnyDropGateway
  asicsDropGateway: AsicsDropGateway
}

export interface PersistConfig {
  key: string;
  storage: any
}

export const configureStore = (dependencies: Partial<Dependencies>, persistConfig?: PersistConfig): ReduxStore => {
  const reducers = {
    inventory,
    ethereumWallet,
    stackingHistory,
    currentStackingAwardRedeem,
    createStripeBuyingSession,
    isMadHunnyWhitelistCheck,
    dropBuyingConfirmationCheck,
    createMadHunnyStripeBuyingSession,
    checkMadHunnyConfirmation,
    isAsicsWhitelistCheck,
    createAsicsStripeBuyingSession,
    checkAsicsConfirmation,
    error,
    stacking,

  };

  let rootReducer = null;

  if (persistConfig) {
    rootReducer = persistReducer<any>(
      persistConfig,
      combineReducers(reducers),
    );
  } else {
    rootReducer = combineReducers(reducers);
  }

  const store = createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(thunk.withExtraArgument(dependencies) as ThunkMiddleware<AppState, Action, any>),
    ),
  );

  return store;
};

export type ReduxStore = Store<AppState> & {
  dispatch: ThunkDispatch<AppState, any, Action>;
}

export type ThunkResult<R> = ThunkAction<R, AppState, any, Action>;

export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}
export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(type: T, payload?: P): ActionWithPayload<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload };
}

type FunctionType = (...args: any[]) => any
type ActionCreatorsMapObject = { [actionCreator: string]: FunctionType };

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;
