import React from 'react';

function MyCollectionCatalogLoading() {
  return (
    <div data-testid="loading">
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 md:gap-10 w-full mt-4 md:mt-10">
        <div className="bg-gray-950 aspect-square animate-pulse rounded-lg" />
        <div className="bg-gray-950 aspect-square animate-pulse rounded-lg" />
        <div className="bg-gray-950 aspect-square animate-pulse rounded-lg" />
        <div className="bg-gray-950 aspect-square animate-pulse rounded-lg" />
        <div className="bg-gray-950 aspect-square animate-pulse rounded-lg" />
        <div className="bg-gray-950 aspect-square animate-pulse rounded-lg" />
      </div>
    </div>
  );
}

export default MyCollectionCatalogLoading;
