import { ThunkResult } from '../../../store';
import { AsicsDropGateway } from '../../gateways/asicsDropGateway';
import { Actions } from './actionCreators';

export const createAsicsStripeBuyingSession = ({
  size, email, countryCode,
}: {size: string, email?: string | null, countryCode: string}): ThunkResult<Promise<void>> => async (
  dispatch,
  getState,
  { asicsDropGateway }: {
    asicsDropGateway: AsicsDropGateway
  },
) => {
  dispatch(Actions.createAsicsStripeBuyingSession());

  const clientSecret = await asicsDropGateway.createBuyingSession(size, countryCode, email);

  clientSecret.ifLeft((err) => {
    dispatch(Actions.createAsicsStripeBuyingSessionError(err));
  });

  clientSecret.ifRight((data) => {
    dispatch(Actions.asicsStripeBuyingSessionCreated(data!));
  });
};
