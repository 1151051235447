import { combineReducers } from 'redux';
import { DropBuyingConfirmationCheck } from '../../appState';
import * as suscribeToActionCreators from '../usecases/drop-buying-confirmation-check/actionCreators';

const data = (
  state: DropBuyingConfirmationCheck | null = null,
  action: suscribeToActionCreators.Actions,
) => {
  switch (action.type) {
    case 'DROP_BUYING_CONFIRMATION_CHECKED':
      return action.payload.dropBuyingConfirmationCheck;
    case 'DROP_BUYING_HUNNY_CONFIRMATION':
      return null;
    default: return state;
  }
};

const isLoading = (
  state: boolean = false,
  action: suscribeToActionCreators.Actions,
) => {
  switch (action.type) {
    case 'DROP_BUYING_CONFIRMATION_CHECKED':
      return false;
    case 'DROP_BUYING_HUNNY_CONFIRMATION':
      return true;
    default: return state;
  }
};

export const dropBuyingConfirmationCheckReducer = combineReducers({
  isLoading,
  data,
});
