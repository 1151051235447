import React from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import AutomaticPlayer from '../../_main/Video/AutomaticPlayer';

function StaniflouDropShowcase() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="flex mx-4 md:mx-0 pt-8 md:pt-20 text-gray-350 font-inter">
        <div className="flex flex-col md:flex-row gap-4 md:gap-8 md:px-16">
          <div className="rounded-xl overflow-hidden h-full md:w-[100%] md:ml-0 2xl:ml-[700px] z-[48] hover:scale-[98%] transition-transform duration-500">
            <AutomaticPlayer>
              <ReactPlayer
                url="https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/stan-x-sneakmart.mp4"
                width="100%"
                loop
                playsinline
                muted
                height="100%"
              />
            </AutomaticPlayer>
          </div>
          <div className="flex flex-col w-[100%] justify-end">
            <p className="font-grotesk text-2xl md:text-3xl font-bold">
              SNEAKMART X STANIFLOU
            </p>
            <div className="text-lg">
              {t('collabDescription1')}
              <br />
              <br />
              {t('collabDescription2')}
              <br />
              <br />
              {t('collabDescription3')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaniflouDropShowcase;
