import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkedinSvg, TwitterSvg } from '../../icons/svg';

const teams = [
  {
    id: 'id0',
    name: 'Anthony',
    label: 'Founder & CEO',
    linkedin: 'https://www.linkedin.com/in/anthony-debrant-1b581210b/',
    twitter: 'https://twitter.com/AnthonyDebrant',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/gary.jpeg',
  },
  {
    id: 'id1',
    name: 'Paul',
    label: 'Co-founder & CTO',
    linkedin: 'https://www.linkedin.com/in/paulnizet/',
    twitter: 'https://twitter.com/PaulNizet',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/explorer.jpeg',
  },
  {
    id: 'id2',
    name: 'Martin',
    label: 'Co-founder & Tech Lead',
    linkedin: 'https://www.linkedin.com/in/martin-seigneuret-668828165/',
    twitter: 'https://twitter.com/Jack_Martin_S',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/cyber.jpeg',
  },
  {
    id: 'id3',
    name: 'Atabak',
    label: 'Creative Director',
    linkedin: 'https://www.linkedin.com/in/atabak-khalilinejad-3aa824151/',
    twitter: 'https://twitter.com/realatabak',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/nyam.jpeg',
  },
  // {
  //   id: 'id4',
  //   name: 'Oscar',
  //   label: 'Business Development',
  //   twitter: 'https://twitter.com/BladeCNXN',
  //   linkedin: 'https://www.linkedin.com/in/oscar-bimes-b0a372238/',
  //   image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/pika.jpeg',
  // },
  // {
  //   id: 'id5',
  //   name: 'Sofiane',
  //   label: 'Community Manager',
  //   linkedin: 'https://www.linkedin.com/in/sofiane-akbar-070001171/?originalSubdomain=fr',
  //   image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/base-booster.jpeg',
  // },
  // {
  //   id: 'id6',
  //   name: 'Yaniv',
  //   label: 'Head of Community',
  //   twitter: 'https://twitter.com/safran_eth',
  //   linkedin: 'https://www.linkedin.com/in/aragonesyaniv/',
  //   image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/gok.jpeg',
  // },
  // {
  //   id: 'id7',
  //   name: 'Stan',
  //   label: 'Community moderator',
  //   twitter: 'https://twitter.com/0xbxy',
  //   linkedin: 'https://www.linkedin.com/in/stanislas-montagard/',
  //   image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/nfts/posters/ripply.jpeg',
  // },
];

function Team() {
  const { t } = useTranslation();

  return (
    <div id="team" className="w-screen">
      <div className="bg-gray-950 px-8 lg:px-16 2xl:px-0">
        <div className="max-w-screen-xl m-auto pt-7 mb-6 lg:pt-5">
          <h1 className="font-extrabold text-gray-350 text-[50px] lg:text-[100px] 2xl:text-[140px]">
            {t('theTeam')}

          </h1>
        </div>
      </div>
      <div className="m-auto max-w-screen-xl py-4 md:py-12 px-8 lg:px-16 2xl:px-0">
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-10 ">
          {teams.map((item) => (
            <div key={item.id}>
              <div className="overflow-hidden">
                <div className="w-full bg-gray-200 aspect-square hover:scale-[1.3] transition-transform duration-1000">
                  <img alt={`${item.name}`} src={item.image} />
                </div>
              </div>
              <div>
                <div className="flex justify-between">
                  <h1 className="font-extrabold text-gray-950 text-[24px] lg:text-[36px] lg:leading-snug">
                    {item.name}

                  </h1>
                  <div className="flex flex-row justify-end cursor-pointer lg:w-full md:pr-2 gap-2 md:pt-1">
                    <a target="_blank" href={item.linkedin} rel="noreferrer">
                      <LinkedinSvg className="hover:fill-blue-550 transition-all duration-100  w-[25px] h-[25px] lg:w-[35px] lg:h-[35px]" />
                    </a>
                    <a target="_blank" href={item.twitter} rel="noreferrer">
                      <TwitterSvg className="hover:fill-blue-550 transition-all duration-100 mt-[1px] w-[25px] h-[25px] lg:w-[35px] lg:h-[35px]" />
                    </a>
                  </div>
                </div>
                <div className="leading-none">
                  <p className="text-gray-950  w-full font-extrabold text-[16px] lg:text-[22px]">
                    {item.label}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Team;
