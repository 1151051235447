import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import shallow from 'zustand/shallow';
import { StaniflouColorway } from '../../../../../appState';
import { useStaniflouDrop } from '../../../store/staniflouDropStore';
import Header from '../../../_main/Header';
import AutomaticPlayer from '../../../_main/Video/AutomaticPlayer';
import PickStaniflouColorways from '../../../components/Collab/Landing/ColorButtons';
import { HalfArrowRightSvg } from '../../../icons/svg';
import NewFooter from '../../../components/Home/Sections/NewFooter';

const colorways = [
  {
    name: 'mocha',
    value: StaniflouColorway.MOCHA,
    hex: [
      '#56421E',
      '#EFEAD5',
      '#A89B62',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-COFFEE.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-COFFEESCENE.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-COFFEE-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-COFFEE.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-COFFEE-COLORWAY.webp',
  },
  {
    name: 'sakura',
    value: StaniflouColorway.SAKURA,
    hex: [
      '#EFBABA',
      '#FFE4E3',
      '#72265D',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-CHERRY.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-CHERRY.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-CHERRY-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-CHERRY.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-CHERRY-COLORWAY.webp',
  },
  {
    name: 'gummy',
    value: StaniflouColorway.GUMMY,
    hex: [
      '#F7F1CF',
      '#BC879D',
      '#81B084',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-JADED.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-JADED.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-JADED-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-JADED.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-JADED-COLORWAY.webp',
  },
  {
    name: 'matcha',
    value: StaniflouColorway.MATCHA,
    hex: [
      '#FEE4E4',
      '#A6995F',
      '#752A76',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-ROCKY.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-ROCKY.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-ROCKY-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-ROCKY.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-ROCKY-COLORWAY.webp',
  },
  {
    name: 'aqua',
    value: StaniflouColorway.AQUA,
    hex: [
      '#B8D7EB',
      '#EACDAB',
      '#003C67',
    ],
    video: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/STAN-x-SNEAKMART-DESERT.mp4',
    videoMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-DESERT.mp4',
    posterMobile: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/posters/STAN-DESERT-POSTER.webp')]",
    poster: "bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/posters/STAN-x-SNEAKMART-DESERT.jpg')]",
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/images/static/STAN-DESERT-COLORWAY.webp',
  },
];

function StaniflouColorwayPage() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const [colorwayState, setColorwayState] = useState<number>(0);
  const { updateCurrentChoosenColorway } = useStaniflouDrop((state) => ({ updateCurrentChoosenColorway: state.updateCurrentChoosenColorway }), shallow);

  const onClickColorway = (e: React.MouseEvent<HTMLButtonElement>, colorway: StaniflouColorway) => {
    updateCurrentChoosenColorway(colorway);
    setColorwayState(e.currentTarget.value as unknown as number);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <div className="bg-black text-gray-350">
      <Header dark />
      <div className="mx-4 md:mx-16">
        <div className="flex flex-col md:flex-row pt-20 md:pt-28 mb-14 gap-4 md:gap-16">
          <div className="flex 2xl:justify-center w-full h-full rounded-xl overflow-hidden min-h-[360px] md:min-h-[620px]">
            {isTabletOrMobile ? (
              <div className="rounded-lg overflow-hidden w-auto h-auto">
                <AutomaticPlayer>
                  <ReactPlayer
                    height="100%"
                    width="100%"
                    loop
                    playsinline
                    muted
                    url={colorways[colorwayState].videoMobile}
                  />
                </AutomaticPlayer>
              </div>
            ) : (
              <div className="rounded-lg overflow-hidden object-cover w-full min-w-[550px]">
                <AutomaticPlayer>
                  <ReactPlayer
                    height="620px"
                    width="620px"
                    loop
                    playsinline
                    muted
                    url={colorways[colorwayState].videoMobile}
                  />
                </AutomaticPlayer>
              </div>
            )}
          </div>
          <div className="flex flex-col justify-between w-full min-h-[420px] md:min-h-[620px]">
            <div className="flex flex-col h-full justify-end mt-6 md:mt-0 lg:mt-0">
              <div className="flex flex-col items-start justify-start md:mb-8">
                <h1 className="flex items-start justify-start font-extrabold text-gray-350 text-2xl md:text-4xl">
                  STANIFLOU 1906D ZEN GARDEN
                </h1>
                <p className="text-gray-450 font-medium text-2xl flex items-start justify-start">
                  Sneakmart x Staniflou
                </p>
                <div className="flex items-start justify-start font-extrabold text-gray-350 font-grotesk text-2xl md:text-2xl">
                  500
                  €
                </div>
              </div>
              <p className="text-lg text-gray-450">
                A one of a kind customized sneaker. Select your colorway and join the Sneakmart+ journey.
                <br />
                Price includes the physical sneakers with the selected colorway, special wooden box, NFC chip numbered in each pair and the digital version.
                <br />
                <br />
                *Not affiliated with the New Balance brand, all the pairs are hand customized by Staniflou.
              </p>
            </div>
            <div className="content-end mt-4" />
            <div className="w-[100%]">
              <PickStaniflouColorways greyMode colorways={colorways} onClickColorway={onClickColorway} handleSubmit={handleSubmit} />
            </div>
            <button className="font-grotesk font-bold text-2xl">
              <div className="flex flex-row justify-center items-center gap-2 mt-4 md:mt-5 bg-gray-550 text-gray-450 pointer-events-none cursor-no-drop w-full rounded-lg px-8 py-2 ">
                The drop is sold out
                <div>
                  <HalfArrowRightSvg />
                </div>
              </div>
            </button>
          </div>
        </div>
        <NewFooter />
      </div>
    </div>
  );
}

export default StaniflouColorwayPage;
