import React from 'react';
import { ArrowRightSvg, SpinnerSvg } from '../../icons/svg';

export enum TextSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ButtonType {
  Submit = 'submit',
  Button = 'button',
  Reset = 'reset'
}

interface Props {
  text: string
  dark?: boolean
  isLoading?: boolean
  onClick?: () => void
  extraTextStyles?: object
  textSize?: TextSize
  disabled?: boolean
  type?: ButtonType
  svg?: React.ReactNode
}

function Button({
  onClick, svg, text, disabled, type, dark = false, isLoading = false, extraTextStyles = {}, textSize = TextSize.Medium,
}: Props) {
  let classNameTextSize = '';

  switch (textSize) {
    case TextSize.Small:
      classNameTextSize = 'text-[20px] md:text-[22px] lg:text-[24px]';
      break;
    case TextSize.Medium:
      classNameTextSize = 'text-[5vw] md:text-[24px] lg:text-[30px]';
      break;
    case TextSize.Large:
      classNameTextSize = 'text-[5vw] md:text-[26px] lg:text-[30px]';
      break;
    default: break;
  }

  const buttonType = type || 'button';

  return (
    <button
      disabled={isLoading}
      type={buttonType}
      data-testid="button"
      {...onClick && { onClick }}
      className={`w-full ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} border-2 rounded-md duration-300 ease-in-out ${disabled ? '' : 'group'} ${dark ? 'hover:bg-gray-950 border-gray-950 hover:drop-shadow-[0_0_1.5rem_gray]' : `${disabled ? 'border-gray-600 bg-gray-600' : 'hover:bg-gray-350 border-gray-350 hover:drop-shadow-[0_0_1.5rem_#01c1fe]'}`}`}
    >
      <div className="flex px-3 relative flex-row items-center gap-4 md:gap-5 w-full py-1 md:py-2 justify-between">
        {isLoading && (
        <div data-testid="loading" className="absolute right-[50%] translate-x-[50%]">
          <SpinnerSvg />
        </div>
        )}
        {svg}
        <p
          className={`${isLoading ? 'invisible' : ''} ${dark ? 'text-gray-950 group-hover:text-gray-350' : 'text-gray-350 group-hover:text-gray-950'} font-extrabold whitespace-nowrap ${classNameTextSize}`}
          style={extraTextStyles}
        >
          {text}
        </p>
        <ArrowRightSvg className={`${isLoading ? 'invisible' : ''} ${dark ? 'fill-gray-950 group-hover:fill-gray-350' : 'fill-gray-350 group-hover:fill-gray-950'}`} />
      </div>
    </button>
  );
}

export default Button;
