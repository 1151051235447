import React from 'react';

function PortalListLoading() {
  return (
    <div data-testid="loading" className="grid grid-cols md:grid-cols-3 w-full gap-10 mb-8 mt-6">
      <div>
        <div className="bg-gray-200 animate-pulse aspect-square" />
        <div className="bg-gray-200 animate-pulse h-12 mt-4 rounded-lg" />
      </div>
      <div>
        <div className="bg-gray-200 animate-pulse aspect-square" />
        <div className="bg-gray-200 animate-pulse h-12 mt-4 rounded-lg" />
      </div>
      <div>
        <div className="bg-gray-200 animate-pulse aspect-square" />
        <div className="bg-gray-200 animate-pulse h-12 mt-4 rounded-lg" />
      </div>
    </div>
  );
}

export default PortalListLoading;
