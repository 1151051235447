import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeCard from '../HomeCard';

const data = {
  image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/who-we-are.png',
  imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/home/who-we-are-mobile.png',
};

function WhoWeAre() {
  const { t } = useTranslation();
  return (
    <div className="h-full md:h-full">
      <div className="font-grotesk font-extrabold text-4xl md:text-5xl text-white uppercase">
        {t('whoWeAre')}
      </div>
      <div className="grid grid-flow-row md:grid-flow-col md:grid-cols-4 gap-y-8 md:gap-y-8 gap-x-0 md:gap-x-8 mt-4">
        <div className="col-span-3">
          <HomeCard data={data} height="h-[460px] md:h-[600px]" />
        </div>
        <div className="md:col-span-1 w-full">
          <div className="flex flex-col text-white">
            <div className="font-grotesk w-full font-extrabold text-2xl text-white pb-2">
              {t('futureOfStreetwear')}
            </div>
            <div className="font-inter text-md">
              {t('whoWeAreText1')}
              <br />
              <br />
              {t('whoWeAreText2')}
              <br />
              <br />
              {t('whoWeAreText3')}
              <br />
              <br />
              {t('whoWeAreText4')}
              <br />
              {t('whoWeAreText5')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhoWeAre;
