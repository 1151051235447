import React from 'react';
import Header from '../../_main/Header';
import NewFooter from '../../components/Home/Sections/NewFooter';
import AsicsItem from '../drops/Asics/single-asics-item';

function SingleAsics() {
  return (
    <div className="w-screen min-h-screen bg-black">
      <Header dark />
      <div>
        <AsicsItem />
        <div className="px-6 md:px-16">
          <NewFooter />
        </div>
      </div>
    </div>
  );
}

export default SingleAsics;
