import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../../../_main/Header';
import { selectStackingAwardVM, selectCurrentStackingAwardRedeemVM } from '../../../../view-models-generation/generateStackingViewModel';
import { selectEthereumWalletVM } from '../../../../view-models-generation/generateEthereumWalletViewModel';
import { retrieveInventory } from '../../../../../core/usecases/inventory-retrieval/retrieveInventory';
import { retrieveStacking } from '../../../../../core/usecases/stacking-retrieval/retrieveStacking';
import { redeemStackingAward } from '../../../../../core/usecases/stacking-award-redeem/redeemStackingAward';
import NewFooter from '../../../components/Home/Sections/NewFooter';
import { HalfArrowRightSvg } from '../../../icons/svg';

type UrlParams = 'awardId'

const shoeSizes = [
  { value: '38.5', label: '38.5' },
  { value: '39', label: '39' },
  { value: '39.5', label: '39.5' },
  { value: '40', label: '40' },
  { value: '40.5', label: '40.5' },
  { value: '41', label: '41' },
  { value: '41.5', label: '41.5' },
  { value: '42', label: '42' },
  { value: '42.5', label: '42.5' },
  { value: '43', label: '43' },
  { value: '43.5', label: '43.5' },
  { value: '44', label: '44' },
  { value: '44.5', label: '44.5' },
  { value: '45', label: '45' },
  { value: '45.5', label: '45.5' },
  { value: '46', label: '46' },
  { value: '46.5', label: '46.5' },
  { value: '47', label: '47' },
  { value: '47.5', label: '47.5' },
  { value: '48', label: '48' },
];

const clothesSizes = [
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'XL', label: 'XL' },
];

function StackingAward() {
  const urlParams = useParams<Record<UrlParams, string>>();
  const dispatch = useDispatch();
  const { address } = useSelector(selectEthereumWalletVM);
  const { t } = useTranslation();
  const { award } = useSelector(selectStackingAwardVM(urlParams.awardId!));
  const [state, setState] = useState<string>(award?.type === 'clothes' ? 'M' : '38.5');
  const { redeem } = useSelector(selectCurrentStackingAwardRedeemVM);

  useEffect(() => {
    dispatch(retrieveInventory);
  }, [dispatch, address]);

  useEffect(() => {
    if (!address) return;
    dispatch(retrieveStacking({ walletAddress: address! }));
  }, [address]);

  const onChangeShoeSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState(e.target.value);
  };

  const onBuy = () => {
    if (!state) return;
    dispatch(redeemStackingAward({
      award: award!, size: award?.type === 'accessories' ? 'ACCESSORY' : state, walletAddress: address!,
    }));
  };

  useEffect(() => {
    if (redeem?.stripeUrl) {
      window.location.replace(redeem!.stripeUrl);
    }
  }, [redeem?.stripeUrl]);

  return (
    <div className="w-screen min-h-screen bg-black text-gray-350 font-grotesk">
      <Header dark />
      <div className="overflow-x-hidden">
        <div className="m-auto max-w-screen-xl px-8 lg:px-16 2xl:px-0 py-8 md:py-0 md:pb-10">
          <div>
            <h1 className="text-[44px] mt-[-20px] lg:text-[144px] md:mt-[60px] leading- font-extrabold">
              AWARD
            </h1>
            <div data-testid="prize" className="grid grid-cols-1 lg:grid-cols-2 items-end mt-16 md:mt-4 gap-10">
              <div className="flex items-center justify-center bg-gray-550 rounded-xl">
                <img src={award?.image} alt="prize" />
              </div>
              <div>
                <p className="font-semibold text-[18px]">
                  {t('freeDropInventory4')}
                </p>
                <div className="flex flex-col gap-3 mt-5">
                  {award?.type !== 'accessories' && (
                  <div className="w-full h-[50px] rounded-sm flex flex-row items-center justify-between pl-4 pr-1 bg-gray-950">
                    <p className="text-[20px] md:text-[22px] font-bold justify-start">Size</p>
                    <select name="shoeSize" onChange={onChangeShoeSize} id="lang" className="w-[202px] font-bold text-gray-950 text-left rounded-sm outline-none my-2 py-2">
                      {(award?.type === 'clothes' ? clothesSizes : shoeSizes).map((size) => (
                        <option
                          key={size.value}
                          value={size.value}
                        >
                          {size.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  )}
                  <div className="w-full">
                    <button
                      onClick={onBuy}
                      type="button"
                      className="font-grotesk font-bold text-2xl w-full"
                    >
                      <div className="flex flex-row justify-start items-center gap-2 mt-4 md:mt-5 bg-black border-2 hover:bg-gray-550 text-gray-350 w-full rounded-lg px-4 py-2">
                        Confirmer
                        <div>
                          <HalfArrowRightSvg />
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 md:px-10">
        <NewFooter />
      </div>
    </div>
  );
}

export default StackingAward;
