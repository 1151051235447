import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import enLocale from 'date-fns/locale/en-US';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import isEmpty from 'lodash/isEmpty';
import { retrieveStackingHistory } from '../../../../../core/usecases/stacking-history-retrieval/retrieveStackingHistory';
import { selectStackingHistoryVM } from '../../../../view-models-generation/generateStackingViewModel';
import ArrowSeparator from './ArrowSeparator';
import { selectEthereumWalletVM } from '../../../../view-models-generation/generateEthereumWalletViewModel';
import { KicksLogoSvg } from '../../../icons/svg';

function StackingHistory() {
  const dispatch = useDispatch();
  const { address } = useSelector(selectEthereumWalletVM);
  const { history, isLoading } = useSelector(selectStackingHistoryVM);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1080px)' });

  useEffect(() => {
    if (!address) return;
    dispatch(retrieveStackingHistory({ walletAddress: address! }));
  }, [address]);

  const renderAmount = ({ type, amount }: {type: 'gain' | 'loss', amount: number}) => (
    <div className="flex flex-row items-center">
      <p className="text-[14px] md:text-[20px]">
        {type === 'gain' ? '+' : '-'}
        {' '}
        {amount}
        {' '}
      </p>
      <KicksLogoSvg width={20} />
      <div className="w-3 inline-block" />
      <div className={`w-2 h-2 inline-block md:h-5 md:w-5 rounded-full ${type === 'gain' ? 'bg-green-500' : 'bg-red-500'}`} />
    </div>
  );

  if (isLoading || isEmpty(history)) return null;

  return (
    <div className="pt-10 text-gray-350">
      <div>
        <h1 className="font-semibold text-[24px] md:text-[40px]">
          HISTORY
        </h1>
        <ArrowSeparator height="h-1" />
      </div>
      <div className="w-full">
        {history!.map((item) => (
          <div key={item.id} className="flex py-4 border-b-[4px] border-gray-950 flex-row justify-between w-full">
            <div className="flex-row flex items-center">
              <div className="h-[70px] w-[70px] flex items-center justify-center">
                <img src={item.type === 'gain' ? 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/images/mtks-pass-1.webp' : item.awardImage} alt="portal-key" />
              </div>
              <div className="flex flex-col gap-2 pl-3">
                <p className="text-[16px] md:text-[22px]">
                  {item.type === 'gain' ? `Portal Key #${item.portalKeyId}` : item.awardName}
                </p>
                <p className="text-blue-550 text-[12px] md:text-[16px]">
                  {item.type === 'gain' ? 'Stacked' : 'Bought'}
                  {' '}
                  {format(new Date(item.occuresAt), 'PPPP', { locale: enLocale })}
                </p>
                {isTabletOrMobile && renderAmount({ type: item.type, amount: item.amount })}
              </div>
            </div>
            {!isTabletOrMobile && renderAmount({ type: item.type, amount: item.amount })}
          </div>
        ))}
      </div>
    </div>
  );
}

export default StackingHistory;
