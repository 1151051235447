import React from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import Header from '../../../../_main/Header';
import NewFooter from '../../../../components/Home/Sections/NewFooter';
import AutomaticPlayer from '../../../../_main/Video/AutomaticPlayer';
import { HalfArrowRightSvg } from '../../../../icons/svg';
import LarveeIceDropShowcase from '../../../../components/Larvee/Ice/LarveeIceDropShowcase';
import LarveeIceVideoStories from '../../../../components/Larvee/Ice/LarveeIceVideoStories';

const videoUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/ice/desktop/videos/laarvee-ice-desktop-header.mp4';
const photoDesktop1 = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/ice/desktop/images/laarvee-desktop-1.webp';
const photoDesktop2 = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/ice/desktop/images/laarvee-desktop-2.webp';

const videoUrlMobile = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/ice/mobile/videos/laarvee-ice-mobile-header.mp4';
const photoMobile1 = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/ice/mobile/images/laarvee-ice-mobile-1.webp';
const photoMobile2 = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/ice/mobile/images/laarvee-ice-mobile-2.webp';

function LarveeIceRaffleLanding() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { t } = useTranslation();
  return (
    <div className="bg-black relative">
      <Header />
      <div className="fixed w-full md:w-full z-[100] px-4 lg:px-10 bottom-0 text-gray-350 py-2 bg-black bg-opacity-95 backdrop-blur-md font-grotesk">
        <div className="flex flex-col lg:flex-row gap-2 mb-2 lg:mb-0 lg:justify-between lg:items-center md:w-full opacity-95">
          <div>
            <p className="text-2xl lg:text-3xl font-bold">
              PEA001 “ICE”
            </p>
            <p className="font-inter text-md lg:text-xl font-medium lg:font-semibold">
              Laarvee x Sneakmart
            </p>
          </div>
          <a
            href="https://onelink.to/laarveexsnkmt2"
            className="hover:scale-[98%] transition-transform duration-500"
          >
            <div className="flex flex-row justify-center items-center gap-2 bg-[#2478E7] hover:bg-white hover:text-brown-550 py-2 md:px-10 font-bold rounded-lg text-xl lg:text-2xl">
              {t('larvee.seeOnSneakmart')}
              <HalfArrowRightSvg height={30} width={20} />
            </div>
          </a>
        </div>
      </div>
      <div className="w-full">
        <div className="flex flex-col lg:flex-row mb-8 md:mb-8 gap-4 lg:gap-16">
          <div className="w-full">
            <div className="rounded-xl object-cover min-h-[692px] md:min-h-[900px]">
              {isTabletOrMobile ? (
                <AutomaticPlayer>
                  <ReactPlayer
                    height="100%"
                    width="100vw"
                    loop
                    playsinline
                    muted
                    url={videoUrlMobile}
                  />
                </AutomaticPlayer>
              ) : (
                <AutomaticPlayer>
                  <ReactPlayer
                    height="100%"
                    width="100vw"
                    loop
                    playsinline
                    muted
                    url={videoUrl}
                  />
                </AutomaticPlayer>
              )}
            </div>
          </div>
        </div>
        <div className="mx-4 mb-4 md:mb-6 md:mx-10 rounded-xl overflow-hidden">
          <img
            className="overflow-hidden w-full"
            src={isTabletOrMobile ? photoMobile1 : photoDesktop1}
            alt=""
          />
        </div>
        <div className="flex flex-col md:flex-row mx-4 md:mx-10 md:mb-4 gap-3 md:gap-8">
          <div className="flex md:w-[180%] items-center rounded-xl overflow-hidden">
            <img
              className="w-full"
              src={isTabletOrMobile ? photoMobile2 : photoDesktop2}
              alt=""
            />
          </div>
          <div className="flex flex-col gap-2 md:gap-4 md:w-[90%] text-white justify-end md:mt-8">
            <h1 className="text-grotesk text-3xl md:ext-4xl font-semibold">
              {t('larvee.title.1')}
            </h1>
            <div className="flex flex-col gap-2 md:gap-4 text-inter text-lg">
              <p>
                {t('larvee.description.ice.1')}
              </p>
              <p>
                {t('larvee.description.ice.2')}
              </p>
              <p>
                {t('larvee.description.ice.3')}
              </p>
            </div>
          </div>
        </div>
        <LarveeIceVideoStories />
        <LarveeIceDropShowcase />
        <div className="mx-4 lg:mx-10 pb-32 md:pb-2">
          <NewFooter />
        </div>
      </div>
    </div>
  );
}

export default LarveeIceRaffleLanding;
