import axios, { AxiosInstance } from 'axios';
import { Either, Right } from 'purify-ts';
import { MadHunnyDropGateway } from '../../../core/gateways/madHunnyDropGateway';
import { ErrorDomain, MadHunnyConfirmationCheck, MadHunnyWhitelistCheck } from '../../../appState';

export class HttpMadHunnyGateway implements MadHunnyDropGateway {
  private _axiosClient: AxiosInstance | null = null;

  async isWhitelist(walletAddress?: string): Promise<Either<ErrorDomain, MadHunnyWhitelistCheck | null>> {
    const isWhitelisted = await this._axiosClient!.get<boolean>('/flower/is-whitelist', {
      params: {
        walletAddress,
      },
    }).then((res) => res.data);

    return Right({
      isWhitelisted,
    });
  }

  async checkConfirmation(flowerOrderId: string): Promise<Either<ErrorDomain, MadHunnyConfirmationCheck | null>> {
    const status = await this._axiosClient!.get<string | null>('/flower-order/status-checking', {
      params: {
        flowerOrderId,
      },
    }).then((res) => res.data);

    return Right({
      status,
    });
  }

  async createBuyingSession(size: string, countryCode: string, walletAddress?: string): Promise<Either<ErrorDomain, string | null>> {
    const location = {
      countryCode,
    };

    const createStripeCheckoutUrl = await this._axiosClient!.post<string>('/flower/create-buying-session', {
      size,
      location,
      walletAddress,
    }).then((res) => res.data);

    return Right(createStripeCheckoutUrl);
  }

  set axiosClient(value: string) {
    this._axiosClient = axios.create({
      baseURL: `${value}/metakicks`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}
