import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { HalfArrowRightSvg } from '../../../icons/svg';

const lastestDrop = {
  image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/ice/desktop/images/laarvee-ice-desktop-header-2.webp',
  imageMobile: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/larvee/ice/mobile/images/laarvee-ice-mobile-header-2.webp',
  title: 'SNEAKMART X LAARVEE',
};

function HeroHeader() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className=" w-full overflow-hidden relative">
      <Link to="drop/laarvee/ice/">
        <div
          style={{
            backgroundImage: `${isTabletOrMobile ? (
              `url(${lastestDrop.imageMobile})`
            ) : (
              `url(${lastestDrop.image})`
            )}`,
          }}
          className="h-[80vh] md:h-[95vh] overflow-hidden rounded-b-xl bg-cover bg-no-repeat bg-center"
        >
          <div className="flex flex-col h-full justify-end text-gray-350 bg-gradient-to-t">
            <div className="flex flex-col mx-4 md:mx-16 2xl:mx-20 md:flex-row mb-4 gap-4 md:gap-12">
              <div className="w-full md:w-[75%]">
                <div className="font-grotesk font-bold text-xl md:text-4xl">
                  <span>{lastestDrop.title}</span>
                </div>
                <div className="font-inter font-medium text-md md:text-2xl">
                  <span>Sneakmart presents its 2nd collaboration with new generation watch maker, Laarvee.</span>
                </div>
              </div>
              <div className="flex md:justify-end md:items-end h-full w-full md:w-[25%]">
                <Link to="drop/laarvee/ice" className="w-full md:bg-opacity-90 bg-[#045285] md:bg-[#045285] hover:bg-white hover:text-gray-950 hover:bg-opacity-100 rounded-lg md:rounded-lg hover:scale-[98%] transition-transform duration-500">
                  <div className="flex flex-row justify-center items-center gap-4 font-grotesk backdrop-blur-0 hover:backdrop-blur-none overflow-hidden rounded-lg font-bold text-lg md:text-2xl px-4 py-2">
                    Visit Drop Page
                    <HalfArrowRightSvg height="20" width="20" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default HeroHeader;
