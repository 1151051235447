import { useEffect, useState } from 'react';

function preloadImage(
  src: string,
  onFinishLoading: () => void,
) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = img.onabort = () => {
      reject(src);
    };
    img.src = src;
  }).then(() => onFinishLoading());
}

export default function useImagePreloader(
  imageList: string[],
) {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);

  const onFinishLoading = () => {
    setProgress((v) => v + ((1 * 100) / imageList.length));
  };

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return;
      }

      const imagesPromiseList: Promise<any>[] = [];

      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i, onFinishLoading));
      }

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  return { progress, imagesPreloaded };
}
