import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Header from '../../_main/Header';
import Stacking from '../../components/Inventory/Stacking';
import ButtonNavigationInventory from '../../components/Inventory/ButtonNavigationInventory';
import NewFooter from '../../components/Home/Sections/NewFooter';
import Catalog from '../../components/Inventory/Catalog/Catalog';
import CatalogAwards from '../../components/Inventory/Catalog/CatalogAwards';
import PromoCode from '../../components/Inventory/PromoCode';

function Inventory() {
  const { t } = useTranslation();
  const location = useLocation();

  const [currentNavigation, setCurrentNavigation] = useState<'inventory' | 'stocking' | 'awards'>(
    'inventory',
  );

  useEffect(() => {
    setCurrentNavigation(
      location.hash === '#awards' ? 'awards' : location.hash === '#stocking' ? 'stocking' : 'inventory',
    );
  }, [location]);

  return (
    <div className="min-h-screen bg-black">
      <Header isEventOnScrollActivated />
      <div className="pt-12 md:pt-24 overflow-x-hidden">
        <div className="flex items-center justify-center w-full h-36 md:h-64 bg-[url('https://sneakmartbucket.s3.eu-west-3.amazonaws.com/inventory-hero.jpg')] bg-cover bg-center">
          <h1 className="text-5xl md:text-[144px] text-gray-350 font-extrabold">
            {t('menuInventory')}
          </h1>
        </div>
        {process.env.REACT_APP_SHOW_PROMO_CODE === 'true' && <PromoCode />}
        <div className="flex justify-center py-2 border-b-2 border-[#7F8080]">
          <ButtonNavigationInventory currentNavigation={currentNavigation} />
        </div>
        <div className="px-0">
          <div className="h-5" />
          <div className="m-auto max-w-screen-xl px-8 md:px-0 2xl:px-0 md:leading-none">
            {(currentNavigation === 'inventory' && process.env.REACT_APP_SHOW_COLLECTION === 'true') && <Catalog />}
          </div>
          <div className="m-auto max-w-screen-xl mx-5 md:mx-auto  md:px-0 2xl:px-0 md:leading-none">
            {currentNavigation === 'awards' && <CatalogAwards />}
          </div>
          {currentNavigation === 'stocking' && <Stacking />}
          <div className="px-10">
            <NewFooter />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
