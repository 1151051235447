import React from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import AutomaticPlayer from '../../_main/Video/AutomaticPlayer';

const videos = [
  {
    url: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/Portal-key-video-1.mp4',
  },
  {
    url: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/Portal-key-video-2.mp4',
  },
  {
    url: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/Portal-key-video-3.mp4',
  },
];

function PortalKeyVideoStories() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="flex flex-none md:flex-row justify-center items-center gap-0 md:gap-8 h-full mx-4 md:mx-20 pt-8 md:pt-4">
      { !isTabletOrMobile ? (
        videos.map((video, index) => (
          <div key={index} className={`rounded-lg aspect-story object-contain hover:scale-[98%] transition-transform duration-500 overflow-hidden ${index === 1 ? 'mt-20' : index === 2 ? 'mt-0' : 'mt-6'}`}>
            <AutomaticPlayer>
              <ReactPlayer
                height="100%"
                width="100%"
                loop
                playsinline
                muted
                url={video.url}
              />
            </AutomaticPlayer>
          </div>
        ))
      ) : (
        <div className="flex justify-center items-center rounded-lg aspect-story overflow-hidden hover:scale-[98%] transition-transform duration-500">
          <AutomaticPlayer>
            <ReactPlayer
              height="100%"
              width="500px"
              loop
              playsinline
              muted
              url={videos[2].url}
            />
          </AutomaticPlayer>
        </div>
      )}
    </div>
  );
}

export default PortalKeyVideoStories;
