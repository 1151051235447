import React from 'react';
import Header from '../../_main/Header';
import Head from '../../components/Home/Head';
import BackToTopButton from '../../_main/BackToTop';
import WhoWeAre from '../../components/Home/Sections/WhoWeAre';
import PreviousDrops from '../../components/Home/Sections/PreviousDrops';
import JoinCommunity from '../../components/Home/Sections/JoinCommunity';
import NewFooter from '../../components/Home/Sections/NewFooter';
import HeroHeader from '../../components/Home/Sections/HeroHeader';
import Advantages from '../../components/Home/Sections/Advantages';

function HomeLanding() {
  return (
    <div className="dark-scrollbar bg-black">
      <Head />
      <Header dark isEventOnScrollActivated />
      <HeroHeader />
      <div className="mx-4 md:mx-16 2xl:mx-20">
        <div className="h-8" />
        <WhoWeAre />
        <div className="h-16" />
        <Advantages />
        <div className="h-16" />
        <PreviousDrops />
        <div className="h-4" />
        <JoinCommunity />
        <NewFooter />
      </div>
      <BackToTopButton />
    </div>
  );
}

export default HomeLanding;
