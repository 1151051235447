import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowRightSvg } from '../../icons/svg';

const articles = [
  {
    id: 'id0',
    name: 'Forbes',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/press/Forbes.webp',
    link: 'https://www.forbes.fr/business/adr-metakicks-by-sneakmart-la-premiere-collection-de-sneakers-nft-sous-la-forme-de-boites-mysteres/',
  },
  {
    id: 'id1',
    name: 'Hyperbeast',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/press/Hypebeast.webp',
    link: 'https://hypebeast.com/2022/2/sneakmart-metaverse-metakicks-sneaker-nft-release-info',
  },
  {
    id: 'id2',
    name: 'cointribune',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/press/cointribune.webp',
    link: 'https://www.cointribune.com/tribunes/adopte-un-projet-crypto/metakicks-by-sneakmart-la-premiere-collection-de-sneakers-nft-sous-la-forme-de-boites-mysteres/',
  },
  {
    id: 'id3',
    name: 'hackernoon',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/press/hackernoon_1.webp',
    link: 'https://hackernoon.com/why-nike-adidas-rkft-and-sneakmart-are-tokenizing-sneakers',
  },
  {
    id: 'id4',
    name: 'Investing.com',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/press/Investing_com.webp',
    link: 'https://www.investing.com/news/cryptocurrency-news/sneakmart-to-launch-firstever-line-of-nft-mystery-sneaker-boxes-2746071',
  },
  {
    id: 'id5',
    name: 'wagmi trends',
    image: 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/press/wagmi-trends.webp',
    link: 'https://www.wagmitrends.com/wagmi-talk/sneakers-et-web-3/',
  },
];

function Press() {
  const { t } = useTranslation();

  return (
    <div
      className="w-screen bg-gray-950 py-8 md:py-10"
    >
      <div className="max-w-screen-xl m-auto px-8 lg:px-16 2xl:px-0">
        <h1 className="font-extrabold text-gray-350 text-[50px] lg:text-[100px] 2xl:text-[140px]">
          {t('press')}

        </h1>
        <div className="grid grid-cols-2 lg:grid-cols-6 gap-3 lg:gap-5">
          {articles.map((item) => (
            <div key={item.id} className="mb-3">
              <button type="button" className="w-full border-2 border-black 0 flex items-center justify-start duration-300 ease-in-out hover:drop-shadow-[0_0_0.2rem_black]">
                <a
                  target="_blank"
                  href={item.link}
                  rel="noreferrer"
                >
                  <img alt={item.name} src={item.image} />
                </a>
              </button>
              <button
                type="button"
                className="flex w-full cursor-pointer mt-3 md:mt-6 flex-row border-2 rounded-md duration-300 ease-in-out group hover:bg-gray-350 border-gray-350"
              >
                <a
                  target="_blank"
                  href={item.link}
                  rel="noreferrer"
                  className="w-full flex flex-row items-center justify-start gap-5 px-3 py-1"
                >
                  <p className="text-gray-350 group-hover:text-gray-950 leading-5 md:leading-7 font-extrabold text-[12px] md:text-[16px]">
                    {t('pressRead')}

                  </p>
                  <ArrowRightSvg className="fill-gray-350 group-hover:fill-gray-950 md:justify-end" />
                </a>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Press;
