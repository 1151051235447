import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Button, { TextSize } from '../../../_main/Form/Button';

const metakicks1906DColorways = [

  {
    id: '1',
    colorway: 'sakura',
    shoeSize: 44,
  },
  {
    id: '2',
    colorway: 'mocha',
    shoeSize: 44,
  },
  {
    id: '3',
    colorway: 'aqua',
    shoeSize: 47.5,
  },
  {
    id: '4',
    colorway: 'sakura',
    shoeSize: 42.5,
  },
  {
    id: '5',
    colorway: 'sakura',
    shoeSize: 46.5,
  },
  {
    id: '6',
    colorway: 'aqua',
    shoeSize: 44.5,
  },
  {
    id: '7',
    colorway: 'aqua',
    shoeSize: 43,
  },
  {
    id: '8',
    colorway: 'aqua',
    shoeSize: 42,
  },
  {
    id: '9',
    colorway: 'sakura',
    shoeSize: 44,
  },
  {
    id: '10',
    colorway: 'aqua',
    shoeSize: 47,
  },
  {
    id: '11',
    colorway: 'sakura',
    shoeSize: 44,
  },
  {
    id: '12',
    colorway: 'aqua',
    shoeSize: 44,
  },
  {
    id: '13',
    colorway: 'sakura',
    shoeSize: 42,
  },
  {
    id: '14',
    colorway: 'aqua',
    shoeSize: 44,
  },
  {
    id: '15',
    colorway: 'aqua',
    shoeSize: 42,
  },
  {
    id: '16',
    colorway: 'aqua',
    shoeSize: 45,
  },
  {
    id: '17',
    colorway: 'mocha',
    shoeSize: 42,
  },
  {
    id: '18',
    colorway: 'aqua',
    shoeSize: 42,
  },
  {
    id: '19',
    colorway: 'aqua',
    shoeSize: 40,
  },
  {
    id: '20',
    colorway: 'aqua',
    shoeSize: 44,
  },
  {
    id: '21',
    colorway: 'sakura',
    shoeSize: 41.5,
  },
  {
    id: '22',
    colorway: 'mocha',
    shoeSize: 43,
  },
  {
    id: '23',
    colorway: 'sakura',
    shoeSize: 43,
  },
  {
    id: '24',
    colorway: 'mocha',
    shoeSize: 38.5,
  },
  {
    id: '25',
    colorway: 'mocha',
    shoeSize: 38.5,
  },
];

interface Props {
  metakicksStaniflouId: string
}
function MetakicksStaniflouDisplay({ metakicksStaniflouId }: Props) {
  let nftUrl: string;

  const nftColorway = metakicks1906DColorways[Number(metakicksStaniflouId) - 1].colorway;
  const nftShoeSize = metakicks1906DColorways[Number(metakicksStaniflouId) - 1].shoeSize;

  switch (nftColorway) {
    case 'mocha':
      nftUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-COFFEESCENE.mp4';
      break;
    case 'sakura':
      nftUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-CHERRY.mp4';
      break;
    case 'gummy':
      nftUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-JADED.mp4';
      break;
    case 'matcha':
      nftUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-ROCKY.mp4';
      break;
    case 'aqua':
      nftUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-DESERT.mp4';
      break;
    default:
      nftUrl = 'https://sneakmartbucket.s3.eu-west-3.amazonaws.com/customs/stan/videos/square/STAN-x-SNEAKMART-COFFEESCENE.mp4';
      break;
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
      <div className="aspect-square bg-gray-200 w-full">
        <ReactPlayer
          url={nftUrl}
          height="100%"
          width="100%"
          playing
          loop
          muted
          playsinline
        />
      </div>
      <div className="flex flex-col justify-between gap-6 lg:gap-3">
        <h1 className="text-3xl md:text-5xl uppercase text-gray-350 leading-10 lg:leading-[80px] font-extrabold">
          Sneakmart x Staniflou 1906D
          #
          {metakicksStaniflouId}
        </h1>
        <p className="text-gray-350 font-inter font-medium text-lg md:text-xl">
          The Sneakmart 1906D is a special collaboration crafted with french sneaker artist Stan Birch (Staniflou), a tribute to creativity and the future of Streetwear culture.
        </p>
        <div>
          <h1 className="text-2xl font-bold">Product details:</h1>
          <div className="text-lg md:text-xl font-medium">
            <p>
              Token ID:
              {' '}
              {metakicksStaniflouId}
            </p>
            <p>
              Colorway:
              {' '}
              {/* // add the nftColorway with a capital first letter */}
              {nftColorway.charAt(0).toUpperCase() + nftColorway.slice(1)}
            </p>
            <p>
              Shoe size:
              {' '}
              {nftShoeSize}
            </p>
          </div>
        </div>
        <div className="w-full ">
          <Link to="/inventory">
            <Button textSize={TextSize.Small} text="Go to your inventory" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MetakicksStaniflouDisplay;
