import { InventoryGateway } from '../../gateways/inventoryGateway';
import { ThunkResult } from '../../../store';
import * as actionCreators from './actionCreators';
import { EthereumWalletGateway } from '../../gateways/ethereumWalletGateway';
import { retrieveEthereumWallet } from '../ethereum-wallet-retrieval/retrieveEthereumWallet';
import { Error } from '../../../error';
import { Integration } from '../../../appState';

export const retrieveInventory: ThunkResult<Promise<void>> = async (
  dispatch,
  getState,
  { inventoryGateway }: {
    inventoryGateway: InventoryGateway,
    ethereumWalletGateway: EthereumWalletGateway,
  },
) => {
  dispatch(actionCreators.Actions.retrieveInventory());

  if (getState()?.ethereumWallet?.integration === Integration.Metamask || getState()?.ethereumWallet?.integration === Integration.WalletConnect || getState()?.ethereumWallet?.integration === Integration.Crossmint) {
    dispatch(retrieveEthereumWallet(getState().ethereumWallet!.integration));
  } else {
    dispatch(actionCreators.Actions.retrieveInventoryError(Error.NeedToChooseAConnectionWallet));
    return;
  }

  const { ethereumWallet } = getState();

  const inventory = await inventoryGateway.retrieve(ethereumWallet!.signerAddress);
  dispatch(actionCreators.Actions.inventoryRetrieved(inventory));
};
