import { ActionsUnion, createAction } from '../../../store';
import { Stacking } from '../../../appState';

export const Actions = {
  retrieveStacking: () => createAction('RETRIEVE_STACKING'),
  stackingRetrieved: (stacking: Stacking | null) => createAction('STACKING_RETRIEVED', { stacking }),

};

export type Actions = ActionsUnion<typeof Actions>
