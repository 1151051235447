import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../_main/Header';
import MetakicksStaniflouNotFound from '../../../components/Collab/Check/MetakicksStaniflouNotFound';
import MetakicksStaniflouDisplay from '../../../components/Collab/Check/MetakicksStaniflouDisplay';
import NewFooter from '../../../components/Home/Sections/NewFooter';

type UrlParams = 'metakicksStaniflouId'

function MetakicksStaniflouItem() {
  const urlParams = useParams<Record<UrlParams, string>>();
  const metakicksStaniflouId = urlParams.metakicksStaniflouId || '';

  return (
    <div className="w-screen min-h-screen bg-black text-gray-350">
      <Header dark />
      <div className="pt-[80px] overflow-x-hidden">
        <div className="m-auto max-w-screen-xl px-8 lg:px-16 2xl:px-0 py-8 md:py-20">
          <div>
            <div>
              <h1 className="text-3xl md:text-7xl mb-6 font-extrabold">
                MY SNEAKMART x STANIFLOU
              </h1>
              {Number(metakicksStaniflouId) < 1 || Number(metakicksStaniflouId) > 25 ? (
                <MetakicksStaniflouNotFound />
              ) : (
                <MetakicksStaniflouDisplay metakicksStaniflouId={metakicksStaniflouId} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 md:px-10">
        <NewFooter />
      </div>
    </div>
  );
}

export default MetakicksStaniflouItem;
