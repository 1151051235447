import { ActionsUnion, createAction } from '../../../store';
import { Inventory, ErrorDomain } from '../../../appState';

export const Actions = {
  retrieveInventoryError: (error: ErrorDomain) => createAction('RETRIEVE_INVENTORY_ERROR', { error }),
  retrieveInventory: () => createAction('RETRIEVE_INVENTORY'),
  inventoryRetrieved: (inventory: Inventory | null) => createAction('INVENTORY_RETRIEVED', { inventory }),
};

export type Actions = ActionsUnion<typeof Actions>
